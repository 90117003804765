import { Button, Divider, Drawer, message, PageHeader, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import TemplateTable from "components/template/TemplateTable";
import TemplateForm from "components/template/TemplateForm";
import useCategoryStore from "stores/categoryStore";
import axios from "axios/axios";
import useTemplateStore from "stores/templateStore";
import authHeader from "utils/services/auth-header";
import CategoryApi from "utils/composable/categoryApi";
import TemplateApi from "utils/composable/templateApi";

const Template = ({currentUser}) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const categories = useCategoryStore((state) => state.categories);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState("");
  const [tableLoading, setTableLoadings] = useState(false);
  //category store
  const { setCategories } = useCategoryStore((state) => ({
    setCategories: state.setCategories,
  }));
  //template store
  const templates = useTemplateStore((state) => state.templates);

  //notification
  const templateAdded = (msg) => {
    message.success(msg);
  };
  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  const { getCategories } = CategoryApi();
  const { addTemplate, getTemplates, updateTemplates } = TemplateApi();

  useEffect(() => {
    if (!categories.length) {
      getCategories();
    }

    if (!templates.length) {
      setTableLoadings(true);
      try {
        getTemplates()
          .then(() => {
            setTableLoadings(false);
          })
          .catch((err) => {
            console.log(err);
            setTableLoadings(false);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const submitHandler = (values, form) => {
    const payload = {
      name: values.name,
      category: values.category,
      fields: values.fields,
    };
    setSubmitLoader(true);
    try {
      addTemplate(payload)
        .then((msg) => {
          setSubmitLoader(false);
          setVisible(false);
          templateAdded(msg);
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          setSubmitLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const editHandler = (template) => {
    setEditData(template);
    setVisible(true);
  };

  const confirmEditHandler = (values, form) => {
    const updatePayload = {
      template_id: values._id,
      name: values.name,
      category: values.category,
      fields: values.fields,
    };
    setSubmitLoader(true);
    try {
      updateTemplates(updatePayload)
        .then((msg) => {
          setSubmitLoader(false);
          setVisible(false);
          editSuccessful(msg);
          form.resetFields();
        })
        .catch((msg) => {
          console.log(msg);
          setSubmitLoader(false);
          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setEditData("");
    setVisible(false);
  };

  const addNewTemplate = () => {
    setEditData(false);
    setVisible(true);
  };

  //Refresh
  const [refreshLoading, setRefreshLoading] = useState(false);

  const refresh = () => {
    setRefreshLoading(true);

    getCategories();
    getTemplates.finally(() => {
      setRefreshLoading(false);
    });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Template"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />
      <Divider orientation="center">
        <Button disabled={!currentUser.privileges.includes('product_management')} type="primary" onClick={addNewTemplate} icon={<PlusOutlined />}>
          Add Template
        </Button>
      </Divider>

      <Skeleton active loading={tableLoading}>
        <TemplateTable  currentUser={currentUser}  editHandler={editHandler} />
      </Skeleton>

      <Drawer
        destroyOnClose={true}
        key={editData.id}
        size="large"
        title={editData ? "Edit Product Template" : "Add Product Template"}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <TemplateForm
          editData={editData}
          submitHandler={submitHandler}
          submitLoader={submitLoader}
          setVisible={setVisible}
          confirmEditHandler={confirmEditHandler}
        />
      </Drawer>
    </>
  );
};

export default Template;
