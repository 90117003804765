import Invoice from "pages/payment/invoice";
import create from "zustand";
import { devtools } from "zustand/middleware";

const paymentStore = (set) => ({
  payments: [],

  setPayments: (apiPayments) => {
    set((state) => ({
      payments: [...apiPayments],
    }));
  },

  updatePayments: (editData) => {
    set((state) => ({
      payments: state.payments.map((payment) => {
        if (payment._id === editData._id) {
          return {
            ...editData,
            client: payment.client,
          };
        } else {
          return payment;
        }
      }),
    }));
  },
  addPayment: (newpayment) => {
    set((state) => ({
      payments: [newpayment, ...state.payments],
    }));
  },


  changePaymentStatus: (status, paymentIds, invoice) => {

    set((state) => ({
      payments: state.payments.map((payment) => {
        if (paymentIds.includes(payment._id)) {
            if (Invoice) {
            return {
              ...payment,
              status: status,
              invoice: invoice,
            };
          } else {
            return {
              ...payment,
              status: status,
            };
          }
        } else {
          return payment;
        }
      }),
    }));
  },
});

const usePaymentStore = create(devtools(paymentStore));

export default usePaymentStore;
