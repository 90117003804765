import { Button, Divider, message, Modal, PageHeader, Skeleton } from "antd";
import VendorTable from "components/vendor/VendorTable";
import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import useVendorStore from "stores/vendorStore";
import VendorApi from "utils/composable/vendorApi";
import VendorForm from "components/vendor/VendorForm";

const Vendor = ({ currentUser }) => {
  const [visible, setVisible] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [tableLoading, setTableLoadings] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [editData, setEditData] = useState("");
  const [refreshLoading, setRefreshLoading] = useState(false);

  //VendorStore
  const vendors = useVendorStore((state) => state.vendors);

  const { addVendor, updateVendor, toggleVendor, getVendors } = VendorApi();

  //notification
  const vendorAdded = (msg) => {
    message.success(msg);
  };
  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  const addNewVendor = () => {
    setEditData(false);
    showModal();
  };

  const handleCancel = () => {
    setEditData("");
    setVisible(false);
    setName("");
    setDescription("");
  };

  const toggleEditMode = (id) => {
    const editData = vendors.find((cat) => cat._id === id);
    setEditData(editData);
    console.log({ editData });
    setVisible(true);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const submitHandler = (values, form) => {
    setLoadings(true);

    try {
      addVendor(values)
        .then((msg) => {
          setLoadings(false);
          setVisible(false);
          vendorAdded(msg);
          form.setFieldsValue({
            name: "",
            email: "",
            phone: "",
            gstin: "",
            vat: "",
            is_active: true,
            pan: "",
            address: "",
            notes: "",
            state: "Karnataka",
            city: editData.city || "",
          });
        })
        .catch((msg) => {
          setLoadings(false);
          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const editHandler = (values, form) => {
    setLoadings(true);

    try {
      updateVendor(values)
        .then((msg) => {
          setLoadings(false);
          setVisible(false);
          editSuccessful(msg);
          form.resetFields();
        })
        .catch((msg) => {
          setLoadings(false);
          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!vendors.length) {
      setTableLoadings(true);

      getVendors()
        .then((response) => {
          setTableLoadings(false);
        })
        .catch((err) => {
          setTableLoadings(false);
        });
    }
  }, []);

  const refresh = () => {
    setRefreshLoading(true);

    getVendors().finally(() => {
      setRefreshLoading(false);
    });
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Vendor"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />

      <Divider orientation="center">
        <Button
          hidden={!currentUser.user.privileges.includes("vendor_creation")}
          type="primary"
          icon={<PlusOutlined />}
          onClick={addNewVendor}
        >
          Add Vendor
        </Button>
      </Divider>

      {tableLoading ? (
        <Skeleton active loading={tableLoading} />
      ) : (
        <VendorTable
          currentUser={currentUser.user}
          toggleEditMode={toggleEditMode}
          toggleStatus={toggleVendor}
        />
      )}

      <Modal
        destroyOnClose={true}
        width={1000}
        footer
        title={editData ? "Edit Vendor Details" : "Enter Vendor Details"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <VendorForm
          submitHandler={submitHandler}
          submitLoading={loadings}
          confirmEditHandler={editHandler}
          name={name}
          description={description}
          editData={editData}
          handleCancel={handleCancel}
        />
      </Modal>
    </div>
  );
};

export default Vendor;
