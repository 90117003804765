import "App.less";

import {
  BankOutlined,
  BarcodeOutlined,
  DeleteOutlined,
  DesktopOutlined,
  DollarCircleOutlined,
  LineChartOutlined,
  SolutionOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import getCurrentUser from "utils/services/get-user";
const { Sider } = Layout;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [path, setPath] = useState(location.pathname);
  const user = getCurrentUser();

  const items = [
    getItem("Dashboard", "/", <LineChartOutlined />),

    user?.user?.privileges?.includes("plan_view") &&
      getItem("Plan", "/plan", <DollarCircleOutlined />),
    user?.user?.privileges?.includes("task_view") &&
      getItem("Task", "/task", <SolutionOutlined />),

      getItem("Team", "/team", <TeamOutlined />),
    user?.user?.privileges?.includes("client_view") &&
      getItem("Client", "/client", <UserOutlined />),
    user?.user?.privileges?.includes("product_view") &&

    user?.user?.privileges?.includes("vendor_view") &&
    getItem("Vendor", "/vendor", <UserSwitchOutlined/>),

    user?.user?.privileges?.includes("product_view") &&  getItem("Product", "sub1", <DesktopOutlined />, [
        getItem("Products", "/products/products"),
        getItem("Category", "/product/category"),
        getItem("Template", "/product/template"),
  
    
      ]),

    user?.user?.privileges?.includes("payment_view") &&   getItem("Payment", "sub2", <DollarCircleOutlined />, [
      getItem("Taxes", "/payment/tax"),
      getItem("Payments", "/payment/payments"),
        getItem("Invoice", "/payment/invoice"),
         getItem("Receipts", "/payment/receipts")


    ]),

      
    user?.user?.privileges?.includes("stand_by_product_view") &&    getItem("Standby Product", "/products/product-request", <DesktopOutlined />),
    user?.user?.privileges?.includes("defective_products_view") && getItem("Defective Products", "/products/defective-products", <DesktopOutlined />),
    user?.user?.privileges?.includes("product_view")&& getItem("Rent", "/product/rent", <DesktopOutlined />),
    user?.user?.privileges?.includes("product_view") &&  getItem("Sales", "/product/sales", <DesktopOutlined />),
    user?.user?.privileges?.includes("warehouse_view") &&  getItem("Warehouse", "/warehouse", <BankOutlined />),
    user?.user?.privileges?.includes("task_view") &&  getItem("Maintenance", "/maintenance", <ToolOutlined /> ),
    getItem("Barcode", "/barcode", < BarcodeOutlined  />),


    user?.user?.privileges?.includes("product_view") &&
    getItem("Bin", "/bin-products",<DeleteOutlined />),
    user?.user?.privileges?.includes("task_view") &&
      getItem("Historical Tasks", "/historical-task", <SolutionOutlined />),
    user?.user?.privileges?.includes("stand_by_product_view") &&    getItem("Historical Standby Requests", "/products/historical-product-request", <DesktopOutlined />),
 

  ];



  const changeMenu = (e) => {

      navigate(e.key);
  
  };

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  
  useEffect(() => {

    setPath(location?.pathname);

  }, [location]);


  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="logo" />

      <Menu
        onClick={changeMenu}
        theme="dark"
        selectedKeys={[path]}
        defaultSelectedKeys={["/"]}
        mode="inline"
        items={items}
      ></Menu>
    </Sider>
  );
};





export default Sidebar;
