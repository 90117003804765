import { Button, Form, Input, Radio, message } from "antd";
import ForgotPassSVG from "assets/ForgotPassSVG";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TeamApi from "utils/composable/teamApi";
import "./login.css";

const PasswordReset = () => {
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const { forgotPassword } = TeamApi();

  const loginError = (msg) => {
    message.error(msg);
  };

  

  const onFinish = (values) => {
    console.log("Success:", values);
    setloading(true);
    const selectedBranchBaseUrl =
    values.branch === "Chennai"
      ? process.env.REACT_APP_CHENNAI_BASEURL
      : process.env.REACT_APP_BANGALORE_BASEURL;

  localStorage.setItem("baseURL", selectedBranchBaseUrl);

    forgotPassword(values.email).finally(() => {
      setloading(false);

      navigate("/login");
    });

    // submitHandler(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  return (
    <div className="loginContainer">
      <Form
        className="loginForm"
        autoComplete="off"
        name="passawordForm"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
          email: "",
          password: "",
          branch: 'Bangalore'
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div style={{ marginLeft: "3rem", padding: "1rem" }}>
          <ForgotPassSVG />
        </div>
        <h1 style={{ fontWeight: "bold", marginLeft: "3rem" }}>
          Reset Password
        </h1>
        <Form.Item
          label="Select Branch"
          name="branch"
          rules={[{ required: true, message: "Please select a branch!" }]}
        >
          <Radio.Group>
            <Radio value="Bangalore">Bangalore</Radio>
            <Radio value="Chennai">Chennai</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          style={{ marginTop: "1rem", marginLeft: "1rem" }}
          label="Enter Your Registered Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email Id!",
            },
          ]}
        >
          <Input autoComplete="off" type={"email"} style={{ width: "300px" }} />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button
            style={{ marginLeft: "-2rem", width: "6rem" }}
            loading={loading}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordReset;
