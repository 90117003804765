import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Divider, Drawer, message, PageHeader, Skeleton } from "antd";
import { useEffect, useState } from "react";

import ProductForm from "components/Product/ProductForm";
import useCategoryStore from "stores/categoryStore";

import ProductsTable from "components/Product/ProductTable";
import useVendorStore from "stores/vendorStore";
import useWarehouseStore from "stores/warehouseStore";
import productApi from "utils/composable/productsApi";
import VendorApi from "utils/composable/vendorApi";
import WareHouseApi from "utils/composable/warehouseApi";

const AddProduct = ({ currentUser }) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const categories = useCategoryStore((state) => state.categories);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState("");
  const [tableLoading, setTableLoadings] = useState(false);
  const [duplicate, setDuplicate] = useState(false);

  const [wareHouseLoading, setWareHouseLoading] = useState(false);
  const [vendorsLoading, setVendorsLoading] = useState(false);
  //category store
  const { setCategories } = useCategoryStore((state) => ({
    setCategories: state.setCategories,
  }));
  const warehouses = useWarehouseStore((state) => state.warehouses);
  const vendors = useVendorStore((state) => state.vendors);
  const { addProduct, getProducts, updateProducts } = productApi();
  const { getWarehouse } = WareHouseApi();
  const { getVendors } = VendorApi();

  //notification
  const productAdded = (msg) => {
    message.success(msg);
  };
  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  useEffect(() => {
    setTableLoadings(true);
    getProducts().then((resp) => {
      setTableLoadings(false);
    });

    if (!warehouses.length) {
      setWareHouseLoading(true);

      getWarehouse().finally(() => {
        setWareHouseLoading(false);
      });
    }

    if (!vendors.length) {
      setVendorsLoading(true);

      getVendors().finally(() => {
        setVendorsLoading(false);
      });
    }
  }, []);

  const submitHandler = (payload, form) => {
    setSubmitLoader(true);
    try {
      addProduct(payload)
        .then((msg) => {
          setSubmitLoader(false);
          setVisible(false);
          productAdded(msg);
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          setSubmitLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const editHandler = (product, duplicate) => {
    if (duplicate) {
      setDuplicate(true);
    } else {
      setDuplicate(false);
    }
    setEditData(product);
    setVisible(true);
  };

  const confirmEditHandler = (updatePayload, form) => {
    setSubmitLoader(true);

    try {
      updateProducts(updatePayload)
        .then((msg) => {
          setSubmitLoader(false);
          setVisible(false);
          editSuccessful(msg);
          form.resetFields();
        })
        .catch((msg) => {
          console.log(msg);
          setSubmitLoader(false);
          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setEditData("");
    setVisible(false);
  };

  const addNewProduct = () => {
    setEditData(false);
    setVisible(true);
  };

  //Refresh
  const [refreshLoading, setRefreshLoading] = useState(false);

  const refresh = () => {
    setRefreshLoading(true);

    getProducts().finally(() => {
      setRefreshLoading(false);
    });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Products"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />
      <Divider orientation="center">
        <Button
          disabled={!currentUser.privileges.includes("product_creation")}
          type="primary"
          onClick={addNewProduct}
          icon={<PlusOutlined />}
        >
          Add Product
        </Button>
      </Divider>

      <Skeleton active loading={tableLoading}>
        <ProductsTable currentUser={currentUser} editHandler={editHandler} />
      </Skeleton>

      <Drawer
        destroyOnClose={true}
        key={editData?._id}
        size="large"
        title={
          editData && !duplicate
            ? "Edit Product "
            : editData && duplicate
            ? "Add Product "
            : "Add product"
        }
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <ProductForm
          wareHouseLoading={wareHouseLoading}
          vendorsLoading={vendorsLoading}
          duplicate={duplicate}
          editData={editData}
          submitHandler={submitHandler}
          submitLoader={submitLoader}
          setVisible={setVisible}
          confirmEditHandler={confirmEditHandler}
        />
      </Drawer>
    </>
  );
};

export default AddProduct;
