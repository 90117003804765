import { message } from "antd";
import axios from "axios/axios";
import useWarehouseStore from "stores/warehouseStore";
import authHeader from "utils/services/auth-header";


const WareHouseApi = () => {
    const {
       addWarehousesToStore,
       setWarehousesInStore,
        updateWarehousesInStore,
        setWarehouseStockInStore,
         updateWarehouseStock
    } = useWarehouseStore((state) => ({
        addWarehousesToStore: state.addWarehouse,
        setWarehousesInStore: state.setWarehouse,
        updateWarehousesInStore: state.updateWarehouse,
        setWarehouseStockInStore:state.setWarehouseStock,
         updateWarehouseStock:state.updateWarehouseStock
       
    }));

    const warehouses = useWarehouseStore((state) => state.warehouses);

    const addWarehouse = async (data) => {
       
        return new Promise((resolve, reject) => {
            axios
                .post("/warehouse", data, {
                    headers: authHeader(),
                })
                .then((resp) => {
          
                    addWarehousesToStore(resp.data.warehouse);
                    resolve(resp.data.message);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error.response.data.message);
                });
        });
    };

    const getWarehouse = async () => {
        if (!warehouses.length) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/warehouses", { headers: authHeader() })
                    .then((response) => {

                        setWarehousesInStore(response.data.warehouses);
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err);
                        message.error(err.response.data.message)
                        reject(err.response.data.message);
                    });
            });
        }
    };


    const getWarehouseStock = async (wareHouseId) => {

      
            return new Promise((resolve, reject) => {
                axios
                    .get("/warehouse-stocks?warehouse_id="+wareHouseId,{ headers: authHeader() })
                    .then((response) => {

                   
                        setWarehouseStockInStore(response.data.stocks);
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err);
                        message.error(err.response.data.message)
                        reject(err.response.data.message);
                    });
            });
        
    };

    const updateWarehouse = async (data) => {
        return new Promise((resolve, reject) => {

       
            axios
                .put("/warehouse", data, { headers: authHeader() })
                .then((resp) => {
                 
                    updateWarehousesInStore(resp.datastocks);
                    resolve(resp.data.message);
                })
                .catch((err) => {
                    console.log(err);
                    message.error(err.response.data.message)
                    reject(err.response.data.message);
                });
        });
    };



    const closeStandByrequest = async (requestId) => {

        return new Promise((resolve, reject) => {

           

            axios.post("/stand-by-product/closed", {request_id: requestId}, { headers: authHeader() })
                .then((resp) => {
                
                    resolve(resp.data.message);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err.response.data.message);
                });
        });
    };


    const receiveProductToWarehouse = async (productId, category) => {

        return new Promise((resolve, reject) => {

        

            axios.post("/warehouse/product-received", { product_id: productId }, { headers: authHeader() })
                .then((resp) => {
           
                    resp.data.product.category = category
                    updateWarehouseStock(resp.data.product);
                    resolve(resp.data.message);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err.response.data.message);
                });
        });
    };



    return { addWarehouse, closeStandByrequest, getWarehouse, updateWarehouse, getWarehouseStock, receiveProductToWarehouse };
};

export default WareHouseApi;
