import {
  AccountBookOutlined,
  BankOutlined,
  CheckOutlined,
  ContactsOutlined,
  DeleteOutlined,
  EyeOutlined,
  FileTextOutlined,
  MoreOutlined,
  SearchOutlined,
  ShrinkOutlined,
  UndoOutlined
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Tooltip
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDefectiveProductStore from "stores/defectiveProductStore";
import useProductStore from "stores/productStore";
import useVendorStore from "stores/vendorStore";
import useWarehouseStore from "stores/warehouseStore";
import DefectiveProductApi from "utils/composable/defectivePorductApi";
import ProductApi from "utils/composable/productsApi";
import VendorApi from "utils/composable/vendorApi";
import WareHouseApi from "utils/composable/warehouseApi";
import humanize from "utils/formate-status";
import "./defectiveTableStyle.css";
const ReceivedDefectiveProductTable = ({
  changeDefectiveProductStatus,
  assignToVendor,
  editHandler,
  noteSubmitHandler,
  noteModalVisible,
  setNoteModalVisible,
  noteLoading,
  createDeliveryJob,
  setFullNoteModalVisible,
  setCurrentNotes,
  setCurrentProductId,
  currentProductId,
  confirmDefectiveEditHandler,
  submitLoader,
  setCurrentDefectiveProduct,
  currentDefectiveProduct,
  setCostModalVisible,
  currentUser,
}) => {
  const navigate = useNavigate();
  const { moveToBinApi, moveToWarehouseApi, switchProductApi } =
    DefectiveProductApi();
  const [value, setCurrentValue] = useState("");
  const [form] = Form.useForm();
  const [switchModal, setSwitchModalVisible] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const { getProducts } = ProductApi();
  const [currentReceiveButton, setCurrentReceiveButton] = useState();
  const [switchProductList, setSwitchProductList] = useState([]);
  const [switchingProduct, setSwitchingProduct] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [warehouseModal, setWarehouseModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [movedFromDefective, setMovedFromDefective] = useState(currentDefectiveProduct?.damagedProductMovedFromDefective? true:false)
  const showNotes = () => {
    setFullNoteModalVisible(true);
  };
  const { getWarehouse } = WareHouseApi();
  const defectiveProducts = useDefectiveProductStore(
    (state) => state.defectiveProducts
  );

  const products = useProductStore((state) => state.Products);
  const warehouses = useWarehouseStore((state) => state.warehouses);

  const ReceivedDefectiveProducts = defectiveProducts.filter(
    (product) => product.status != "picked-up" && product.status != "created"
  );

  const { getVendors } = VendorApi();

  useEffect(() => {
    if (!vendors.length) getVendors();
    if (!products.length) getProducts();
    if (!warehouses.length) getWarehouse();

    let saleProductListForSwitching = products.filter(
      (product) => product.use == "sale" && product.status == "in_stock"
    );

    let switchProductList = saleProductListForSwitching.map((product) => {
      return {
        label: `${product.name}`,
        value: product._id,
        key: product._id,
      };
    });

    console.log({ switchProductList, saleProductListForSwitching });

    setSwitchProductList(switchProductList);
  }, []);





  useEffect(() => {
    console.log({currentDefectiveProduct});
  
  }, [currentDefectiveProduct]);



  useEffect(() => {
    console.log("screen refresh");
    setRefreshed(!refreshed);
  }, [defectiveProducts]);

  const vendors = useVendorStore((state) => state.vendors);

  const vendorsList = vendors.map((vendor) => {
    return {
      label: vendor.name,
      value: vendor._id,
    };
  });

  const handleRadioChange = (id) => {
    setSelectedWarehouse(id);
  };

  const handleChange = (value, defectiveProduct) => {
    setCurrentDefectiveProduct(defectiveProduct);

    const handleOkay = (defectiveProduct) => {
      assignToVendor(value, defectiveProduct);
    };

    Modal.confirm({
      title: "Confirm",

      content: "Assign to vendor ?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => handleOkay(defectiveProduct),
    });
  };

  const moveToBin = (product) => {
    console.log(product, "product");

    setModalLoading(true);

    Modal.confirm({
      title: "Confirm",
      content: "Move Damaged Product to Bin Before Switching?",
      okText: "Yes",
      cancelText: "Cancel",
      loading: modalLoading,
      onOk: () => {
        moveToBinApi(product, switchingProduct).then(() => {
          setMovedFromDefective(true)
        
          setModalLoading(false);
          Modal.destroyAll();
        }).catch(()=>{
            setModalLoading(false);
          setMovedFromDefective(true)

        });
      },
    });
  };

  const switchProduct = (product) => {

    console.log({ product});
    setSwitchingProduct(true);
    setMovedFromDefective(product?.damagedProductMovedFromDefective?true:false)
    setCurrentDefectiveProduct(product);
    setSwitchModalVisible(true);
  };

  const switchCurrentDefectiveProduct = (product) => {
    // setCurrentDefectiveProduct(product)

    console.log(product);

    Modal.confirm({
      title: "Confirm",
      content: "Switch this Product?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {switchProductApi(product, currentDefectiveProduct).then(()=>{

        Modal.destroyAll()
        setSwitchModalVisible(false)

      })},
      onCancel: () => handleCancel(),
    });
  };

  const moveToWarehouse = (product, switchingProduct) => {
    console.log(product, "product");

    setCurrentDefectiveProduct(product);
    setWarehouseModal(true);
  };

  const humanizLocal = (str) => {
    var i,
      frags = str.split("-");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  };

  //for In Progress

  const handleCancel = (defectiveProduct) => {
    //     document.getElementById(req._id).innerHTML=req.product.name

    //   console.log("Clicked cancel button insode");

    var name = `product${currentDefectiveProduct?._id}`;

    var status = humanize(currentDefectiveProduct?.status);

    form.setFieldsValue({ [currentDefectiveProduct._id]: status });

    // setCurrentReq(request)

    // setOpen(false);
  };

  const handleStatusChange = (value, defectiveProduct) => {
    setCurrentDefectiveProduct(defectiveProduct);
    setCurrentValue(value);

    console.log({ value });

    const handleOkay = (defectiveProduct) => {
      changeDefectiveProductStatus(value, defectiveProduct);
    };

    const handleCancel = () => {
      //     document.getElementById(req._id).innerHTML=req.product.name

      //   console.log("Clicked cancel button insode");

      var name = `product${defectiveProduct._id}`;

      var status = humanize(defectiveProduct.status);

      form.setFieldsValue({ [defectiveProduct._id]: status });

      // setCurrentReq(request)

      // setOpen(false);
    };

    Modal.confirm({
      title: "Confirm",
      content: "Change Status ?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => handleOkay(defectiveProduct),
    });
  };

  const showVendorData = (vendorDetails) => {
    Modal.info({
      title: "Contact Details",
      content: (
        <div>
          <Divider> </Divider>
          {
            <>
              <>
                <p>{vendorDetails?.vendor?.name}</p>
                <p>{vendorDetails?.vendor?.phone}</p>
                <p>{vendorDetails?.vendor?.email}</p>
              </>
            </>
          }
        </div>
      ),
      onOk() {},
    });
  };

  //Fuzzy filter

  const [filteredData, setFilteredData] = useState(false);
  const [currentQuery, setCurrentQuery] = useState("");

  const fuzzyFilter = (query, clientProduct) => {
    console.log(query);
    setCurrentQuery(query);

    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length > 2) {
      results = [];

      for (j = 0, len = ReceivedDefectiveProducts.length; j < len; j++) {
        var request = ReceivedDefectiveProducts[j];

        if (clientProduct) {
          relevance = string_similarity(
            query,
            request?.client_product?.name || ""
          );
        } else {
          relevance = string_similarity(query, request?.client?.name || "");
        }

        obj = {
          ...request,
          relevance: relevance,
        };
        results.push(obj);
      }

      var sortedResults = results.filter((result) => result.relevance > 0.2);

      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();

      console.log({ sortedResults });

      request = [...sortedResults];

      setFilteredData(sortedResults);
      console.log(results);
    }
  };

  const columns = [
    {
      title: "Client",
      dataIndex: "client",
      render: (record) => record?.name,

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
             <AutoComplete
          style={{ margin: "1rem", width: "25rem" }}
          autoFocus
          value={selectedKeys[0]}
          options={[...new Set(defectiveProducts.map(reqst => reqst.client.name))].map(name => ({ value: name }))}

          onSearch={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          onSelect={(value) => {
            setSelectedKeys([value]);
            confirm();
          }}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          placeholder="Search"
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.client.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Product",
      dataIndex: "product",
      key: "pro",
      width: "2%",
      render: (_, request) => {
        return request?.product?.name || request?.client_product?.name;
      },

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0], true);
              }}
              onBlur={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Barcode",
      dataIndex: "product",
      key: "9",
      width: "5%",

      render: (_, request) => {
        return request?.product?.barcode || request?.client_product?.barcode;
      },

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record?.client_product?.barcode
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
    },

    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return moment(request?.createdAt).utc().format("DD-MM-YYYY");
      },
    },

    {
      title: "Vendor",
      dataIndex: "vendor",
      align: "center",
      width: "6%",
      render: (_, record) => (
        <Space>
          {record?.vendor?.name ? (
            <>
              <p>{record?.vendor?.name}</p>
              {record.vendor && (
                <ContactsOutlined
                  onClick={() => showVendorData(record)}
                  type="primary"
                  style={{ marginBottom: "1rem" }}
                />
              )}
            </>
          ) : (
            <Form form={form} key={record._id}>
              <Form.Item
                style={{ marginBottom: "0rem", width: "10rem" }}
                key={record._id}
                name={record._id + "vendor"}
                initialValue={"Assign to vendor "}
              >
                <Select
                  disabled={
                    !(record.status == "in-progress") ||
                    !currentUser.user.privileges.includes(
                      "defective_products_management"
                    )
                  }
                  onChange={(value) => handleChange(value, record)}
                  options={vendorsList}
                  style={{ marginBottom: "0rem", width: "10rem" }}
                />
              </Form.Item>
            </Form>
          )}
        </Space>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.vendor?.name
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
    },

    {
      title: "Status",

      width: "4%",
      align: "center",
      render: (req) => (
        <Form form={form} key={req._id}>
          <Form.Item
            key={req._id}
            style={{ marginBottom: "0rem", width: "10rem" }}
            name={req._id}
          >
            <Select
              disabled={
                req.status == "moved-to-bin" ||
                req.status == "returned" ||
                req.status == "damaged" ||
                req.status == "closed" ||
                req.status == "ready-to-return" ||
                !currentUser.user.privileges.includes(
                  "defective_products_management"
                )
              }
              defaultValue={humanizLocal(req?.status)}
              onChange={(value) => handleStatusChange(value, req)}
              style={{ width: "10rem" }}
            >
              <Select.Option value="in-progress">In Progress</Select.Option>

              <Select.Option value="completed">Completed</Select.Option>
              <Select.Option value="damaged">Damaged</Select.Option>
              {req.status == "closed" && (
                <Select.Option value="closed">Closed</Select.Option>
              )}
              {req.status == "moved-to-bin" && (
                <Select.Option value="moved-to-bin">Moved To Bin</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Form>
      ),

      filters: [
        { text: "Received", value: "received" },
        { text: "In Progress", value: "in-progress" },
        { text: "Completed", value: "completed" },
        { text: "Damaged", value: "damaged" },
        { text: "Returned", value: "returned" },
        { text: "Closed", value: "closed" },
        { text: "Moved to Bin", value: "moved-to-bin" },
        { text: "Ready To Return", value: "ready-to-return" },
      ],

      onFilter: (value, record) => {
        console.log({ value, record });

        return record?.status?.includes(value);
      },

      key: "1",
    },

    {
      title: "Description",
      key: "9",
      dataIndex: "description",
      align: "center",
      width: "2%",
    },

    {
      title: "Notes",
      key: "8",
      render: (_, product) => (
        <FileTextOutlined
          onClick={() => {
            showNotes(product);
            setCurrentNotes(product?.notes);
            setCurrentProductId(product._id);
          }}
        />
      ),
      align: "center",
      width: "2%",
    },

    {
      title: "Action",
      key: "3",
      render: (_, product) => (
        <Space size={"large"}>
          <Tooltip title={"Repair Cost Details"}>
            <AccountBookOutlined
              hidden={
                !currentUser.user.privileges.includes(
                  "defective_products_management"
                )
              }
              onClick={() => {
                setCostModalVisible(true);
                setCurrentDefectiveProduct(product);
              }}
            />
          </Tooltip>

          {product.status == "returned" && product.picked_up_by && (
            <Tooltip title={"Receive Back Product"}>
              <Button
                icon={<CheckOutlined />}
                loading={submitLoader && currentReceiveButton == product._id}
                onClick={() => {
                  setCurrentReceiveButton(product._id);
                  confirmDefectiveEditHandler(product);
                }}
              ></Button>
            </Tooltip>
          )}

          <Tooltip title={"Show details"}>
            <EyeOutlined
              hidden={
                !currentUser.user.privileges.includes(
                  "defective_products_management"
                )
              }
              onClick={() =>
                navigate("/products/defective-products/" + product?._id, {
                  state: product,
                })
              }
            />
          </Tooltip>

          <Tooltip title={"Damaged Product options"}>
            <>
              {" "}
              <Dropdown
                disabled={
                  ((product.status != "damaged" &&
                    product.status != "moved-to-bin" &&
                    product.status != "completed") ||
                    (product.status == "moved-to-bin" &&
                      !product.stand_by_product_request) ||
                    product?.standby_return_scheduled) &&
                  product.status !== "switched"
                }
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      {product.status == "moved-to-bin" &&
                      product.stand_by_product_request ? (
                        <Button
                          key={"Return Standby Product"}
                          type="link"
                          icon={<UndoOutlined />}
                          hidden={
                            !currentUser.user.privileges.includes(
                              "defective_products_management"
                            ) ||
                            (product.status == "moved-to-bin" &&
                              !product.stand_by_product_request)
                          }
                          onClick={() => {
                            product.defectiveFlow = true;
                            navigate(
                              "/standbyRequest/return/" +
                                product?.stand_by_product_request,
                              { state: product }
                            );
                          }}
                        >
                          Return Standby Product
                        </Button>
                      ) : product.status != "moved-to-bin" ? (
                        <Button
                          key={"Return Product"}
                          type="link"
                          icon={<UndoOutlined />}
                          hidden={
                            !currentUser.user.privileges.includes(
                              "defective_products_management"
                            ) || product.rent_product_switched
                          }
                          onClick={() =>
                            navigate(
                              "/products/defective-products/" + product?._id,
                              { state: product }
                            )
                          }
                        >
                          Return Product
                        </Button>
                      ) : (
                        ""
                      )}
                    </Menu.Item>
                    <Menu.Item key="2">
                      <Button
                        hidden={
                          product.status == "moved-to-bin" ||
                          product.status == "completed" ||
                          product.status == "switched"
                        }
                        type="link"
                        icon={<DeleteOutlined />}
                        onClick={() => moveToBin(product)}
                      >
                        Move Product to Bin
                      </Button>
                    </Menu.Item>

                    <Menu.Item key="3">
                      <Button
                        hidden={
                          !(
                            product.status == "completed" &&
                            product?.client_product?.plan == "RENT"
                          )
                        }
                        type="link"
                        icon={<BankOutlined />}
                        onClick={() => moveToWarehouse(product)}
                      >
                        Move Product to Warehouse
                      </Button>
                    </Menu.Item>

                    <Menu.Item key="7">
                      <Button
                        hidden={
                          product.product_switched == true ||
                          product.status != "damaged"||
                          product.client_product.plan == "RENT"
                        }
                        type="link"
                        icon={<ShrinkOutlined />}
                        onClick={() => switchProduct(product)}
                      >
                        Switch Product
                      </Button>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  style={{ borderRadius: "5px", width: "1.8rem" }}
                  icon={<MoreOutlined />}
                ></Button>
              </Dropdown>
            </>
          </Tooltip>
        </Space>
      ),

      align: "center",
      width: "2%",
    },
  ];

  return (
    <>
      <Table
        className="dark"
        key={defectiveProducts?._id}
        rowKey={"_id"}
        columns={columns}
        dataSource={filteredData ? filteredData : ReceivedDefectiveProducts}
      />

      <Modal
        destroyOnClose={true}
        footer={[]}
        title="Switch Current Defective Product"
        style={{ marginLeft: "10rem" }}
        centered
        visible={switchModal}
        onOk={() => {
          setSwitchModalVisible(false);
        }}
        onCancel={() => {
          setSwitchingProduct(false);
          setSwitchModalVisible(false);
        }}
        width={850}
      >
        <>
          {(!movedFromDefective )&& <Divider>
            {" "}
            <>
              <Space>
                <h5>Current Product -</h5>
                <h4>
                  {" "}
                  {`${currentDefectiveProduct?.client_product?.name} ${
                    currentDefectiveProduct?.client_product?.barcode
                      ? "( " +
                        currentDefectiveProduct?.client_product?.barcode +
                        " )"
                      : ""
                  }`}
                </h4>
              </Space>
            </>
          </Divider>}

          {(!movedFromDefective ) &&   <div style={{ display: "flex", justifyContent: "center" }}>
            <Space>
              <Button
                onClick={() =>
                  moveToWarehouse(currentDefectiveProduct, switchingProduct)
                }
                type="primary"
              >
                Move to WareHouse
              </Button>
              <Button
                onClick={() =>
                  moveToBin(currentDefectiveProduct, switchingProduct)
                }
                type="primary"
              >
                Move to Bin
              </Button>{" "}
            </Space>
          </div>}

          {(movedFromDefective  ) && <> <Divider>Switch Product</Divider>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Form form={form}>
                <Form.Item
                  style={{ width: "15rem" }}
                  initialValue={
                    currentDefectiveProduct?.product?.name
                      ? `${currentDefectiveProduct?.product?.name} ${currentDefectiveProduct?.product?.barcode
                        ? currentDefectiveProduct?.product?.barcode
                        : ""
                      }`
                      : "Select a Product"
                  }
                >
                  <Select
                    defaultValue={"Select a Product"}
                    onChange={switchCurrentDefectiveProduct}
                    options={switchProductList}
                    style={{ width: "13rem" }}
                  />
                </Form.Item>
              </Form>
            </div></>}
        </>
      </Modal>

      <Modal
        title="Select Warehouse"
        content="Move Repaired Product to Warehouse?"
        okText="Confirm"
        centered
        visible={warehouseModal}
        cancelText="Cancel"
        confirmLoading={modalLoading}
        onOk={() => {
          setModalLoading(true);
          moveToWarehouseApi(
            currentDefectiveProduct,
            selectedWarehouse,
            switchingProduct
          )
            .then(() => {

              setWarehouseModal(false)
              setModalLoading(false);
             setMovedFromDefective(true)
              setCurrentDefectiveProduct(currentDefectiveProduct)
            }).catch(()=>{
              setModalLoading(false);
              setMovedFromDefective(true)
            })
            .finally(() => {
              setModalLoading(false);
            });
        }}
        onCancel={() => { setWarehouseModal(false); setSelectedWarehouse(false) }}
      >
        {warehouses.map((warehouse) => (
          <Radio
            key={warehouse._id}
            onChange={() => handleRadioChange(warehouse._id)}
            checked={selectedWarehouse === warehouse._id}
          >
            {warehouse.title}
          </Radio>
        ))}
      </Modal>
    </>
  );
};

export default ReceivedDefectiveProductTable;
