import { logRoles } from "@testing-library/react";
import { Button, Divider, Form, Input, Radio, Space, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";





const NotesTimeline = ({

   currentNotes,
    noteSubmitHandler ,
  noteModalVisible,
  setNoteModalVisible,
  noteLoading,
  createDeliveryJob,
  setFullNoteModalVisible,
  currentProductId,
  setCurrentNotes, }) => {



  const onNoteFinish = (newNote) => {
    console.log(newNote);
    noteSubmitHandler(newNote, currentProductId);
  };


  return (
    <>
      <Timeline   mode={"left"}>
        
        {currentNotes.map((log) => {
         

          var color;
          var locaTime = new Date(log.time);
          var time = locaTime.toString();

          if (log.vendor_transferred_date) {
            color = "#007FFF";
          } else if (log.vendor_transferred_date) {
            color = "green";
          } else {
            color = "#5D76A9";
          }

          function formatTimeShow(h_24) {
            var h = h_24 % 12;
            if (h === 0) h = 12;
            return h + `: ${time.substr(19, 2)}` + (h_24 < 12 ? " am" : " pm");
          }

          var exactTime = formatTimeShow(parseFloat(time.substr(15, 10)));
          var text;
          var suffix = "";

            text = "by  " + log?.by_name
    
          


          return (
            <Timeline.Item
              style={{ fontSize: "15px" }}
              dot={log.type == "complete" ? <CheckOutlined /> : ""}
              color={color}
            >
              {suffix} {log?.text} {text}{" "}
              <span style={{ fontWeight: "200" }}> on</span>{" "}
              <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                {" "}
                {time.substr(3, 12)}, {exactTime}{" "}
              </span>
            </Timeline.Item>
            
          );
        })}
      </Timeline>
      <Divider>Add New Note</Divider>

      <Form name="note" onFinish={onNoteFinish} autoComplete="off">
        <Space>
          <Form.Item
          
            name="note"
            labelCol={{ span: 24, offset: 0 }}
            style={{ width: "42rem", marginTop: "2.5rem"}}
            rules={[
              {
                required: true,
                message: "Missing Note",
              },
            ]}
          >
            <Input  placeholder="Enter your new note" type={"text"} />
          </Form.Item>

          <Button
            loading={noteLoading}
            type="primary"
            htmlType="submit"
            style={{ marginTop: "1rem" }}
          >
            Submit
          </Button>
        </Space>
      </Form>
    </>
  );
};

export default NotesTimeline;
