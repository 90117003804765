import create from "zustand";
import { devtools } from "zustand/middleware";

const clientLocationStore = (set) => ({
    clientLocations: [],

    addClientLocation: (newClientLocation) => {
        set((state) => ({
            clientLocations: [newClientLocation, ...state.clientLocations],
        }));
    },
    setClientLocation: (apiClientLocation) => {
        set((state) => ({
            clientLocations: [...apiClientLocation],
        }));
    },

    updateClientLocation: (editData) => {
        set((state) => ({
            clientLocations: state.clientLocations.map((clientLocation) => {
                if (clientLocation._id === editData._id) {
                    return {
                        ...editData

                    };
                } else {
                    return clientLocation;
                }
            }),
        }));
    },


    deleteClientLocationInStore:(locationId)=>{

        set((state)=>({

            clientLocations:state.clientLocations.filter((location)=>location._id !== locationId)

        }))


    }

});

const useClientLocationStore = create(devtools(clientLocationStore));

export default useClientLocationStore