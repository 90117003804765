import React, { useState } from 'react';
import { Select, Tag } from 'antd';
const options = [
    {   label:'Engineers',
        value: 'engineer',
    },
    {
        label: 'Service ',
        value: 'service',
    },
    {
        label: 'Delivery',
        value: 'delivery',
    },
   
];
const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

        console.log(value);

    const onPreventMouseDown = (event) => {
        // event.preventDefault();
        // event.stopPropagation();
    };
    return (
        <Tag
            color={value == "engineer" ? "orange" : value == "service" ? "dodgerBlue":value=="delivery"?"darkcyan":'default'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginRight: 3,
            }}
        >
            {label}
        </Tag>
    );
};
const AgentsFielter = ({ setFilterValue}) => (



    <Select
        mode="multiple"
        showArrow
        onChange={(e) => { setFilterValue(e);}}
        tagRender={tagRender}
        defaultValue={[]}
        style={{
            minWidth: '18rem',
            color:'black    '
        }}
        options={options}
    />
);
export default AgentsFielter;

