import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tooltip
} from "antd";
import { useEffect, useState } from "react";
import useProductStore from "stores/productStore";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import useRentStore from "stores/rentStore";
import salesApi from "utils/composable/salesApi";

const PendingRentTable = ({
  editHandler,
  createRentProductDeliveryJob,
  showRentTask,
  currentUser,
  closeRent,
}) => {
  const rents = useRentStore((state) => state.rents).filter(
    (rent) => rent.pending_delivery === true
  );

  console.log({ rents }, "pending rents ");
  const navigate = useNavigate();

  const showMoreData = (sale) => {
    Modal.info({
      title: "More",
      content: (
        <div>
          <Divider>Description</Divider>
          {sale.description}
        </div>
      ),
      onOk() {},
    });
  };

  const { getSales } = salesApi();

  const products = useProductStore((state) => state.Products);

  const content = (data) => {
    console.log(data, "data");

    return (
      <div>
        <p>{`Item Price :  ₹ ${data?.price.item_total.toFixed(2)}`}</p>
        <p>{`Discount :  ₹ ${data?.price.discount.toFixed(2)}`}</p>
        <p>{`Tax :  ₹ ${data?.price.tax_amount.toFixed(2)}`}</p>
        <p>{`Charges :  ₹ ${data?.price.charges.toFixed(2)}`}</p>
      </div>
    );
  };

  //Fuzzy filter

  const [filteredData, setFilteredData] = useState(false);
  const [currentQuery, setCurrentQuery] = useState("");

  const fuzzyFilter = (query, product) => {
    console.log(query);
    setCurrentQuery(query);
    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length > 2) {
      results = [];

      for (j = 0, len = rents.length; j < len; j++) {
        var rent = rents[j];

        if (product) {
          relevance = string_similarity(query, rent?.product?.name || "");
        } else {
          relevance = string_similarity(query, rent?.client?.name || "");
        }

        obj = {
          ...rent,
          relevance: relevance,
        };
        results.push(obj);
      }

      var sortedResults = results.filter((result) => result.relevance > 0.2);
      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();
      console.log({ sortedResults });
      rent = [...sortedResults];
      setFilteredData(sortedResults);
      console.log(results);
    }
  };

  useEffect(() => {
    if (filteredData.length) {
      fuzzyFilter(currentQuery);
    }
  }, []);

  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "2",
      render: (product) => product?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <AutoComplete
          style={{ margin: "1rem", width: "25rem" }}
          autoFocus
          value={selectedKeys[0]}
          options={[...new Set(rents.map(rent => rent.product.name))].map(name => ({ value: name }))}

          onSearch={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          onSelect={(value) => {
            setSelectedKeys([value]);
            confirm();
          }}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          placeholder="Search"
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.product.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Client",
      dataIndex: "client",
      key: "2",
      render: (client) => client?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
             <AutoComplete
          style={{ margin: "1rem", width: "25rem" }}
          autoFocus
          value={selectedKeys[0]}
          options={[...new Set(rents.map(rent => rent.client.name))].map(name => ({ value: name }))}

          onSearch={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          onSelect={(value) => {
            setSelectedKeys([value]);
            confirm();
          }}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          placeholder="Search"
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.product.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Barcode",

      render: (data) => data.product?.barcode,
      key: "2",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.product?.barcode
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
    },
    {
      title: "Brand",
      render: (data) => data.product?.brand,
      key: "3",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.product.brand
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
    },

    {
      title: "Rented Date",
      dataIndex: "rented_date",
      key: "4",
      width: "15%",
      render: (record) => moment.utc(record).format("DD-MM-YYYY"),
    },

    {
      title: "Due Date",
      width: "15%",
      key: "4",
      render: (record) =>
        moment.utc(record?.next_due_date).format("DD-MM-YYYY"),
    },

    {
      title: "Descriptions",
      dataIndex: "description",
      key: "1",
      width: "20%",
    },

    
    {
      title: "Amount",

      key: "1",
      width: "20%",
      width: "20%",
      render: (record) => (
        <>
          {record?.price?.amount.toFixed(2)}

          <Popover
            style={{ padding: "1rem" }}
            content={() => content(record)}
            title="Payment Details"
          >
            <ExclamationCircleOutlined
              style={{ paddingLeft: "0.5rem" }}
            ></ExclamationCircleOutlined>
          </Popover>
        </>
      ),
    },

    {
      title: "Action",

      key: "4",
      render: (rent) =>
        rent.task ? (
          <Tooltip title={"Show Task"}>
            <EyeOutlined onClick={() => showRentTask(rent.task)} />
          </Tooltip>
        ) : (
          <Space>
            <Tooltip title={"Close Rent"}>
              <Popconfirm
                title="Close this rent?"
                onConfirm={() => closeRent(rent?._id)}
              >
                <CheckCircleOutlined />
              </Popconfirm>
            </Tooltip>

            <Tooltip title={"Create Delivery Task"}>
              <PlusCircleOutlined
                hidden={
                  !currentUser.user.privileges.includes("payment_management")
                }
                onClick={() => createRentProductDeliveryJob(rent)}
              />
            </Tooltip>
          </Space>
        ),
    },
  ];

  return (
    <>
      <Table
        key={rents?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={false}
        dataSource={filteredData ? filteredData : rents}
      />
    </>
  );
};

export default PendingRentTable;
