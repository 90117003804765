import { message } from "antd";
import axios from "axios/axios";
import useInvoiceStore from "stores/invoiceStore";
import usePaymentStore from "stores/paymentStore";
import authHeader from "utils/services/auth-header";

const PaymentApi = () => {
 
  const {
    setPaymentsToStore,
    changePaymentStatus,
    updatePaymentsInStore,
    addPaymentToStore,
  } = usePaymentStore((state) => ({
    setPaymentsToStore: state.setPayments,
    updatePaymentsInStore: state.updatePayments,
    changePaymentStatus: state.changePaymentStatus,
    addPaymentToStore: state.addPayment,
  }));

  const {  updateInvoicesInStore } =
    useInvoiceStore((state) => ({
      setInvoicesToStore: state.setInvoices,
      updateInvoicesInStore: state.updateInvoices,
      changeInvoiceStatus: state.changeInvoiceStatus,
    }));

  const getPayments = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/payments", { headers: authHeader() })
        .then((response) => {
       
          setPaymentsToStore(response.data.payments);
          resolve();
        })
        .catch((err) => {
 
          message.error(err.response.data.message);
          reject(err.response.data.message);
        });
    });
    // }
  };

  const updatePayment = async (data) => {
   
    return new Promise((resolve, reject) => {
      axios
        .put("/payment", data, { headers: authHeader() })
        .then((resp) => {
 

          if (data.cancel) {
            changePaymentStatus("cancelled", [data.payment_id]);
          } else if (data.restore) {
            changePaymentStatus("pending", [data.payment_id]);
          } else {
            updatePaymentsInStore(resp.data.payment);
          }

          message.success(resp.data.message);
          resolve(resp.data.message);
        })
        .catch((err) => {
          console.log(err);
          reject(err.response.data.message);
        });
    });
  };

  const createPaymentApi = async (data) => {
  
    return new Promise((resolve, reject) => {
      axios
        .post("/payment", data, { headers: authHeader() })
        .then((resp) => {
       

          addPaymentToStore(resp.data.newPayment);
          message.success(resp.data.message);
          resolve(resp.data.message);
        })
        .catch((err) => {
          console.log(err);
          reject(err.response.data.message);
        });
    });
  };

  //import Api
  const addReceipts = async (data, importData) => {


    return new Promise((resolve, reject) => {
      axios
        .post(
          "/payment/receipts",
          { receipts: data },
          { headers: authHeader() }
        )
        .then((resp) => {
   

        
          const storeData = importData.map((receipt) => {
            receipt.paid_amount =
              parseFloat(receipt.paid_amount) +
              parseFloat(receipt.price.received_amount);

 

            // receipt.balance_amount =  receipt?.price.amount - receipt?.paid_amount  + receipt.price?.adjusted_amount
            if (receipt.status === "partially_paid") {
              receipt.adjusted_amount =
                receipt.adjusted_amount +
                Math.trunc(receipt?.price?.adjusted_amount || 0);

              receipt.balance_amount =
                Math.trunc(receipt.balance_amount) -
                (Math.trunc(receipt?.price?.received_amount || 0) +
                  Math.trunc(+receipt?.price.adjusted_amount || 0));
            } else {
              receipt.adjusted_amount =
                receipt.adjusted_amount +
                Math.trunc(receipt?.price?.adjusted_amount || 0);
              receipt.balance_amount =
                Math.trunc(receipt.price.amount) -
                (Math.trunc(receipt?.price?.received_amount || 0) +
                  Math.trunc(+receipt?.price.adjusted_amount || 0));
            }

      

            if (receipt.balance_amount === 0) {
              receipt.status = "paid";
            } else if (receipt.balance_amount < 0) {
              receipt.status = "paid";
            } else if (receipt.balance_amount === 0) {
              receipt.status = "paid";
            } else {
              receipt.status = "partially_paid";
            }

            return receipt;
          });

          updateInvoicesInStore(storeData);

          // changeInvoiceStatus('paid', invoiceNumbers)

          message.success(resp.data.message);

          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject(err.response.data.message);
          message.error(err.response.data.message);
        });
    });
  };

  const getReceipts = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/payment/receipts", { headers: authHeader() })
        .then((response) => {

          // setPaymentsToStore(response.data.payments);
          resolve(response.data.receipts);
        })
        .catch((err) => {
          console.log(err);

          reject(err.response.data.message);
        });
    });
    // }
  };

  return {
    getPayments,
    updatePayment,
    addReceipts,
    getReceipts,
    createPaymentApi,
  };
};

export default PaymentApi;
