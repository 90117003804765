import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  message,
  PageHeader,
  Skeleton,
  Statistic,
  Table,
} from "antd";

import { RedoOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import WareHouseTable from "components/warehouse/WarehouseTable";
import useWarehouseStore from "stores/warehouseStore";
import ProductApi from "utils/composable/productsApi";
import WareHouseApi from "utils/composable/warehouseApi";



const WarehouseProducts = ({ currentUser }) => {
  const [loadings, setLoadings] = useState(false);
  const [tableloading, setTableloading] = useState(false);

  const warehouseId = useParams();

  const { state } = useLocation();

  const { getProducts } = ProductApi();

  const {
    getWarehouseStock,
    closeStandByrequest,
    receiveProductToWarehouse,
    getWarehouse,
  } = WareHouseApi();



  const warehouses = useWarehouseStore((state) => state.warehouses);



  const {

    setWarehouseStockInStore,
   
  } = useWarehouseStore((state) => ({
    addWarehousesToStore: state.addWarehouse,
    setWarehousesInStore: state.setWarehouse,
    updateWarehousesInStore: state.updateWarehouse,
    setWarehouseStockInStore: state.setWarehouseStock,
    updateWarehouseStock: state.updateWarehouseStock,
  }));



  var currentWarehouseStock = useWarehouseStore(
    (state) => state.warehouse_stock
  );


  const inStockProducts = currentWarehouseStock.filter(
    (stock) => stock.status === "in_stock"
  );
  const rentedProducts = currentWarehouseStock.filter(
    (stock) => stock.status === "rented"
  );

  const toClientsProducts = currentWarehouseStock.filter(
    (stock) => stock.status === "to_client"
  );
  const toWarehouseProducts = currentWarehouseStock.filter(
    (stock) => stock.status === "to_warehouse"
  );
  const withClientOnStandByProducts = currentWarehouseStock.filter(
    (stock) => stock.status === "with_client_on_stand_by"
  );

  const Currentwarehouse = warehouses.find(
    (warehouse) => warehouse._id === warehouseId.warehouseId
  );

  useEffect(() => {
    getWarehouse();
    getWarehouseStock(warehouseId.warehouseId);

    if (!currentWarehouseStock.length) {
      setTableloading(true);
    } else {
      setTableloading(false);
    }

    // getStandByRequest();

    return () => {
      setWarehouseStockInStore([]);
    };
  }, []);

  useEffect(() => {
    if (!currentWarehouseStock.length) {
      setTableloading(true);
    } else {
      setTableloading(false);
    }
  }, [currentWarehouseStock]);

  const receivedStandByitem = (requestId) => {
    setLoadings(true);

    closeStandByrequest(requestId)
      .then((msg) => {
        setLoadings(false);
        message.success(msg);
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
      });
  };

  const receiveProduct = (productId, category) => {
    setLoadings(true);

    receiveProductToWarehouse(productId, category)
      .then((msg) => {
        setLoadings(false);
        message.success(msg);
      })
      .catch((err) => {
        console.log(err);
        setLoadings(false);
        message.error(err);
      });
  };

  const managerColumns = [
    {
      title: "Manager",
      width: "15%",
      key: "1",
      render: (_, record) => record.name,
    },
    {
      title: "Phone",
      width: "15%",
      key: "2",
      render: (record) => record?.phone,
    },
    {
      title: "Email",

      key: "3",
      render: (record) => record?.email,
    },
  ];

  //Refresh
  const [refreshLoading, setRefreshLoading] = useState(false);

  const refresh = () => {
    setRefreshLoading(true);

    getProducts();

    getWarehouseStock(warehouseId.warehouseId).finally(() => {
      setRefreshLoading(false);
    });
  };

  return (
    <>
      <div className="site-page-header-ghost-wrapper">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={state.title}
          subTitle={state?.city}
          extra={[
            <Button
              icon={<RedoOutlined />}
              loading={refreshLoading}
              onClick={refresh}
              key="1"
              type="dashed"
            ></Button>,
          ]}
        >
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Address">
              {state?.address || Currentwarehouse?.address}
            </Descriptions.Item>
            <Descriptions.Item label="Manager">
              <Table
                className={"managerTable"}
                size="small"
                showHeader={false}
                pagination={false}
                border={"1.5rem"}
                rowKey={"_id"}
                columns={managerColumns}
                tableLayout={"auto"}
                dataSource={state?.managers || Currentwarehouse?.managers}
              />
            </Descriptions.Item>

            {/* <Descriptions.Item label="Description">
                            <a>{Currentwarehouse?.description}</a>
                        </Descriptions.Item> */}
          </Descriptions>

          <Divider style={{ fontWeight: "bolder" }}>Stock Status </Divider>

          <div
            style={{
              margin: "0 auto",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="site-statistic-demo-card"
          >
            <Col span={4}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Statistic
                  title="Products In Stock"
                  value={inStockProducts.length}
                  style={{
                    alignContent: "center",
                    justifyContent: "center",
                    border: "none",
                    textAlign: "center",
                  }}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </Card>
            </Col>

            <Col span={5}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Statistic
                  title="Products In Transit To Client"
                  value={toClientsProducts.length}
                  valueStyle={{
                    color: "#cf1322",
                  }}
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Statistic
                  title="Products With Client"
                  value={withClientOnStandByProducts.length}
                  valueStyle={{
                    color: "#cf1322",
                  }}
                />
              </Card>
            </Col>

            <Col span={6}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Statistic
                  title="Products Yet To Be Received"
                  value={toWarehouseProducts.length}
                  valueStyle={{
                    color: "#cf1322",
                  }}
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Statistic
                  title="Rented Products"
                  value={rentedProducts.length}
                  valueStyle={{
                    color: "#cf1322",
                  }}
                />
              </Card>
            </Col>
          </div>
        </PageHeader>
      </div>

      <Skeleton active loading={false}>
        {/* <Divider style={{fontWeight:'bolder'}} >   Standby Request </Divider>

                <WarehouseRequestTable  loading={loadings} receivedStandByitem={receivedStandByitem} warehouseId={warehouseId.warehouseId}  /> */}

        <WareHouseTable
          currentUser={currentUser}
          tableloading={tableloading}
          receiveButtonLoading={loadings}
          receiveProductToWareHouse={receiveProduct}
          receivedStandByitem={receivedStandByitem}
          warehouseId={warehouseId.warehouseId}
          warehouseProducts={currentWarehouseStock}
        />
      </Skeleton>
    </>
  );
};

export default WarehouseProducts;
