import create from "zustand";
import { devtools } from "zustand/middleware";

const dashboardStore = (set) => ({
    reports: {},


    setReports: (apiReports) => {
        set((state) => ({
            reports: {...apiReports}, 
        }));
    },


});

const useDashboardStore = create(devtools(dashboardStore));

export default useDashboardStore;
;
