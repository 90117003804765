import {
  Button,
  Checkbox,
  Descriptions,
  Drawer,
  message,
  Modal,
  PageHeader,
  Popover,
 
  Timeline,
} from "antd";
import {

  ExclamationCircleOutlined,
  CheckOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ClientProductForm from "components/client/ClientProductForm";

import ClientApi from "utils/composable/clientApi";
// import ClientPlanForm from "components/client/ClientProductPlanForm";
import moment from "moment";
import humanize from "utils/formate-status";
import useUserStore from "stores/teamStore";
import InvoiceApi from "utils/composable/invoiceApi";

import TeamApi from "utils/composable/teamApi";
import InvoiceItemTable from "components/Invoice/InvoiceItemTable";
import InvoiceForm from "components/Invoice/InvoiceForm";

const InvoiceDetails = () => {
  const [loadings, setLoadings] = useState(true);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState("");
  const [planEditData, setPlanEditData] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [selectedproducts, setSelectedProducts] = useState([]);
  const [bulkUpdates, setBulkUpdatestrue] = useState(false);
  const [planDrawervisible, setPlanDrawerVisible] = useState(false);
  const [additionalPlan, setAdditionalPlan] = useState("");
  const [currentInvoice, setCurrentInvoice] = useState("");
  const [currentClient, setCurrentClient] = useState("");
  const [inVoiceFormVisible, setInvoiceFormVisible] = useState("");
  const [cancelPayment, setCancelPayment] = useState(false);
  var cancelPaymentCheck = false;
  const invoiceId = useParams();
  const { state } = useLocation();

  const {
    clientProducts,
    updateClientProducts,
  
  } = ClientApi();

  const [refreshLoading, setRefreshLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { getInvoice, updateInvoice } = InvoiceApi();

  const { getUsers } = TeamApi();
  const users = useUserStore((state) => state.users);

  useEffect(() => {


    getUsers();

  
    getInvoice(invoiceId.invoiceId).then((invoice) => {
      setCurrentInvoice(invoice);
     
    });
  }, []);


  useEffect(() => {


 
  }, [currentInvoice])
  

  const showSpecs = (specifications) => {
    const specs = Object.entries(specifications);

    Modal.info({
      title: "Specifications",
      content: (
        <div>
          {Object.keys(specifications).map((key, i) => (
            <p key={i}>
              <span style={{ fontWeight: "bolder" }}>{key + ":"}</span>
              {""}
              <span style={{ float: "inline-start" }}>
                {" " + specifications[key]}
              </span>
            </p>
          ))}
        </div>
      ),
      onOk() {},
    });
  };

  const editHandler = (invoice) => {
    const formatDate = (date) => {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    };

    invoice.date = formatDate(invoice.date);

    setEditData(invoice);
    setInvoiceFormVisible(true);
  };


  const confirmEditHandler = (updatePayload, form) => {
    setSubmitLoader(true);

    try {
      updateClientProducts(updatePayload)
        .then((msg) => {
          setSubmitLoader(false);
          setVisible(false);
          message.success(msg);
          form.resetFields();
        })
        .catch((msg) => {
          console.log(msg);
          setSubmitLoader(false);
          message.error(msg);
        });
    } catch (error) {
      console.log(error);
      setSubmitLoader(false);
    }
  };

  const onClose = () => {
    setEditData("");
 

    setInvoiceFormVisible(false);
  };

  const refresh = () => {
    setRefreshLoading(true);

    clientProducts(invoiceId.invoiceId).finally(() => {
      setRefreshLoading(false);
    });
  };

  var currentProducts;



  const content = (data) => {


    return (
      <div>
        <p>{`Tax :  ₹ ${(data?.price?.tax_amount.toFixed(2))}`}</p>
       
      </div>
    );
  };

  const logTimeline = (data) => {
    Modal.info({
      width: "50rem",

      title: "Payment Logs",
      content: (
        <Timeline mode={"left"}>
          {data.logs.map((log) => {
   

            var color;
            var locaTime = new Date(log.date);
            var time = locaTime.toString();

            if (log.vendor_transferred_date) {
              color = "#007FFF";
            } else if (log.vendor_transferred_date) {
              color = "green";
            } else {
              color = "#5D76A9";
            }

            function formatTimeShow(h_24) {
              var h = h_24 % 12;
              if (h === 0) h = 12;
              return (
                h + `: ${time.substr(19, 2)}` + (h_24 < 12 ? " am" : " pm")
              );
            }

            var exactTime = formatTimeShow(parseFloat(time.substr(15, 10)));
            var text;
            var suffix = "";

            text = "by  " + users.find((user) => user._id == log.by).name;

            return (
              <Timeline.Item
                style={{ fontSize: "15px" }}
                dot={
                  log.type == "created" ? (
                    <CheckOutlined />
                  ) : log.type == "cancelled" ? (
                    "X"
                  ) : (
                    ""
                  )
                }
                color={color}
              >
                {suffix} {log?.text} {text}{" "}
                <span style={{ fontWeight: "200" }}> on</span>{" "}
                <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                  {" "}
                  {time.substr(3, 12)}, {exactTime}{" "}
                </span>
              </Timeline.Item>
            );
          })}
        </Timeline>
      ),
      onOk() {},
    });
  };

  const updateHandler = (data) => {



    setButtonLoading(true);
    setSubmitLoader(true);

    updateInvoice(data)
      .then(() => {

        setButtonLoading(false);
        setSubmitLoader(false);
        if (data.cancel) {

          currentInvoice.status = "cancelled";

        } else if (data.restore) {

          currentInvoice.status = "pending";
        }

        if (data.date) {
          currentInvoice.date = data.date;
        }

        if (data.description) {
          currentInvoice.description = data.description;
        }
        setCurrentInvoice(currentInvoice)
        setInvoiceFormVisible(false);
      }).finally(()=>{

        setButtonLoading(false);
        setSubmitLoader(false);
      })


    
    
  };

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          className="site-page-header"
          onBack={() => window.history.back()}
          title="Invoice"
          subTitle={
            <span style={{ fontWeight: 'bold', color: currentInvoice?.status == 'pending' ? 'orange' : currentInvoice?.status == 'paid' ? '#77dd77' : currentInvoice?.status == 'partially_paid' ? 'darkgreen' :'darkred' }} >
              {humanize(currentInvoice?.status || "")}

            </span>}
          extra={[


            currentInvoice.status == "pending" && ( <Button
                hidden={currentInvoice.status == "cancelled"}
                loading={buttonLoading}
                onClick={() => {
                  Modal.confirm({
                    title: "Cancel invoice",
                    content: (
                      <>
                        <Checkbox
                          onChange={(e) => {
                            cancelPaymentCheck = e.target.checked;
                        
                            setCancelPayment(e.target.checked);
                          }}
                        >
                          {" "}
                          Cancel Invoice and Payments{" "}
                        </Checkbox>{" "}
                      </>
                    ),
                    okText: "Yes",
                    cancelText: "Cancel",

                    onOk: () => {
                      updateHandler({
                        cancel: true,
                        invoice_id: currentInvoice._id,
                        cancel_payment: cancelPaymentCheck ? true : null,
                      });
                    },
                    // onCancel: () => handleCancel(),
                  });
                }}
                key="3"
              >
                Cancel Invoice
              </Button>
            ),

            currentInvoice.status == "pending" && (
              <Button
                hidden={currentInvoice.status == "cancelled"}
                loading={refreshLoading}
                onClick={() => {
                  editHandler(currentInvoice);
                }}
                key="1"
              >
                Edit Invoice
              </Button>
            ),

            currentInvoice.status == "cancelled" && (
              <Button
                loading={buttonLoading}
                onClick={() => {
                  const paymentId = currentInvoice.items.map(
                    (item) => item.payment_id
                  );

                  Modal.confirm({
                    title: "Restore invoice",

                    okText: "Yes",
                    cancelText: "Cancel",

                    onOk: () => {
                      updateHandler({
                        restore: true,
                        restore_payment_ids: paymentId,
                        invoice_id: currentInvoice._id,
                        client: currentInvoice.client._id,
                      });
                    },
                    // onCancel: () => handleCancel(),
                  });
                }}
                key="1"
              >
                Restore
              </Button>
            ),

         
          ]}
        >
          <Descriptions column={4} style={{ marginTop: "1rem" }}>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Client Name"
            >
              {currentInvoice?.client?.name}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Created at"
            >
              {currentInvoice.createdAt
                ? moment(currentInvoice.date).format("DD/MM/YYYY")
                : ""}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Invoice Number"
            >
              {currentInvoice.invoice_number}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Total Item Price"
            >
              {currentInvoice?.price?.item_total.toFixed(2)}
            </Descriptions.Item>

        


            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Tax Amount"
            >
              {currentInvoice?.price?.tax_amount?.toFixed(2)}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Total Amount"
            >
              <>
                {currentInvoice?.price?.amount.toFixed(2)}
                <Popover
                  style={{ padding: "1rem" }}
                  content={() => {
                    return content(currentInvoice);
                  }}
                  title="Payment Details
                "
                >
                  <ExclamationCircleOutlined
                    style={{ paddingLeft: "0.5rem" }}
                  ></ExclamationCircleOutlined>
                </Popover>
              </>
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Paid Amount"
            >
              {currentInvoice?.paid_amount?.toFixed(2)}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Adjusted Amount"
            >
              {currentInvoice?.adjusted_amount}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Balance Amount"
            >
              {Math.trunc(currentInvoice?.balance_amount?.toFixed(2)) }
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Logs"
            >
              <FieldTimeOutlined
                size={"large"}
                onClick={() => logTimeline(currentInvoice)}
                style={{ paddingLeft: "0.5rem" }}
              >
                {" "}
              </FieldTimeOutlined>
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Status"
            >
              {humanize(currentInvoice?.status || "")}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Description"
            >
              {currentInvoice.description}
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      </div>

      <InvoiceItemTable
        updateHandler={updateHandler}
        item={currentInvoice.items}
        state={state}
        products={currentProducts}
        selectedproducts={selectedproducts}
        setSelectedProducts={setSelectedProducts}
        editHandler={editHandler}
        showSpecs={showSpecs}
      />

      <Drawer
        destroyOnClose={true}
        key={editData?._id}
        title={
          editData
            ? "Edit Product "
            : `Update ${selectedproducts.length} Products`
        }
        placement="right"
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
      >
        <ClientProductForm
          selectedproducts={selectedproducts}
          bulkUpdates={bulkUpdates}
          editData={editData}
          submitLoader={submitLoader}
          setVisible={setVisible}
          confirmEditHandler={confirmEditHandler}
        />
      </Drawer>

      <Drawer
        destroyOnClose={true}
        key={editData?._id}
        width={"1000px"}
        title={"Edit Invoice"}
        placement="right"
        onClose={onClose}
        visible={inVoiceFormVisible}
      >
        <InvoiceForm
          handleInvoiceEdit={updateHandler}
          currentClient={currentClient}
          setCurrentClient={setCurrentClient}
          additionalPlan={additionalPlan}
          planEditData={planEditData}
          selectedproducts={selectedproducts}
          bulkUpdates={bulkUpdates}
          editData={editData}
          submitLoader={submitLoader}
          setVisible={setInvoiceFormVisible}
          confirmEditHandler={confirmEditHandler}
        />
      </Drawer>
    </>
  );
};

export default InvoiceDetails;
