import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Divider, message, Modal, PageHeader, Skeleton } from "antd";
import ClientContactsTable from "components/client/ClientContactsTable";
import ClientForm from "components/client/ClientForm";
import ClientLocationTable from "components/client/ClientLocationTable";
import GoogleMap from "components/googleMap/GoogleMap";
import { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import useClientLocationStore from "stores/clientLocationStore";
import useClientStore from "stores/clientStore";
import ClientApi from "utils/composable/clientApi";
import ClientLocationApi from "utils/composable/clientLocationApi";

const Client_locations = ({ currentUser}) => {
  const clientId = useParams();
  const { state } = useLocation();
  const [clientsLocationsLoading, setClientsLocationsLoading] = useState(false);
  const [locationEditData, setLocationEditData] = useState(true);
  const [visible, setVisible] = useState(false);
  const [branches, setBranches] = useState(true);
  const [editData, setEditData] = useState("");
  const [locationDetails, setLocationDetails] = useState("");
  const [submitLoading, setsubmitLoading] = useState(false);
  const [contacts, setContacts] = useState([]);

  const [clientMap, setClientMap] = useState(true);
  const [refreshLoading, setRefreshLoading] = useState(false);

  //store

  const clients = useClientStore((state) => state.clients);

  const {
    setClientLocationToStore,
  
  } = useClientLocationStore((state) => ({
    setClientLocationToStore: state.setClientLocation,
    addClientLocationToStore: state.addClientLocation,
    updateClientLocationToStore: state.updateClientLocation,
  }));

  const {
    deleteLocation,
    getClientLocation,
    addClientLocation,
    updateClientLocation,
  } = ClientLocationApi();
  const { getClients } = ClientApi();


  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData.location.coordinates);
  };

  //notifications
  const locationNotAvailable = () => {
    message.error(`Please Select Client Location `);
  };

  const contactsNotAvailable = () => {
    message.error(`Minimum One Contact Required`);
  };

  const clientLocationAdded = (msg) => {
    message.success(msg);
  };

  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  useEffect(() => {
    if (!clients.length) {
      try {
        getClients();
      } catch (error) {
        console.log(error);
      }
    }
  

    setBranches(true);
    setClientsLocationsLoading(true);


    getClientLocation(clientId.clientId)
      .then(() => {
        setClientsLocationsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setClientsLocationsLoading(false);
      });

    return () => {
      setBranches(false);

      setClientLocationToStore([]);
    };
  }, []);

  const editHandler = (client) => {
    setContacts(false);
    setEditData(client);
    setLocationEditData(client?.location?.coordinates);
    setLocationDetails(false);
    setVisible(true);
  };

  const locationSubmitHandler = (values, form) => {
    setsubmitLoading(true);

    if (values.contacts) {
      values.contacts.forEach((contact) => {
        contact.role = [contact.role];
      });
    } else {
      contactsNotAvailable();
      setsubmitLoading(false);
    }

    if (!locationDetails) {
      locationNotAvailable();
      setsubmitLoading(false);
    } else {
      const payload = {
        ...values,
        client: clientId.clientId,
        location_keywords: locationDetails.keywords,
        location: {
          type: "Point",
          coordinates: [
            locationDetails.coordinates.lng,
            locationDetails.coordinates.lat,
          ],
        },
      };

      addClientLocation(payload)
        .then((msg) => {
          setsubmitLoading(false);
          setVisible(false);
          form.resetFields();
          clientLocationAdded(msg);
        })
        .catch((msg) => {
          console.log(msg);
          setsubmitLoading(false);
          errorMsg(msg);
        });
    }
  };

  const confirmBranchEditHandler = (editFormValues, form) => {
    setsubmitLoading(true);

    const editPayload = {
      name: editFormValues.name,
      contacts: editFormValues.contacts,
      address: editFormValues.address,
      landline:editFormValues?.landline||'',
      location_id: editData?._id,
      client: editData.client,
      is_active: editFormValues.is_active,
      maintenance_available: editFormValues.maintenance_available,
      maintenance_interval: editFormValues.maintenance_interval,
      next_maintenance_date: editFormValues.next_maintenance_date,
      location_keywords:
        locationDetails?.keywords || editData?.location_keywords,
      location: {
        type: "Point",
        coordinates: [
          locationDetails?.coordinates?.lng || editData.location.coordinates[0],
          locationDetails?.coordinates?.lat || editData.location.coordinates[1],
        ],
      },
    };

    updateClientLocation(editPayload)
      .then((msg) => {
        setsubmitLoading(false);
        setVisible(false);
        editSuccessful(msg);
        form.resetFields();
      })
      .catch((msg) => {
        console.log(msg);
        setsubmitLoading(false);
        errorMsg(msg);
      });
  };

  const refresh = () => {
    setRefreshLoading(true);

    getClientLocation(clientId.clientId).finally(() => {
      setRefreshLoading(false);
    });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Locations"
        subTitle={state.name}
        
        extra={[

          <Button icon={<RedoOutlined />} loading={refreshLoading} onClick={refresh} key="1" type="dashed">

          </Button>,
        ]}

      ></PageHeader>

      <Divider orientation="center">
        <Button


          hidden={!currentUser?.user.privileges?.includes('client_management')}
          type="primary"
          onClick={() => {
            setContacts(false);
            setVisible(true);
            setEditData(false);
          }}
          icon={<PlusOutlined />}
        >
          Add Location
        </Button>
      </Divider>

      <Skeleton active loading={clientsLocationsLoading}>
        <ClientLocationTable
        currentUser={currentUser}
          editHandler={editHandler}
          setContacts={setContacts}
          setVisible={setVisible}
          deleteLocation={deleteLocation}
        />
      </Skeleton>

      <Modal
        destroyOnClose={true}
        maskClosable={false}
        footer={[]}
        style={{ marginLeft: "10rem" }}
        title={
          contacts
            ? contacts?.landline? `Contacts (Landline - ${contacts?.landline})`:"Contacts"
            : editData
            ? "Edit Client"
            : "Add New Client Location"
        }
        centered
        visible={visible}
        onOk={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
        width={1000}
        getContainer={false}
      >
        {contacts ? (
          <ClientContactsTable contacts={contacts.contacts}> </ClientContactsTable>
        ) : (
          <>
            <Divider style={{ padding: "1rem" }}>
              {editData ? "Edit Client Details" : "Enter Client Details"}
            </Divider>

            <ClientForm
              setContacts={setContacts}
              submitLoading={submitLoading}
              branches={branches}
              editData={editData}
              ChangeLocation={ChangeLocation}
              locationSubmitHandler={locationSubmitHandler}
              confirmBranchEditHandler={confirmBranchEditHandler}
            />
            <Divider style={{ padding: "1rem" }}>
              {editData ? "Current Location" : "Add Client Location"}
            </Divider>

            <GoogleMap
              clientMap={clientMap}
              editData={editData}
              locationEditData={locationEditData}
              setLocationDetails={setLocationDetails}
            />
            {editData ? (
              <Button
                className="submitButton"
                form="clientForm"
                key="submit"
                type="primary"
                htmlType="submit"
                loading={submitLoading}
              >
                Confirm
              </Button>
            ) : (
              <Button
                className="submitButton"
                form="clientForm"
                key="submit"
                type="primary"
                htmlType="submit"
                loading={submitLoading}
              >
                Submit
              </Button>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default Client_locations;
