import create from "zustand";
import { devtools } from "zustand/middleware";

const clientStore = (set) => ({
    clients: [],

    addClient: (newClient) => {
        set((state) => ({
            clients: [newClient, ...state.clients],
        }));
    },
    setClient: (apiClient) => {
        set((state) => ({
            clients: [...apiClient],
        }));
    },

    updateClient: (editData) => {
        set((state) => ({
            clients: state.clients.map((client) => {
                if (client._id === editData._id) {
                    return {
                        ...editData

                    };
                } else {
                    return client;
                }
            }),
        }));
    },

    AddNewPlanToStore: (planDetails) => {
        set((state) => ({
            clients: state.clients.map((client) => {
                if (client._id === planDetails.client) {

                    // client.plan.amount=planDetails.plan.amount
                    // client.plan.no_of_products = planDetails.plan.no_of_products
                    // client.plan.start_date=planDetails.plan.start_date
                  
                    client.plan.name = [planDetails.plan.name]
                    return {
                        ...client

                    };
                } else {
                    return client;
                }
            }),
        }));
    }

});

const useClientStore = create(devtools(clientStore));

export default useClientStore