import create from "zustand";
import { devtools } from "zustand/middleware";

const vendorStore = (set) => ({
    vendors: [],

    addVendor: (newVendor) => {
        set((state) => ({
            vendors: [newVendor, ...state.vendors],
        }));
    },

    setVendors: (apiVendors) => {
        set((state) => ({
            vendors: [...apiVendors],
        }));
    },

    updateVendors: (editData) => {
        set((state) => ({
            vendors: state.vendors.map((vendor) => {
                if (vendor._id === editData._id) {
                    return {
                        ...editData,
                      
                    };
                } else {
                    return vendor;
                }
            }),
        }));
    },

    toggleVendor: (toggleVendor) => {
        set((state) => ({
            vendors: state.vendors.map((vendor) => {
                if (vendor._id === toggleVendor._id) {
                    return {
                        ...vendor,
                        is_active: !vendor.is_active,
                    };
                } else {
                    return vendor;
                }
            }),
        }));
    },

});

const useVendorStore = create(devtools(vendorStore));

export default useVendorStore
