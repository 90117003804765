import { message } from "antd";
import axios from "axios/axios";
import useDefectiveProductStore from "stores/defectiveProductStore";
import authHeader from "utils/services/auth-header";

const DefectiveProductApi = () => {
  const {
    setDefectiveProductsToStore,
    updateDefectiveProductToStore,
    addNewNoteInStore,
    moveToBinInStore,
    moveToWarehouseInStore,
      switchDefectiveProductInStore
  } = useDefectiveProductStore((state) => ({
    setDefectiveProductsToStore: state.setDefectiveProduct,
    updateDefectiveProductToStore: state.updateDefectiveProduct,
    addNewNoteInStore: state.addNewNoteInStore,
    moveToBinInStore: state.moveToBinInStore,
    moveToWarehouseInStore: state.moveToWarehouseInStore,
      switchDefectiveProductInStore: state.switchDefectiveProduct
  }));

  const getDefectiveProduct = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/defective-products", { headers: authHeader() })
        .then((response) => {
      
          setDefectiveProductsToStore(response.data.defective_products);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
          reject(err.response.data.message);
        });
    });
    // }
  };
  const getHistoricalDefectiveProduct = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/historical-defective-products", { headers: authHeader() })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
          reject(err.response.data.message);
        });
    });
    // }
  };

  const updateDefectiveProduct = async (data) => {

    return new Promise((resolve, reject) => {
      axios
        .put("/defective-product", data, { headers: authHeader() })
        .then((resp) => {
 
          updateDefectiveProductToStore(resp.data.defective_products);
      
          resolve(resp.data.message);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
          reject(err.response.data.message);
        });
    });
  };

  const switchProductApi = async (productId, defectiveProductId) => {

    return new Promise((resolve, reject) => {
      axios
        .put(
          "/switch-product/",
          { productId, defectiveProductId },
          { headers: authHeader() }
        )
        .then((resp) => {


            switchDefectiveProductInStore(resp.data.defectiveProduct);

          resolve(resp.data.message);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
          reject(err.response.data.message);
        });
    });
  };

    const moveToBinApi = async (data, switchingProduct) => {
     //switchingProduct will be tru for  switching flow
    data.switchingProduct = switchingProduct

    return new Promise((resolve, reject) => {
      axios
        .post("/move-to-bin", data, { headers: authHeader() })
        .then((resp) => {
 
        !switchingProduct&&  moveToBinInStore(data);
       
          message.success(resp.data.message);
          resolve(resp.data.message);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.error);
          reject(err.response.data.error);
        });
    });
  };

  const getBinProductsApi = async (data) => {

    return new Promise((resolve, reject) => {
      axios
        .get("/move-to-bin", data, { headers: authHeader() })
        .then((resp) => {
    
          // moveToBinInStore(data);

          resolve(resp.data);
        })
        .catch((err) => {
          console.log(err);

          reject();
        });
    });
  };

  const moveToWarehouseApi = async (
    data,
    selectedWarehouse,
    switchingProduct
  ) => {


    const payload = {
      defectiveProduct: data._id,
      task: data.task,
      client_product: data.client_product._id,
      plan: data.client_product.plan,
      switchingProduct,
      selectedWarehouse,
    };


    return new Promise((resolve, reject) => {
      axios
        .post("/move-to-warehouse", payload, { headers: authHeader() })
        .then((resp) => {
       
          !switchingProduct && moveToWarehouseInStore(data);
       
          message.success(resp.data.message);
          resolve(resp.data.message);
        })
        .catch((err) => {
          console.log(err);
            message.error(err.response.data.error);
          reject(err.response.data.message);
        });
    });
  };

  const receiveDefectiveProduct = async (product) => {
    // client_product_id


    return new Promise((resolve, reject) => {
      axios
        .post(
          "/defective-product/received",
          {
            product_id: product._id,
            client_product_id: product.client_product._id,
          },
          { headers: authHeader() }
        )
        .then((resp) => {
      

          product.status = "received";
          product.picked_up_by = false;
          updateDefectiveProductToStore(product);
      
          resolve(resp.data.message);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
          reject(err.response.data.message);
        });
    });
  };

  const addNewNote = async (data) => {

    return new Promise((resolve, reject) => {
      axios
        .post("/defective-product/note", data, { headers: authHeader() })
        .then((resp) => {
      
          resolve(resp.data.message);
          addNewNoteInStore({
            text: data.note,
            time: new Date(),
            product_id: data.product_id,
          });
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
          reject(err.response.data.message);
        });
    });
  };

  const submitCost = async (data) => {


    return new Promise((resolve, reject) => {
      axios
        .post("/defective-product/cost", data, { headers: authHeader() })

        .then((resp) => {
    
          resolve(resp.data);
          updateDefectiveProductToStore(resp.data.defective_product);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
          reject(err?.response?.data?.message);
        });
    });
  };

  const editCost = async (data) => {


    return new Promise((resolve, reject) => {
      axios
        .put("/defective-product/cost", data, { headers: authHeader() })

        .then((resp) => {
          // message.success(resp.data.message)
  
          resolve(resp.data);
          updateDefectiveProductToStore(resp.data.defective_product);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
          reject(err?.response?.data?.message);
        });
    });
  };

  return {
    switchProductApi,
    getDefectiveProduct,
    getHistoricalDefectiveProduct,
    updateDefectiveProduct,
    addNewNote,
    receiveDefectiveProduct,
    submitCost,
    editCost,
    moveToBinApi,
    moveToWarehouseApi,
    getBinProductsApi,
  };
};

export default DefectiveProductApi;
