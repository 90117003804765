import {
  Button,
  Divider,
  Input,
  Modal,
  Popover,
  Space,
  Table,
  Tag,
  Timeline,
  Checkbox,
  Form,
} from "antd";
import {
  PlusOutlined,
  RedoOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import useProductStore from "stores/productStore";
import {
  EyeOutlined,
  EditOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import humanize from "utils/formate-status";
import usePaymentStore from "stores/paymentStore";
import moment from "moment";
import PaymentApi from "utils/composable/paymentApi";
import useInvoiceStore from "stores/invoiceStore";
import "./importTable.css";
import useItems from "antd/lib/menu/hooks/useItems";
const ImportTable = ({
  handleCancel,
  confirmEditHandler,
  editHandler,
  showSpecs,
  selectedproducts,
  setSelectedProducts,
  products,
  state,
  currentClient,
  setCurrentClient,
  updateHandler,
  importedInvoices,
  setFinalImportData,
  manualReceive,
}) => {
  const payments = usePaymentStore((state) => state.payments);
  const invoices = useInvoiceStore((state) => state.invoices);
  const [importedInvoicesData, setImportedInvoicesData] = useState([]);
  const [importedAmount, setImportedAmount] = useState("");
  const [adjustedAmount, setAdjustedAmount] = useState("");

  useEffect(() => {
    console.log(importedInvoices, "Imported Invoices");

    const importedInvoicesIds = importedInvoices.map(
      (invoice) => invoice.invoice
    );

    console.log({ importedInvoicesIds });
    const filteredInvoicesDatas = invoices.filter(
      (invoice) =>
        importedInvoicesIds.includes(invoice.invoice_number.toString()) &&
        (invoice.status == "pending" || invoice.status == "partially_paid")
    );

    console.log({ filteredInvoicesDatas });

    const importedInvoicesData = filteredInvoicesDatas
      .map((invoice) => {
        return importedInvoices.map((importedInvoice) => {
          if (
            invoice.invoice_number.toString() ==
            importedInvoice.invoice.toString()
          ) {
            console.log(invoice, importedInvoice, "invoice");

            invoice.price.received_amount = importedInvoice.amount;

            return invoice;
          }
        });
      })
      .flat()
      .filter((item) => item);

    console.log({ importedInvoicesData });

    setImportedInvoicesData(importedInvoicesData);

    setFinalImportData(importedInvoicesData);
  }, []);

  const navigate = useNavigate();

  const content = (data) => {
    return (
      <div>
        <p>{`Item Price :  ₹ ${Math.trunc(data?.price.item_total)}`}</p>
        <p>{`Qty :  ₹ ${Math.trunc(data?.price.qty)}`}</p>
        <p>{`Tax :  ₹ ${Math.trunc(data?.price.tax_amount)}`}</p>
      </div>
    );
  };

  const logTimeline = (data) => {
    Modal.info({
      width: "50rem",

      title: "Plan Logs",
      content: (
        <Timeline mode={"left"}>
          {data.logs.map((log) => {
            console.log(log, "logs");

            var color;
            var locaTime = new Date(log.date);
            var time = locaTime.toString();

            if (log.vendor_transferred_date) {
              color = "#007FFF";
            } else if (log.vendor_transferred_date) {
              color = "green";
            } else {
              color = "#5D76A9";
            }

            function formatTimeShow(h_24) {
              var h = h_24 % 12;
              if (h === 0) h = 12;
              return (
                h + `: ${time.substr(19, 2)}` + (h_24 < 12 ? " am" : " pm")
              );
            }

            var exactTime = formatTimeShow(parseFloat(time.substr(15, 10)));
            var text;
            var suffix = "";

            text = "by  " + log?.by.name;

            return (
              <Timeline.Item
                style={{ fontSize: "15px" }}
                dot={
                  log.type == "created" ? (
                    <CheckOutlined />
                  ) : log.type == "cancelled" ? (
                    "X"
                  ) : (
                    ""
                  )
                }
                color={color}
              >
                {suffix} {log?.text} {text}{" "}
                <span style={{ fontWeight: "200" }}> on</span>{" "}
                <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                  {" "}
                  {time.substr(3, 12)}, {exactTime}{" "}
                </span>
              </Timeline.Item>
            );
          })}
        </Timeline>
      ),
      onOk() {},
    });
  };

  useEffect(() => {
    setAdjustedAmount("");
  }, [importedAmount]);

  const changeImportedAmount = (e, row) => {
    console.log(row);

    const finalImportedData = importedInvoicesData.map((invoice) => {
      if (invoice._id == row._id) {
        invoice.price.received_amount = parseFloat(e.target.value);
        invoice.changed = true;
        setImportedAmount(e.target.value);
        return invoice;
      } else {
        return invoice;
      }
    });

    console.log({ finalImportedData });
    //   finalImportedData(finalImportedData)
  };

  const changeAdjustedAmount = (e, row) => {
    console.log(row);

    const finalImportedData = importedInvoicesData.map((invoice) => {
      if (invoice._id == row._id) {
        invoice.price.adjusted_amount = parseFloat(e.target.value);

        return invoice;
      } else {
        return invoice;
      }
    });

    console.log({ finalImportedData });

    setFinalImportData(finalImportedData);
  };

  const columns = [
    {
      title: "Invoice No",
      dataIndex: "invoice_number",
      width: "6%",
      key: "6",
    },

    {
      title: "Client",
      render: (_, record) => record?.client?.name,
      key: "2",
      editable: true,

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target?.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.name?.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "4",
      render: (_, record) => {
        if (record?.status == "invoice_created") {
          return (
            <Button
              onClick={() =>
                navigate("/invoice/" + record?.invoice, {
                  state: {
                    payment: record,
                  },
                })
              }
            >
              Invoice Created
            </Button>
          );
        } else {
          return humanize(record?.status || "");
        }
      },

      filters: [
        { text: "Paid", value: "paid" },
        { text: "Pending", value: "pending" },
        { text: "Cancelled", value: "cancelled" },
      ],

      onFilter: (value, record) => record.status.includes(value),
    },

    {
      title: "Date",
      dataIndex: "date",
      render: (_, record) => moment(_).format("DD-MM-YYYY"),
      key: "3",
    },

    {
      title: "Description",
      dataIndex: "description",
      render: (_, record) => record.description,
      key: "3",
    },

    {
      title: "Total Amount",
      dataIndex: "item_total",
      render: (_, record) => (
        <>
          {Math.trunc(record.price.amount)}
          <Popover
            style={{ padding: "1rem" }}
            content={() => content(record)}
            title="Payment Details
                "
          >
            <ExclamationCircleOutlined
              style={{ paddingLeft: "0.5rem" }}
            ></ExclamationCircleOutlined>
          </Popover>
        </>
      ),
      key: "3",
    },

    {
      title: "Paid Amount",
      render: (_, record) => record.paid_amount,
      key: "3",
    },

    {
      title: "Adjusted Amount",
      render: (_, record) => record.adjusted_amount,
      key: "3",
    },

    {
      title: "Due Amount",
      render: (record) => {
        if (record.status != "partially_paid") {
          return (
            <>
              <span
                style={{
                  color:
                    Math.trunc(record.price.amount) ==
                    Math.trunc(record?.price?.received_amount)
                      ? "green"
                      : "red",
                }}
              >
                {" "}
                {Math.trunc(record?.price?.amount)}{" "}
              </span>
            </>
          );
        } else {
          return (
            <>
              <span style={{ color: "#FF9933" }}>
                {" "}
                {Math.trunc(record?.balance_amount)}{" "}
              </span>

              
            </>
          );
        }
      },
      key: "2",
    },

    {
      title: "Received Amount",

      render: (value, row, index) => {
        if (
          Math.trunc(row?.price?.received_amount) ==
            Math.trunc(row.price.amount) &&
          !manualReceive
        ) {
          return row?.price?.received_amount;
        } else {
          return (
            <>
              <Input
                defaultValue={row.price.received_amount}
                onChange={(e) => changeImportedAmount(e, row)}
              />
            </>
          );
        }
      },
      key: "2",
    },

    {
      title: "Adjusted Amount",

      render: (value, row, index) => {
        if (
          Math.trunc(row?.price?.received_amount) ==
            Math.trunc(row.price.amount) &&
          !row.changed
        ) {
          return "";
        } else {
          return (
            <>
              <Input onChange={(e) => changeAdjustedAmount(e, row)} />
            </>
          );
        }
      },
      key: "2",
    },

    {
      title: "Balance",

      render: (value, row, index) => {
        if (row.status == "partially_paid") {
          return (
            Math.trunc(row.balance_amount) -
            (Math.trunc(row?.price?.received_amount || 0) +
              Math.trunc(+row?.price.adjusted_amount || 0))
          );
        } else {
          return (
            Math.trunc(row.price.amount) -
            (Math.trunc(row?.price?.received_amount || 0) +
              Math.trunc(row?.price.adjusted_amount || 0))
          );
        }
      },
      key: "2",
    },
  ];

  return (
    <>
      <Table
        key={invoices?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={false}
        dataSource={importedInvoicesData}
      />
    </>
  );
};

export default ImportTable;
