
import { AutoComplete, Button, Dropdown, Input, Menu, Space, Table, Tag, Tooltip, } from "antd";

import {
  CodepenOutlined,
  ContactsFilled,
  EditOutlined,
  EnvironmentTwoTone,
  MoreOutlined,
  ProfileOutlined,
  SearchOutlined
} from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useClientStore from "stores/clientStore";

const ClientTable = ({ setVisible, setContacts, editHandler, currentUser }) => {
  const clients = useClientStore((state) => state.clients);
  var clientsList = clients
  const navigate = useNavigate();

  const [filteredClient, setFilteredClient] = useState(false)

  const showContacts = (client) => {
    setVisible(true);

  

    setContacts(client);

  };








  const [currentQuery, setCurrentQuery] = useState('')

  const fuzzyFilter = (query, values) => {

    console.log(query);

    setCurrentQuery(query)

    var get_bigrams, j, len, name, names, obj, relevance, results, string_similarity;


    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };


    string_similarity = function (str1, str2) {

      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };


    if (query.length > 2) {
    results = [];

    for (j = 0, len = clientsList.length; j < len; j++) {
      var client = clientsList[j];
      relevance = string_similarity(query, client?.name || "");
      obj = {
        ...client,
        relevance: relevance
      };
      results.push(obj);
    }


    var sortedResults = results.filter((result) => result.relevance > 0.2)

    sortedResults = sortedResults.sort((a, b) => a.relevance - b.relevance).reverse()

    console.log({ sortedResults });

    clientsList = [...sortedResults]

    setFilteredClient(sortedResults)
    console.log(results);
  }
  }

  useEffect(() => {

    if (filteredClient.length) {

      fuzzyFilter(currentQuery)

    }


  }, [clients])


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
            <AutoComplete
          style={{ margin: "1rem", width: "25rem" }}
          autoFocus
          value={selectedKeys[0]}
          options={[...new Set(clients.map(client => client.name))].map(name => ({ value: name }))}

          onSearch={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          onSelect={(value) => {
            setSelectedKeys([value]);
            confirm();
          }}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          placeholder="Search"
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
        <Button
          style={{ margin: "1rem" }}
          type="primary"
          onClick={() => {
            confirm();
          }}
        >
          Search
        </Button>
        <Button
          style={{ marginRight: "1rem" }}
          onClick={() => {
            clearFilters({ confirm: true });
            setFilteredClient(false)
            setSelectedKeys("");
            confirm()
          }}
        >
          Reset
        </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name.replace(/[^a-zA-Z]/g, "").toLowerCase().includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "30%",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.address.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Status",
      key: "4",
      dataIndex: "status",
      render: (_, clients) => {
        const str1 = clients.status;
        const str2 = clients.status.charAt(0).toUpperCase() + str1.slice(1);
        return str2;
      },
      key: "15",
      width: "10%",

      filters: [
        { text: "VIP", value: "VIP" },
        { text: "Ordinary", value: "Ordinary" },
        { text: "Blacklisted", value: "Blacklistedca" },
      ],

      onFilter: (value, record) => record.status.includes(value),
    },

    {
      title: "Contacts",
      dataIndex: "contacts",
      key: "5",
      render: (_, clients) => (
        <>


          <Tooltip
            title={"Contacts"}
          >


            <ContactsFilled
              onClick={() => {
                showContacts(clients);
              }}
            />{" "}
            ({clients?.contacts?.length})

          </Tooltip>



        </>
      ),
      align: "center",
      width: "3%",
    },

    {
      title: "Locations",
      dataIndex: "_id",
      key: "6",
      width: "3%",
      render: (_, clients) => (
        <>


          <Tooltip
            title={"Client Locations"}
          >

            <EnvironmentTwoTone
              onClick={() =>
                navigate("/client/locations/" + clients?._id, {
                  state: { name: clients.name },
                })
              }
            />{" "}
            ({clients?.additional_locations?.length})
          </Tooltip>

        </>
      ),
      align: "center",

    },
    {
      title: "Products",
      dataIndex: "products",
      key: "7",
      render: (_, clients) => (
        <>

          <Tooltip
            title={"Client Products"}
          >
            <CodepenOutlined onClick={() =>
              navigate("/client/products/" + clients?._id, {
                state: {
                  name: clients.name,
                  id: clients._id
                },
              })
            } />{" "}
            ({(clients?.products?.length - (clients.additional_locations?.length+1))<0?0:clients?.products?.length - (clients.additional_locations?.length+1)})
          </Tooltip>

        </>
      ),
      align: "center",
      width: "3%",


    },

    {

      title: "Plan",

      key: "3",
      render: (record, row) => (row?.plan?.name.map((planName) => <Tag>{planName}</Tag>)),
      onFilter: (value, record) => record?.plan?.name?.includes(value),
      filters: [
        { text: "PCB", value: "PCB" },
        { text: "ASC", value: "ASC" },
        { text: "AMC", value: "AMC" },
      ],


    },
    

    {
      title: "Next Maintenance ",
      dataIndex: "next_maintenance_date",
      key: "3",
      render: (record, row) => (row.is_active && row.maintenance_interval) ? moment.utc(record).format("DD-MM-YYYY") : '',
      width: "2rem",
    },
    


    {
      title: "Action",
      key: "dropDown",
      render: (_, clients) => (

        <>  <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">

                <Button
                  type="link"
                  icon={<EditOutlined />}
                  hidden={!currentUser.privileges.includes('client_management')} onClick={() => editHandler(clients)}
                >
                  Update Client

                </Button>

              </Menu.Item>
              <Menu.Item key="2">
                <Button
                  type="link"
                  icon={<ProfileOutlined />}
                  disabled={!currentUser.privileges.includes('client_management')}
                  onClick={() =>
                    navigate("/client/products/" + clients?._id, {
                      state: {
                        name: clients.name,
                        id: clients._id,
                        ASC_AMC: true,

                      },
                    })
                  } >Update Plan

                </Button>
              </Menu.Item>
            </Menu>
          }
        >
          <Button

            style={{ borderRadius: '5px', width: '1.8rem' }} icon={
              <MoreOutlined />} >

          </Button>
        </Dropdown>
        </>



      ),
      align: "center",
    },




  ];

  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      ></Space>

      <Table rowKey={"_id"} columns={columns} dataSource={filteredClient ? filteredClient : clientsList} />
    </>
  );
};

export default ClientTable;
