import { Divider, Modal, Popover, Table, Timeline } from "antd";

import React, { useEffect, useState } from "react";

import { CheckOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import humanize from "utils/formate-status";
import usePaymentStore from "stores/paymentStore";
import moment from "moment";
import PaymentApi from "utils/composable/paymentApi";
const InvoiceItemTable = ({
  item,
  handleCancel,
  confirmEditHandler,
  editHandler,
  showSpecs,
  selectedproducts,
  setSelectedProducts,
  products,
  state,
  currentClient,
  setCurrentClient,
  updateHandler,
}) => {
  const payments = usePaymentStore((state) => state.payments);





  const content = (data) => {
    return (
      <div>
        <p>{`Tax :  ₹ ${data?.price.tax_amount.toFixed(2)}`}</p>
      </div>
    );
  };

  

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "4",
      render: (_, record) => {
        return humanize(_ ? _ : "");
      },
    },

    {
      title: "Date",
      dataIndex: "date",
      render: (_, record) => moment(_).format("DD-MM-YYYY"),
      key: "3",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "1",
      width: "20%",
    },

    {
      title: "Price",

      key: "1",
      width: "10%",
      render: (record) => {
        return (
          <>
            {record.price.item_price.toFixed(2)}

            <Popover
              style={{ padding: "1rem" }}
              content={() => content(record)}
              title="Payment Details
                "
            ></Popover>
          </>
        );
      },
    },

    {
      title: "Qty",
      key: "1",
      width: "10%",
      render: (record) => {
        return <>{Math.trunc(record.price.qty)}</>;
      },
    },

    {
      title: "Tax",

      key: "1",
      width: "5%",
      render: (record) => {
        return <>{record.price.tax_amount.toFixed(2)}</>;
      },
    },

    {
      title: "Amount",

      render: (record) => {
        return (
          <>
            {record.price.amount.toFixed(2)}

            <Popover
              style={{ padding: "1rem" }}
              content={() => content(record)}
              title="Payment Details
                "
            ></Popover>
          </>
        );
      },
      key: "2",
    },
  ];

  return (
    <>
      <Divider>Items</Divider>
      <Table
        key={payments?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={false}
        dataSource={item}
        bordered
      />
    </>
  );
};

export default InvoiceItemTable;
