import { message } from "antd";
import axios from "axios/axios";
import authHeader from "utils/services/auth-header";
import useTaxStore from "../../stores/taxStore";

const TaxApi = () => {
  const { addTaxToStore, setTaxesToStore } = useTaxStore((state) => ({
    setTaxesToStore: state.setTaxes,
    addTaxToStore: state.addTax,
  }));

  const getTaxes = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/taxes", { headers: authHeader() })
        .then((response) => {
  
          setTaxesToStore(response.data.taxes);
          resolve(response.data.taxes);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
          reject(err.response.data.message);
        });
    });
    // }
  };

  const addTax = async (data) => {

    return new Promise((resolve, reject) => {
      axios
        .post("/tax", data, { headers: authHeader() })
        .then((resp) => {
    
          addTaxToStore(resp.data.tax);

          resolve(resp.data.tax);
        })
        .catch((err) => {
          console.log(err);
          reject(err.response.data.message);
        });
    });
  };

  const deleteTax = async (data) => {


    return new Promise((resolve, reject) => {
      axios
        .delete("/tax", {
          headers: authHeader(),
          data: { tax: data },
        })
        .then((resp) => {
 
          // updateRequestInStore(resp.data.request);

          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject(err.response.data.message);
        });
    });
  };

  return { getTaxes, addTax, deleteTax };
};

export default TaxApi;
