import "../App.less";
import React, { useEffect, useState } from "react";
import { Button, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import getCurrentUser from "utils/services/get-user";
import Circuteklogo from '../assets/circuteklogo.png'
import CALOGO from '../assets/CALOGO.png'



import {
  PoweroffOutlined, UserOutlined
  
} from "@ant-design/icons";
import DropDown from "components/header.js/DropDown";
import DropDownMenu from "components/header.js/DropDown";
const Header = () => {


  const navigate = useNavigate()

  const user=getCurrentUser()



const logOut=()=>{

localStorage.removeItem('user')
  navigate('/login')

}

  const { Header } = Layout;
  return (
    <Header
    
      className="site-layout-background"
      style={{
        marginBottom: 30,
        padding: 0,
        position:'relative !important',
        height:'3rem',
  
      
        
      }}
    >
   



       {/*<img style={{ height: '3rem', float: 'left', padding: '3px', marginLeft: '1rem' }} src={CALOGO} alt="Logo" />*/}

        <img style={{height:'3rem',float:'left',padding:'3px',marginLeft:'1rem'}}  src={Circuteklogo} alt="Logo" />  



      {/* {user && <Button type="primary" icon={<PoweroffOutlined />} onClick={logOut} style={{ marginTop:'.5rem', width: '3rem', position:'absolute',right:'1rem' }} ></Button>}     */}
      {user && <div style={{ marginTop: '-.4rem', width: '3rem', position: 'absolute', right: '6rem' }} >< DropDownMenu  /> </div> }  

    </Header>
  );
};

export default Header;
