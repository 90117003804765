import { CheckOutlined, PlusOutlined, RedoOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Modal,
  PageHeader,
  Skeleton,
  Statistic,
  Timeline,
  message,
} from "antd";
import GoogleMap from "components/googleMap/GoogleMap";
import TaskForm from "components/task/TaskForm";
import TaskTable from "components/task/TaskTable";
import TaskTimeline from "components/task/TaskTimeline";
import { useEffect, useState } from "react";
import useTaskStore from "stores/taskStore";
import ClientApi from "utils/composable/clientApi";
import TaskApi from "utils/composable/taskApi";
import TeamApi from "utils/composable/teamApi";

const Task = ({ currentUser }) => {
  const [visible, setVisible] = useState(false);
  const [tableLoading, setTableLoadings] = useState(false);
  const [moreData, setMoredata] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [locationDetails, setLocationDetails] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [hideAddressBox] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState();
  const [editData, setEditData] = useState("");
  const [jobEditMode, setJobEditMode] = useState(false);
  const [jobAddMode, setJobAddMode] = useState(editData ? false : true);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [jobCancelLoading, setJobCancelLoading] = useState(false);
  const [taskCancelLoading, setTaskCancelLoading] = useState(false);
  const [taskcancelled, setTaskCancelled] = useState(false);

  // for cchanging googlemap class name
  const [taskForm, setTaskForm] = useState(true);

  //composable
  const { getClients } = ClientApi();
  const { getUsers } = TeamApi();
  const {
    updateTask,
    addTask,
    getTasks,
    getEngineers,
    editTaskJobs,
    AddTaskJobs,
    cancelTaskApi,
    cancelJobApi,
    closeTaskApi
  } = TaskApi();



  const getTaskCountByStatus = (tasks, status) => {
    return tasks.filter((task) => task.status === status).length;
  };
  
  const getTaskCountByType = (tasks, type) => {
    return tasks.filter((task) => task.type === type).length;
  };
  
  const getTodaysTaskCount = (tasks) => {
    const today = new Date().toISOString().split('T')[0]; // Extract the date part (YYYY-MM-DD)
  
    return tasks.filter((task) => {
      const taskDate = new Date(task.createdAt).toISOString().split('T')[0]; // Extract the date part from the task's createdAt
      return taskDate === today;
    }).length;
  };
  const taskTypes = {
    complaint: "Complaint",
    inventory_check: "Inventory Check",
    product_delivery: "Product Delivery",
    stand_by_product_return: "Stand By Rertun",
    // Add more types as needed
  };
  const TaskStatistic = ({ title, value, color = "black" }) => (
    <div style={{ marginLeft: "2rem", marginTop: "5px" }}>
      <Statistic
        title={title}
        value={value}
        valueStyle={{ color, fontSize: "15px", marginLeft: "3rem" }}
      />
    </div>
  );
  

  const tasks = useTaskStore((state) => state.tasks);

  const unassignedCount = getTaskCountByStatus(tasks, "unassigned");
const todaysTasksCount = getTodaysTaskCount(tasks);

const taskTypeCounts = Object.keys(taskTypes).map((type) => ({
  type: taskTypes[type],
  count: getTaskCountByType(tasks, type),
}));


  const handleCancel = (form) => {
    setVisible(false);
  };

  //notification
  const taskAdded = (msg) => {
    message.success(msg);
  };

  const jobSuccessMsg = (msg) => {
    message.success(msg);
  };

  const jobErrorMsg = (msg) => {
    message.error(msg);
  };

  const fillForm = () => {
    message.error("Please select Client and Date first ");
  };
  const taskUpdated = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  useEffect(() => {
    getClients();
    getUsers();

    if (!tasks.length) {
      setTableLoadings(true);

      getTasks()
        .then((resp) => {
      
          
        })
        .finally(() => {
          setTableLoadings(false);
        });
    }

    setTaskForm(true);
  }, []);

  const refresh = () => {
    setRefreshLoading(true);
    getClients();
    getUsers();
    getTasks().finally(() => {
      setRefreshLoading(false);
    });
  };

  const editMode = (task) => {
    const formatDate = (date) => {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    };

    task.formattedDate = formatDate(task.due_date);


    

    task.jobs = task.jobs.map((job) => {
  
      return {
        ...job,
        productId: job.product?._id,
      };
    });

   
    // //reversing lat and lng position for google map
    // task.location.coordinates = task.location?.coordinates?.reverse();
    setEditData(task);
    setVisible(true);
  };

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData?.location?.coordinates);
  };


  const submitHandler = (payload, form) => {


    setsubmitLoading(true);
    addTask(payload)
      .then((msg) => {
        setsubmitLoading(false);
        setVisible(false);
        form.resetFields();
        taskAdded(msg);
        setLocationEditData(false);
        setLocationDetails(false);
      })
      .catch((msg) => {
        setsubmitLoading(false);
        errorMsg(msg);
      });
  };

  const updateHandler = (payload, form) => {


    setsubmitLoading(true);
    updateTask(payload)
      .then((msg) => {
        setsubmitLoading(false);
        setVisible(false);
        form.resetFields();
        taskUpdated(msg);
        setLocationEditData(false);
        setLocationDetails(false);
      })
      .catch((msg) => {
        setsubmitLoading(false);
        errorMsg(msg);
      });
  };

  const jobUpdateHandler = (key, form) => {
    let jobs = form.getFieldValue("jobs");

    const editedJob = jobs[key];

    editedJob.job_id =
      editedJob?._id === "undefined"
        ? tasks.find((task) => task?._id === editData?._id).jobs[key]?._id
        : editedJob?._id;

    editedJob.task_id = editData._id;

    editedJob.job = {
      product: editedJob.productId,
      description: editedJob.description,
      status: editedJob.status,
      type: editedJob.type,
      assigned: editedJob.assigned[0]._id
        ? editedJob.assigned[0]._id
        : editedJob.assigned,
    };

    if (editedJob?.stand_by_product_request) {
      const jobUpdatePayload = {
        job_id:
          editedJob?._id ||
          tasks.find((task) => task._id === editData._id).jobs[key]._id,
        task_id: editData._id,
        job: {
          stand_by_product_request:
            editedJob?.stand_by_product_request?._id || "",
          product: editedJob.productId,
          description: editedJob.description,
          status: editedJob.status,
          type: editedJob.type,
          assigned: editedJob.assigned[0]._id
            ? editedJob.assigned[0]._id
            : editedJob.assigned,
        },
      };

      setJobLoading(true);
      editTaskJobs(jobUpdatePayload, key, editedJob)
        .then((resp) => {
          setJobLoading(false);
          setJobEditMode(false);
          jobSuccessMsg(resp);
        })
        .catch((err) => {
          setJobLoading(false);
          console.log(err);
          jobErrorMsg(err);
        });
    } else {
      const jobUpdatePayload = {
        job_id:
          editedJob?._id ||
          tasks.find((task) => task._id === editData._id).jobs[key]._id,
        task_id: editData._id,
        job: {
          product: editedJob.productId,
          description: editedJob.description,
          status: editedJob.status,
          type: editedJob.type,
          assigned: editedJob.assigned[0]._id
            ? editedJob.assigned[0]._id
            : editedJob.assigned,
        },
      };

      setJobLoading(true);
      editTaskJobs(jobUpdatePayload, key, editedJob)
        .then((resp) => {
          setJobLoading(false);
          setJobEditMode(false);
          jobSuccessMsg(resp);
        })
        .catch((err) => {
          setJobLoading(false);
          console.log(err);
          jobErrorMsg(err);
        });
    }
  };

  const jobSubmitHandler = (key, form, fieldEngineer) => {
    const jobs = form.getFieldValue("jobs");

    jobs[jobs.length - 1].assigned = fieldEngineer;
    const newJob = jobs[jobs.length - 1];

    
    if (!newJob.type || !newJob.productId) {
      message.error("Please  fill the job details");
      return;
    }

    const newJobPayload = {
      task_id: editData._id,
      job: {
        product: newJob.productId,
        description: newJob.description,
        assigned: fieldEngineer,
        type: newJob.type,
      },
    };

    if(newJob.type === 'installation'){
      newJobPayload.job.parentJob = newJob.parentJob;
    }

    setJobLoading(true);

    AddTaskJobs(newJobPayload)
      .then((task) => {
        if (editData) {
          editMode(task);
        }
        setJobLoading(false);
        setJobAddMode(false);
        jobSuccessMsg("Job added");
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  const cancelTask = (task_id, task) => {
    setTaskCancelLoading(true);
    //to Control fuzzy search useEffect
    setTaskCancelled(true);

    cancelTaskApi(task_id, task)
      .then(() => {
        setTaskCancelLoading(false);
        setTaskCancelled(false);
      })
      .finally(() => {
        setTaskCancelLoading(false);
      });
  };


  const closeTask = (task_id, task) => {

    setTaskCancelLoading(true);
    setTaskCancelled(true);

    closeTaskApi(task_id, task)
      .then(() => {
        setTaskCancelLoading(false);
        setTaskCancelled(false);
      })
      .finally(() => {
        setTaskCancelLoading(false);
      });
  };



  const cancelJob = (task_id, job_id, jobNumber) => {
    setJobCancelLoading(true);

    cancelJobApi(task_id, job_id, jobNumber)
      .then(() => {
        setJobCancelLoading(false);

        let currentTask = tasks.find((task) => task._id === task_id);
        let pendingJobs = currentTask.jobs.filter(
          (job) =>
            job.status === "assigned" ||
            job.status === "unassigned" ||
            job.status === "in_progress" ||
            job.status === "unable_to_complete"
        );
        if (!pendingJobs.length) {
          Modal.confirm({
            title:
              "'All jobs are cancelled. Do you want to cancel the entire task?'",
            okText: "Yes",
            cancelText: "Cancel",

            onOk: () => {
              cancelTask(task_id, currentTask);
            },
            // onCancel: () => handleCancel(),
          });
        }
      })
      .finally(() => {
        setJobCancelLoading(false);
      });
  };

  const getFieldEngineers = (client, date) => {
  
    if (!client || !date) {
      fillForm();
    } else {
      setAssignLoading(true);
      getEngineers(client, date)
        .then((resp) => {
          setAssignLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAssignLoading(false);
          jobErrorMsg(err);
        });
    }
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        footer={[]}
        style={{ marginLeft: "10rem" }}
        title={moreData ? "More" : " "}
        centered
        visible={modalVisible}
        onOk={() => {
          setModalVisible(false);
        }}
        onCancel={() => {
          setJobEditMode(false);
          setEditData(false);
          setModalVisible(false);
        }}
        width={800}
        getContainer={false}
      >
        <h3> {`Assigned By : ${moreData?.assigned_by?.name}`} </h3>
        <Divider>Description</Divider>
        {moreData.description}
        <br></br>
        <Divider>Notes</Divider>

        <Timeline mode={"left"}>
          {moreData?.notes?.map((log) => {
            var color;
            var locaTime = new Date(log.time);
            var time = locaTime.toString();

            if (log.vendor_transferred_date) {
              color = "yellow";
            } else if (log.vendor_transferred_date) {
              color = "green";
            } else {
              color = "darkBlue";
            }

            function formatTimeShow(h_24) {
              var h = h_24 % 12;
              if (h === 0) h = 12;
              return (
                h + `: ${time.substr(19, 2)}` + (h_24 < 12 ? " am" : " pm")
              );
            }

            var exactTime = formatTimeShow(parseFloat(time.substr(15, 10)));
            var text;
            var suffix = "";

            text = "by  " + log?.by_name;

            return (
              <Timeline.Item
                style={{ fontSize: "15px" }}
                dot={log.type === "complete" ? <CheckOutlined /> : ""}
                color={color}
              >
                {suffix} {log?.text} {text}{" "}
                <span style={{ fontWeight: "200" }}> on</span>{" "}
                <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                  {" "}
                  {time.substr(3, 12)}, {exactTime}{" "}
                </span>
              </Timeline.Item>
            );
          })}
        </Timeline>
        <Divider>Logs </Divider>
        <TaskTimeline taskLogs={moreData.logs} />
      </Modal>

      <PageHeader
      className="site-page-header"
      style={{ padding: 0, marginTop: -15 }}
     title="Task"
     extra={<Button
      icon={<RedoOutlined />}
      loading={refreshLoading}
      onClick={refresh}
      key="1"
      type="dashed"
    ></Button>}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap", // Keep items on a single line
          justifyContent: "space-between", // Distribute space evenly
          gap: "1rem",
          marginTop: "5px",
          overflowX: "auto", // Allow horizontal scrolling if content overflows
        }}
      >
        <TaskStatistic title="Unassigned Tasks" value={unassignedCount} color="orange" />
        <TaskStatistic title="Today's Tasks" value={todaysTasksCount} color="blue" />
        {taskTypeCounts.map(({ type, count }) => (
          <TaskStatistic key={type} title={`${type} Tasks`} value={count} />
        ))}
      </div>
    </PageHeader>

      <Divider orientation="center">
        <Button
          disabled={!currentUser?.user.privileges?.includes("task_creation")}
          type="primary"
          onClick={() => {
            setEditData("");
            setVisible(true);
            setLocationEditData(false);
            setLocationDetails(false);
          }}
          icon={<PlusOutlined />}
        >
          Create Task
        </Button>
      </Divider>

      <Skeleton active loading={tableLoading}>
        <TaskTable
          closeTask={closeTask}
          currentUser={currentUser.user}
          taskcancelled={taskcancelled}
          jobCancelLoading={jobCancelLoading}
          taskCancelLoading={taskCancelLoading}
          cancelJob={cancelJob}
          cancelTask={cancelTask}
          refresh={refresh}
          setVisible={setVisible}
          setModalVisible={setModalVisible}
          setMoredata={setMoredata}
          editMode={editMode}
        />
      </Skeleton>

      <div
        className="container"
        style={{ maxWidth: "100% !important", overflowX: "hidden" }}
      >
        <Drawer
          destroyOnClose={true}
          width={"100vW"}
          title={
            editData ? `Update Task ${editData.task_number} ` : "Create Task"
          }
          size={"large"}
          placement="right"
          onClose={handleCancel}
          visible={visible}
        >
          <Divider style={{ padding: "1rem" }}>Enter Task Details</Divider>

          <TaskForm
          
            jobSubmitHandler={jobSubmitHandler}
            jobUpdateHandler={jobUpdateHandler}
            locationDetails={locationDetails}
            submitHandler={submitHandler}
            ChangeLocation={ChangeLocation}
            editData={editData}
            updateHandler={updateHandler}
            getFieldEngineers={getFieldEngineers}
            assignLoading={assignLoading}
            setJobLoading={setJobLoading}
            jobLoading={jobLoading}
            setJobEditMode={setJobEditMode}
            jobEditMode={jobEditMode}
            jobAddMode={jobAddMode}
            setJobAddMode={setJobAddMode}
          />

          <Divider style={{ marginBottom: "1rem" }}>Client Location</Divider>

          <GoogleMap
            taskForm={taskForm}
            hideAddressBox={hideAddressBox}
            locationEditData={locationEditData}
            setLocationDetails={setLocationDetails}
          />

          {!editData ? (
            <Button
              className="submitButtonTask"
              form="taskForm"
              key="submit"
              type="primary"
              htmlType="submit"
              loading={submitLoading}
            >
              Submit
            </Button>
          ) : (!jobAddMode && !jobEditMode) ||
            (jobEditMode &&
              (editData.type === "inventory_check" ||
                editData.type === "bill_delivery")) ? (
            <Button //has !jobEditMode
              type="primary"
              className="submitButtonTask"
              form="taskForm"
              key="submit"
              hidden={
                editData.status === "completed" || editData.status === "cancelled"
              }
              htmlType="submit"
              loading={submitLoading}
            >
              Update
            </Button>
          ) : (
            ""
          )}
        </Drawer>
      </div>
    </>
  );
};

export default Task;
