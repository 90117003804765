import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { Space } from "antd";

// Define the libraries array outside the component
const libraries = ["places", "visualization"];

export default function Places({
  setLocationDetails,
  setMapVisible,
  locationEditData,
  hideAddressBox,
  editData,
  clientForm,
  taskForm,
  clientMap,
  clientLocation,
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries, // Use the static array here
    loadingStrategy: "beforeInteractive", // Set the loading strategy for better performance
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Map
      key={editData?._id}
      setMapVisible={setMapVisible}
      setLocationDetails={setLocationDetails}
      locationEditData={locationEditData}
      editData={editData}
      hideAddressBox={hideAddressBox}
      clientForm={clientForm}
      taskForm={taskForm}
      clientMap={clientMap}
      clientLocation={clientLocation}
    />
  );
}

function Map({
  clientLocation,
  clientMap,
  setLocationDetails,
  taskForm,
  locationEditData,
  editData,
  hideAddressBox,
}) {
  const [selected, setSelected] = useState(
    editData
      ? {
          lat: editData.location.coordinates[1],
          lng: editData.location.coordinates[0],
        }
      : null
  );

  
  
  useEffect(() => {
    if (editData) {
      console.log("edit data present");
      setEditLocation([
        editData.location.coordinates[1],
        editData.location.coordinates[0],
      ]);
    } else if (locationEditData) {
      console.log("edit data not present");
      // Changing coordinate order for Google Map

      const lngLat = [locationEditData[1], locationEditData[0]];

      setEditLocation(lngLat);
    }
  }, [editData, locationEditData]);

  const [buttonVisible, setButtonVisible] = useState(false);
  const [keywords, setKeywords] = useState(false);
  const [newData, setNewData] = useState(false);
  const center = useMemo(
    () => ({ lat: selected?.lat || 20.5937, lng: selected?.lng || 78.9629 }),
    [selected]
  );
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const setEditLocation = async (coordinates) => {
    const results = await getGeocode({
      location: { lat: coordinates[0], lng: coordinates[1] },
    });

    if (results) {
      var address = results[0].formatted_address;
      setValue(address, false);
      var addressKeywords = results[0].address_components.map(
        (address) => address.long_name
      );
      setKeywords(addressKeywords);
    }

    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
  };

  const getMarkerLocation = async (e) => {
    setNewData(false);
    const { latLng } = e;
    const results = await getGeocode({ latLng });

    if (results) {
      setButtonVisible(true);
      var address = results[0].formatted_address;

      setValue(address, false);
      var addressKeywords = results[0].address_components.map(
        (address) => address.long_name
      );
      setKeywords(addressKeywords);
    }

    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    if (addressKeywords && results && lat && lng) {
      setLocationDetails({
        keywords: addressKeywords,
        coordinates: { lat, lng },
      });
    }
  };

  const submitLocation = () => {
    setLocationDetails({ keywords: keywords, coordinates: selected });
    setButtonVisible(false);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });

    if (results) {
      setButtonVisible(true);
      var addressKeywords = results[0].address_components.map(
        (address) => address.long_name
      );
      setKeywords(addressKeywords);
      const { lat, lng } = await getLatLng(results[0]);
      setSelected({ lat, lng });
      if (addressKeywords && results && lat && lng) {
        setLocationDetails({
          keywords: addressKeywords,
          coordinates: { lat, lng },
        });
      }
    }
  };

  return (
    <>
      <div
        className={!clientMap ? "places-container" : "client-places-container"}
      >
        <Combobox className="combobox" onSelect={handleSelect}>
          <Space>
            {!hideAddressBox && (
              <ComboboxInput
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  setButtonVisible(false);
                }}
                disabled={!ready}
                className="combobox-input"
                placeholder="Search an address"
              />
            )}
          </Space>
          <ComboboxPopover
            portal={false}
            className={
              clientMap ? "ClientLocationComboboxPopover" : "ComboboxPopover"
            }
          >
            <ComboboxList className="ComboboxList">
              {status === "OK" &&
                data.map(({ place_id, description }) => (
                  <ComboboxOption key={place_id} value={description} />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>

        <GoogleMap
          key={editData?._id}
          zoom={15}
          center={center}
          mapContainerClassName={taskForm ? "map-container-task" : "map-container"}
        >
          {selected && (
            <Marker
              draggable={clientMap}
              onDragEnd={(e) => getMarkerLocation(e)}
              position={selected}
            />
          )}
        </GoogleMap>
      </div>
    </>
  );
}
