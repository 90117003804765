import { Button, Divider, Form, Input, Modal, Space, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import useProductStore from "stores/productStore";
import {
  EditOutlined,
 
  CloseCircleOutlined,
  CloseOutlined,
  CheckOutlined
} from "@ant-design/icons";
import useDefectiveProductStore from "stores/defectiveProductStore";
import moment from "moment";

import DefectiveProductApi from "utils/composable/defectivePorductApi";


const CostTable = ({
  editHandler,
  noteSubmitHandler,

  setFullNoteModalVisible,

  currentDefectiveProduct,
  setDrawerVisible,
  costEditHandler,
  setCostModalVisible

}) => {
  const [currentProductId, setCurrentProdductId] = useState("");
  const [currentButton, setCurrentButton] = useState('');
  const [approveLoading, setApproveLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const { editCost } = DefectiveProductApi();
  const onNoteFinish = (newNote) => {
    console.log(newNote);

    noteSubmitHandler(newNote, currentProductId);
  };

  const showNotes = () => {
    setFullNoteModalVisible(true);
  };

  const defectiveProducts = useDefectiveProductStore(
    (state) => state.defectiveProducts
  );

  //filtering pickedup nd received  products
  // const pickedUpDefectiveProduct = defectiveProducts.filter(
  //   (defectiveProduct) => defectiveProduct.status == "picked-up"
  // );



  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (_, record) => moment(_).format("DD-MM-YYYY"),
      width: "5%",
      key: "2",
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "9",
      width: "3%",
    },

    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      render: (_, record) => {

        const str1 = record?.status;

        var i,
          frags = str1.split("-");
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(" ");
      },
      filters: [
        { text: "Approved", value: "approved" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Rejected", value: "rejected" },
        { text: "Waiting For Approval", value: "waiting-for-approval" },

      ],

      onFilter: (value, record) => record?.status.includes(value),
      key: "1",
    },
    {
      title: "Item Price",
   
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.price?.item_price.toFixed(2);
      },
    },
    {
      title: "Qty",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.price?.qty.toFixed(2);
      },
    },
    {
      title: "Discount",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.price?.discount.toFixed(2);
      },
    },
    {
      title: "Tax",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return (request?.price?.tax_amount.toFixed(2))
      },
    },
    {
      title: "Charge",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.price?.charges?.toFixed(2);
      },
    },

    {
      title: "Amount",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.price?.amount?.toFixed(2);
      },
    },
    {
      title: "Action",
      key: "8",
      width: "5%",
      render: (_, product) => (
        
        product.status =='waiting-for-approval'&& <Space>

          <Tooltip
            title={"Approve"}
          >
          <Button
            icon={<CheckOutlined />}
            loading={approveLoading && currentButton == product._id}
            onClick={() => {

              Modal.confirm({
                title: "Confirm",
                content: "Approve ?",
                okText: "Yes",
                cancelText: "Cancel",
                onOk: () => {

                  setCurrentButton(product._id);
                  // confirmDefectiveEditHandler(product);
                  setApproveLoading(true)
                  setCancelLoading(false)
                  setRejectLoading(false)
                  editCost({
                    product_id: currentDefectiveProduct._id,
                    cost_id: product._id,
                    type: "approved",
                  }).finally(() => {

                    setCurrentButton('');
                    setApproveLoading(false)
                    setCancelLoading(false)
                    setRejectLoading(false)
                    product.status ="Approved"

                  });
                },
              });
            }}
          >
   
          </Button>

          </Tooltip>
          

          <Tooltip
            title={" Reject"}
          >



          <Button
            loading={rejectLoading && currentButton == product._id}
            icon={<CloseOutlined /> }
            onClick={() => {
              // setCurrentReceiveButton(product._id);
              // confirmDefectiveEditHandler(product);
              Modal.confirm({
                title: "Confirm",
                content: "Reject ?",
                okText: "Yes",
                cancelText: "Cancel",
                onOk: () => {

                  setCurrentButton(product._id);
                  setApproveLoading(false)
                  setCancelLoading(false)
                  setRejectLoading(true)
                  editCost({
                    product_id: currentDefectiveProduct._id,
                    cost_id: product._id,
                    type: "rejected",
                  }).finally(() => {
                    product.status  = "Rejected"
                    setCurrentButton('');
                    setApproveLoading(false)
                    setCancelLoading(false)
                    setRejectLoading(false)

                  });
                },
              });
            }}
          >

          </Button>
          </Tooltip>



          <Tooltip
            title={"Cancel"}
          >

         <Button
         
            loading={cancelLoading && currentButton == product._id}
            icon={<CloseCircleOutlined/>}
            onClick={() => {
              // setCurrentReceiveButton(product._id);
              // confirmDefectiveEditHandler(product);
            
              Modal.confirm({
                title: "Confirm",
                content: "Cancel ?",
                okText: "Yes",
                cancelText: "Cancel",
                onOk: () => {

                  setCurrentButton(product._id);
                  setApproveLoading(false)
                  setCancelLoading(true)
                  setRejectLoading(false)

                  editCost({
                    product_id: currentDefectiveProduct._id,
                    cost_id: product._id,
                    type: "cancelled",
                  }).finally(() => {

                    setCurrentButton('');
                    setApproveLoading(false)
                    setCancelLoading(false)
                    setRejectLoading(false)
                    product.status = "Cancelled"

                  });
                },
              });
            }}
         />
          </Tooltip>
           
          <Tooltip
            title={" Edit"}
          >
        
          <Button
            icon={<EditOutlined/>}
            key={product?._id}
            onClick={() => {
              setCostModalVisible(false)
              costEditHandler(product);
            }}
          />

  </Tooltip>

        </Space>
      ),
      align: "center",
      width: "5%",
    },
  ];

  return (
    <>
      <Divider>
        <Button
          onClick={() => {
            setDrawerVisible(true);
            setCostModalVisible(false)
            
          }}
        >
          Add Cost
        </Button>{" "}
      </Divider>

      <Table
        key={defectiveProducts?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={{ defaultPageSize: "5", showLessItems: "true" }}
        dataSource={currentDefectiveProduct.repair_details}
      />


    </>
  );
};

export default CostTable;
