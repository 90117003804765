import { Button, Divider, message, Modal, PageHeader, Skeleton } from "antd";
import CategoryTable from "components/category/CategoryTable";
import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import CategoryForm from "components/category/CategoryForm";
import axios from "axios/axios";
import useCategoryStore from "stores/categoryStore";
import authHeader from "utils/services/auth-header";
import CategoryApi from "utils/composable/categoryApi";

const Category = ({ currentUser }) => {
  const [visible, setVisible] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [tableLoading, setTableLoadings] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [editData, setEditData] = useState("");

  //categoryStore
  const categories = useCategoryStore((state) => state.categories);

  const { addCategory, getCategories, updateCategory } = CategoryApi();

  //notification
  const categoryAdded = (msg) => {
    message.success(msg);
  };
  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  const addNewCategory = () => {
    setEditData("");
    showModal();
  };

  const handleCancel = () => {
    setEditData("");
    setName("");
    setDescription("");
    setVisible(false);
  };

  const toggleEditMode = (id) => {
    const editData = categories.find((cat) => cat._id === id);
    setEditData(editData);
    setVisible(true);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const submitHandler = (values, form) => {
    setLoadings(true);

    try {
      addCategory({
        name: values.name,
        description: values.description,
      })
        .then((msg) => {
          setLoadings(false);
          setVisible(false);
          categoryAdded(msg);
          form.resetFields();
        })
        .catch((msg) => {
          setLoadings(false);
          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const editHandler = (values, form) => {
    setLoadings(true);

    try {
      updateCategory({
        name: values.name,
        description: values.description,
        category_id: values._id,
      })
        .then((msg) => {
          setLoadings(false);
          setVisible(false);
          editSuccessful(msg);
          form.resetFields();
        })
        .catch((msg) => {
          setLoadings(false);
          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!categories.length) {
      setTableLoadings(true);

      getCategories()
        .then((response) => {
          setTableLoadings(false);
        })
        .catch((err) => {
          setTableLoadings(false);
        });
    }
  }, []);

  //Refresh
  const [refreshLoading, setRefreshLoading] = useState(false);

  const refresh = () => {
    setRefreshLoading(true);

    getCategories().finally(() => {
      setRefreshLoading(false);
    });
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Category"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />

      <Divider orientation="center">
        <Button
          disabled={!currentUser.privileges.includes("product_management")}
          type="primary"
          icon={<PlusOutlined />}
          onClick={addNewCategory}
        >
          Add Category
        </Button>
      </Divider>

      {tableLoading ? (
        <Skeleton active loading={tableLoading} />
      ) : (
        <CategoryTable
          currentUser={currentUser}
          toggleEditMode={toggleEditMode}
        />
      )}

      <Modal
        destroyOnClose={true}
        width={800}
        footer
        title={editData ? "Edit Category Details" : "Enter Category Details"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CategoryForm
          submitHandler={submitHandler}
          loadings={loadings}
          editHandler={editHandler}
          name={name}
          description={description}
          editData={editData}
          handleCancel={handleCancel}
        />
      </Modal>
    </div>
  );
};

export default Category;
