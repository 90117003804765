import { Button, Divider, message, Modal, PageHeader, Skeleton } from "antd";
import ClientTable from "components/client/ClientTable";
import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import ClientContactsTable from "components/client/ClientContactsTable";
import ClientForms from "components/client/ClientForm";
import GoogleMap from "components/googleMap/GoogleMap";
import usePlanStore from "stores/planStore";
import useClientStore from "stores/clientStore";
import axios from "axios/axios";
import "components/googleMap/map.css";
import authHeader from "utils/services/auth-header";
import ClientApi from "utils/composable/clientApi";

const Client = ({currentUser}) => {
  const [locationDetails, setLocationDetails] = useState("");
  const [visible, setVisible] = useState(false);
  const [clientsLoading, setClientsLoading] = useState(false);
  const [editData, setEditData] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const plans = usePlanStore((state) => state.plans);
  const clients = useClientStore((state) => state.clients);
  const [clientMap,setClientMap]=useState(true)
  const [refreshLoading, setRefreshLoading] = useState(false)



  const { getClients, addClient, updateClient } = ClientApi();

  const { setPlan } = usePlanStore((state) => ({
    setPlan: state.setPlan,
  }));

  const locationNotAvailable = () => {
    message.error(`Please Select Client Location `);
  };

  const clientAdded = (msg) => {
    message.success(msg);
  };
  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMessage = (msg) => {
    message.error(msg);
  };

  const contactsNotAvailable = () => {
    message.error(`Minimum One Contact Required`);
  };

  useEffect(() => {

 

    if (!clients.length) {
      setClientsLoading(true);

      getClients()
        .then(() => {
          setClientsLoading(false);

        })
        .finally(() => {
          setClientsLoading(false);
        });
    }
  }, []);

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData.location.coordinates);
  };

  const editHandler = (client) => {
    setContacts(false);
    setEditData(client);
    setLocationEditData(client?.location?.coordinates);
    setLocationDetails(false);
    setVisible(true);
  };

  const confirmEditHandler = (editFormValues, form) => {
    setsubmitLoading(true);

    const editPayload = {
      ...editFormValues,
      client_id: editData._id,
      location_keywords:
        locationDetails?.keywords || editData?.location_keywords,
      location: {
        type: "Point",
        coordinates: [
          locationDetails?.coordinates?.lng || editData.location.coordinates[0],
          locationDetails?.coordinates?.lat || editData.location.coordinates[1],
        ],
      },
    };


    updateClient(editPayload)
      .then((msg) => {
        setsubmitLoading(false);
        setVisible(false);
        editSuccessful(msg);
        form.resetFields();
      })
      .catch((msg) => {
        setsubmitLoading(false);
        console.log(msg);
        errorMessage(msg)
      });
  };

  const submitHandler = (values, form) => {

console.log(values,' client values');

    setsubmitLoading(true);

    if (!values.contacts) {
      contactsNotAvailable();
      setsubmitLoading(false);
    }

    if (!locationDetails) {
      locationNotAvailable();
      setsubmitLoading(false);
    } else {
      const payload = {
        ...values,
        location_keywords: locationDetails.keywords,
        location: {
          type: "Point",
          coordinates: [
   
            locationDetails.coordinates.lng,
            locationDetails.coordinates.lat,
          ],
        },
      };

      addClient(payload)
        .then((msg) => {
          setsubmitLoading(false);
          setVisible(false);
          clientAdded(msg);
          form.resetFields();
        })
        .catch((msg) => {
          setsubmitLoading(false);
          errorMessage(msg)
          console.log(msg);
        });
    }
  };




  const refresh = () => {

    setRefreshLoading(true)

    getClients().finally(() => {

      setRefreshLoading(false)


    })


  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Client"


        extra={[

          <Button icon={<RedoOutlined />} loading={refreshLoading} onClick={refresh} key="1" type="dashed">
      
          </Button>,
        ]}

      />

      <Divider orientation="center">
        <Button
        disabled={!currentUser.user.privileges.includes('client_creation')}
          type="primary"
          onClick={() => {
            setContacts(false);
            setVisible(true);
            setEditData("");
            setLocationEditData(false);
            setLocationDetails(false);
          }}
          icon={<PlusOutlined />}
        >
          Add Client
        </Button>
      </Divider>

      <Skeleton loading={clientsLoading} active style={{ height: "30rem" }}>
        <ClientTable
          currentUser={currentUser.user}
          setVisible={setVisible}
          setContacts={setContacts}
          editHandler={editHandler}
        />
      </Skeleton>

      <Modal
        destroyOnClose={true}
        maskClosable={false}
        footer={[]}
        style={{ marginLeft: "10rem" }}
        title={
          contacts ? contacts?.landline ? `Contacts ( Landline - ${contacts?.landline} )` : "Contacts" : editData ? "Edit Client" : "Add New Client"
        }
        centered
        visible={visible}
        onOk={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
        width={1000}
        getContainer={false}
      >
        {contacts ? (
          <ClientContactsTable contacts={contacts.contacts}> </ClientContactsTable>
        ) : (
          <>
            <Divider style={{ padding: "1rem" }}>
                {editData ? "Edit Client Details" : "Primary Office Details"}
            </Divider>

            <ClientForms
              locationDetails={locationDetails}
              setContacts={setContacts}
              submitLoading={submitLoading}
              submitHandler={submitHandler}
              editData={editData}
              ChangeLocation={ChangeLocation}
              confirmEditHandler={confirmEditHandler}
            />
              <Divider style={{ marginBottom: "1rem", fontWeight: 'normal' }}>
              {editData ? "Current Primary Location" : " Location"}{" "}
            </Divider>
            <GoogleMap
                clientMap={clientMap}
              editData={editData}
              locationEditData={locationEditData}
              setLocationDetails={setLocationDetails}
            />
            {editData ? (
              <Button
                className="submitButton"
                form="clientForm"
                key="submit"
                type="primary"
                htmlType="submit"
                loading={submitLoading}
              >
                Confirm
              </Button>
            ) : (
              <Button
                className="submitButton"
                form="clientForm"
                key="submit"
                type="primary"
                htmlType="submit"
                loading={submitLoading}
              >
                Submit
              </Button>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default Client;
