import create from "zustand";
import { devtools } from "zustand/middleware";


const taskStore = (set) => ({
  tasks: [],
  historicalTasks: [],
  fieldEngineers: [],
  serviceWorkers: [],
  deliveryWorkers: [],

  addTask: (newTask) => {
    set((state) => ({
      tasks: [newTask, ...state.tasks],
    }));
  },

  setTasks: (apiTasks) => {
    set((state) => ({
      tasks: [...apiTasks],
    }));
  },
  setHistoricalTasks: (apiTasks) => {
    set((state) => ({
      historicalTasks: [...apiTasks],
    }));
  },
  

  updateTaskToStore: (editData) => {
    set((state) => ({
      tasks: state.tasks.map((task) => {
        if (task?._id === editData?._id) {
          return {
            ...editData,
          };
        } else {
          return task;
        }
      }),
    }));
  },

  updateTaskJobToStore: (key, editData, users, taskStatus) => {
   
    set((state) => ({
      tasks: state.tasks.map((task) => {
        if (task._id === editData?.task_id) {
         
          if (task.jobs[key].status === "unassigned") {
            task.jobs[key].status = "assigned";
          }

          task.jobs[key].assigned = editData.job.assigned;
          task.jobs[key].status = editData.job.status;

        

          const assinedFEIds = task.jobs.map((job) => job.assigned);

          const FeWithDetails = assinedFEIds.map((fe) => {
            return users.find((user) => user._id === fe);
          });


          task.assigned = FeWithDetails;
          task.status = taskStatus
          return task;
        } else {
          return task;
        }
      }),
    }));
  },

  cancelJobInStore: (taskId, jobNumber, taskStatus) => {
    const key=jobNumber-1
    


    set((state) => ({
      tasks: state?.tasks.map((task) => {
        if (task._id === taskId) {
          task.jobs[key].status = "cancelled";
          task.status = taskStatus

          return task;
        } else {
          return task;
        }
      }),
    }));
  },

  
  setFieldEngineers: (fieldEngineers) => {
    set((state) => ({
      fieldEngineers: [...fieldEngineers],
    }));
  },

  setServiceWorkers: (serviceWorkers) => {
    set((state) => ({
      serviceWorkers: [...serviceWorkers],
    }));
  },

  setDeliveryWorkers: (deliveryWorkers) => {
    set((state) => ({
      deliveryWorkers: [...deliveryWorkers],
    }));
  },


});

const useTaskStore = create(devtools(taskStore));

export default useTaskStore;
