
import { Button, Form, Input, Select, Space, Switch, Tag } from "antd";

import findRegion from "utils/constants/state&cities";
import { useEffect, useState } from "react";

import TextArea from "antd/lib/input/TextArea";
import useUserStore from "stores/teamStore";
import TeamApi from "utils/composable/teamApi";

const WareHouseForm = ({
  submitHandler,
  editData,
  submitLoading,
  confirmEditHandler,
}) => {
  const [form] = Form.useForm();
  const { changeCity, optionsList } = findRegion();
  const { Option } = Select;
  const [cityList, setCityList] = useState("");

  const onChange = (value) => {
    //setting city values
    console.log(`${value}`);
    var newCity = changeCity(value);
    console.log(newCity.optionsList);

    const CityList = newCity.optionsList.map((city, index) => {
      return {
        label: city,
        value: city,
        key: index,
      };
    });

    setCityList(CityList);
  };

  //UserStore
  const users = useUserStore((state) => state.users);

  const { getUsers } = TeamApi();

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const onFinish = (values) => {
    // values.country = "india";
    console.log({ values });

    if (editData) {
      values.warehouse_id = editData._id;

      confirmEditHandler(values, form);
    } else {
      submitHandler(values, form);
    }
  };

  const managerList = users
    .map((user, index) => {
      if (user.role == "manager") {
        console.log(user, "user");

        return {
          label: user.name,
          value: user._id,
          key: index,
        };
      } else {
        return;
      }
    })
    .filter((user) => user !== undefined);

  useEffect(() => {
    console.log({ managerList });

    if (!users.length) {
      getUsers();
    }

    const newCity = [
      "Bagalkot",
      "Bangalore Rural",
      "Bangalore Urban",
      "Belgaum",
      "Bellary",
      "Bidar",
      "Vijayapura",
      "Chamarajanagar",
      "Chikkaballapur",
      "Chikkamagaluru",
      "Chitradurga",
      "Dakshina Kannada",
      "Davanagere",
      "Dharwad",
      "Gadag",
      "Gulbarga",
      "Hassan",
      "Haveri",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysore",
      "Raichur",
      "Ramanagara",
      "Shimoga",
      "Tumkur",
      "Udupi",
      "Uttara Kannada",
      "Yadgir",
    ];

    const CityList = newCity.map((city, index) => {
      return {
        label: city,
        value: city,
        key: index,
      };
    });

    setCityList(CityList);
  }, []);

  useEffect(() => {
    //setting edit values

    form.setFieldsValue({
      title: editData?.title || "",
      landmark: editData.landmark || "",
      managers: editData?.managers?.map((manager)=>manager._id),
      address: editData?.address || "",
      description: editData.description || "",
      state: editData.state || "Karnataka",
      city: editData.city || "",
    });
  }, [editData]);

  const tagRender = (props) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Form
      form={form}
      key={editData?._id}
      id="vendorForm"
      name="vendorForm"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Space size={0} align="end" direction="horizontal">
        <Form.Item
          style={{ marginLeft: "3rem" }}
          labelCol={{ span: 24, offset: 0 }}
          name="title"
          label="Title"
          rules={[{ required: true }]}
        >
          <Input
            placeholder="Enter The Warehouse Title"
            style={{ width: 400 }}
          />
        </Form.Item>

        <Form.Item
          style={{ marginLeft: "-1rem", width: "430px" }}
          labelCol={{ span: 24, offset: 0 }}
          name="managers"
          label={"Managers"}
          rules={[
            {
              required: true,
              message: "Missing  managers",
            },
          ]}
        >
          <Select
            mode="multiple"
            showArrow
            tagRender={tagRender}
            style={{ width: "100%" }}
            options={managerList}
          ></Select>
        </Form.Item>
      </Space>

      <Space>
        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          name="landmark"
          label="LandMark"
          rules={[{ required: true }]}
          style={{ width: 250, marginLeft: "3rem" }}
        >
          <Input placeholder="Enter The Landmark" />
        </Form.Item>

        <Form.Item
          style={{ marginLeft: "3rem", width: "300px" }}
          labelCol={{ span: 24, offset: 0 }}
          name="state"
          label="State"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Select a State"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="Andhra Pradesh">Andhra Pradesh</Option>
            <Option value="Andaman and Nicobar Islands">
              Andaman and Nicobar Islands
            </Option>
            <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
            <Option value="Assam">Assam</Option>
            <Option value="Bihar">Bihar</Option>
            <Option value="Chandigarh">Chandigarh</Option>
            <Option value="Chhattisgarh">Chhattisgarhv</Option>
            <Option value="Dadar and Nagar Haveli">
              Dadar and Nagar Haveli
            </Option>
            <Option value="Daman and Diu">Daman and Diu</Option>
            <Option value="Delhi">Delhi</Option>
            <Option value="Lakshadweep">Lakshadweep</Option>
            <Option value="Puducherry">Puducherry</Option>
            <Option value="Goa">Goa</Option>
            <Option value="Gujarat">Gujarat</Option>
            <Option value="Haryana">Haryana</Option>
            <Option value="Himachal Pradesh">Himachal Pradesh</Option>
            <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
            <Option value="Jharkhand">Jharkhand</Option>
            <Option value="Karnataka">Karnataka</Option>
            <Option value="Kerala">Kerala</Option>
            <Option value="Madhya Pradesh">Madhya Pradesh</Option>
            <Option value="Maharashtra">Maharashtra</Option>
            <Option value="Manipur">Manipur</Option>
            <Option value="Meghalaya">Meghalaya</Option>
            <Option value="Mizoram">Mizoram</Option>
            <Option value="Nagaland">Nagaland</Option>
            <Option value="Odisha">Odisha</Option>
            <Option value="Punjab">Punjab</Option>
            <Option value="Rajasthan">Rajasthan</Option>
            <Option value="Sikkim">Sikkim</Option>
            <Option value="Tamil Nadu">Tamil Nadu</Option>
            <Option value="Telangana">Telangana</Option>
            <Option value="Tripura">Tripura</Option>
            <Option value="Uttar Pradesh">Uttar Pradesh</Option>
            <Option value="Uttarakhand">Uttarakhand</Option>
            <Option value="West Bengal">West Bengal</Option>
          </Select>
        </Form.Item>

        <Form.Item
          style={{ marginLeft: "2rem", width: "200px" }}
          labelCol={{ span: 24, offset: 0 }}
          name="city"
          label="City"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Select a City"
            optionFilterProp="children"
            onSearch={onSearch}
            options={cityList}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          ></Select>
        </Form.Item>
      </Space>

      <Form.Item
        style={{ marginLeft: "3rem" }}
        labelCol={{ span: 24, offset: 0 }}
        name="address"
        label="Address"
        rules={[{ required: true }]}
      >
        <Input
          placeholder="Enter The Address"
          style={{ width: 855 }}
          type={"text"}
        />
      </Form.Item>

      <Form.Item
        style={{ marginLeft: "3rem" }}
        labelCol={{ span: 24, offset: 0 }}
        name="description"
        label="Description"
  
      >
        <TextArea placeholder="Notes" style={{ width: 855 }} type={"text"} />
      </Form.Item>

      

      {editData ? (

        
        <Button
          style={{ marginLeft: "52rem" }}
          key="submit"
          type="primary"
          htmlType="submit"
          loading={submitLoading}
        >
          Confirm
        </Button>
      ) : (
        <Button
            style={{ marginLeft: "52rem" }}
          key="submit"
          type="primary"
          htmlType="submit"
          loading={submitLoading}
        >
          Submit
        </Button>
      )}
    </Form>
  );
};

export default WareHouseForm;
