import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Select, Space, Switch } from "antd";
// import useCategoryStore from "stores/categoryStore";
import React, { useEffect, useState } from "react";
import TemplateApi from "utils/composable/templateApi";
import useTemplateStore from "stores/templateStore";
import TextArea from "antd/lib/input/TextArea";
import useProductStore from "stores/productStore";
import useWarehouseStore from "stores/warehouseStore";
import useVendorStore from "stores/vendorStore";
import VendorApi from "utils/composable/vendorApi";
const DefectiveProductForm = ({
  submitHandler,
  submitLoader,
  editData,
  setVisible,
  confirmEditHandler,
}) => {
  const templates = useTemplateStore((state) => state.templates);
  
  const vendors = useVendorStore((state)=>state.vendors)
  // const [taskForm, setTaskForm] = useState(true)
  const [form] = Form.useForm();
  // const [currentFields, setCurrentfields] = useState([]);
 
  const {  getTemplates,  } = TemplateApi();

 


  const {getVendors}= VendorApi()
  const vendorList = vendors.map((vendor)=>{

  return {
    label: vendor.name,
    value: vendor._id,
  };

});


  useEffect(() => {
 

    if (editData) {
      form.setFieldsValue({
        product_id: editData?._id|| "",
        description: editData?.description || "",
        due_date: editData?.formattedDate || "",
        stand_by_product_request: editData?.stand_by_product_request || null,
        status: editData.status|| "",
        vendor:editData?.vendor?._id
    
      });

      // setCurrentfields([editData.specifications]);
    } else {
      form.setFieldsValue({
        fields: [],
        name: "",
        category: "",
      });
    }
  }, [editData]);

  useEffect(() => {
    if (!templates.length) {

      getVendors()

      // setTableLoadings(true);

      try {
        getTemplates()
          .then(() => {
            // setTableLoadings(false);
          })
          .catch((err) => {
            console.log(err);

            // setTableLoadings(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

  }, []);

  const onFinish = (values) => {

    console.log('finish called');
    if (!editData) {

  
      console.log(values, "Final Payload");

      // submitHandler(finalPaylaod, form);
    } else {

      if (!editData?.stand_by_product_request=='') delete values.stand_by_product_request

      values.due_date = new Date(values.due_date)
      console.log(values);
      confirmEditHandler(values, form);


    }
  };


  return (
    <Form
      form={form}
      key={editData?._id}
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
    >


<Space>
        <Form.Item
          key={editData?._id}
          hidden
          labelCol={{ span: 24, offset: 0 }}
          name="stand_by_product_request"
          label="stand_by_product_request"
     

        >
          <Select
            value={editData.stand_by_product_request}
          />
        </Form.Item>


        <Form.Item
          key={editData?._id}
          style={{ width: "12rem" }}
          labelCol={{ span: 24, offset: 0 }}
          name="status"
          label="Status"
      
          rules={[
            {
              required: true,
              message: "Missing Status",
            },
          ]}
        >
          <Select

            key={editData?._id}
          >
            <Select.Option key={1} value="picked-up">
              Picked{" "}
            </Select.Option>
          <Select.Option key={1} value="received">
            Received{" "}
          </Select.Option>
          <Select.Option key={2} value="in-progress">
            In Progress{" "}
          </Select.Option>
          <Select.Option key={3} value="completed">
            Completed{" "}
          </Select.Option>
          <Select.Option key={4} value="damaged">
            Damaged{" "}
          </Select.Option>
          <Select.Option key={4} value="returned">
            Returned{" "}
          </Select.Option>
        </Select>


        </Form.Item>

        <Form.Item
          label="Date"
          name="due_date"
          labelCol={{ span: 24, offset: 0 }}
          style={{ width: "12rem" }}
          rules={[
            {
              required: true,
              message: "Missing Date",
            },
          ]}
        >
          <Input type={'date'} />
        </Form.Item>

        <Form.Item
          label="Vendor"
          name="vendor"
          labelCol={{ span: 24, offset: 0 }}
          style={{ width: "12rem" }}
         
        >
            <Select

            options={vendorList}
            
            />
        </Form.Item>

        <Form.Item
          label="product_id"
          name="product_id"
          hidden={true}
          labelCol={{ span: 24, offset: 0 }}

        >
          <Input />
        </Form.Item>


</Space>


      <Form.Item
        style={{ width: "37rem",marginLeft:'.5rem' }}
        labelCol={{ span: 24, offset: 0 }}  label="Description" name="description">
        <TextArea />
      </Form.Item>

  
      <Form.Item
        wrapperCol={{
          offset: 18,
          span: 10,
        }}
      >
        <Space size={8}>
          <Button key={editData._id} onClick={() => setVisible(false)}>Cancel</Button>
          {editData ? (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Confirm
            </Button>
          ) : (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Submit
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default DefectiveProductForm;
