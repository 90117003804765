import { Button, Form, Input, Radio, message } from "antd";
import SVG from 'assets/svg';
import axios from "axios/axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import './login.css';

const App = () => {

  const [loading, setloading] = useState(false);
    const navigate = useNavigate()

  const loginError= (msg) => {
    message.error(msg);
  };

  const submitHandler = (values,form) => {


    setloading(true);

    // Determine the base URL based on the selected branch and save it in local storage
    const selectedBranchBaseUrl =
      values.branch === "Chennai"
        ? process.env.REACT_APP_CHENNAI_BASEURL
        : process.env.REACT_APP_BANGALORE_BASEURL;

    localStorage.setItem("baseURL", selectedBranchBaseUrl);

    try {
      axios
        .post("/login", values)
        .then((resp) => {

          console.log(resp.data.user.role);

          if (resp.data.token) {

            if (resp.data.user.role == 'engineer' || resp.data.user.role == 'delivery' || resp.data.user.role == 'service'){

              message.error("Please Login in Mobile App")

            }else{
              localStorage.setItem("user", JSON.stringify(resp.data));
              navigate('/')
              window.location.reload() 

            }


          
          }
          console.log(resp);
          setloading(false);
        })
        .catch((err) => {
          console.log(err.response.data.message);
          loginError(err.response.data.message);
          setloading(false);

            
        });
    } catch (error) {
      console.log(error);
    }
    
  };

  const onFinish = (values) => {
 
    submitHandler(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (

    <div className="loginContainer" >
      <Form
        autocomplete="off"
      className="loginForm"
  

        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
          email:'',
          password:'',
          branch: 'Bangalore'
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

        <SVG style={{width:'5rem'}} ></SVG>
        <h1  style={{fontWeight:'bold',marginLeft:'7rem'}} >Login</h1>
        <Form.Item
          label="Select Branch"
          name="branch"
          rules={[{ required: true, message: "Please select a branch!" }]}
        >
          <Radio.Group>
            <Radio value="Bangalore">Bangalore</Radio>
            <Radio value="Chennai">Chennai</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
        
          style={{ marginTop: "1rem" }}
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email Id!",
            },
          ]}
        >
          <Input autoComplete="off"  type={"email"} style={{ width: "300px" }} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password autoComplete="off"value={'test'} style={{ width: "300px" }} />
        </Form.Item>
        <a href="/reset-password" style={{float:'left'}} >Forgot Password?</a>
        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          {/* <Checkbox>Remember me</Checkbox> */}
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button  style={{marginLeft:'-2rem',width:'6rem'}} loading={loading} type="primary" htmlType="submit">
         Login
          </Button>
        </Form.Item>
      </Form>

    </div>
   
  );
};

export default App;
