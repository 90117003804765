
import { Button, Divider, Form, Input, Select, Space, Switch } from "antd";

import React, { useEffect, useState } from "react";

import TextArea from "antd/lib/input/TextArea";
import useProductStore from "stores/productStore";

import useClientStore from "stores/clientStore";
import Price from "components/Price/price";
import moment from "moment";
const RentForm = ({
  submitHandler,
  submitLoader,
  editData,
  setVisible,
  confirmEditHandler,
}) => {

  const [form] = Form.useForm();
  const [currentFields, setCurrentfields] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(false);

  const [priceData, setPriceData]=useState('')
  const [productList,setProductList]=useState()
  // const [clientList,setClinetList]=useState([])
 
  
  //productStore

  let storeProducts= useProductStore((state) => state.Products)
let products = storeProducts.filter((product) => product.status == "in_stock"&&  product.use=='rent');




  const clients = useClientStore((state) => state.clients);

  //Creating options for client list


  const [clientList, setClientList] = useState([])


  useEffect(() => {

    form.setFieldsValue({
      fields: [],
      name: "",
      category: "",
      rented_date: moment(new Date()).format('YYYY-MM-DD')
    });

   
    var clientList = clients.map((client) => {
      return {
        label: `${client.name}`,
        value: client._id,
        key: client._id,


      };
    });


    setClientList(clientList)


  

  }, []);





useEffect(() => {
  
  const productList = products
    .filter((product) => product.status == "in_stock")
    .map((product) => {
      if (!product.sold_on) {
        return {
          label: product.name,
          value: product._id,
        };
      } else return {};
    });

  setProductList(productList)

}, [])











  const onFinish = (values) => {

    console.log(values, "finish called");
    const finalPayload = values;
    values.clientData = clients.find((client) => client._id == values.client);
    values.productData = products.find(
      (product) => product._id == values.product
    );
    values.rented_date = moment(values.rented_date).format("MM-DD-YYYY")
    values.price = priceData
    console.log(finalPayload, "Final Payload");

    console.log(values,'Values');
    submitHandler(values, form);
  };






  //Fuzzy filter



  const fuzzyFilter = (query, product) => {


   if(query!=='') {console.log(query);
    var get_bigrams, j, len, obj, relevance, results, string_similarity;


    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };


    string_similarity = function (str1, str2) {

      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

     if (query.length >= 2) {

    results = [];

    for (j = 0, len = clients.length; j < len; j++) {
      var client = clients[j];

    console.log({client});
        relevance = string_similarity(query, client?.name || "");
      


      obj = {
        ...client,
        relevance: relevance
      };
      results.push(obj);
    }



    console.log({results});
    var sortedResults = results.filter((result) => result.relevance > 0.1)
    sortedResults = sortedResults.sort((a, b) => a.relevance - b.relevance).reverse()
    console.log({ sortedResults });


    //  clientList= ([...sortedResults])

    let clientList = sortedResults.map((client) => {
      return {
        label: `${client.name}`,
        value: client._id,
        key: client._id,

      };
    });




    setClientList(clientList)
  
  
  
  }else{



     let clientList = clients.map((client) => {
       return {
         label: `${client.name}`,
         value: client._id,
         key: client._id,

       };
     });


     setClientList(clientList)


  }

}
  }




  const productFuzzyFilter = (query, product) => {


    if (query !=='') {
      console.log(query);
      var get_bigrams, j, len, obj, relevance, results, string_similarity;


      get_bigrams = function (string) {
        console.log({ string });
        var i, j, ref, s, v;
        s = string.toLowerCase();
        v = new Array(s.length - 1);
        for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
          v[i] = s.slice(i, i + 2);
        }
        return v;
      };


      string_similarity = function (str1, str2) {

        console.log({ str1, str2 });

        var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
        if (str1.length > 0 && str2.length > 0) {
          pairs1 = get_bigrams(str1);
          pairs2 = get_bigrams(str2);
          union = pairs1.length + pairs2.length;
          hit_count = 0;
          for (j = 0, len = pairs1.length; j < len; j++) {
            x = pairs1[j];
            for (k = 0, len1 = pairs2.length; k < len1; k++) {
              y = pairs2[k];
              if (x === y) {
                hit_count++;
              }
            }
          }
          if (hit_count > 0) {
            return (2.0 * hit_count) / union;
          }
        }
        return 0.0;
      };


      if (query.length >= 2) {
      results = [];

      for (j = 0, len = products.length; j < len; j++) {
        var product = products[j];

        console.log({ product });
        relevance = string_similarity(query, product?.name || "");



        obj = {
          ...product,
          relevance: relevance
        };
        results.push(obj);
      }



      console.log({ results });
      var sortedResults = results.filter((result) => result.relevance > 0.1)
      sortedResults = sortedResults.sort((a, b) => a.relevance - b.relevance).reverse()
      console.log({ sortedResults });


      //  clientList= ([...sortedResults])

       const productList = sortedResults
      .filter((product) => product.status == "in_stock")
      .map((product,index) => {
        if (!product.sold_on) {
          return {
            label: product.name,
            value: product._id,
            key: product._id,
            index:index
          };
        } else return {};
      });

    
      setProductList(productList)


    } else {


      console.log('Else Case');

      let productList = products.map((product) => {
        return {
          label: `${product.name}`,
          value: product._id,
          key: product._id,

        };
      }) ;


      setProductList(productList)


    }

  }
  }






  return (
    <Form
      form={form}
      key={editData?._id}
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
    >

<Space>
        <Form.Item
          style={{ width: '21rem' }}
          labelCol={{ span: 24, offset: 0 }}
          name="product"
          label="Product"
          rules={[{ required: true }]}
        >
          <Select
         

            style={{ width: '21rem' }}
            disabled={editData}
            onSearch={(input) => {

              productFuzzyFilter(input)

            }}
            showSearch
            placeholder="Select a Product"
            optionFilterProp="children"
            options={productList}
            filterOption={(input, data) => {


              return true 

            }

            }

          ></Select>
        </Form.Item>

        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          name="client"
          label="Client Name"
          rules={[{ required: true }]}
          style={{ width: '21rem' }}
        >
          <Select
           onSearch={(input)=>{

              fuzzyFilter(input)

               }}
            loading={!clientList.length}
            disabled={editData}
            style={{ width: '21rem' }}
            showSearch
            placeholder="Select a client"
            optionFilterProp="children"
            options={clientList}
            
            filterOption={(input, client) =>{


   
                return true}

            }
          >
          
          </Select>
        </Form.Item>
</Space>
      


 <Space>

        <Form.Item
          style={{ width: '21rem' }}
          labelCol={{ span: 24, offset: 0 }}
          name="rented_date"
          label="Rented Date"
          rules={[{ required: true }]}
        >
          <Input style={{ width: '21rem' }} type={"date"} />
        </Form.Item>



        <Form.Item
          style={{ width: '21rem' }}
          labelCol={{ span: 24, offset: 0 }}
          name="payment_cycle"
          label="Payment Cycle"
          rules={[{ required: true }]}
        >
          <Select
            disabled={editData}
            style={{ width: '21rem' }}

            placeholder="Select a Payment Cycle"
         
          >
            <Select.Option value="monthly">Monthly</Select.Option>
          </Select>
        </Form.Item>




    
            </Space>
      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        label="Description"
        name="description"
        initialValue={""}
      >
        <TextArea />
      </Form.Item>

      <Price setPriceData={setPriceData}  />



      {(currentTemplate || editData) && <Divider>Specifications</Divider>}

      {currentFields?.map((field, index) => (
        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          key={field._id}
          label={editData ? field?.name.toUpperCase() : field?.name}
          name={field?.name?.toLowerCase()}
          // hidden={field.name.toLowerCase() == "template"}
          rules={[
            {
              required: true,
              message: "Missing ",
            },
          ]}
          initialValue={field?.value || ""}
        >
          <Input type={field.data_type} />
        </Form.Item>
      ))}

      <Form.Item
        wrapperCol={{
          offset: 18,
          span: 10,
        }}
      >
        <Space size={8}>
          <Button key={editData._id} onClick={() => setVisible(false)}>
            Cancel
          </Button>
          {editData ? (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Confirm
            </Button>
          ) : (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Submit
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};



export default RentForm;
