import { message } from "antd";
import axios from "axios/axios";
import useVendorStore from "stores/vendorStore";
import authHeader from "utils/services/auth-header";

const VendorApi = () => {
  const {
    addVendorsToStore,
    setVendorsInStore,
    toggleVendorInStore,
    updateVendorsInStore,
  } = useVendorStore((state) => ({
    addVendorsToStore: state.addVendor,
    setVendorsInStore: state.setVendors,
    updateVendorsInStore: state.updateVendors,
    toggleVendorInStore: state.toggleVendor,
  }));


  const addVendor = async (data) => {

    return new Promise((resolve, reject) => {
      axios
        .post("/vendor", data, {
          headers: authHeader(),
        })
        .then((resp) => {
    
          addVendorsToStore(resp.data.vendor);
          resolve(resp.data.message);
        })
        .catch((error) => {
          console.log(error.response.data.message);
          reject(error.response.data.message);
        });
    });
  };

  const getVendors = async () => {


      return new Promise((resolve, reject) => {
        axios
          .get("/vendors", { headers: authHeader() })
          .then((response) => {

            setVendorsInStore(response.data.vendors);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            message.error(err.response.data.message)
            reject(err.response.data.message);
          });
      });
    
  };

  const updateVendor = async (data) => {
    return new Promise((resolve, reject) => {

 
      axios
        .put("/vendor", data, { headers: authHeader() })
        .then((resp) => {
     
          updateVendorsInStore(resp.data.vendor);

          resolve(resp.data.message);
        })
        .catch((err) => {
          console.log(err);
          reject(err.response.data.message);
        });
    });
  };

  const toggleVendor = async (data) => {
    let payLoad = {
      vendor_id: data._id,
      is_active: !data.is_active
}




    toggleVendorInStore(data)
    return new Promise((resolve, reject) => {
      axios
        .put("/vendor/status", payLoad
        , { headers: authHeader() })
        .then((resp) => {
    
          resolve();
        })
        .catch((err) => {
          toggleVendorInStore(data)
          message.error(err.response.data.message)
          console.log(err);
          reject();
        });
    });
  };

  return { addVendor, getVendors, updateVendor, toggleVendor};
};

export default VendorApi;
