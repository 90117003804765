import {
  Button,
  Divider,
  Input,
  List,
  Modal,
  Radio,
  Space
} from "antd";

import { FilterFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import useTaskStore from "stores/taskStore";
import humanize from "utils/formate-status";

import AgentsFielter from "./AgentsFielter";



const FieldEngineersList = ({
setFieldEngineer,
editData,
activeId,
setActiveId,
value,
setValue,
otherFieldEngineers,
currentRole,
}) => {
const onChange = (e) => {
  setValue(e.target.value);
  setFieldEngineer(e.target.value);
};

const [isModalVisible, setIsModalVisible] = useState(false);
const [filterValue, setFilterValue] = useState([]);
const [showFilter, setShowFilter] = useState(false);
const [tasks, setTasks] = useState();
const [searchTerm, setSearchTerm] = useState("");

const [sortedFieldEngineers, setSortedFieldEngineers] = useState([]);
var [other, setOther] = useState(false);
var currentfieldEngineersList = useTaskStore((state) => state.fieldEngineers);

var sortedByDistanceFieldEngineers = currentfieldEngineersList.sort(function (
  a,
  b
) {
  return a.distance - b.distance;
});
var OtherFieldAgents = otherFieldEngineers;
const [filteredOtherAgents, setFilteredOtherAgents] = useState([]);


const handleSearchChange = (e) => {
  setSearchTerm(e.target.value.toLowerCase());
};
const filterEngineers = (engineers) => {
  if (!searchTerm) return engineers;
  return engineers.filter(engineer =>
    engineer.name.toLowerCase().includes(searchTerm)
  );
};


const showModal = (tasks) => {

  setTasks(tasks);
  setIsModalVisible(true);
};

const handleOk = () => {
  setIsModalVisible(false);
};

const handleCancel = () => {

  setIsModalVisible(false);
};

useEffect(() => {
  setSortedFieldEngineers(sortedByDistanceFieldEngineers);

  if (!sortedByDistanceFieldEngineers.length) {
    // sortedFieldEngineers = otherFieldEngineers;
    setSortedFieldEngineers(otherFieldEngineers);
  }
}, [sortedByDistanceFieldEngineers]);

useEffect(() => {
  if (filterValue.length) {
    if (sortedByDistanceFieldEngineers.length) {
      const filteredAgents = sortedByDistanceFieldEngineers.filter((agent) =>
        filterValue.includes(agent.role)
      );

      setSortedFieldEngineers(filteredAgents);
    }

    if (!sortedByDistanceFieldEngineers.length) {
      const filteredOtherAgents = otherFieldEngineers.filter((OAgent) =>
        filterValue.includes(OAgent.role)
      );

      setFilteredOtherAgents(filteredOtherAgents);
    }
  }


}, [filterValue]);

useEffect(() => {


  document.getElementById(activeId)?.scrollIntoView();
}, [activeId]);

useEffect(() => {
  if (otherFieldEngineers.length)
    setFilterValue(["engineer", "service", "delivery"]);

  setActiveId(activeId);

  setFieldEngineer(activeId);
}, []);

useEffect(() => {
  setFilterValue(["engineer", "service", "delivery"]);
}, [showFilter]);


return (
  <>
    <Space direction="vertical">
      {OtherFieldAgents?.length ? (
        <>
          <h5 style={{ marginLeft: "6rem", opacity: ".5" }}>
            {currentRole === "engineer"
              ? "No near by engineers found"
              : currentRole === "service"
              ? "No near by Service Agent "
              : currentRole === "delivery"
              ? "No near by Agents found"
              : currentRole === "onboard"
              ? "No near by Agents found "
              : ""}
          </h5>

          <h3 style={{ marginLeft: "3rem", marginTop: "rem" }}>
            {" "}
            {currentRole === "engineer"
              ? "Showing other Engineers Found"
              : currentRole === "service"
              ? "Showing other Service Agents "
              : currentRole === "delivery"
              ? "Showing All Other Agents Found"
              : currentRole === "onboard"
              ? "Showing All Other Agents Found"
              : ""}
          </h3>
        </>
      ) : (
        ""
      )}

      <Radio.Group
        onChange={onChange}
        value={value ? value : editData?.assigned?._id}
      >
        <Space>
          <Button
            disabled={currentRole === "engineer" || currentRole === "service"}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
            icon={<FilterFilled />}
          ></Button>

          {showFilter && <AgentsFielter setFilterValue={setFilterValue} />}
        </Space>
       

        <div
          style={{ height: "40rem", overflowY: "auto", marginTop: "1rem" }}
        >
           <Input
  placeholder="Search by engineer name"
  value={searchTerm}
  onChange={handleSearchChange}
  style={{ marginBottom: "1rem", width: "20rem" }}
/>

          <List
            style={{ width: "20rem" }}
            itemLayout="horizontal"
            dataSource={
              
  filterEngineers(sortedByDistanceFieldEngineers.length
    ? sortedFieldEngineers
    : filteredOtherAgents.length
    ? filteredOtherAgents
    : otherFieldEngineers)
            }
            renderItem={(sortedFieldEngineers) => (
              <>
       
                <List.Item
                  id={sortedFieldEngineers.id}
                  key={sortedFieldEngineers.id}
                  style={{
                    width: "20rem",
                    border:
                      activeId === sortedFieldEngineers.id
                        ? "2px solid #FF9933"
                        : "",
                  }}
                >
                  <Radio
                    onClick={() => setActiveId(sortedFieldEngineers?.id)}
                    name="fEngg"
                    key={sortedFieldEngineers.id}
                    value={sortedFieldEngineers.id}
                   
                  >
                    {" "}
                  </Radio>
      <List.Item.Meta
                    title={sortedFieldEngineers?.name}
                    description={ sortedFieldEngineers.id === activeId ? (
                      <>
                        {OtherFieldAgents.length ? (
                          <>
                            {" "}
                            <span
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              {" "}
                              Role:
                            </span>{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                color:
                                  sortedFieldEngineers?.role === "engineer"
                                    ? "orange"
                                    : sortedFieldEngineers?.role === "delivery"
                                    ? "darkcyan"
                                    : "#318CE7",
                              }}
                            >
                              {" "}
                              {humanize(sortedFieldEngineers?.role)}
                            </span>
                          </>
                        ) : (
                          ""
                        )}

                        {!OtherFieldAgents.length && (
                          <>
                            <span
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              {other}
                              Distance :{" "}
                            </span>{" "}
                            {`${Math.round(
                              sortedFieldEngineers.distance / 1000
                            )} km`}{" "}
                            <br></br>
                            <span
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              {" "}
                              Role:
                            </span>{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                color:
                                  sortedFieldEngineers?.role === "engineer"
                                    ? "orange"
                                    : sortedFieldEngineers?.role === "delivery"
                                    ? "darkcyan"
                                    : "#318CE7",
                              }}
                            >
                              {" "}
                              {humanize(sortedFieldEngineers?.role)}
                            </span>
                            <br></br>
                            <span
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              {" "}
                              Task Assigned :{" "}
                            </span>{" "}
                            {sortedFieldEngineers?.tasks?.length ? (
                              <Button
                                key={sortedFieldEngineers.id}
                                onClick={() =>
                                  showModal(sortedFieldEngineers.tasks)
                                }
                                style={{
                                  marginLeft: "0.5rem",
                                  borderRadius: "5px",
                                }}
                              >
                                Show tasks
                              </Button>
                            ) : (
                              "No"
                            )}
                          </>
                        )}
                      </>): (
                          ""
                        )
                    }
                  />
                </List.Item>
              </>
            )}
          />
        </div>
      </Radio.Group>
    </Space>

    <Modal
      title="Assigned Tasks"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <br></br>
      {tasks?.map((task, id) => {
        const str1 = task.type;
        const taskType = task.type.charAt(0).toUpperCase() + str1.slice(1);

        return (
          <>
            <Divider>{`Task ${id + 1}`}</Divider>
            <span style={{ fontWeight: "bold", color: "black" }}>
              Client :
            </span>
            {task?.client.name}
            <br></br>
            <span style={{ fontWeight: "bold", color: "black" }}>Type :</span>
            {taskType}
            <br></br>

            <span style={{ fontWeight: "bold", color: "black" }}>
              Address :
            </span>
            {task?.address}
            <span style={{ fontWeight: "bold", color: "black" }}>
              <br></br>
              Status :
            </span>
            {humanize(task?.status)}
          </>
        );
      })}
    </Modal>
  </>
);
};

export default FieldEngineersList;
