import { CheckOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  message,
  Modal,
  PageHeader,
  Select,
  Skeleton,
  Space,
  Timeline
} from "antd";
import GoogleMap from "components/googleMap/GoogleMap";
import HistoricalTaskTable from "components/task/HistoricalTaskTable";
import TaskForm from "components/task/TaskForm";
import TaskTimeline from "components/task/TaskTimeline";
import { useEffect, useState } from "react";
import useClientStore from "stores/clientStore";
import useTaskStore from "stores/taskStore";
import useUserStore from "stores/teamStore";
import ClientApi from "utils/composable/clientApi";
import TaskApi from "utils/composable/taskApi";
import TeamApi from "utils/composable/teamApi";
import './historical-task.css';
const { RangePicker } = DatePicker;
const Task = ({ currentUser }) => {
  const [visible, setVisible] = useState(false);
  const [tableLoading, setTableLoadings] = useState(false);
  const [moreData, setMoredata] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [locationDetails, setLocationDetails] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [hideAddressBox] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState();
  const [editData, setEditData] = useState("");
  const [jobEditMode, setJobEditMode] = useState(false);
  const [jobAddMode, setJobAddMode] = useState(editData ? false : true);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [jobCancelLoading, setJobCancelLoading] = useState(false);
  const [taskCancelLoading, setTaskCancelLoading] = useState(false);
  const [taskcancelled, setTaskCancelled] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const clients = useClientStore((state) => state.clients);
  const users = useUserStore((state) => state.users);

 

  const onDateChange = (dates) => {
    if(dates){
      setDateRange(dates);
    }else{
      setDateRange([]);
    }
    
  };

  const onSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // for cchanging googlemap class name
  const [taskForm, setTaskForm] = useState(true);

  //composable
  const { getClients } = ClientApi();
  const { getUsers } = TeamApi();
  const {
    updateTask,
    addTask,
    getHistoricalTasks,
    getEngineers,
    editTaskJobs,
    AddTaskJobs,
    cancelTaskApi,
    cancelJobApi,
    closeTaskApi
  } = TaskApi();
  const tasks = useTaskStore((state) => state.historicalTasks);
  const [clientList, setClientList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const handleCancel = (form) => {
    setVisible(false);
  };

  

  //notification
  const taskAdded = (msg) => {
    message.success(msg);
  };

  const jobSuccessMsg = (msg) => {
    message.success(msg);
  };

  const jobErrorMsg = (msg) => {
    message.error(msg);
  };

  const fillForm = () => {
    message.error("Please select Client and Date first ");
  };
  const taskUpdated = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  useEffect(() => {
    getClients();
    getUsers();

    const clientList = clients.map((client) => {
      return {
        label: ` ${client.name}`,
        value: client._id,
        key: client._id,
      };
    });
    setClientList(clientList);

    const teamList = users.map((user) => {
      return {
        label: ` ${user.name}`,
        value: user._id,
        key: user._id,
      };
    });
    setTeamList(teamList);

    if (!tasks.length) {
      setTableLoadings(true);

      getHistoricalTasks()
        .then((resp) => {
      
          
        })
        .finally(() => {
          setTableLoadings(false);
        });
    }

    setTaskForm(true);
  }, []);
  const searchTasks = () => {

    if(!dateRange){
      dateRange = [null, null];
    }
    const [start, end] = dateRange;
   


    setRefreshLoading(true);
    const searchPayload = {
      startDate: start ? start.format('YYYY-MM-DD') : null,
      endDate: end ? end.format('YYYY-MM-DD') : null,
      client: selectedClient?._id || null,
      team: selectedTeam?._id || null,
    }
    getHistoricalTasks(searchPayload).finally(() => {

      setRefreshLoading(false);
    });
  }
  const refresh = () => {
    setRefreshLoading(true);
    getClients();
    getUsers();
    getHistoricalTasks().finally(() => {
      setRefreshLoading(false);
    });
  };

  const alphabeticFilter = (query, clients, setClientList) => {
    const filteredClients = clients.filter((client) =>
      client.name.toLowerCase().includes(query.toLowerCase())
    );
  
    const clientList = filteredClients.map((client) => ({
      label: client.name,
      value: client._id,
      key: client._id,
    }));
  
    setClientList(clientList);
  };

  const alphabeticTeamFilter = (query, users, setTeamList) => {
    const filteredUsers = users.filter((user) =>
      user.name.toLowerCase().includes(query.toLowerCase())
    );
  
    const teamList = filteredUsers.map((user) => ({
      label: user.name,
      value: user._id,
      key: user._id,
    }));
  
    setTeamList(teamList);
  };

  const editMode = (task) => {
    const formatDate = (date) => {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    };

    task.formattedDate = formatDate(task.due_date);


    

    task.jobs = task.jobs.map((job) => {
  
      return {
        ...job,
        productId: job.product?._id,
      };
    });

   
    // //reversing lat and lng position for google map
    // task.location.coordinates = task.location?.coordinates?.reverse();
    setEditData(task);
    setVisible(true);
  };

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData?.location?.coordinates);
  };

  const submitHandler = (payload, form) => {


    setsubmitLoading(true);
    addTask(payload)
      .then((msg) => {
        setsubmitLoading(false);
        setVisible(false);
        form.resetFields();
        taskAdded(msg);
        setLocationEditData(false);
        setLocationDetails(false);
      })
      .catch((msg) => {
        setsubmitLoading(false);
        errorMsg(msg);
      });
  };

  const updateHandler = (payload, form) => {


    setsubmitLoading(true);
    updateTask(payload)
      .then((msg) => {
        setsubmitLoading(false);
        setVisible(false);
        form.resetFields();
        taskUpdated(msg);
        setLocationEditData(false);
        setLocationDetails(false);
      })
      .catch((msg) => {
        setsubmitLoading(false);
        errorMsg(msg);
      });
  };

  const jobUpdateHandler = (key, form) => {
    let jobs = form.getFieldValue("jobs");

    const editedJob = jobs[key];

    editedJob.job_id =
      editedJob?._id === "undefined"
        ? tasks.find((task) => task?._id === editData?._id).jobs[key]?._id
        : editedJob?._id;

    editedJob.task_id = editData._id;

    editedJob.job = {
      product: editedJob.productId,
      description: editedJob.description,
      status: editedJob.status,
      type: editedJob.type,
      assigned: editedJob.assigned[0]._id
        ? editedJob.assigned[0]._id
        : editedJob.assigned,
    };

    if (editedJob?.stand_by_product_request) {
      const jobUpdatePayload = {
        job_id:
          editedJob?._id ||
          tasks.find((task) => task._id === editData._id).jobs[key]._id,
        task_id: editData._id,
        job: {
          stand_by_product_request:
            editedJob?.stand_by_product_request?._id || "",
          product: editedJob.productId,
          description: editedJob.description,
          status: editedJob.status,
          type: editedJob.type,
          assigned: editedJob.assigned[0]._id
            ? editedJob.assigned[0]._id
            : editedJob.assigned,
        },
      };

      setJobLoading(true);
      editTaskJobs(jobUpdatePayload, key, editedJob)
        .then((resp) => {
          setJobLoading(false);
          setJobEditMode(false);
          jobSuccessMsg(resp);
        })
        .catch((err) => {
          setJobLoading(false);
          console.log(err);
          jobErrorMsg(err);
        });
    } else {
      const jobUpdatePayload = {
        job_id:
          editedJob?._id ||
          tasks.find((task) => task._id === editData._id).jobs[key]._id,
        task_id: editData._id,
        job: {
          product: editedJob.productId,
          description: editedJob.description,
          status: editedJob.status,
          type: editedJob.type,
          assigned: editedJob.assigned[0]._id
            ? editedJob.assigned[0]._id
            : editedJob.assigned,
        },
      };

      setJobLoading(true);
      editTaskJobs(jobUpdatePayload, key, editedJob)
        .then((resp) => {
          setJobLoading(false);
          setJobEditMode(false);
          jobSuccessMsg(resp);
        })
        .catch((err) => {
          setJobLoading(false);
          console.log(err);
          jobErrorMsg(err);
        });
    }
  };

  const jobSubmitHandler = (key, form, fieldEngineer) => {
    const jobs = form.getFieldValue("jobs");

    jobs[jobs.length - 1].assigned = fieldEngineer;
    const newJob = jobs[jobs.length - 1];

    if (!newJob.type || !newJob.productId) {
      message.error("Please  fill the job details");
      return;
    }

    const newJobPayload = {
      task_id: editData._id,
      job: {
        product: newJob.productId,
        description: newJob.description,
        assigned: fieldEngineer,
        type: newJob.type,
      },
    };

    setJobLoading(true);

    AddTaskJobs(newJobPayload)
      .then((task) => {
        if (editData) {
          editMode(task);
        }
        setJobLoading(false);
        setJobAddMode(false);
        jobSuccessMsg("Job added");
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  const cancelTask = (task_id, task) => {
    setTaskCancelLoading(true);
    //to Control fuzzy search useEffect
    setTaskCancelled(true);

    cancelTaskApi(task_id, task)
      .then(() => {
        setTaskCancelLoading(false);
        setTaskCancelled(false);
      })
      .finally(() => {
        setTaskCancelLoading(false);
      });
  };


  const closeTask = (task_id, task) => {

    setTaskCancelLoading(true);
    setTaskCancelled(true);

    closeTaskApi(task_id, task)
      .then(() => {
        setTaskCancelLoading(false);
        setTaskCancelled(false);
      })
      .finally(() => {
        setTaskCancelLoading(false);
      });
  };

  const onClientChange = (clientId) => {

    
    //finding selected client
    const selectedClient = clients.find((client) => client._id == clientId);
   
    setSelectedClient(selectedClient);
  
  };
  
  const onTeamChange = (userId) => {

    
    //finding selected client
    const selectedTeam = users.find((user) => user._id == userId);
   
    setSelectedTeam(selectedTeam);
  
  };



  const cancelJob = (task_id, job_id, jobNumber) => {
    setJobCancelLoading(true);

    cancelJobApi(task_id, job_id, jobNumber)
      .then(() => {
        setJobCancelLoading(false);

        let currentTask = tasks.find((task) => task._id === task_id);
        let pendingJobs = currentTask.jobs.filter(
          (job) =>
            job.status === "assigned" ||
            job.status === "unassigned" ||
            job.status === "in_progress" ||
            job.status === "unable_to_complete"
        );
        if (!pendingJobs.length) {
          Modal.confirm({
            title:
              "'All jobs are cancelled. Do you want to cancel the entire task?'",
            okText: "Yes",
            cancelText: "Cancel",

            onOk: () => {
              cancelTask(task_id, currentTask);
            },
            // onCancel: () => handleCancel(),
          });
        }
      })
      .finally(() => {
        setJobCancelLoading(false);
      });
  };

  const getFieldEngineers = (client, date) => {
  
    if (!client || !date) {
      fillForm();
    } else {
      setAssignLoading(true);
      getEngineers(client, date)
        .then((resp) => {
          setAssignLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAssignLoading(false);
          jobErrorMsg(err);
        });
    }
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        footer={[]}
        style={{ marginLeft: "10rem" }}
        title={moreData ? "More" : " "}
        centered
        visible={modalVisible}
        onOk={() => {
          setModalVisible(false);
        }}
        onCancel={() => {
          setJobEditMode(false);
          setEditData(false);
          setModalVisible(false);
        }}
        width={800}
        getContainer={false}
      >
        <h3> {`Assigned By : ${moreData?.assigned_by?.name}`} </h3>
        <Divider>Description</Divider>
        {moreData.description}
        <br></br>
        <Divider>Notes</Divider>

        <Timeline mode={"left"}>
          {moreData?.notes?.map((log) => {
            var color;
            var locaTime = new Date(log.time);
            var time = locaTime.toString();

            if (log.vendor_transferred_date) {
              color = "yellow";
            } else if (log.vendor_transferred_date) {
              color = "green";
            } else {
              color = "darkBlue";
            }

            function formatTimeShow(h_24) {
              var h = h_24 % 12;
              if (h === 0) h = 12;
              return (
                h + `: ${time.substr(19, 2)}` + (h_24 < 12 ? " am" : " pm")
              );
            }

            var exactTime = formatTimeShow(parseFloat(time.substr(15, 10)));
            var text;
            var suffix = "";

            text = "by  " + log?.by_name;

            return (
              <Timeline.Item
                style={{ fontSize: "15px" }}
                dot={log.type === "complete" ? <CheckOutlined /> : ""}
                color={color}
              >
                {suffix} {log?.text} {text}{" "}
                <span style={{ fontWeight: "200" }}> on</span>{" "}
                <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                  {" "}
                  {time.substr(3, 12)}, {exactTime}{" "}
                </span>
              </Timeline.Item>
            );
          })}
        </Timeline>
        <Divider>Logs </Divider>
        <TaskTimeline taskLogs={moreData.logs} />
      </Modal>

      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Historical Task"
      />
    <Space
    className="search-form"
  style={{
    marginBottom: 16,
    marginTop: 16,
    width: '100%',
    justifyContent: 'space-between',
   
  }}
>
  <div style={{ flex: '1 1 30%', minWidth: '300px', marginRight: '10px', marginBottom: '10px' }}>
    <Select
      showSearch
      placeholder="Search a client"
      optionFilterProp="children"
      onChange={onClientChange}
      onSearch={(input) => {
        alphabeticFilter(input, clients, setClientList);
      }}
      options={clientList}
      filterOption={false}
      style={{ width: '100%' }}
      allowClear
    />
  </div>

  <div style={{ flex: '1 1 25%', minWidth: '200px', marginRight: '10px', marginBottom: '10px' }}>
    <Select
      showSearch
      placeholder="Search a team"
      optionFilterProp="children"
      onChange={onTeamChange}
      onSearch={(input) => {
        alphabeticTeamFilter(input, users, setTeamList);
      }}
      options={teamList}
      filterOption={false}
      style={{ width: '100%' }}
      allowClear
    />
  </div>

  <div style={{ flex: '1 1 25%', minWidth: '200px', marginRight: '10px', marginBottom: '10px' }}>
    <RangePicker
      format="YYYY-MM-DD"
      onChange={onDateChange}
      style={{ width: '100%' }}
    />
  </div>

  <div style={{ flex: '1 1 20%', minWidth: '100px', marginBottom: '10px' }}>
    <Button
      type="primary"
      icon={<SearchOutlined />}
      onClick={searchTasks}
      style={{ width: '100%' }}
    >
      Search
    </Button>
  </div>
</Space>


  
      <Skeleton active loading={tableLoading}>
        <HistoricalTaskTable
          closeTask={closeTask}
          currentUser={currentUser.user}
          taskcancelled={taskcancelled}
          jobCancelLoading={jobCancelLoading}
          taskCancelLoading={taskCancelLoading}
          cancelJob={cancelJob}
          cancelTask={cancelTask}
          refresh={refresh}
          setVisible={setVisible}
          setModalVisible={setModalVisible}
          setMoredata={setMoredata}
          editMode={editMode}
        />
      </Skeleton>

      <div
        className="container"
        style={{ maxWidth: "100% !important", overflowX: "hidden" }}
      >
        <Drawer
          destroyOnClose={true}
          width={"100vW"}
          title={
            editData ? `Update Task ${editData.task_number} ` : "Create Task"
          }
          size={"large"}
          placement="right"
          onClose={handleCancel}
          visible={visible}
        >
          <Divider style={{ padding: "1rem" }}>Enter Task Details</Divider>

          <TaskForm
          
            jobSubmitHandler={jobSubmitHandler}
            jobUpdateHandler={jobUpdateHandler}
            locationDetails={locationDetails}
            submitHandler={submitHandler}
            ChangeLocation={ChangeLocation}
            editData={editData}
            updateHandler={updateHandler}
            getFieldEngineers={getFieldEngineers}
            assignLoading={assignLoading}
            setJobLoading={setJobLoading}
            jobLoading={jobLoading}
            setJobEditMode={setJobEditMode}
            jobEditMode={jobEditMode}
            jobAddMode={jobAddMode}
            setJobAddMode={setJobAddMode}
          />

          <Divider style={{ marginBottom: "1rem" }}>Client Location</Divider>

          <GoogleMap
            taskForm={taskForm}
            hideAddressBox={hideAddressBox}
            locationEditData={locationEditData}
            setLocationDetails={setLocationDetails}
          />

          {!editData ? (
            <Button
              className="submitButtonTask"
              form="taskForm"
              key="submit"
              type="primary"
              htmlType="submit"
              loading={submitLoading}
            >
              Submit
            </Button>
          ) : (!jobAddMode && !jobEditMode) ||
            (jobEditMode &&
              (editData.type === "inventory_check" ||
                editData.type === "bill_delivery")) ? (
            <Button //has !jobEditMode
              type="primary"
              className="submitButtonTask"
              form="taskForm"
              key="submit"
              hidden={
                editData.status === "completed" || editData.status === "cancelled"
              }
              htmlType="submit"
              loading={submitLoading}
            >
              Update
            </Button>
          ) : (
            ""
          )}
        </Drawer>
      </div>
    </>
  );
};

export default Task;
