import { ContactsOutlined, EyeOutlined, RollbackOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Input, Modal, Space, Table, Tooltip } from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useStandByReqStore from "stores/standByReqStore";
import humanize from "utils/formate-status";

const StandByTable = ({
  currentUser
}) => {

  let request = useStandByReqStore((state) => state.standByReqs);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");


  const [filteredData, setFilteredData] = useState(false)

  const fuzzyFilter = (query,productSearch,clientProduct) => {

    
    var get_bigrams, j, len, obj, relevance, results, string_similarity;


    get_bigrams = function (string) {
    
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };


    string_similarity = function (str1, str2) {



      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length >= 2) {

    results = [];

    for (j = 0, len = request.length; j < len; j++) {
      var payment = request[j];

      if (productSearch) {
        relevance = string_similarity(query, `${payment?.product?.name} ${payment?.product?.barcode}` || "");
      } else if (clientProduct) {

        relevance = string_similarity(query, `${payment?.client_product?.name} ${payment?.client_product?.barcode}`|| "");
      } else {

        relevance = string_similarity(query, payment?.client?.name || "");
      }



      obj = {
        ...payment,
        relevance: relevance
      };
      results.push(obj);
    }


    var sortedResults = results.filter((result) => result.relevance > 0.2)

    sortedResults = sortedResults.sort((a, b) => a.relevance - b.relevance).reverse()

 
    request = [...sortedResults]

    setFilteredData(sortedResults)
 
  }
  }








  const showEngineerData = (record) => {
    Modal.info({
      title: "Contact Details",
      content: (
        <div>
          {
            <>
              <p> {record?.requested_by?.name}</p>
              <p>{record?.requested_by?.phone}</p>
              <p>{record?.requested_by?.email}</p>
            </>
          }
        </div>
      ),

      onOk() {},
    });
  };

  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
 
    setOpen(false);
  };


  const columns = [
    {
      title: "Client",
      dataIndex: "client",
      render: (req) => req?.name,
      key:'client',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
          <AutoComplete
          style={{ margin: "1rem", width: "25rem" }}
          autoFocus
          value={selectedKeys[0]}
          options={[...new Set(request.map(reqst => reqst.client.name))].map(name => ({ value: name }))}

          onSearch={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          onSelect={(value) => {
            setSelectedKeys([value]);
            confirm();
          }}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          placeholder="Search"
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
           
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false)
                setSelectedKeys("");
                confirm()
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.client.name.replace(/[^a-zA-Z]/g, "").toLowerCase().includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Date",
      dataIndex: "createdAt",
      // render: (req) => req?.name,
      key: "Date",
      render: (_, request) => {
        return moment(request?.createdAt).utc().format("DD-MM-YYYY");
      },
    },

    {
      title: "Product",
      width: "15%",

      render: (req) => {
        const productName = req?.product?.name || 'Product not assigned';
        const productBarcode = req?.product?.barcode ? `(${req.product.barcode})` : "";
        
        return `${productName} ${productBarcode}`;
      },

      key: "Product",


      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0],true)
              }}
              onBlur={() => {

                fuzzyFilter(selectedKeys[0],true);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0],true);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false)
                setSelectedKeys("");
                confirm()
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name.replace(/[^a-zA-Z]/g, "").toLowerCase().includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },




    {
      title: "Client Product",
      dataIndex: "client_product",
      width: "15%",
      render: (req) => `${req?.name}  ${req?.barcode? (req.barcode) :"" }`,
      key: "client_product",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0], false,true)
              }}
              onBlur={() => {

                fuzzyFilter(selectedKeys[0], false,true);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0], false,true);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false)
                setSelectedKeys("");
                confirm()
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name.replace(/[^a-zA-Z]/g, "").toLowerCase().includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Warehouse",
      dataIndex: "contact",
      width: "10%",
      render: (_, record) => record?.product?.warehouse?.title,
    },

    {
      title: "Requested By",
      dataIndex: "requested_by",
      width: "10%",
      render: (_, record) => (
        <Space>
          <p> {record?.requested_by?.name}</p>
          <ContactsOutlined
            onClick={() => showEngineerData(record)}
            type="primary"
            style={{ marginBottom: "1rem" }}
          />
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, request) => {
        return humanize(request.status)
      },
      filters: [
        { text: "Requested", value: "requested" },
        { text: "Processed", value: "processed" },
        { text: "With Client", value: "with_client" },
        { text: "Picked Up", value: "picked-up" },
        { text: "Returned", value: "returned" },
          ],

      onFilter: (value, record) => {

     
        return record?.status?.includes(value)
      }
      ,
    },
{
      title: "Action",
      dataIndex: "",

      key: "action",
      render: (req) => {
        return (
          <Space key={req?._id} size="middle">
            {req.status === 'with_client' && !req.returned_scheduled && (
              <Tooltip title="Return Standby Product">
                <RollbackOutlined
                  key={req._id}
                  onClick={() =>
                    navigate("/standbyRequest/return/" + req?._id, { state: { ...req, returnTask: true } })
                  }
                />
              </Tooltip>
            )}
      
            <EyeOutlined
              hidden={!currentUser.privileges.includes('stand_by_product_management')}
              onClick={() =>
                navigate("/standbyRequest/" + req?._id, { state: req })
              }
            />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title="Title"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>

      <Table
        // key={request._id}
        rowKey={"_id"}
        columns={columns}
        pagination={true}
        // expandable={{
        //     expandedRowRender: (templates) => (
        //         <>
        //             {templates.fields.map((field) => {

        //                 return <Tag key={field._id}>{field.name.toUpperCase()}</Tag>;
        //             })}
        //         </>
        //     ),
        //     rowExpandable: (templates) => templates.fields.length > 3,
        // }}
        dataSource={filteredData ? filteredData : request}
      />

    </>
  );
};

export default StandByTable;
