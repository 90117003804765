import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import axios from "axios/axios";
import useClientLocationStore from "stores/clientLocationStore";
import authHeader from "utils/services/auth-header";

const ClientLocationApi = () => {
  //notification
  const clientLocationDeleted = (msg) => {
    message.success(msg);
  };

  const {
    setClientLocationToStore,
    updateClientLocationToStore,
    addClientLocationToStore,
    deleteClientLocationInStore,
  } = useClientLocationStore((state) => ({
    setClientLocationToStore: state.setClientLocation,
    addClientLocationToStore: state.addClientLocation,
    updateClientLocationToStore: state.updateClientLocation,
    deleteClientLocationInStore: state.deleteClientLocationInStore,
  }));

  const { confirm } = Modal;
  const getClientLocation = (clientId) => {


    return new Promise((resolve, reject) => {
      axios
        .get("/client/locations?client=" + clientId, {
          headers: authHeader(),
        })
        .then((response) => {

          setClientLocationToStore(
            response.data.client_locations,
            "client locations"
          );
          resolve();
        })
        .catch((err) => {
          message.error(err.response.data.message)
          console.log(err);
          reject();
        });
    });
  };

  const addClientLocation = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/client/location", data, { headers: authHeader() })
        .then((response) => {
          addClientLocationToStore(response.data.client_location);
          resolve(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };

  const updateClientLocation = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/client/location", data, { headers: authHeader() })
        .then((resp) => {
          updateClientLocationToStore(resp.data.client_location);
          resolve(resp.data.message);

        })
        .catch((error) => {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };


  //confirm modal
  const showPromiseConfirm = (data) => {
    confirm({
      title: "Do you want to delete this client location?",
      icon: <ExclamationCircleOutlined />,
 
       

      onOk() {
        return new Promise((resolve, reject) => {
  

          axios
            .delete("/client/location", {
              headers: authHeader(),
              data: { location_id: data._id, client: data.client },
            })
            .then((resp) => {
          
              deleteClientLocationInStore(data._id);
              clientLocationDeleted(resp.data.message);
              resolve();
            })
            .catch((err) => {
              console.log(err);
            });
        }).catch(() => console.log("Oops errors!"));
      },

      onCancel() {},
    });
  };

  const deleteLocation = async (data) => {

    showPromiseConfirm(data);
  };

  return {
    deleteLocation,
    getClientLocation,
    addClientLocation,
    updateClientLocation,
  };
};

export default ClientLocationApi;
