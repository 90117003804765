import {
  ContactsOutlined,
  PlusCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Divider,
  Input,
  Modal,
  Space,
  Table,
  Tooltip
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useDefectiveProductStore from "stores/defectiveProductStore";
import humanize from "utils/formate-status";

const PendingPickupsTable = ({
  editHandler,
  noteSubmitHandler,
  noteModalVisible,
  setNoteModalVisible,
  noteLoading,
  createDeliveryJob,
  setFullNoteModalVisible,
  setCurrentNotes,
  confirmDefectiveEditHandler,
  submitLoader,
}) => {
  const [currentReceiveButton, setCurrentReceiveButton] = useState();

  const defectiveProducts = useDefectiveProductStore(
    (state) => state.defectiveProducts
  );

  //filtering  pending pickup products
  const pendingPickUpDefectiveProduct = defectiveProducts.filter(
    (defectiveProduct) => defectiveProduct.status == "created"
  );

  const showMoreData = (EngineerDetails) => {
    Modal.info({
      title: "Contact Details",
      content: (
        <div>
          <Divider> </Divider>
          {
            <>
              <p>{EngineerDetails.registered_by?.name}</p>
              <p>{EngineerDetails?.registered_by?.phone}</p>
              <p>{EngineerDetails?.registered_by?.email}</p>
            </>
          }
        </div>
      ),
      onOk() {},
    });
  };

  const navigate = useNavigate();

  //Fuzzy filter function

  const [filteredData, setFilteredData] = useState(false);

  const fuzzyFilter = (query, productSearch, clientProduct) => {
    console.log(query);

    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length > 2) {
      results = [];

      for (j = 0, len = pendingPickUpDefectiveProduct.length; j < len; j++) {
        var request = pendingPickUpDefectiveProduct[j];

        if (productSearch) {
          relevance = string_similarity(query, request?.product?.name || "");
        } else if (clientProduct) {
          relevance = string_similarity(
            query,
            request?.client_product?.name || ""
          );
        } else {
          relevance = string_similarity(query, request?.client?.name || "");
        }

        obj = {
          ...request,
          relevance: relevance,
        };
        results.push(obj);
      }

      var sortedResults = results.filter((result) => result.relevance > 0.2);

      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();

      console.log({ sortedResults });

      request = [...sortedResults];

      setFilteredData(sortedResults);
      console.log(results);
    }
  };

  const columns = [
    {
      title: "Client",
      dataIndex: "client",
      render: (record) => record?.name,
      key: "2",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
           <AutoComplete
          style={{ margin: "1rem", width: "25rem" }}
          autoFocus
          value={selectedKeys[0]}
          options={[...new Set(defectiveProducts.map(reqst => reqst.client.name))].map(name => ({ value: name }))}

          onSearch={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          onSelect={(value) => {
            setSelectedKeys([value]);
            confirm();
          }}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          placeholder="Search"
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
           
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.client.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.product?.name || request?.client_product?.name;
      },

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0], true);
              }}
              onBlur={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Barcode",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.product?.barcode || request?.client_product?.barcode;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return (
          record?.product?.barcode ||
          record?.client_product?.barcode
            ?.toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },

    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "9",
      width: "25%",
      render: (_, request) => {
        return moment(request?.createdAt).utc().format("DD-MM-YYYY");
      },
    },
    {
      title: "Registered By",
      dataIndex: "registered_by",
      width: "15%",
      render: (_, record) => (
        <Space>
          <p>{record?.registered_by?.name}</p>
          <ContactsOutlined
            onClick={() => showMoreData(record)}
            type="primary"
            style={{ marginBottom: "1rem" }}
          />
        </Space>
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      width: "25%",
      key: "3",
    },

    {
      title: "Status",
      dataIndex: "status",
      width: "15%",
      render: (_, record) => {
        return humanize(record.status);
      },
      key: "1",
    },

    {
      title: "Received",
      key: "8",
      width: "5%",
      render: (_, product) => (
        <Tooltip title={"Create Pickup Job"}>
          <PlusCircleOutlined
            hidden={product.defectiveProduct_return_scheduled}
            loading={submitLoader && currentReceiveButton == product._id}
            onClick={() =>
              navigate("/products/defective-return/" + product?._id, {
                state: product,
              })
            }

            // setCurrentReceiveButton(product._id);
            // confirmDefectiveEditHandler( product  );
          />
        </Tooltip>
      ),
      align: "center",
    },
  ];

  return (
    <>
      <Table
        key={defectiveProducts?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={{ defaultPageSize: "5", showLessItems: "true" }}
        dataSource={filteredData ? filteredData : pendingPickUpDefectiveProduct}
      />
    </>
  );
};

export default PendingPickupsTable;
