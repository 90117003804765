import {
  CheckOutlined,
  ExclamationCircleOutlined,
  FieldTimeOutlined,
  RedoOutlined
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Drawer,
  message,
  Modal,
  PageHeader,
  Popover,
  Skeleton,
  Tag,
  Timeline,
} from "antd";
import ClientPlanForm from "components/client/ClientPlanForm";
import ClientProductForm from "components/client/ClientProductForm";
import ProductsTable from "components/client/ClientProductsTable";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useClientProductStore from "stores/clientProductStore";
import useUserStore from "stores/teamStore";
import ClientApi from "utils/composable/clientApi";
import TeamApi from "utils/composable/teamApi";
import humanize from "utils/formate-status";

const Products = ({currentUser}) => {
  const [loadings, setLoadings] = useState(true);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState("");
  const [planEditData, setPlanEditData] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [additionalPlanLoading, setAdditionalPlanLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bulkUpdates, setBulkUpdatestrue] = useState(false);
  const [planDrawervisible, setPlanDrawerVisible] = useState(false);
  const [monthNumber, setMonthNumber] = useState();
  const [additionalPlan, setAdditionalPlan] = useState(false);
  const { getUsers } = TeamApi();
  const users = useUserStore((state) => state.users);
  const clientId = useParams();
  const { state } = useLocation();
  console.log(state);
  const {
    clientProducts,
    updateClientProducts,
    bulkUpdateApi,
    newPlanSubmitApi,
    newAdditionalPlanSubmitApi,
    getSingleClient,
  } = ClientApi();
  const { setClientProductsToStore } = useClientProductStore((state) => ({
    setClientProductsToStore: state.setClientProducts,
  }));
  const [refreshLoading, setRefreshLoading] = useState(false);

  // const users = useUserStore((state)=>state.users)

  useEffect(() => {

    const number = state?.clientDetails?.plan?.payment_cycle?.slice(4, 6);

    setMonthNumber(parseInt(number));



    getSingleClient(state?.id).then((response) => {
      if (response.client.plan?.name.find((names) => names == "PCB")) response.client.plan = null
      state.clientDetails = response.client;
      setLoadings(false);
      clientProducts(clientId?.clientId).then(() => {
        setLoadings(false);
      });
    });

    getUsers();

    return setClientProductsToStore([]);




  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const showSpecs = (specifications) => {
    const specs = Object.entries(specifications);

    Modal.info({
      title: "Specifications",
      content: (
        <div>
          { Object.entries(specifications).map(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        const displayValue = typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value;
        const formattedKey = capitalizeFirstLetter(key.replace(/_/g, ' '));
        return (
          <p key={key}>
            <strong>{formattedKey}:</strong> {displayValue}
          </p>
        );
      }
      return null;
    })}
        </div>
      ),
      onOk() {},
    });
  };

  const editHandler = (template) => {
    const formatDate = (date) => {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    };

    if (
      template?.last_paid_date &&
      template?.start_date &&
      template?.due_date
    ) {
      template.start_date = formatDate(template?.start_date || "");
      template.last_paid_date = formatDate(template?.last_paid_date || "");
      template.due_date = formatDate(template?.due_date || "");
    }
    setEditData(template);
    setVisible(true);
    setSelectedRowKeys([])
  };



  const confirmEditHandler = (updatePayload, form) => {
    setSubmitLoader(true);
 
    try {
      updateClientProducts(updatePayload)
        .then((msg) => {
          setSubmitLoader(false);
          setVisible(false);
          message.success(msg);
          form.resetFields();
        })
        .catch((msg) => {
          console.log(msg);
          setSubmitLoader(false);
          message.error(msg);
        });
    } catch (error) {
      console.log(error);
      setSubmitLoader(false);
    }
  };

  const handleBulkUpdate = (values, form) => {
    if (!selectedRowKeys.length) message.error("Please Select the Products");
    values.product_ids = selectedRowKeys;
    values.client = state.id;
    values.payment_cycle = "none";

    setSubmitLoader(true);
    bulkUpdateApi(values).finally(() => {
      setSubmitLoader(false);
      setVisible(false);
      setSelectedRowKeys([]);
    });
  };

  const handleNewPlanSubmit = (values, form) => {
 

    console.log('Edit');

    values.client = state.id;

    // values.totalCost=values.plan.price.amount

    var itemPrice = parseInt(values?.plan?.price?.item_price) 
    
    setSubmitLoader(true);
    if (additionalPlan) {


      values.plan.price.item_price = (parseFloat(values.plan.price.item_price) / 12) * parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6))
      values.plan.price.charges = (parseFloat(values.plan.price.charges) / 12) * parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6)) 


     
      values.plan.price.qty = 0;




      setAdditionalPlan(true);
      newAdditionalPlanSubmitApi(values).then((response) => {
        function percentage(num, per) {
          return (num / 100) * per;
        }



        setAdditionalPlan(false);
        setSubmitLoader(false);
    

        state.clientDetails.plan_logs = [...state.clientDetails.plan_logs, ...response?.data?.new_logs]
     
        state.clientDetails.plan.no_of_products =state?.clientDetails.plan.no_of_products + parseInt(values.plan.no_of_products);
        state.clientDetails.plan.description = state?.clientDetails.plan.description +" "+ values.plan.description;
     
        const totalTax = state?.clientDetails.plan.price.taxes
          .map((tax) => {
            return tax.percentage;
          })
          .reduce((partialSum, a) => partialSum + a, 0);

  

        var totalPrice =
          parseFloat(values.plan.price.item_price) +
          state.clientDetails.plan.price.item_price;

   
        const discountAmount = percentage(
          totalPrice,
          state?.clientDetails.plan.price.discount_percentage
        );

        const totalCharge =
          parseInt(values.plan?.price?.charges) +
          parseInt(state.clientDetails?.plan?.price?.charges);

        totalPrice =
          totalPrice +
          (totalCharge ? totalCharge : 0) -
          (discountAmount ? discountAmount : 0);


        const totalTaxAmount = percentage(totalPrice, totalTax);

        const chargeTax = percentage(totalCharge, totalTax);

  

        state.clientDetails.plan.price.amount =totalPrice + (totalTaxAmount ? totalTaxAmount : 0);


 
        state.clientDetails.plan.price.tax_amount = totalTaxAmount;
        state.clientDetails.plan.price.discount = discountAmount;
        state.clientDetails.plan.price.charges = totalCharge;
        state.clientDetails.plan.price.item_price += parseFloat(values.plan.price.item_price) ;
        state.clientDetails.plan.price.item_total += parseFloat(values.plan.price.item_price);
   
     
        setPlanDrawerVisible(false);


      });
    } else {
  


      if(editData){


        values.plan.price.item_price = (parseFloat(values.plan.price.item_price) / 12) * parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6))
        values.plan.price.charges = (parseFloat(values.plan.price.charges) / 12) * parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6)) 

      }else{



        values.plan.price.item_price = (parseFloat(values.plan.price.item_price) / 12) * parseInt(values.plan?.payment_cycle?.slice(4, 6))
        values.plan.price.charges = (parseFloat(values.plan.price.charges) / 12) * parseInt(values.plan?.payment_cycle?.slice(4, 6)) 

      }


     

      newPlanSubmitApi(values).then((response) => {
        
   
   
        state.clientDetails.plan_logs = [...state.clientDetails.plan_logs, ...response?.data?.new_logs]

        setPlanDrawerVisible(false);
        setSubmitLoader(false);
    

        // values.plan.price.item_price =  itemPrice;  

        if (planEditData) {


    

          values.plan.next_due_date = state.clientDetails.plan.next_due_date 

          state.clientDetails.plan = values.plan;
          // state.clientDetails.plan.start_date = moment(values.plan.start_date).format('DD-MM-YYYY')

       

          const totalTax = values.plan.price.taxes
            .map((tax) => {
              return tax.percentage;
            })
            .reduce((partialSum, a) => partialSum + a, 0);

    

          const totalPrice = parseFloat(values.plan.price.item_price) * 1;

          const discountAmount = percentage(
            totalPrice,
            values.plan.price?.discount_percentage || 0
          );

          state.clientDetails.plan.price.amount =
            totalPrice -
              (discountAmount ? discountAmount : 0) +
              values.plan.price?.charges || 0;

    

          const totalTaxAmount = percentage(
            state?.clientDetails?.plan?.price.amount,
            totalTax
          );

      

          state.clientDetails.plan.price.amount = state?.clientDetails?.plan?.price.amount + totalTaxAmount;
          state.clientDetails.plan.price.discount = discountAmount
          state.clientDetails.plan.price.tax_amount =totalTaxAmount
          state.clientDetails.plan.price.charges = parseFloat(values.plan.price?.charges) 
          state.clientDetails.plan.price.item_price = parseFloat(values.plan.price.item_price) 
          state.clientDetails.plan.description = values.plan.description
          
        

          function percentage(num, per) {
            return (num / 100) * per;
          }

   

          setPlanDrawerVisible(false);
        } else {




        

          state.clientDetails.plan = values.plan;
       
          state.clientDetails.plan.start_date = moment(values.plan.start_date).format('MM-DD-YYYY')
      
          const totalTax = values.plan.price.taxes
            .map((tax) => {
              return tax.percentage;
            })
            .reduce((partialSum, a) => partialSum + a, 0);

    

          const totalPrice = parseFloat(values.plan.price.item_price) * 1;

          const discountAmount = percentage(
            totalPrice,
            values.plan.price?.discount_percentage || 0
          );

          state.clientDetails.plan.price.amount =
            totalPrice -
            (discountAmount ? discountAmount : 0) +
            values.plan.price?.charges || 0;



          const totalTaxAmount = percentage(
          state?.clientDetails?.plan?.price.amount,
            totalTax
          );

       
          // console.log(state?.clientDetails.plan.price.amount, "Amount");




          state.clientDetails.plan.price.amount = state?.clientDetails?.plan?.price.amount + totalTaxAmount;
          state.clientDetails.plan.price.discount = discountAmount
          state.clientDetails.plan.price.tax_amount = totalTaxAmount
          state.clientDetails.plan.price.charges = parseFloat(values.plan.price?.charges)
          state.clientDetails.plan.price.item_price = parseFloat(values.plan.price.item_price)
          state.clientDetails.plan.description = values.plan.description


          state.clientDetails.plan.next_due_date = moment(state.clientDetails.plan.start_date).add(parseInt(state.clientDetails.plan?.payment_cycle.slice(4, 6)),'M')
        

          function percentage(num, per) {
            return (num / 100) * per;
          }



          setPlanDrawerVisible(false);
        }
      }).finally(()=>{
        setSubmitLoader(false);
      });
    }
  };



  const onClose = () => {
    setEditData("");
 

    setPlanDrawerVisible(false);
  };

  const refresh = () => {
    setRefreshLoading(true);

    Promise.all([getSingleClient(state.id), clientProducts(clientId?.clientId)])

      .then((response) => {
        console.log({ response });
        state.clientDetails = response[0].client;
      })
      .finally(() => {
        setRefreshLoading(false);
      });
  };

  var currentProducts;

  const products = useClientProductStore((state) => state.products).filter((product)=>product.barcode!='0000');
  if (state?.ASC_AMC) {
    currentProducts = products.filter(
      (product) => product.plan == "ASC" || product.plan == "AMC"
    );

       currentProducts = currentProducts;

  } else {
    currentProducts = products;
  }

  const content = (
    <div>   

      <p>{`Annual Cost :  ₹ ${(state?.clientDetails?.plan?.price?.amount / parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6)) * 12).toFixed(2)  }`}</p>

      <p>{`Item Price :  ₹ ${state?.clientDetails?.plan?.price?.item_price.toFixed(2) }`}</p>

      <p>{`Tax :  ₹ ${state?.clientDetails?.plan?.price?.tax_amount.toFixed(2)   || ""}`}</p>

  

    </div>
  );

  const logTimeline = (data) => {


  
    Modal.info({
      width: "50rem",

      title: "Plan Logs",
      content: (
        <Timeline mode={"left"}>
          {data.plan_logs.map((log) => {
           

            var color;
            var locaTime = new Date(log.date);
            var time = locaTime.toString();

            if (log.vendor_transferred_date) {
              color = "#007FFF";
            } else if (log.vendor_transferred_date) {
              color = "green";
            } else {
              color = "#5D76A9";
            }

            function formatTimeShow(h_24) {
              var h = h_24 % 12;
              if (h === 0) h = 12;
              return (
                h + `: ${time.substr(19, 2)}` + (h_24 < 12 ? " am" : " pm")
              );
            }

            var exactTime = formatTimeShow(parseFloat(time.substr(15, 10)));
            var text;
            var suffix = "";

            text = "by  " + users.find((user) => user._id == log.by).name;

            return (
              <Timeline.Item
                style={{ fontSize: "15px" }}
                dot={
                  log.type == "created" ? (
                    <CheckOutlined />
                  ) : log.type == "cancelled" ? (
                    "X"
                  ) : (
                    ""
                  )
                }
                color={color}
              >
                {suffix} {log?.text} {text}{" "}
                <span style={{ fontWeight: "200" }}> on</span>{" "}
                <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                  {" "}
                  {time.substr(3, 12)}, {exactTime}{" "}
                </span>
              </Timeline.Item>
            );
          })}
        </Timeline>
      ),
      onOk() {},
    });
  };

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          className="site-page-header"
          onBack={() => window.history.back()}
          title={state.ASC_AMC? "Products under ASC/AMC":"Products"}
          subTitle={state?.name}
          extra={[
            <Button
              icon={<RedoOutlined />}
              loading={refreshLoading}
              onClick={refresh}
              key="1"
              type="dashed"
            ></Button>,

            <Button
            
            disabled={!selectedRowKeys.length }
              hidden={state?.ASC_AMC || !currentUser.user.privileges.includes('client_management') }
              loading={refreshLoading}
              onClick={() => {
                setBulkUpdatestrue(true);
                setVisible(true);
              }}
              key="1"
            >
              Bulk Update
            </Button>,

            !state?.clientDetails?.plan ? (
              <Button
                hidden={!state?.ASC_AMC}
                loading={additionalPlanLoading}
                onClick={() => {
                  setAdditionalPlan(false);
                  setPlanDrawerVisible(true);
                }}
                key="1"
              >
                Add Main Plan
              </Button>
            ) : (
              <>
                <Button
                  hidden={!state.ASC_AMC}
                  onClick={() => {
                    setAdditionalPlan(false);
                    setPlanEditData(state?.clientDetails);
                    setPlanDrawerVisible(true);
                  }}
                  key="1"
                  type="dashed"
                >
                  Edit Main Plan
                </Button>
                <Button
                  hidden={!state.ASC_AMC}
                  onClick={() => {
                    setAdditionalPlan(true);
                    setPlanDrawerVisible(true);
                    setPlanEditData(false);
                    // setAdditionalPlanLoading(true);
                  }}
                  key="1"
                  type="dashed"
                >
                  Add Additional Plan
                </Button>
              </>
            ),
          ]}
        >
          {state?.ASC_AMC && (
            <Descriptions style={{ marginTop: "1rem" }}>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Client Name"
              >
                {state?.name}
              </Descriptions.Item>

              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="No of AMC/ASC Products"
              >
                {state?.clientDetails?.plan?.no_of_products}
              </Descriptions.Item>

              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Plans"
              >
                {state?.clientDetails?.plan?.name.map((name) => (
                  <Tag>{name}</Tag>
                ))}
              </Descriptions.Item>

              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Start Date"
              >
                {state?.clientDetails?.plan?.start_date ? moment(state?.clientDetails?.plan?.start_date).format( "DD-MM-YYYY" )
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Payment"
              >
                {`₹ ${ 
                  ((state?.clientDetails?.plan?.price?.amount.toFixed(2) ||0) )
                } `}
                {humanize(state?.clientDetails?.plan?.payment_cycle||"")}

              
                {state?.clientDetails?.plan?.price?.amount ? (
                  <Popover
                    style={{ padding: "1rem" }}
                    content={content}
                    title="Payment Details
                "
                  >
                    <ExclamationCircleOutlined
                      style={{ paddingLeft: "0.5rem" }}
                    ></ExclamationCircleOutlined>
                  </Popover>
                ) : (
                  ""
                )}
              </Descriptions.Item>

              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Due Date"
              >
                {state?.clientDetails?.plan?.next_due_date&& moment(state?.clientDetails?.plan?.next_due_date).format("DD/MM/YYYY")}

              </Descriptions.Item>

              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Plan Timeline"
              >
                {" "}
                <FieldTimeOutlined
                  size={"large"}
                  onClick={() => logTimeline(state?.clientDetails)}
                  style={{ paddingLeft: "0.5rem" }}
                >
                  {" "}
                </FieldTimeOutlined>{" "}
              </Descriptions.Item>

              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Description"
              >
                {state?.clientDetails?.plan?.description}
              </Descriptions.Item>

            </Descriptions>
          )}
        </PageHeader>
      </div>

      <Skeleton active loading={loadings}>
        <ProductsTable
          currentUser={currentUser.user}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedRowKeys={selectedRowKeys}
          setBulkUpdatestrue={setBulkUpdatestrue}
          state={state}
          products={currentProducts}
          editHandler={editHandler}
          showSpecs={showSpecs}
        />
      </Skeleton>

      <Drawer
        destroyOnClose={true}
        key={7}
        size=""
        title={
          editData
            ? "Edit Product "
            : `Update ${selectedRowKeys.length} Products`
        }
        placement="right"
        onClose={() => {
          setEditData(false);
          setBulkUpdatestrue(false);
          setVisible(false);
        }}
        visible={visible}
      >

        <ClientProductForm
          state={state}
          selectedproducts={selectedRowKeys}
          handleBulkUpdate={handleBulkUpdate}
          bulkUpdates={bulkUpdates}
          editData={editData}
          submitLoader={submitLoader}
          setVisible={setVisible}
          confirmEditHandler={confirmEditHandler}
        />
      </Drawer>

      <Drawer
        destroyOnClose={true}
        key={10}
        size={"large"}
        title={
          additionalPlan ? "Additional Plan Details" : "Client Plan Details"
        }
        placement="right"
        onClose={onClose}
        visible={planDrawervisible}
      >
        <ClientPlanForm
        currentUser={currentUser.user}
          state={state}
          additionalPlan={additionalPlan}
          planEditData={planEditData}
          handleNewPlanSubmit={handleNewPlanSubmit}
          selectedproducts={selectedRowKeys}
          handleBulkUpdate={handleBulkUpdate}
          bulkUpdates={bulkUpdates}
          editData={editData}
          submitLoader={submitLoader}
          setVisible={setPlanDrawerVisible}
          confirmEditHandler={confirmEditHandler}
        />
      </Drawer>
    </>
  );
};

export default Products;
