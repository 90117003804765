import {
  Button,
  Card,
  Divider,
  Drawer,
  message,
  Modal,
  PageHeader,
  Skeleton,
  Space,
} from "antd";

import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import useUserStore from "stores/teamStore";
import TeamForm from "components/team/TeamForm";
import TeamTable from "components/team/TeamTable";

import TeamApi from "utils/composable/teamApi";
import ArchivedTeamTable from "components/team/ArchivedTeamTable";

const Team = ({ currentUser }) => {
  const [visible, setVisible] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [tableLoading, setTableLoadings] = useState(false);
  const [editData, setEditData] = useState("");
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  //UserStore
  const users = useUserStore((state) => state.users);

  const { getUsers, addUser, updateUser, getArchivedUser } = TeamApi();

  //notification
  const UserAdded = (msg) => {
    message.success(msg);
  };

  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  const addNewUser = () => {
    setEditData("");
    showModal();
  };

  const handleCancel = (form) => {
    setEditData("");
    setVisible(false);
  };

  const editHandler = (user) => {
    console.log(user, "edit handler");
    setEditData(user);
    setVisible(true);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  const submitHandler = (values, form) => {
    setLoadings(true);
    console.log(values);
    try {
      addUser({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        role: values.role,
        address: values.address,
        phone: values.phone,
        privileges: values.privileges,
      })
        .then((msg) => {
          setLoadings(false);
          setVisible(false);
          UserAdded(msg);
          form.resetFields();
          form.setFieldsValue({
            privileges: [
              "team_view",
              "team_creation",
              "team_management",
              "client_view",
              "client_creation",
              "client_management",
              "client_product_management",
              "vendor_view",
              "vendor_creation",
              "vendor_management",
              "plan_view",
              "plan_creation",
              "plan_management",
              "product_view",
              "product_creation",
              "product_management",
              "task_view",
              "task_creation",
              "task_management",
            ],
          });
        })
        .catch((errMsg) => {
          setLoadings(false);
          errorMsg(errMsg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!users.length) {
      setTableLoadings(true);
      getUsers()
        .then(() => {
          setTableLoadings(false);
        })
        .catch(() => {
          setTableLoadings(false);
        });
    }
  }, []);

  const confirmEditHandler = (editFormValues, form) => {
    console.log("confirm called");
    setLoadings(true);
    updateUser(editFormValues)
      .then((msg) => {
        setLoadings(false);
        setVisible(false);
        editSuccessful(msg);
        form.resetFields();
      })
      .catch((errMsg) => {
        setLoadings(false);
        errorMsg(errMsg);
      });
  };

  const refresh = () => {
    setRefreshLoading(true);

    getUsers().finally(() => {
      setRefreshLoading(false);

      getArchivedUser();
    });
  };
  const tabList = [
    {
      key: "tab1",
      tab: (
        <span style={{ color: "darkGreen", fontWeight: "400" }}>
          Active Users
        </span>
      ),
    },

    {
      key: "tab2",
      tab: "Archived Users",
    },
  ];

  const contentList = {
    tab1: (
      <Skeleton active loading={tableLoading}>
        <TeamTable currentUser={currentUser.user} editHandler={editHandler} />
      </Skeleton>
    ),

    tab2: (
      <Skeleton active loading={tableLoading}>
        <ArchivedTeamTable
          currentUser={currentUser.user}
          editHandler={editHandler}
        />
      </Skeleton>
    ),
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Team"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />

      <Divider orientation="center">
        <Button
          disabled={!currentUser.user.privileges.includes("team_creation")}
          type="primary"
          icon={<PlusOutlined />}
          onClick={addNewUser}
        >
          Create Team
        </Button>
      </Divider>

      <Card
        style={{ width: "100%" }}
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={(key) => {
          onTab1Change(key);
        }}
      >
        {contentList[activeTabKey1]}
      </Card>

    

      <Drawer
        destroyOnClose={true}
        width={"600px"}
        title={!editData ? `Add User` : "Edit User"}
        size={"large"}
        placement="right"
        onClose={handleCancel}
        visible={visible}
      >
        <TeamForm
          submitHandler={submitHandler}
          confirmEditHandler={confirmEditHandler}
          loadings={loadings}
          editData={editData}
          handleCancel={handleCancel}
        />
      </Drawer>
    </div>
  );
};

export default Team;
