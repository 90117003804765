// axios.js
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BANGALORE_BASEURL, // Default base URL if none is set
});

// Interceptor to dynamically update the base URL
instance.interceptors.request.use(
  (config) => {
    const baseURL = localStorage.getItem('baseURL');
    if (baseURL) {
      config.baseURL = baseURL; // Use the stored base URL
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
