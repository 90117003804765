import {
  PageHeader,
  Button,
  Space,
  Divider,
  Modal,
  message,
  Skeleton,
} from "antd";

import React, { useEffect, useState } from "react";
import Card from "components/plans/PlansCard";

import usePlanStore from "stores/planStore";

import Plans from "utils/constants/plans";

const Plan = () => {
 
  const [desc, setDesc] = useState("");
  const [descVisible, setDescVisible] = useState(false);

  const [cardLoading, setcardLoadings] = useState(false);
 
  //planStore
  const plans = Plans()



 
  const showDesc = (desc) => {
    setDescVisible(true);
    setDesc(desc);
  };
  


  const handleCancel = () => {
 
    setDescVisible(false);
  
  };



 

  return (
    <>

    
      <Modal

        className="customModal"
        width={800}
        cancelButtonProps={{ style: { display: "none" } }}
        style={{ marginRight: "16rem", borderBottom: "none" }}
        title={"Description"}
        visible={descVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
      >
        <h4>{desc}</h4>
      </Modal>

      <Space align="baseline" direction="horizontal">
        <PageHeader
          className="site-page-header"
          style={{ padding: 0, marginTop: -15 }}
          title="Plan"
        />
      </Space>
 
       
  
      <Skeleton active loading={cardLoading} />
      <Space size={20} direction="horizontal" wrap>
        <Card
     
          plans={plans}

          showDesc={showDesc}
        />
      </Space>
    </>
  );
};

export default Plan;
