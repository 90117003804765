import {
  Button,
  Input,
  Modal,
  Space,
  Table,
  Timeline,
  Checkbox,
  Tooltip,
  Form,
} from "antd";
import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import useProductStore from "stores/productStore";
import { EditOutlined, SearchOutlined, CheckOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import humanize from "utils/formate-status";
import usePaymentStore from "stores/paymentStore";
import moment from "moment";
import PaymentApi from "utils/composable/paymentApi";
import useUserStore from "stores/teamStore";

const Paymenttable = ({
  editHandler,
  handleCancel,
  confirmEditHandler,
  currentClient,
  setCurrentClient,
  selectedproducts,
  setSelectedProducts,
  buttonLoading,
  setButtonLoading,
  selectedproductsPriceDetails,
  setSelectedProductsPriceDetails,
  currentUser,
  createPayment,
}) => {
  var payments = usePaymentStore((state) => state.payments);

  const pendingPayments = usePaymentStore((state) => state.payments).filter(
    (payment) => payment.status == "pending"
  );

  const [selectAllButtonOn, setSelectAllButtonOn] = useState(false);
  const [currentButtonId, setCurrentButtonId] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [clientFilter, setClientFilter] = useState(false);
  const [pendingFilter, setPendingFilter] = useState(false);
  const [currentQuery, setCurrentQuery] = useState("");
  const users = useUserStore((state) => state.users);
  var filterCount = 0;
  const navigate = useNavigate();
  const { getPayments, updatePayment } = PaymentApi();

  const logTimeline = (data) => {
    Modal.info({
      width: "50rem",

      title: "Payment Logs",
      content: (
        <Timeline mode={"left"}>
          {data.logs.map((log) => {
            console.log(log, "logs");

            var color;
            var locaTime = new Date(log.date);
            var time = locaTime.toString();

            if (log.vendor_transferred_date) {
              color = "#007FFF";
            } else if (log.vendor_transferred_date) {
              color = "green";
            } else {
              color = "#5D76A9";
            }

            function formatTimeShow(h_24) {
              var h = h_24 % 12;
              if (h === 0) h = 12;
              return (
                h + `: ${time.substr(19, 2)}` + (h_24 < 12 ? " am" : " pm")
              );
            }

            var exactTime = formatTimeShow(parseFloat(time.substr(15, 10)));
            var text;
            var suffix = "";
            text = "by  " + users.find((user) => user._id == log?.by)?.name;

            return (
              <Timeline.Item
                style={{ fontSize: "15px" }}
                dot={
                  log.type == "created" ? (
                    <CheckOutlined />
                  ) : log.type == "cancelled" ? (
                    "X"
                  ) : (
                    ""
                  )
                }
                color={color}
              >
                {suffix} {log?.text} {text}{" "}
                <span style={{ fontWeight: "200" }}> on</span>{" "}
                <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                  {" "}
                  {time.substr(3, 12)}, {exactTime}{" "}
                </span>
              </Timeline.Item>
            );
          })}
        </Timeline>
      ),
      onOk() {},
    });
  };

  // useEffect(() => {

  //   if (!payments.length) {
  //    setTableLoading(true)

  //     getPayments().then(() => {});

  //       setTableLoading(false)
  //   }

  // }, []);

  //to Reflect changes in filtered array when payment status changes
  useEffect(() => {
    if (filteredData.length) {
      fuzzyFilter(currentQuery);
    }
  }, [payments]);

  const deSelectAllButton = () => {
    if (filteredData.length) {
      filteredData = filteredData.map((payment) => {
        if (selectedproducts.includes(payment._id)) {
          payment.checked = false;
        }

        return payment;
      });
    } else {
      payments = payments.map((payment) => {
        if (selectedproducts.includes(payment._id)) {
          payment.checked = false;
        }

        return payment;
      });
    }

    setSelectAllButtonOn(false);
    setSelectedProducts([]);
    setSelectedProductsPriceDetails([]);
  };

  const selectAllButton = () => {
    setSelectedProducts([]);
    setSelectedProductsPriceDetails([]);

    console.log(selectedproducts);
    console.log(selectedproductsPriceDetails._id);

    if (filteredData.length) {
      filteredData = filteredData.map((payment) => {
        if (
          payment.status == "pending" &&
          payment.client._id == currentClient._id
        ) {
          console.log("Inside");

          payment.checked = true;

          selectedproducts.push(payment._id);
          payment.price._id = payment._id;
          selectedproductsPriceDetails.push(payment);

          let uniquSelectedproducts = [...new Set([...selectedproducts])];

          let uniquSelectedproductsDetails = [
            ...new Set([...selectedproductsPriceDetails]),
          ];

          setSelectedProducts([...uniquSelectedproducts]);
          setSelectedProductsPriceDetails([...uniquSelectedproductsDetails]);
        }

        return payment;
      });
    } else {
      payments = payments.map((payment) => {
        if (
          payment.status == "pending" &&
          payment.client._id == currentClient._id
        ) {
          console.log("Inside");

          payment.checked = true;

          selectedproducts.push(payment._id);
          payment.price._id = payment._id;
          selectedproductsPriceDetails.push(payment);

          let uniquSelectedproducts = [...new Set([...selectedproducts])];

          let uniquSelectedproductsDetails = [
            ...new Set([...selectedproductsPriceDetails]),
          ];

          setSelectedProducts([...uniquSelectedproducts]);
          setSelectedProductsPriceDetails([...uniquSelectedproductsDetails]);
        }

        return payment;
      });
    }

    setSelectAllButtonOn(true);
  };

  const onSelection = (a, c, payment, index) => {
    console.log({ index });

    console.log({ a, c, payment });
    console.log(a.target.checked);
    setCurrentClient(payment.client);
    if (a.target.checked == true) {
      if (filteredData.length) {
        setFilteredData(
          filteredData.map((payment) => {
            if (payment._id == c) {
              payment.checked = true;
            }
            return payment;
          })
        );
      } else {
        payments = payments.map((payment) => {
          if (payment._id == c) {
            payment.checked = true;
          }
          return payment;
        });
      }

      selectedproducts.push(c);
      payment.price._id = payment._id;
      selectedproductsPriceDetails.push(payment);
      setSelectedProducts([...selectedproducts]);
      setSelectedProductsPriceDetails([...selectedproductsPriceDetails]);
    } else {
      if (filteredData.length) {
        if (!selectedproducts.length) {
          setCurrentClient("");
        }

        setFilteredData(
          filteredData.map((payment) => {
            if (payment._id == c) {
              payment.checked = false;
            }

            return payment;
          })
        );
      } else {
        payments = payments.map((payment) => {
          if (payment._id == c) {
            payment.checked = false;
          }

          return payment;
        });

        if (!selectedproducts.length) {
          setCurrentClient("");
        }
      }

      console.log("here");

      setSelectedProductsPriceDetails(
        selectedproductsPriceDetails.filter((product) => product._id !== c)
      );

      setSelectedProducts(selectedproducts.filter((product) => product !== c));
    }

    console.log(selectedproducts, "Selected product");
  };

  //Fuzzy filter function

  var [filteredData, setFilteredData] = useState(false);

  const fuzzyFilter = (query) => {
    setCurrentQuery(query);

    console.log(query);

    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1?.length > 0 && str2?.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length > 2) {
      results = [];

      for (j = 0, len = payments.length; j < len; j++) {
        var payment = payments[j];
        relevance = string_similarity(query, payment?.client?.name || "");
        obj = {
          ...payment,
          relevance: relevance,
        };
        results.push(obj);
      }

      var sortedResults = results.filter((result) => result.relevance > 0.2);

      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();

      console.log({ sortedResults });

      payments = [...sortedResults];

      setFilteredData(sortedResults);
      console.log(results);
    }
  };

  const columns = [
    {
      key: "7",

      render: (_, payments, index) => {
        if (!(clientFilter && pendingFilter)) {
          if (selectedproducts.length) {
            if (
              payments?.client?._id == currentClient?._id &&
              payments?.status == "pending"
            ) {
              return (
                <Form>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    valuePropName="checked"
                    name="ignoreSkills"
                    getValueProps={(value) => ({ checked: payments.checked })}
                    getValueFromEvent={(e) => !e.target.checked}
                  >
                    <Checkbox
                      key={payments?._id}
                      onChange={(e, v) =>
                        onSelection(e, payments?._id, payments, index)
                      }
                    ></Checkbox>
                  </Form.Item>
                </Form>
              );
            } else {
              return (
                <Form>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    valuePropName="checked"
                    name="ignoreSkills"
                    getValueProps={(value) => ({ checked: payments.checked })}
                    getValueFromEvent={(e) => !e.target.checked}
                  >
                    <Checkbox
                      key={payments?._id}
                      onChange={(e, v) =>
                        onSelection(e, payments._id, payments, index)
                      }
                      style={{ color: "FF9933", backgroundColor: "FF9933" }}
                      disabled
                    ></Checkbox>
                  </Form.Item>
                </Form>
              );
            }
          } else {
            if (payments.status == "pending") {
              return (
                <Form>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    valuePropName="checked"
                    name="ignoreSkills"
                    getValueProps={(value) => ({ checked: payments.checked })}
                    getValueFromEvent={(e) => !e.target.checked}
                  >
                    <Checkbox
                      key={payments?._id}
                      onChange={(e, v) =>
                        onSelection(e, payments._id, payments, index)
                      }
                      style={{ display: "hidden" }}
                    ></Checkbox>
                  </Form.Item>
                </Form>
              );
            } else {
              return (
                <Form>
                  <Form.Item
                    getValueProps={(value) => ({ checked: payments.checked })}
                  >
                    <Checkbox
                      key={payments?._id}
                      onChange={(e, v) =>
                        onSelection(e, payments._id, payments, index)
                      }
                      style={{ color: "FF9933", backgroundColor: "FF9933" }}
                      disabled
                    ></Checkbox>
                  </Form.Item>
                </Form>
              );
            }
          }
        }
      },
    },

    {
      title: "No",
      dataIndex: "payment_number",
      width: "3%",
      key: "1",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },

      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.payment_number?.toString()?.includes(value?.toString());
      },
      defaultSortOrder: "descend",
      sorter: (a, b) => a.payment_number - b.payment_number,
    },

    {
      title: "Client",
      render: (_, record) =>
        record.client?.name
          ? record.client?.name
          : users.find((user) => user._id == record.client)?.name,
      key: "2",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0]);
              }}
              onBlur={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      width: "3%",
      key: "3",

      render: (_, record) => {
        if (record.status == "invoice_created") {
          return (
            <Button
              style={{ padding: "0" }}
              type="link"
              onClick={() =>
                navigate("/invoice/" + record?.invoice, {
                  state: {
                    payment: record,
                  },
                })
              }
            >
              Invoice Created
            </Button>
          );
        } else {
          return humanize(record.status);
        }
      },

      filters: [
        { text: "Invoice Created", value: "invoice_created" },
        { text: "Pending", value: "pending" },
        { text: "Cancelled", value: "cancelled" },
      ],

      onFilter: (value, record) => {
        if (value == "pending") {
          filterCount++;
          if (filterCount <= 1) setPendingFilter(true);
        }
        return record.status.includes(value);
      },
    },

    {
      title: "Type",
      dataIndex: "type",
      width: "3%",
      key: "4",
      render: (_, record) => {
        return humanize(_ ? _ : "");
      },

      filters: [
        { text: "Other", value: "other" },
        { text: "Sale", value: "sale" },
        { text: "Additional plan", value: "additional_plan" },
        { text: "Plan Creation", value: "plan_creation" },
        { text: "Plan Recurring", value: "plan_recurring" },

        { text: "Defective Repair Cost", value: "defective_repair_cost" },
        { text: "Onsite Repair Cost", value: "onsite_repair_cost" },
      ],

      onFilter: (value, record) => record?.type?.includes(value),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (_, record) => {
        return moment(_).format("DD-MM-YYYY");
      },
      key: "5",
    },

    {
      title: "Description",
      dataIndex: "date",
      render: (_, record) => record?.description,
      key: "3",
    },

    {
      title: "Price",

      render: (_, record) => record?.price?.item_price?.toFixed(2),
      width: "3%",
      key: "6",
    },

    // {
    //   title: "Qty",

    //   render: (_, record) => record.price.qty,
    //   key: "3",
    // },

    {
      title: "Tax",
      dataIndex: "date",
      render: (_, record) => record?.price?.tax_amount?.toFixed(2),
      key: "8",
    },

    {
      title: "Amount",

      render: (record) => {
        return <>{record?.price?.amount?.toFixed(2)}</>;
      },
      key: "10",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },

      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.price?.amount.toString()?.includes(value.toString());
      },
    },
    {
      title: "Action",

      key: "11",
      render: (payment) => {
        if (payment.status == "pending") {
          return (
            <Space key={payment?._id} size="small">
              <Tooltip title={" Edit"}>
                <Button
                  hidden={
                    !currentUser.user.privileges.includes("payment_management")
                  }
                  icon={<EditOutlined />}
                  key={payment?._id}
                  onClick={() => editHandler(payment)}
                />
              </Tooltip>

              <Tooltip title={" Cancel Payment"}>
                <Button
                  hidden={
                    !currentUser.user.privileges.includes("payment_management")
                  }
                  disabled={currentClient}
                  icon={<CloseCircleOutlined />}
                  loading={buttonLoading && currentButtonId == payment._id}
                  onClick={() => {
                    Modal.confirm({
                      title: "Confirm",
                      content: "Cancel this payment ?",
                      okText: "Yes",
                      cancelText: "Cancel",
                      onOk: () => {
                        confirmEditHandler(payment, true, false);
                        setButtonLoading(true);
                        setCurrentButtonId(payment._id);
                      },
                      // onCancel: () => handleCancel(),
                    });
                  }}
                />
              </Tooltip>

              <Tooltip title={"Logs"}>
                <ExclamationCircleOutlined
                  onClick={() => logTimeline(payment)}
                  style={{ paddingLeft: "0.5rem" }}
                ></ExclamationCircleOutlined>
              </Tooltip>
            </Space>
          );
        } else if (payment.status == "cancelled") {
          return (
            <Space>
              <Tooltip title={"Restore"}>
                <Button
                  hidden={
                    !currentUser.user.privileges.includes("payment_management")
                  }
                  disabled={currentClient}
                  icon={<RetweetOutlined />}
                  loading={buttonLoading && currentButtonId == payment._id}
                  onClick={() => {
                    Modal.confirm({
                      title: "Confirm",
                      content: "Restore this payment ?",
                      okText: "Yes",
                      cancelText: "Cancel",
                      onOk: () => {
                        confirmEditHandler(payment, false, true);
                        setButtonLoading(true);
                        setCurrentButtonId(payment._id);
                      },
                      // onCancel: () => handleCancel(),
                    });
                  }}
                />
              </Tooltip>

              <Tooltip title={"Logs"}>
                <ExclamationCircleOutlined
                  onClick={() => logTimeline(payment)}
                  style={{ paddingLeft: "0.5rem" }}
                ></ExclamationCircleOutlined>
              </Tooltip>
            </Space>
          );
        }
      },
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleSelect = (record, selected) => {
    if (selected) {
      setSelectedProducts([...selectedproducts, record._id]);

      setSelectedRowKeys((keys) => [...keys, record._id]);

      console.log(record, "selected record");

      record.price._id = record._id;

      selectedproductsPriceDetails.push(record);
    } else {
      console.log(selectedRowKeys);
      setSelectedProducts(
        selectedproducts.filter((product) => product !== record._id)
      );
      setSelectedProductsPriceDetails(
        selectedproductsPriceDetails.filter(
          (product) => product._id !== record._id
        )
      );

      selectedproductsPriceDetails.filter(
        (product) => product._id !== record._id
      );

      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record._id);

        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  return (
    <>
      {!selectAllButtonOn ? (
        <Tooltip
          title={
            !selectedproducts.length
              ? "Select a client and click ‘Select All’ to select all payable items for that particular client."
              : ""
          }
        >
          {" "}
          <Button
            style={{ marginBottom: "1rem" }}
            disabled={!selectedproducts.length}
            onClick={() => selectAllButton()}
          >
            {" "}
            Select All{" "}
          </Button>{" "}
        </Tooltip>
      ) : (
        <Button
          style={{ marginBottom: "1rem" }}
          disabled={!selectedproducts.length}
          onClick={() => deSelectAllButton()}
        >
          Deselect All{" "}
        </Button>
      )}

      <Table
        loading={tableLoading}
        key="1"
        rowKey="_id"
        columns={columns}
        pagination={true}
        dataSource={filteredData ? filteredData : payments}
      />
    </>
  );
};

export default Paymenttable;
