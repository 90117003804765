import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  message,
  PageHeader,
  Skeleton,
} from "antd";
import { useEffect, useState } from "react";

import useCategoryStore from "stores/categoryStore";

import useRentStore from "stores/rentStore";
import productApi from "utils/composable/productsApi";

import GoogleMap from "components/googleMap/GoogleMap";
import PendingRentTable from "components/rent/PendingRentTable";
import PendingReturnTable from "components/rent/PendingReturnTable";
import RentForm from "components/rent/RentForm";
import RentProductWithUsTable from "components/rent/RentProductWithUsTable";
import RentTable from "components/rent/RentTable";
import TaskForm from "components/task/TaskForm";
import useClientStore from "stores/clientStore";
import useSalesStore from "stores/salesStore";
import useTaskStore from "stores/taskStore";
import { default as clientApi, default as ClientApi } from "utils/composable/clientApi";
import RentApi from "utils/composable/rentApi";
import TaskApi from "utils/composable/taskApi";
import WareHouseApi from "utils/composable/warehouseApi";

const Rent = ({ currentUser }) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const categories = useCategoryStore((state) => state.categories);
  const [visible, setVisible] = useState(false);
  const [taskFormVisible, setTaskFormVisible] = useState(false);

  const [editData, setEditData] = useState("");
  const [tableLoading, setTableLoadings] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  const [saleTask, setsaleTask] = useState(true);

  const [locationDetails, setLocationDetails] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [hideAddressBox, setHideAddressBox] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState();
  const [rentData, setRentData] = useState("");
  const [jobEditMode, setJobEditMode] = useState(false);
  const [jobAddMode, setJobAddMode] = useState(editData ? false : true);
  const [jobViewMode, setJobViewMode] = useState(false);
  const [returnTask, setReturnTask] = useState(false);
  const { getRentHistory, changeProduct } = RentApi();
  const [taskForm, setTaskForm] = useState(true);

  const { clientProducts } = ClientApi();

  const {

    addTask,
    getTasks,
    getEngineers,
  
  } = TaskApi();

  const tasks = useTaskStore((state) => state.tasks);



  const rents = useRentStore((state) => state.rents).filter(
    (rent) => rent.pending_delivery === true
  );

  const ReturnRents = useRentStore((state) => state.rents).filter(
    (rent) => rent.pending_delivery === false && rent.returned == false
  );
  const rentWithUs = useRentStore((state) => state.rentWithUs);



  const {
    addProduct,
    getProducts,
    updateProducts,
    getProductsWithStatusAndUse,
  } = productApi();
  const { getWarehouse } = WareHouseApi();


  //client Store
  const clients = useClientStore((state) => state.clients);

 
  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  const sales = useSalesStore((state) => state.sales);
  const pendingSales = sales.filter((sale) => sale.pending_delivery == true);
  const { getRents, addRent, closeRent, getRentWithUs } = RentApi();
  const { getClients } = clientApi();

  useEffect(() => {
    !tasks.length && getTasks();

    setTableLoadings(true);
    getProductsWithStatusAndUse("in_stock", "rent").then((resp) => {
      setTableLoadings(false);
    });

    if (!clients.length) {
      getClients();
    }

    if (!sales.length) {
      getRents().then(() => {});
    }

    if (!rentWithUs.length) {
      getRentWithUs()
        .then(() => {})
        .finally(() => {});
    }
  }, []);

  const submitHandler = (payload, form) => {
    setSubmitLoader(true);
    try {
      addRent(payload)
        .then((data) => {
          setSubmitLoader(false);
          setVisible(false);
          setTaskFormVisible(false);

          // message.success(data.message);
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
          setSubmitLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const taskSubmitHandler = (payload, form) => {
    console.log(payload, "submit paylaod");
    setsubmitLoading(true);
    addTask(payload)
      .then((msg) => {
        getRents();
        setsubmitLoading(false);
        setTaskFormVisible(false);
        setVisible(false);
        form.resetFields();
        message.success(msg);
        setLocationEditData(false);
        setLocationDetails(false);
      })
      .catch((msg) => {
        setsubmitLoading(false);
        errorMsg(msg);
      });
  };

  const editHandler = (template) => {
    setEditData(template);
    setVisible(true);
  };

  const confirmEditHandler = (updatePayload, form) => {
    setSubmitLoader(true);

    try {
      updateProducts(updatePayload)
        .then((msg) => {
          setSubmitLoader(false);
          setVisible(false);
          editSuccessful(msg);
          form.resetFields();
        })
        .catch((msg) => {
          console.log(msg);
          setSubmitLoader(false);
          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setEditData("");
    setVisible(false);
    setTaskFormVisible(false);
  };

  const addNewSale = () => {
    setEditData(false);
    setTaskFormVisible(true);
  };

  const fillForm = () => {
    message.error("Please select Client and Date first ");
  };

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData?.location?.coordinates);
  };

  const getFieldEngineers = (client, date) => {
    if (!client || !date) {
      fillForm();
    } else {
      setAssignLoading(true);
      getEngineers(client, date)
        .then((resp) => {
          setAssignLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAssignLoading(false);
          message.error(err);
        });
    }
  };

  const refresh = async () => {
    setRefreshLoading(true);

    //    getProducts()
    // getSales().finally(() => {
    // })
    let promise3 = getRentHistory();
    let pormise1 = getProducts();
    let promise2 = getRents();
    let promises = [pormise1, promise2, promise3];
    await Promise.allSettled(promises).finally(() => {
      setRefreshLoading(false);
    });
  };

  const handleCancel = (form) => {
    setVisible(false);
  };

  const tabList = [
    {
      key: "tab1",
      tab: (
        <span style={{ fontWeight: "500" }}>
          {" "}
          Products with client ( {ReturnRents.length} ){" "}
        </span>
      ),
    },

    {
      key: "tab2",
      tab: (
        <span style={{ fontWeight: "500" }}>
          {" "}
          Pending Deliveries ( {rents.length} ){" "}
        </span>
      ),
    },
    {
      key: "tab3",
      tab: (
        <span style={{ fontWeight: "500" }}>
          {" "}
          Returned ( {rentWithUs.length} ){" "}
        </span>
      ),
    },
    {
      key: "tab4",
      tab: (
        <span style={{ fontWeight: "200", color: "grey" }}> Rent History </span>
      ),
    },
  ];

  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  const createRentProductDeliveryJob = async (
    rentData,
    returnTask,
    productUpdatedDelivery
  ) => {
    console.log({ rentData });

    setEditData(false);
    setJobViewMode(false);

    if (returnTask) setReturnTask(true);

    clientProducts(rentData.client._id);

    var productList;

    if (returnTask) {
      productList = ReturnRents.filter(
        (rent) => rent.client._id == rentData.client._id
      ).map((rent) => {
        console.log(rent, "rent.....");
        rent.product.clientProduct = rent.client_product;
        rent.product.rentId = rent._id;
        const returnProduct = rent.product;
        return returnProduct;
      });
    } else if (productUpdatedDelivery) {
      productList = rentWithUs
        .filter(
          (rent) => rent.client._id == rentData.client._id && rent.task == null
        )
        .map((rent) => {
          console.log(rent, "rent.....");
          rent.product.clientProduct = rent.client_product;
          rent.product.rentId = rent._id;
          const returnProduct = rent.product;
          return returnProduct;
        });
    } else {
      productList = rents
        .filter(
          (rent) => rent.client._id == rentData.client._id && rent.task == null
        )
        .map((rent) => {
          console.log(rent, "rent.....");

          rent.product.clientProduct = rent.client_product;
          rent.product.rentId = rent._id;

          const returnProduct = rent.product;

          return returnProduct;
        });
    }

    const jobs = productList.map((product) => {
      return {
        productId: product?.clientProduct || product?.client_product,
        status: "unassigned",
        type: returnTask ? "rent_product_return" : "rent_product_delivery",
        description: "",
        product: product?.clientProduct || product?.client_product,
        company_product: product?._id,
        rent: product.rentId,
      };
    });

    const productListProp = productList.map((product) => {
      return {
        label: `${product?.product?.name || product?.name} - ${
          product?.product?.barcode || ""
        }    ( ${product?.plan || "Rent"} )`,
        value: product?.clientProduct || product?.client_product,
        key: product?.key,
      };
    });

    var tempServiceData = {};

    tempServiceData = {
      ...rentData,
      jobs: jobs,
      productList: productListProp,
    };

    setRentData(tempServiceData);

    // setJobAddMode(true);

    setVisible(true);
  };

  const showRentTask = (task_id) => {
    setRentData("");

    const editMode = (task) => {
      console.log({ task, task_id });
    

      // task.formattedDate = formatDate(task?.due_date || "");

      task.jobs = task.jobs.map((job) => {
        return {
          ...job,
          productId: job.product?._id,
        };
      });

      // task.location.coordinates = task.location?.coordinates?.reverse();
      setEditData(task);
      setVisible(true);
    };

    editMode(tasks.find((task) => task._id == task_id));
    setJobViewMode(true);
  };

  const contentList = {
    tab1: (
      <Skeleton active loading={tableLoading}>
        <PendingReturnTable
          currentUser={currentUser}
          createRentProductReturnJob={createRentProductDeliveryJob}
          editHandler={editHandler}
          showRentTask={showRentTask}
        />
      </Skeleton>
    ),

    tab2: (
      <Skeleton active loading={tableLoading}>
        <PendingRentTable
          closeRent={closeRent}
          currentUser={currentUser}
          createRentProductDeliveryJob={createRentProductDeliveryJob}
          editHandler={editHandler}
          showRentTask={showRentTask}
        />
      </Skeleton>
    ),
    tab3: (
      <Skeleton active loading={tableLoading}>
        <RentProductWithUsTable
          createRentProductDeliveryJob={createRentProductDeliveryJob}
          changeProduct={changeProduct}
          closeRent={closeRent}
          currentUser={currentUser}
          showRentTask={showRentTask}
          editHandler={editHandler}
        />
      </Skeleton>
    ),
    tab4: (
      <Skeleton active loading={tableLoading}>
        <RentTable
          currentUser={currentUser}
          showRentTask={showRentTask}
          editHandler={editHandler}
        />
      </Skeleton>
    ),
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Rent"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />
      <Divider orientation="center">
        <Button
          disabled={!currentUser.user.privileges.includes("payment_management")}
          type="primary"
          onClick={addNewSale}
          icon={<PlusOutlined />}
        >
          New Rent
        </Button>
      </Divider>

      <Card
        style={{ width: "100%" }}
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={(key) => {
          onTab1Change(key);
        }}
      >
        {contentList[activeTabKey1]}
      </Card>

      <Drawer
        size={"large"}
        destroyOnClose={true}
        key={editData?._id}
        title={editData ? "Edit Rent Data" : "Create New Rent"}
        placement="right"
        onClose={onClose}
        visible={taskFormVisible}
      >
        <RentForm
          editData={editData}
          submitHandler={submitHandler}
          submitLoader={submitLoader}
          setVisible={setVisible}
          confirmEditHandler={confirmEditHandler}
        />
      </Drawer>

      <Drawer
        destroyOnClose={true}
        width={"100vw"}
        title={`Rent Task ${editData?.task_number || ""} `}
        placement="right"
        onClose={handleCancel}
        visible={visible}
      >
        <Divider style={{ padding: "1rem" }}>Enter Task Details</Divider>

        <TaskForm
          rentData={rentData}
          jobViewMode={jobViewMode}
          returnTask={returnTask}
          // jobSubmitHandler={jobSubmitHandler}
          // jobUpdateHandler={jobUpdateHandler}
          locationDetails={locationDetails}
          submitHandler={taskSubmitHandler}
          ChangeLocation={ChangeLocation}
          editData={editData}
          // updateHandler={updateHandler}
          getFieldEngineers={getFieldEngineers}
          assignLoading={assignLoading}
          setJobLoading={setJobLoading}
          jobLoading={jobLoading}
          setJobEditMode={setJobEditMode}
          jobEditMode={jobEditMode}
          jobAddMode={jobAddMode}
          setJobAddMode={setJobAddMode}
        />

        <Divider style={{ marginBottom: "1rem" }}>Client Location</Divider>

        <GoogleMap
          taskForm={taskForm}
          hideAddressBox={hideAddressBox}
          locationEditData={locationEditData}
          setLocationDetails={setLocationDetails}
        />
        <Button
          hidden={editData}
          className="submitButtonTask"
          form="taskForm"
          key="submit"
          type="primary"
          htmlType="submit"
          loading={submitLoading}
        >
          Submit
        </Button>
      </Drawer>
    </>
  );
};

export default Rent;
