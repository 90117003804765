import create from "zustand";
import { devtools } from "zustand/middleware";

const taxStore = (set) => ({
    taxes: [],

    addTax: (newtax) => {
        set((state) => ({
            taxes: [...state.taxes, newtax],
        }));
    },
    setTaxes: (apiTaxes) => {
        set((state) => ({
            taxes: [...apiTaxes], 
        }));
    },


});

const useTaxStore = create(devtools(taxStore));

export default useTaxStore;
;
