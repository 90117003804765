import create from "zustand";
import { devtools } from "zustand/middleware";

const StandByReqStore = (set) => ({
    standByReqs: [],

    setRequest: (apiRequest) => {
        set((state) => ({
            standByReqs: [...apiRequest],
        }));
    },

    updateRequest: (editData) => {

            

        set((state) => ({
            standByReqs: state.standByReqs?.map((request) => {
                if (request._id === editData._id) {
                    return {
                     ...editData
                    };
                } else {
                    return request;
                }
            }),
        }));
    },

});

const useStandByReqStore = create(devtools(StandByReqStore));

export default useStandByReqStore;
;
