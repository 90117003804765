
import create from "zustand";
import { devtools } from "zustand/middleware";

const rentStore = (set) => ({
  rents: [],
  rentHistory: [],
  rentWithUs: [],

  foundProduct: {},
  addRent: (newRent) => {
    set((state) => ({
      rents: [newRent, ...state.rents],
    }));
  },

  setRent: (apiRents) => {
    set((state) => ({
      rents: [...apiRents],
    }));
  },

  setRentHistory: (apiRentHistory) => {
    set((state) => ({
      rentHistory: [...apiRentHistory],
    }));
  },

  setRentWithUsToStore: (apiRentWithUs) => {
    set((state) => ({
      rentWithUs: [...apiRentWithUs],
    }));
  },

  closeRent: (rentId) => {
    set((state) => ({
      rents: state.rents.filter((rent) => rent._id !== rentId),
      rentWithUs: state.rentWithUs.filter((rent) => rent._id !== rentId),
    }));
  },

  ChangeProductInStore: (rentId, product,companyProduct,updatedRent) => {
    set((state) => ({
      rentWithUs: state.rentWithUs
        .map((rent) => {
       

          if (rentId === rent._id) {

     
            
              updatedRent.task = null;
              updatedRent.pending_delivery = true;
            // rent.product = product;
            //   rent.company_product = companyProduct._id
              state.rents = [...state.rents, updatedRent];
              return {...updatedRent}
          }else{

              return rent;
          }
     
        })
        .filter((rent) => rent._id !== rentId),

    }));
  },
});

const useRentStore = create(devtools(rentStore));
export default useRentStore;
