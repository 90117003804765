import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  Form,
  message,
  Modal,
  PageHeader,
  Skeleton,
  Space
} from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import GoogleMap from "components/googleMap/GoogleMap";
import TaskForm from "components/task/TaskForm";
import useClientProductStore from "stores/clientProductStore";
import useDefectiveProductStore from "stores/defectiveProductStore";
import useProductStore from "stores/productStore";
import ClientApi from "utils/composable/clientApi";
import ProductApi from "utils/composable/productsApi";
import StandByApi from "utils/composable/standByApi";

import useTaskStore from "stores/taskStore";
import TaskApi from "utils/composable/taskApi";
import TeamApi from "utils/composable/teamApi";

import moment from "moment";
import useStandByReqStore from "stores/standByReqStore";
import useUserStore from "stores/teamStore";

import humanize from "utils/formate-status";

const StandbyProductReturnDetails = () => {


 

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [currentTask, setCurrentTask] = useState({});
  const [currentStandbyRequest, setCurrentStandbyReq] = useState(false);
  const [currentDefectiveProductId, setCurrentDefectiveProductId] =
    useState("");
  const [standByProductJob, setStandByProductJob] = useState(false);
  const [jobViewMode, setJobViewMode] = useState(false);
  const [reqModalVisible, setReqModalVisible] = useState(false);
  const [standByProductReturnTaskData, setStandByProductReturnTaskData] =
    useState(false);
  const { state } = useLocation();
  const { returnTask } = state;
  const users = useUserStore((state) => state.users);

  const { getProducts, getProductsWithStatusAndUse } = ProductApi();
  const [form] = Form.useForm();
  const { getStandByRequest, updateStandByRequest } = StandByApi();


  const { setClientProductsToStore } = useClientProductStore((state) => ({
    setClientProductsToStore: state.setClientProducts,
  }));

  const standbyProducts = useStandByReqStore((state) => state.standByReqs);
  const { setReturnTaskSheduledInDefectiveStore } = useDefectiveProductStore(
    (state) => ({
      setReturnTaskSheduledInDefectiveStore:
        state.setReturnTaskSheduledInDefectiveStore,
    })
  );

  //notification
  const taskAdded = (msg) => {
    message.success(msg);
  };

  const jobSuccessMsg = (msg) => {
    message.success(msg);
  };

  const jobErrorMsg = (msg) => {
    message.error(msg);
  };

  const fillForm = () => {
    message.error("Please select Client and Date first ");
  };


  const errorMsg = (msg) => {
    message.error(msg);
  };


  const [visible, setVisible] = useState(false);
  const [tableLoading, setTableLoadings] = useState(false);
  const [moreData, setMoredata] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [locationDetails, setLocationDetails] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [hideAddressBox, setHideAddressBox] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState();
  const [editData, setEditData] = useState("");
  const [jobEditMode, setJobEditMode] = useState(false);
  const [jobAddMode, setJobAddMode] = useState(editData ? false : true);
  const [submitLoader, setSubmitLoader] = useState(false);

  const [taskForm, setTaskForm] = useState(true);

  const products = useProductStore((state) => state.Products);
  const requests = useStandByReqStore((state) => state.standByReqs);

  const [currentReq, setCurrentReq] = useState();

  //composable
  const { getClients } = ClientApi();
  const { getUsers } = TeamApi();
  const {
    updateTask,
    addTask,
    getTasks,
    getAllHistoricalTasks,
    getEngineers,
    editTaskJobs,
    AddTaskJobs,
  } = TaskApi();

  
  const activeTasks = useTaskStore((state) => state.tasks);
  const [historicalTasks, setHistoricalTasks] = useState([]);
  let [tasks, setTasks] = useState([]);
 
 
  useEffect(() => {
    const fetchHistoricalasks = async () => {
      try {
        const result = await getAllHistoricalTasks();
       setHistoricalTasks(result);
      } catch (error) {
        console.error("Failed to fetch historical tasks:", error);
      }
    };
    if(!historicalTasks.length){
      fetchHistoricalasks();
    }
    
  }, [historicalTasks.length]); 

  useEffect(() => {
    setTasks([...activeTasks, ...historicalTasks]);
  }, [activeTasks, historicalTasks]);

  // Log merged tasks for debugging
  useEffect(() => {
  }, [tasks]); // Dependencies ensure this runs when tasks state changes

  const handleCancel = (form) => {
    setVisible(false);

    const task = tasks.find((task) => task._id == currentStandbyRequest.task);

    task.jobs[task.jobs.length - 1].assigned == "" && task.jobs.pop();
  };

  useEffect(() => {
  
    getClients();
    getUsers();

    if (!tasks.length) {
      setTableLoadings(true);

      getTasks()
        .then((resp) => {
          let task = tasks.find((task) => task._id == state.task);
          if (task) {
            setCurrentTask(task);
          } 

        })
        .finally(() => {
          setTableLoadings(false);
        });
    } else {
      
      let task = tasks.find((task) => task._id == state.task);
     if (task) {
        task.type = "complaint";
        setCurrentTask(task);
      } 
     
    }

    setTaskForm(true);
  }, []);

  const inStockProducts = products?.filter(
    (product) => product?.status == "in_stock" && product?.use == "stand-by"
  );

  const productList = inStockProducts?.map((product) => ({
    label: `${product?.name}     ${
      product?.barcode || "" ? "(" + product?.barcode + ")" : ""
    }`,
    value: product?._id,
    key: product?._id,
  }));

  useEffect(() => {
    getStandByRequest().then((requests) => {
      let currentReq = requests.find(
        (request) =>
          request._id == (state?.stand_by_product_request || state._id)
      );

      setCurrentStandbyReq(currentReq);

      state.product = currentReq.product;
    });

    if (!products.length) getProductsWithStatusAndUse("in_stock", "stand-by");

    setCurrentReq(requests);

    productList.push({
      label: `Select a stand by product`,
      value: undefined,
    });

    return () => {
      setCurrentReq({});
    };
  }, []);

  useEffect(() => {
  }, [currentStandbyRequest]);

 
  const editMode = (task) => {
    
    
    task.type = "Standby Product Return";
    task.jobs = task.jobs.map((job) => {
      return {
        ...job,
        productId: job.product?._id,
      };
    });
 setEditData(task);
    setVisible(true);
  };

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData?.location?.coordinates);
  };

  const changeStandbyProduct = (value, request) => {

    const updatePayload = {
      request_id: request._id,
      client: request?.client?._id,
      product: value,
    };

    updateStandByRequest(updatePayload)
      .then((resp) => {
        setCurrentReq(resp);
        message.success("Request updated");
        state.product = resp.product;
        setSubmitLoader(false);
        setVisible(false);
        setReqModalVisible(false);
      })
      .catch((err) => {
        setVisible(false);
        setReqModalVisible(false);
        setSubmitLoader(false);
        message.error(err);
      });
  };

  const jobUpdateHandler = (key, form) => {
    let jobs = form.getFieldValue("jobs");
    const editedJob = jobs[key];
    editedJob.job_id = editedJob._id;
    editedJob.task_id = editData._id;

    editedJob.job = {
      product: editedJob.productId,
      description: editedJob.description,
      status: editedJob.status,
      type: editedJob.type,
      assigned: editedJob?.assigned[0]?._id
        ? editedJob?.assigned[0]?._id
        : editedJob.assigned,
    };

    const jobUpdatePayload = {
      job_id: editedJob._id,
      task_id: editData._id,
      job: {
        product: editedJob.productId,
        description: editedJob.description,
        status: editedJob.status,
        type: editedJob.type,
        assigned: editedJob?.assigned[0]?._id
          ? editedJob.assigned[0]?._id
          : editedJob?.assigned,
        stand_by_product_request: editData._id,
        defective_product: editedJob.defective_product._id,
      },
    };

   setJobLoading(true);
    editTaskJobs(jobUpdatePayload, key, editedJob)
      .then((resp) => {
        setJobLoading(false);
        setJobEditMode(false);
        jobSuccessMsg(resp);
      })
      .catch((err) => {
        setJobLoading(false);
        console.log(err);
        jobErrorMsg(err);
      });
  };

  const jobSubmitHandler = (key, form, fieldEngineer) => {
    // if (typeof fieldEngineer == "object") {
    //   message.error("Please assign a field engineer ");
    //   return;
    // }

    const jobs = form.getFieldValue("jobs");
    jobs[jobs.length - 1].assigned = fieldEngineer;
    const newJob = jobs[jobs.length - 1];
    newJob.assigned = fieldEngineer;
   
    const newJobPayload = {
      task_id: editData?._id,
      job: {
        product: currentStandbyRequest.product._id,
        description: newJob.description,
        assigned: typeof fieldEngineer == "object" ? null : fieldEngineer,
        type: "stand_by_product_return",
        stand_by_product_request: state?.stand_by_product_request,
      },
    };

    setJobLoading(true);

    setReturnTaskSheduledInDefectiveStore(state._id);

    AddTaskJobs(newJobPayload)
      .then((task) => {
        if (editData) {
          editMode(task);
        }

        setJobLoading(false);
        setJobAddMode(false);
        const currentStandByProduct = standbyProducts.find(
          (SBP) => (SBP._id = state._id)
        );
        currentStandByProduct.status = "Processed";
        state.status = "Processed";

        jobSuccessMsg("Job added");
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  const getFieldEngineers = (client, date) => {
   
    if (!client || !date) {
      fillForm();
    } else {
      setAssignLoading(true);
      getEngineers(client, date)
        .then((resp) => {
          setAssignLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAssignLoading(false);
          jobErrorMsg(err);
        });
    }
  };

  //task section ends here

  //Defective Flow

  const createReturnJob = (standByReq) => {
    setStandByProductReturnTaskData(false);

    //return job
    standByReq.product = currentStandbyRequest?.product;
    const task = tasks.find((task) => task._id == standByReq.task);
    setCurrentStandbyReq(standByReq);
    setCurrentDefectiveProductId(standByReq.defective_product);

    const newJob = {
      product: currentStandbyRequest?.product._id,
      status: "Unassigned",
      type: "stand_by_product_return",
      assigned: "",
      stand_by_product_request: currentStandbyRequest?._id,
    };

    task.standByProduct = standByReq?.product;
    task.jobs.push(newJob);
    setCurrentTask(task);
    setJobAddMode(true);
    editMode(task);
    setStandByProductJob(true);
  };

  //  Standby Moduke Flow
  const createReturnTask = (standByReq) => {
    setEditData(false);
    setJobViewMode(false);
    setStandByProductJob(false);
    const task = tasks.find((task) => task._id == standByReq.task);
    if (task.client_location){

      standByReq.client_location = task.client_location
    }
  

    setStandByProductReturnTaskData(standByReq);


    setCurrentStandbyReq(standByReq);
    setCurrentDefectiveProductId(standByReq.defective_product);

    // const task=

    const newJob = {
      productId: currentStandbyRequest?.client_product._id,
      product: currentStandbyRequest?.product._id,
      status: "Unassigned",
      type: "stand_by_product_return",
      standbyProduct: currentStandbyRequest?.product.name,
      assigned: "",
      stand_by_product_request: currentStandbyRequest?._id,
    };

    standByReq.job = [newJob];

    standByReq.productList = [
      {
        label: `${standByReq.client_product?.name}     ${
          standByReq.client_product?.barcode || ""
            ? "(" + standByReq.client_product?.barcode + ")"
            : ""
        }`,
        value: standByReq.client_product?._id,
        key: standByReq.client_product?._id,
      },
    ];

    setStandByProductReturnTaskData(standByReq);
  
    setJobAddMode(false);
    setVisible(true);
    // editMode(task);
  };

  const handleReject = (req) => {
    setOpen(true);

    const handleOkay = (req) => {
     
      setConfirmLoading(true);

      setOpen(false);
    };

    Modal.confirm({
      title: "Confirm",

      content: "Are you sure?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => handleOkay(req),
    });
  };

  const taskSubmitHandler = (payload, form) => {
    setsubmitLoading(true);
    addTask(payload)
      .then((msg) => {
        state.returned_scheduled=true
   

        setsubmitLoading(false);
        setVisible(false);
        setVisible(false);
        form.resetFields();
        message.success(msg);
        // setLocationEditData(false);
        // setLocationDetails(false);
      })
      .catch((msg) => {
        setsubmitLoading(false);
        errorMsg(msg);
      });
  };

  return (
    <>
      <div className="site-page-header-ghost-wrapper">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={state.product?.name || "Product Not Assigned"}
          subTitle={state?.product?.barcode || ""}
          extra={[
           

            state.status == "requested" && (
              <Button
                loading={confirmLoading}
                onClick={() => handleReject(state)}
                key="2"
              >
                Reject
              </Button>
            ),

            <Button
              onClick={() => {
                editMode(tasks.find((task) => task._id == state.task));
                setJobViewMode(true);
              }}
            >
              Show Task
            </Button>,

           ( state.product?.name &&
              !currentStandbyRequest?.standby_return_scheduled ) ? (
              <Space>
                <Button
                  hidden={state.requested_by}
                  onClick={() => createReturnJob(state)}
                  key="1"
                  type="primary"
                >
                  {" "}
                  Create Return Job
                </Button>

                <Button
                   hidden={state.returned_scheduled || state.defectiveFlow  }
                  onClick={() => createReturnTask(state)}
                  key="1"
                  type="primary"
                >
                  {" "}
                  Create Return Task
                </Button>
              </Space>
            ) : (
              ""
            ),
          ]}
        >
          <Descriptions
            bordered
            style={{ margin: "1rem" }}
            labelStyle={{ fontWeight: "bolder" }}
            size="small"
            column={1}
          >
            <Descriptions.Item label="Client Name">
              {state.client?.name}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Client Product"
            >
              {state.client_product?.name} -{state?.client_product?.barcode}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Warehouse"
            >
              <a>{state.product?.warehouse.title}</a>
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Status"
            >
              <a>{humanize(state.status)}</a>
            </Descriptions.Item>

            {state?.picked_up_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Picked Up on"
              >
                {moment(state?.picked_up_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                - {users.find((user) => user._id == state?.picked_up_by)?.name}
              </Descriptions.Item>
            )}

            {currentStandbyRequest?.delivered_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Delivered on"
              >
                {moment(currentStandbyRequest?.delivered_on)
                  .utc()
                  .format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                -{" "}
                {
                  users.find(
                    (user) => user._id == currentStandbyRequest?.delivered_by
                  )?.name
                }
              </Descriptions.Item>
            )}

            {state?.returned_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label=" Returned on "
              >
                {moment(state?.returned_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                - {users.find((user) => user._id == state?.returned_by)?.name}
              </Descriptions.Item>
            )}

            {state?.closed_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label=" Closed on "
              >
                {moment(state?.closed_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                - {users.find((user) => user._id == state?.closed_by)?.name}
              </Descriptions.Item>
            )}

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label=" Current Standby Product "
            >
              {currentStandbyRequest?.product?.name}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Task No"
            >
              <a>{currentTask?.task_number}</a>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      </div>

      <Skeleton active loading={false}>
        <Divider style={{ fontWeight: "bold" }}>Description</Divider>

        <a>{state.description}</a>
      </Skeleton>

      <Drawer
        destroyOnClose={true}
        width={"100vw"}
        title={
          standByProductJob
            ? "Create Standby Product Deilvery Job"
            : editData && !jobViewMode
            ? `Update Task ${editData.task_number} `
            : editData && jobViewMode
            ? `Related Task ${editData.task_number} `
            : "Create Task"
        }
        size={"large"}
        placement="right"
        onClose={handleCancel}
        visible={visible}
      >
        <Divider style={{ padding: "1rem" }}>Enter Task Details</Divider>

        <TaskForm
          jobViewMode={jobViewMode}
          standByProductJob={standByProductJob}
          jobSubmitHandler={jobSubmitHandler}
          jobUpdateHandler={jobUpdateHandler}
          locationDetails={locationDetails}
          submitHandler={taskSubmitHandler}
          ChangeLocation={ChangeLocation}
          editData={editData}
          // updateHandler={updateHandler}
          getFieldEngineers={getFieldEngineers}
          assignLoading={assignLoading}
          setJobLoading={setJobLoading}
          jobLoading={jobLoading}
          setJobEditMode={setJobEditMode}
          jobEditMode={jobEditMode}
          jobAddMode={jobAddMode}
          setJobAddMode={setJobAddMode}
          returnTask={false}
          standbyReturnJob={standByProductReturnTaskData ? false : true} //true
          standByProductReturnTaskData={standByProductReturnTaskData}
        />

        <Divider style={{ marginBottom: "1rem" }}>Client Location</Divider>

        <GoogleMap
          taskForm={taskForm}
          hideAddressBox={hideAddressBox}
          locationEditData={locationEditData}
          setLocationDetails={setLocationDetails}
        />
        <Button
          hidden={!standByProductReturnTaskData}
          className="submitButtonTask"
          form="taskForm"
          key="submit"
          type="primary"
          htmlType="submit"
          loading={submitLoading}
        >
          Submit
        </Button>
      </Drawer>
    </>
  );
};

export default StandbyProductReturnDetails;
