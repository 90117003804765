import { message } from "antd";
import axios from "axios/axios";
import useTaskStore from "stores/taskStore";
import useUserStore from "stores/teamStore";
import authHeader from "utils/services/auth-header";

const TaskApi = () => {
  const {
    updateTaskToStore,
    addNewTask,
    setTasks,
    setHistoricalTasks,
    setFieldEngineers,
    updateTaskJobToStore,
    cancelJobInStore,
    setServiceWorkers,
    setDeliveryJob,
  } = useTaskStore((state) => ({
    addNewTask: state.addTask,
    setTasks: state.setTasks,
    setHistoricalTasks: state.setHistoricalTasks,
    updateTaskToStore: state.updateTaskToStore,
    setFieldEngineers: state.setFieldEngineers,
    setServiceWorkers: state.setServiceWorkers,
    setDeliveryJob: state.setDeliveryWorkers,
    updateTaskJobToStore: state.updateTaskJobToStore,
    cancelJobInStore: state.cancelJobInStore,
  }));

  const users = useUserStore((user) => user.users);
  const addTask = async (data) => {


    data.jobs = data.jobs.map((job) => {
      if (job.product._id) {
        job.product = job.product._id;
        return job;
      } else {
        return job;
      }
    });

    if (data.client_location === "") data.client_location = null;

    return new Promise((resolve, reject) => {
      axios
        .post("/task", data, {
          headers: authHeader(),
        })
        .then((resp) => {

          addNewTask(resp.data.task);
          resolve(resp.data.message);
        })
        .catch((error) => {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };

  const getTasks = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/tasks", {
          headers: authHeader(),
        })
        .then((resp) => {
       
          setTasks(resp.data.tasks);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  };

  const getHistoricalTasks = async (searchPayload = {}) => {
    const { startDate, endDate, client, team} = searchPayload;
    const params = new URLSearchParams();
  
    if (startDate && endDate) {
      params.append('startDate', startDate);
      params.append('endDate', endDate);
    }
    if (client) {
      params.append('client', client);
    }
    if (team) {
      params.append('team', team);
    }
    const queryString = params.toString() ? `?${params.toString()}` : '';
    console.log("Calling History API")
    return new Promise((resolve, reject) => {
      axios
        .get(`/historical-tasks${queryString}`, {
          headers: authHeader(),
        })
        .then((resp) => {
          console.log("History API Result", resp.data.tasks)
          setHistoricalTasks(resp.data.tasks);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  };

  const getAllHistoricalTasks = async () => {
    console.log("Calling History API")

    try {
      console.log("Calling History API");
      
      const response = await axios.get(`/historical-tasks`, {
        headers: authHeader(),
      });
  
      console.log("History API Result", response.data.tasks);
  
      return response.data.tasks; // Return the tasks directly
    } catch (error) {
      console.error("Error fetching historical tasks:", error);
      throw error; // Propagate the error to be handled by the caller
    }
    
    
  };


  

  const cancelTaskApi = async (taskId, task) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/task/cancel",
          { task_id: taskId },
          {
            headers: authHeader(),
          }
        )
        .then((resp) => {
          task.status = "cancelled";
          task.jobs = task.jobs.map((job) => {
            job.status = "cancelled";
            return job;
          });
          updateTaskToStore(resp.data.task);
   
          // setTasks(resp.data.tasks);
          message.success(resp.data.message);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          message.error(error.data.message);
          reject();
        });
    });
  };


  

  const closeTaskApi = async (taskId, task) => {

    return new Promise((resolve, reject) => {
      axios
        .post(
          "/task/close",
          { task_id: taskId },
          {
            headers: authHeader(),
          }
        )
        .then((resp) => {
          task.status = "closed";
         ;
          updateTaskToStore(resp.data.task);

          // setTasks(resp.data.tasks);
          message.success(resp.data.message);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          message.error(error.data.message);
          reject();
        });
    });
  };










  const cancelJobApi = async (taskId, jobId, jobNumber) => {

    return new Promise((resolve, reject) => {
      axios
        .put(
          "/task/job/cancel",
          { task_id: taskId, job_id: jobId },
          {
            headers: authHeader(),
          }
        )
        .then((resp) => {
     
          cancelJobInStore(taskId, jobNumber, resp.data.taskStatus);

          message.success(resp.data.message);

          resolve();
        })
        .catch((error) => {
          console.log(error);
          message.error(error.response.data.message);
          reject();
        });
    });
  };

  const updateTask = async (data) => {
  
    return new Promise((resolve, reject) => {
      axios
        .put("/task", data, { headers: authHeader() })
        .then((resp) => {
    
          updateTaskToStore(resp.data.task);
          resolve(resp.data.message);
        })
        .catch((err) => {
          reject();
          message.error(err.response.data.message);
          console.log(err.response.data.message);
        });
    });
  };

  const editTaskJobs = async (data, key, editedJob) => {  
  

    //converting status to lowercase
    var jobStatus = data.job.status;
    data.job.status = jobStatus.charAt(0).toLowerCase() + jobStatus.slice(1);



    const {
      job_number,
      type,
      company_product,
      sale,
      rent,
      stand_by_product_request,
      defective_product,
      assigned_by,
      repair_costs,
      notes,
      logs,
    } = editedJob;

    const jobPayload = {
      job_number,
      type,
      company_product,
      sale,
      rent,
      stand_by_product_request,
      defective_product,
      assigned_by,
      repair_costs,
      notes,
      logs,
      ...data.job,
    };
    data.job = jobPayload;

    return new Promise((resolve, reject) => {
      axios
        .put("/task/job", data, { headers: authHeader() })
        .then((resp) => {


          let taskStatus = resp.data.taskStatus
          //  updateTaskToStore(data)
         

          updateTaskJobToStore(key, data, users, taskStatus);

          resolve(resp.data.message);
        })
        .catch((err) => {
          reject(err);
          message.error(err.response.data.message);
          console.log(err.response.data.message);
        });
    });
  };

  const AddTaskJobs = async (data) => {


    return new Promise((resolve, reject) => {
      axios
        .post("/task/job", data, { headers: authHeader() })
        .then((resp) => {
  

          if (
            resp.data.task.jobs[resp.data.task.jobs.length - 1].type ===
            "product_delivery"
          ) {
            let newJob = resp.data.task.jobs[resp.data.task.jobs.length - 1];

            if (
              newJob?.stand_by_product_request?.status === "requested" &&
              newJob?.stand_by_product_request?.status === "processed" &&
              newJob?.stand_by_product_request?.status === "picked-up"
            ) {
              setTimeout(
                () =>
                  message.warning(
                    "This Defective Product has an Active Standby Request"
                  ),
                4000
              );
            }
          }

          updateTaskToStore(resp.data.task);
          resolve(resp.data.task);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          reject(err);
          console.log(err);
        });
    });
  };

  const getEngineers = (client, date, role) => {
   
    const [year, month, day] = date.split("-");
    const formatedDate = [month, day, year].join("-");


    return new Promise((resolve, reject) => {
  

      if (!role || role === "all" ) {
        axios
          .get(
            `/nearby-engineers?lat=${client.location.coordinates[1]}&lng=${client.location.coordinates[0]}&date=${formatedDate}`
          )
          .then((resp) => {
         
            setFieldEngineers(resp.data.users);
            setServiceWorkers(resp.data.users);
            setDeliveryJob(resp.data.users);

            resolve(resp.data.message);
          })
          .catch((err) => {
            setFieldEngineers([]);
            reject();
            message.error(err.response.data.message);
           
          });
      } else {
        axios
          .get(
            `/nearby-engineers?lat=${client.location.coordinates[1]}&lng=${client.location.coordinates[0]}&date=${formatedDate}&role=${role}`
          )
          .then((resp) => {
         

            if (role === "engineer") {
              setFieldEngineers(resp.data.users);
            } else if (role === "delivery") {
              setFieldEngineers(resp.data.users);
              setDeliveryJob(resp.data.users);
            } else if (role === "service") {
              setFieldEngineers(resp.data.users);
              setServiceWorkers(resp.data.users);
            }

         
            resolve(resp.data.message);
          })
          .catch((err) => {
            setFieldEngineers([]);
            reject();
            err.response.data?.message &&
              message.error(err.response.data.message);
           
          });
      }
    });
  };

  return {
    closeTaskApi,
    updateTask,
    addTask,
    getTasks,
    getHistoricalTasks,
    getAllHistoricalTasks,
    getEngineers,
    editTaskJobs,
    AddTaskJobs,
    cancelTaskApi,
    cancelJobApi,
  };
};

export default TaskApi;
