import {
  Button,

  Input,

  Table,

} from "antd";

import React, { useEffect, useState } from "react";
import useProductStore from "stores/productStore";
import {

  SearchOutlined,

} from "@ant-design/icons";


import usePaymentStore from "stores/paymentStore";
import moment from "moment";

const ReceiptsTable = ({
  receipts,

}) => {
  const payments = usePaymentStore((state) => state.payments);





  const columns = [
    {
      title: "Invoice No",
      dataIndex: "invoice_number",
      width: "6%",
      key: "6",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
          
        );
      },

      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.invoice_number.toString()?.includes(value.toString());
      },
    },




    {
      title: "Date",

      render: (_, record) => moment(record.date).format("DD-MM-YYYY"),
      key: "3",
    },

    {
      title: "Client",
      dataIndex: "client_name",
    
      key: "2",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>



            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>


          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.client_name?.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: "Amount",

      render: (record) => {
        return (
          <>
            {record.amount.toFixed(2)}

        
          </>
        );
      },
      key: "2",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.amount.toString()?.includes(value.toString());
      },
    },

    {
      title: "Adjusted Amount",
      dataIndex: "adjusted_amount",
      key: "1",
      width: "20%",
    },

  

  
    
  ];

  return (
    <>
      <Table
        key={payments?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={true}
        dataSource={receipts}
      />
    </>
  );
};

export default ReceiptsTable;
