import axios from "axios/axios";

import { message } from "antd";
import useDashboardStore from "stores/dashboardStore";
import authHeader from "utils/services/auth-header";



const DashboardApi = () => {





    const { setReports } = useDashboardStore((state) => ({

            setTaxesToStore: state.setTaxes,
        setReports: state.setReports

    }));



    const getReports = async () => {

        
        return new Promise((resolve, reject) => {
            axios
                .get("/report/snippets", { headers: authHeader() })
                .then((response) => {

                    setReports(response.data);
                    resolve(response.data);
                })
                .catch((err) => {
                    message.error(err.response.data.message)
                    console.log(err);

                    reject(err.response.data.message);
                });
        });
        // }

    }




    return { getReports};
};

export default DashboardApi;
