import DefectiveProductDetails from "components/defectiveProduct/Defective-Product-Details";
import DefectiveProductReturn from "components/defectiveProduct/Defective-Product-Return";
import StandbyProductReturnDetails from "components/stand-by-request/Stand-by-Product-Return-Details";
import StandbyReqDetails from "components/stand-by-request/Stand-by-req-Details";
import Barcode from "pages/barcode";
import BinProducts from "pages/binProducts";
import Category from "pages/category";
import Client from "pages/client";
import Client_locations from "pages/client/locations";
import DashBoard from "pages/dashboard";
import DefectiveProduct from "pages/defective-products";
import HistoricalDefectiveProduct from "pages/historical-defective-products";
import HistoricalStandByProductReq from "pages/historical-stand-by-request";
import HistoricalTask from "pages/historical-task";
import Import from "pages/import";
import ChangePassword from "pages/login/changePassword";
import Login from "pages/login/login";
import PasswordReset from "pages/login/passwordReset";
import Service from "pages/maintenance";
import Invoice from "pages/payment/invoice";
import InvoiceDetails from "pages/payment/invoiceDetails";
import Payments from "pages/payment/payments";
import Taxes from "pages/payment/tax";
import Plan from "pages/plan";
import Products from "pages/products";
import Receipts from "pages/receipts";
import Rent from "pages/rent";
import Sales from "pages/sales";
import StandByProductReq from "pages/stand-by-request";
import Task from "pages/task";
import Team from "pages/team";
import Template from "pages/template";
import Vendor from 'pages/vendor';
import WareHouse from "pages/warehouse/warehouse";
import WarehouseProducts from "pages/warehouse/warehouse-products";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthVerify from "utils/TokenVerify";
import "./App.less";
import Completelayout from "./layout/completeLayout";
import LoginLayout from "./layout/loginLayout";
import Client_Products from "./pages/client/products";
import getCurrentUser from "./utils/services/get-user";

const App = () => {
  const [currentUser, setCurrentUser] = useState(getCurrentUser());

  useEffect(() => {
    const user = getCurrentUser();

    if (user) {
      setCurrentUser(user);
    } else {
      setCurrentUser(false);
    }

  
  }, []);




  return (
    <>
      {currentUser ? (


<>
          <Routes>
            <Route element={<Completelayout />}>
              <Route
                exact
                path="/"
                element={currentUser ? <DashBoard /> : <Navigate to="/login" />}
              />
              <Route
                exact
                path="/task"
                element={currentUser ? <Task currentUser={ currentUser} /> : <Navigate to="/login" />}
              />
              <Route
                exact
                path="/historical-task"
                element={currentUser ? <HistoricalTask currentUser={ currentUser} /> : <Navigate to="/login" />}
              />
              <Route
                exact
                path="/team"
                element={currentUser ? <Team currentUser={currentUser}  /> : <Navigate to="/login" />}
              />
              <Route
                exact
                path="/plan"
                element={currentUser ? <Plan /> : <Navigate to="/login" />}
              />
              <Route
                exact
                path="/product/category"
                element={currentUser ? <Category currentUser={currentUser.user}      /> : <Navigate to="/login" />}
              />
              <Route
                exact
                path="/client"
                element={currentUser ? <Client currentUser={currentUser}  /> : <Navigate to="/login" />}
              />
              <Route
                exact
                path="/vendor"
                element={currentUser ? <Vendor   currentUser={currentUser}   /> : <Navigate to="/login" />}
              />
              <Route
                exact
                path="/client/locations/:clientId"
                element={
                  currentUser ? <Client_locations  currentUser={currentUser}  /> : <Navigate to="/login" />
                }
              />
              <Route
                exact
                path="/invoice/:invoiceId"
                element={
                  currentUser ? <InvoiceDetails /> : <Navigate to="/login" />
                }
              />


              <Route
                exact
                path="/client/products/:clientId"
                element={
                  currentUser ? <Client_Products  currentUser={currentUser}  /> : <Navigate to="/login" />
                }
              />

              <Route
                exact
                path="/products/products"
                element={
                  currentUser ? <Products currentUser={currentUser.user}  /> : <Navigate to="/login" />
                }
              />


              <Route
                exact
                path="/warehouse"
                element={
                  currentUser ? <WareHouse currentUser={currentUser}    /> : <Navigate to="/warehouse" />
                }
              />




              <Route
                exact
                path="/products/product-request"
                element={
                  currentUser ? < StandByProductReq  currentUser={currentUser}  /> : <Navigate to="/login" />
                }
              />
            <Route
                exact
                path="/products/historical-product-request"
                element={
                  currentUser ? < HistoricalStandByProductReq  currentUser={currentUser}  /> : <Navigate to="/login" />
                }
              />


              <Route
                exact
                path="/products/defective-products"
                element={
                  currentUser ? < DefectiveProduct    currentUser={currentUser} /> : <Navigate to="/login" />
                }
              />
              <Route
                exact
                path="/products/historical-defective-products"
                element={
                  currentUser ? < HistoricalDefectiveProduct    currentUser={currentUser} /> : <Navigate to="/login" />
                }
              />


              <Route
                exact
                path="/product/sales"
                element={
                  currentUser ? < Sales currentUser={currentUser} /> : <Navigate to="/login" />
                }
              />

              <Route
                exact
                path="/product/rent"
                element={
                  currentUser ? < Rent currentUser={currentUser} /> : <Navigate to="/login" />
                }
              />



              <Route
                exact
                path="/warehouse/:warehouseId"
                element={
                  currentUser ? <WarehouseProducts currentUser={currentUser} /> : <Navigate to="/login" />
                }
              />


              <Route
                exact
                path="/maintenance"
                element={
                  currentUser ? <Service currentUser={currentUser}  /> : <Navigate to="/login" />
                }
              />


              <Route
                exact
                path="/barcode"
                element={
                  currentUser ? <Barcode /> : <Navigate to="/login" />
                }
              />
                 <Route
                exact
                path="/bin-products"
                element={
                  currentUser ? <BinProducts /> : <Navigate to="/login" />
                }
              />

              <Route
                exact
                path="/import"
                element={
                  currentUser ? <Import /> : <Navigate to="/login" />
                }
              />



              <Route
                exact
                path="/payment/tax"
                element={
                  currentUser ? <Taxes  currentUser={currentUser} />: <Navigate to="/login" />
                }
              />
              <Route
                exact
                path="/payment/payments"
                element={
                  currentUser ? <Payments currentUser={currentUser} />: <Navigate to="/login" />
                }
              />

              <Route
                exact
                path="/payment/invoice"
                element={
                  currentUser ? <Invoice currentUser={currentUser}    /> : <Navigate to="/login" />
                }
              />

              <Route
                exact
                path="/payment/receipts"
                element={
                  currentUser ? <Receipts /> : <Navigate to="/login" />
                }
              />

        

              <Route
                exact
                path="/products/defective-products/:defectiveProductId"
                element={
                  currentUser ? <DefectiveProductDetails /> : <Navigate to="/login" />
                }
              />


              <Route
                exact
                path="/products/defective-return/:defectiveProductId"
                element={
                  currentUser ? <DefectiveProductReturn /> : <Navigate to="/login" />
                }
              />


              <Route
                exact
                path="/standbyRequest/:requestId"
                element={
                  currentUser ? <StandbyReqDetails /> : <Navigate to="/login" />
                }
              />



<Route
                exact
                path="/standbyRequest/return/:requestId"
                element={
                  currentUser ? <StandbyProductReturnDetails /> : <Navigate to="/login" />
                }
              />
           
                <Route exact path="/change-password" element={<ChangePassword />} />
  


              <Route
                exact
                path="/product/template"
                element={currentUser ? <Template currentUser={currentUser.user}    /> : <Navigate to="/login" />}
              />
             

            </Route>

           

            <Route element={<LoginLayout />}>
              <Route exact path="/login" element={<Login />} />
            </Route>
            
            


          </Routes>

          <AuthVerify/>
          
          </>
        
      ) : (
        <>
          <Routes>
            <Route
              exact
              path="/"
              element={currentUser ? <DashBoard /> : <Navigate to="/login" />}
            />
            <Route element={<LoginLayout />}>
              <Route exact path="/login" element={<Login />} />
                <Route
                  exact
                  path="/reset-password"
                  element={<PasswordReset />}
                />
            </Route>
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
