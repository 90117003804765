
import { RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Input, PageHeader, Skeleton, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import StandByApi from "utils/composable/standByApi";
import humanize from "utils/formate-status";

const StandByProductReq = ({ currentUser }) => {
  const [tableLoading, setTableLoadings] = useState(false);
  //const [filteredData, setFilteredData] = useState(false)

  const { getHistoricalStandByRequest} = StandByApi();
  

  const [request, setRequest] = useState([]);

  useEffect(() => {
    if (!request.length) {
      setTableLoadings(true);
      getHistoricalStandByRequest().then((resp) => {
        setRequest(resp); // Set the state with the fetched data
        setTableLoadings(false);
      });
    }
  }, [request.length]);



  const [refreshLoading, setRefreshLoading] = useState(false);

  const refresh = () => {
    setRefreshLoading(true);

    
    getHistoricalStandByRequest().finally(() => {
      setRefreshLoading(false);
    });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      // render: (req) => req?.name,
      key: "Date",
      render: (_, request) => {
        return moment(request?.createdAt).utc().format("DD-MM-YYYY");
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      render: (req) => req?.name,
      key:'client',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
          <AutoComplete
          style={{ margin: "1rem", width: "25rem" }}
          autoFocus
          value={selectedKeys[0]}
          options={[...new Set(request.map(reqst => reqst.client.name))].map(name => ({ value: name }))}

          onSearch={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          onSelect={(value) => {
            setSelectedKeys([value]);
            confirm();
          }}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          placeholder="Search"
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
           
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                //fuzzyFilter(selectedKeys[0]);
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

              //  setFilteredData(false)
                setSelectedKeys("");
                confirm()
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record?.client?.name.replace(/[^a-zA-Z]/g, "").toLowerCase().includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Product",
      width: "15%",

      render: (req) => {
        const productName = req?.product?.name || 'Product not assigned';
        const productBarcode = req?.product?.barcode ? `(${req.product.barcode})` : "";
        
        return `${productName} ${productBarcode}`;
      },

      key: "Product",


      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                 confirm();

               //fuzzyFilter(selectedKeys[0],true)
              }}
              onBlur={() => {
                confirm();
                //fuzzyFilter(selectedKeys[0],true);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
               // fuzzyFilter(selectedKeys[0],true);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

               // setFilteredData(false)
                setSelectedKeys("");
                confirm()
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name.replace(/[^a-zA-Z]/g, "").toLowerCase().includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },
   
    {
      title: "Task",
      width: "15%",
      dataIndex: "task",
      render: (req) => req?.task_number,
      key: "task",
    },
   

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, request) => {
        return humanize(request.status)
      },
      filters: [
        { text: "Closed", value: "closed" },
        { text: "Rejected", value: "rejected" },
       
          ],

      onFilter: (value, record) => {

     
        return record?.status?.includes(value)
      }
      ,
    },

  ];

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Historical Stand-By Product Request"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />
      <Skeleton active loading={tableLoading}>
      <Table
        rowKey={"_id"}
        columns={columns}
        pagination={true}
        dataSource={request}
      />
      </Skeleton>
      
    </>
  );
};

export default StandByProductReq;
