import { Button, Divider, Drawer, message, PageHeader, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, RedoOutlined } from "@ant-design/icons";

import useCategoryStore from "stores/categoryStore";
import ReceiptsTable from "components/receipts/ReceiptsTable";

import useWarehouseStore from "stores/warehouseStore";
import PaymentApi from "utils/composable/paymentApi";

import usePaymentStore from "stores/paymentStore";

import InvoiceApi from "utils/composable/invoiceApi";

const Receipts = () => {
  const [tableLoading, setTableLoadings] = useState(false);

  const [receipts, setReceipts] = useState([]);

  const { getReceipts } = PaymentApi();

  useEffect(() => {
    if (!receipts?.length) {
      setTableLoadings(true);

      getReceipts().then((receipts) => {
        setTableLoadings(false);
        setReceipts(receipts);
      });
    }
  }, []);

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Receipts"
      />

      <Skeleton active loading={tableLoading}>
        <ReceiptsTable receipts={receipts} />
      </Skeleton>
    </>
  );
};

export default Receipts;
