import { message } from "antd";
import axios from "axios/axios";
import useServiceDueProductStore from "stores/serviceDueStore";
import authHeader from "utils/services/auth-header";




const ServiceApi = () => {


    const {setServiceDueProduct} = useServiceDueProductStore ((state) => ({ setServiceDueProduct: state.setServiceDueProduct, }));



    const getServiceDueProducts = async () => {

        return new Promise((resolve, reject) => {
            axios
                .get("/clients/maintenance-service/due", { headers: authHeader() })
                .then((response) => {


                    const dueClientsWithDue = response.data.clients.map((client) => {

                        client.is_active = true
                        if (!client.additional_locations) client.branch = true
                        return client

                    })

                    setServiceDueProduct(dueClientsWithDue)
                    resolve();
                })
                .catch((err) => {

                    console.log(err);
                    message.error(err.response.data.message)
                    reject(err.response.data.message);
                });
        });
        // }


    }


    const getServiceDueProductsWithLocation = async (lat, lng, month, distance) => {

        return new Promise((resolve, reject) => {
            axios.get(`clients/maintenance-service/due/?lat=${lat}&lng=${lng}&month=${month}&distance=${distance}`, { headers: authHeader() })
                .then((response) => {


         

                    const dueClientsWithLocation = response.data.clients.map((client)=>{

                        client.is_active=true
                        if (!client.additional_locations) client.branch=true
                        return client

                    })

                    setServiceDueProduct(dueClientsWithLocation)
                    resolve();
                })
                .catch((err) => {

                    console.log(err);
                    message.error(err.response.data.message)
                    reject(err.response.data.message);
                });
        });
        // }


    }

  



    return { getServiceDueProducts, getServiceDueProductsWithLocation }
};

export default ServiceApi;
