import { Space, Form, Input, Select, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import useTaxStore from "stores/taxStore";
import TaxApi from "utils/composable/taxApi";

const Price = ({
  additionalPlanStartDate,
  additionalPlan,
  currentPlanAmount,
  state,
  setCurrentPlanAmount,
  setPriceData,
  priceEditData,
  setCurrentAnnualCost,
  clientPlanForm,
  setStateChanged,
}) => {
  const [currentAmount, setCurrentAmount] = useState(
    priceEditData
      ? Math.trunc(
          (priceEditData?.plan?.price?.item_price /
            parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6))) *
            12
        )
      : 0
  );
  const [currentCharge, setCurrentCharge] = useState(
    priceEditData
      ? (priceEditData?.plan?.price?.charges /
          parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6))) *
          12
      : 0
  );
  const [currentDiscountPercentage, setDiscountPercentage] = useState(
    priceEditData ? priceEditData?.plan?.price?.discount_percentage : 0
  );
  const [currentTaxes, setCurrentTaxes] = useState(
    priceEditData ? priceEditData?.plan?.price?.taxes : []
  );
  const { getTaxes } = TaxApi();
  const StoreTaxes = useTaxStore((state) => state.taxes);
  // const [currentFinalAmount, setCurrentFinalAmount] = useState("");
  // const [additionalPlanLiveAmount, setAdditionalPlanLiveAmount] = useState(0);

  const [taxLoading, setTaxloading] = useState(true);
  var AdditionalAmount = 0;

  function percentage(num, per) {
    return (num / 100) * per;
  }

  useEffect(() => {
    setTaxloading(true);

    getTaxes().finally(() => {
      setTaxloading(false);
    });
  }, []);

  useEffect(() => {
    if (currentAmount) {
      calculateCurrentPlanAmount();
    }

    if (priceEditData) {
      const totalTax = priceEditData?.plan?.price?.taxes
        ?.map((tax) => {
          return tax.percentage;
        })
        ?.reduce((partialSum, a) => partialSum + a, 0);

      console.log({ totalTax });

      const totalPrice = currentAmount;

      console.log({ totalPrice });
      console.log({ currentCharge });
      const discountAmount = percentage(
        totalPrice,
        priceEditData.plan?.price?.discount_percentage
      );

      AdditionalAmount =
        parseFloat(totalPrice) -
        parseFloat(discountAmount ? discountAmount : 0);

      const totalTaxAmount = percentage(currentAmount, totalTax);

      console.log({ totalTaxAmount });
      console.log({ discountAmount });

      AdditionalAmount =
        parseFloat(AdditionalAmount) +
        parseFloat(totalTaxAmount ? totalTaxAmount : 0);

      // setCurrentFinalAmount(AdditionalAmount);

      console.log({ totalTaxAmount });
      console.log({ discountAmount });
      console.log(AdditionalAmount, "Amount");

      function percentage(num, per) {
        return (num / 100) * per;
      }
    }

    var price = {
      amount: parseFloat(AdditionalAmount),
      item_price: parseFloat(currentAmount),
      qty: 1,
      tds: 0,
      discount_percentage: parseFloat(currentDiscountPercentage),
      charges: parseFloat(currentCharge || 0),
      taxes: currentTaxes,
    };

    console.log({ priceEditData });
    console.log({ price });

    setPriceData(price ? price : null);
  }, [
    currentAmount,
    currentCharge,
    additionalPlanStartDate,
    currentTaxes,
    currentDiscountPercentage,
  ]);

  useEffect(() => {
    if (additionalPlan && (currentAmount || currentCharge)) {
      const initialCharge = state?.clientDetails?.plan?.price?.charges;
      const initialItemPrice = state?.clientDetails?.plan?.price?.item_price;

      const totalTax = state.clientDetails.plan.price.taxes
        .map((tax) => {
          return tax.percentage;
        })
        .reduce((partialSum, a) => partialSum + a, 0);

      console.log({ totalTax });

      var totalPrice =
        (parseInt(currentAmount ? currentAmount : 0) / 12) *
          parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6)) +
        initialItemPrice;

      const discountAmount = percentage(
        totalPrice,
        state.clientDetails.plan.price.discount_percentage
      );

      const totalCharge =
        (currentCharge / 12) *
          parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6)) +
        parseInt(initialCharge);

      totalPrice =
        totalPrice +
        (totalCharge ? totalCharge : 0) -
        (discountAmount ? discountAmount : 0);

      console.log(totalPrice, "After Discount total price");
      const totalTaxAmount = percentage(totalPrice, totalTax);

      console.log({ totalPrice });

      state.clientDetails.plan.price.amount =
        totalPrice + (totalTaxAmount ? totalTaxAmount : 0);
      // state.clientDetails.plan.price.item_price = totalPrice
      state.clientDetails.plan.price.tempCharges = parseFloat(totalCharge);
      state.clientDetails.plan.price.tempItem_price =
        (parseInt(currentAmount ? currentAmount : 0) / 12) *
          parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6)) +
        initialItemPrice;
      state.clientDetails.plan.price.discount = discountAmount;
      state.clientDetails.plan.price.tax_amount = totalTaxAmount;
    }

    // setAdditionalPlanLiveAmount(state?.clientDetails?.plan?.price?.amount || 0);
  }, [currentAmount, currentCharge]);

  const calculateCurrentPlanAmount = () => {
    var totalTax = 0;

    if (additionalPlan) {
      totalTax = state?.clientDetails?.plan?.price?.taxes
        ?.map((tax) => {
          return tax.percentage;
        })
        ?.reduce((partialSum, a) => partialSum + a, 0);
    } else {
      totalTax =
        state?.clientDetails?.plan?.price?.taxes ||
        currentTaxes
          ?.map((tax) => {
            return tax.percentage;
          })
          ?.reduce((partialSum, a) => partialSum + a, 0);
    }

    console.log({ totalTax });

    const totalPrice = currentAmount;

    console.log({ totalPrice });
    console.log({ currentCharge });

    const discountAmount = percentage(
      totalPrice,
      state?.clientDetails?.plan?.price?.discount_percentage ||
        currentDiscountPercentage
    );

    console.log({ discountAmount }, "Discount Amount..");

    AdditionalAmount = parseFloat(totalPrice) - parseFloat(discountAmount);

    console.log(AdditionalAmount, "Amount");

    const totalTaxAmount = percentage(AdditionalAmount, totalTax);

    console.log(currentCharge, "currentCharge");

    AdditionalAmount =
      parseFloat(AdditionalAmount) +
      parseFloat(totalTaxAmount ? totalTaxAmount : 0);

    // setCurrentFinalAmount(AdditionalAmount);

    console.log({ totalTaxAmount });
    console.log({ discountAmount });
    console.log({ currentCharge });
    // console.log({ chargetax });
    console.log(AdditionalAmount, "Amount");

    //Additonal Plan calculation
    if (additionalPlan) {
      console.log(AdditionalAmount);
      console.log(state.clientDetails.plan.start_date, "main plan start date");
      console.log(state.clientDetails.plan.next_due_date, "Due date");
      let start_Date = moment(additionalPlanStartDate);
      let MainPlanStartDate = moment(state.clientDetails.plan.start_date);
      console.log({ MainPlanStartDate });
      let end_Date = moment(state.clientDetails.plan.next_due_date);

      console.log({ end_Date });
      let totalDays = end_Date.diff(start_Date, "days");

      let mainPlanTotalDays = end_Date.diff(
        state.clientDetails.plan.start_date,
        "days"
      );

      console.log({ mainPlanTotalDays });
      console.log({ totalDays });

      // const amountPerDay = parseFloat(AdditionalAmount) / parseInt(mainPlanTotalDays)
      const amountPerDay = parseFloat(AdditionalAmount / 360);

      console.log({ amountPerDay });
      console.log(Math.round(amountPerDay), "trunked");
      const currentPlanAmount = amountPerDay * totalDays;

      setCurrentPlanAmount(Math.trunc(parseFloat(currentPlanAmount)));
    }
  };

  const taxList = StoreTaxes.map((tax) => {
    return {
      label: `${tax.title}(${tax.percentage}%)`,
      value: tax._id,
      key: tax._id,
    };
  });

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  const TaxValues = (taxes) => {
    console.log({ taxes });

    const selectedTaxes = StoreTaxes.filter(
      (tax) => taxes.includes(tax._id) || taxes.includes(tax.title)
    ).map((tax) => {
      return {
        title: tax.title,
        percentage: parseFloat(tax.percentage),
      };
    });

    console.log(selectedTaxes);

    setCurrentTaxes(selectedTaxes);
  };

  if (state?.ASC_AMC) {
    return (
      <div>
        <Space>
          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            name="item_price"
            label={state ? "Annual Cost" : "Item Price"}
            style={{ width: "21rem" }}
          >
            <Input
              style={{ width: "21rem" }}
              // disabled={!additionalPlanStartDate && additionalPlan}
              onChange={(e, v) => {
                // calculateCurrentPlanAmount(e, v);
                setCurrentAmount(e.target.value);
                setStateChanged(true);
                setCurrentAnnualCost && setCurrentAnnualCost(e.target.value);
              }}
              type={"number"}
            />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            name="qty"
            label={"Quantity"}
            hidden
            initialValue={1}

            // rules={[{ required: true }]}
          >
            <Input
              value={1}
              disabled={!additionalPlanStartDate && additionalPlan}
              type={"number"}
            />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            style={{ width: "21rem" }}
            name="charges"
            hidden={state.ASC_AMC}
            label={clientPlanForm ? "Charges (Annually)" : "Charges"}

            // rules={[{ required: true }]}
          >
            <Input
              value={0}
              style={{ width: "21rem" }}
              disabled={!additionalPlanStartDate && additionalPlan}
              onChange={(e, v) => {
                // calculateCurrentPlanAmount(e, v);
                setCurrentCharge(e.target.value);
                setStateChanged(true);
              }}
              type={"number"}
            />
          </Form.Item>
          <Form.Item
            hidden={additionalPlan}
            style={{ width: "21rem" }}
            labelCol={{ span: 24, offset: 0 }}
            name="taxes"
            label="Tax"
            // rules={[{ required: true }]}
          >
            <Select
              loading={taxLoading}
              onChange={TaxValues}
              style={{ width: "21rem" }}
              mode="multiple"
              showArrow
              tagRender={tagRender}
              options={taxList}
            ></Select>
          </Form.Item>
        </Space>

        <Space>
          {!additionalPlan && (
            <>
              <Form.Item
                hidden={state.ASC_AMC}
                style={{ width: "21rem" }}
                labelCol={{ span: 24, offset: 0 }}
                name="discount_percentage"
                label={"Discount Percentage"}

                // rules={[{ required: true }]}
              >
                <Input
                  value={0}
                  style={{ width: "21rem" }}
                  disabled={!additionalPlanStartDate && additionalPlan}
                  onChange={(e, v) => {
                    setDiscountPercentage(e.target.value);
                  }}
                  type={"number"}
                />
              </Form.Item>
            </>
          )}
        </Space>
        {additionalPlan && currentPlanAmount ? (
          <div
            style={{ fontWeight: "400", color: "#ff9933", marginTop: "-.5rem" }}
          >{`Amount Due for Current Period ${
            currentPlanAmount ? currentPlanAmount.toFixed(2) : ""
          }`}</div>
        ) : (
          ""
        )}
      </div>
    );
  } else {
    return (
      <div>
        <Space>
          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            name="item_price"
            label={state ? "Annual Cost" : "Item Price"}
            style={{ width: "21rem" }}
          >
            <Input
              style={{ width: "21rem" }}
              disabled={!additionalPlanStartDate && additionalPlan}
              onChange={(e, v) => {
                // calculateCurrentPlanAmount(e, v);
                setCurrentAmount(e.target.value);
                setStateChanged(true);
                setCurrentAnnualCost && setCurrentAnnualCost(e.target.value);
              }}
              type={"number"}
            />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            name="qty"
            label={"Quantity"}
            hidden
            initialValue={1}

            // rules={[{ required: true }]}
          >
            <Input
              value={1}
              disabled={!additionalPlanStartDate && additionalPlan}
              type={"number"}
            />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            style={{ width: "21rem" }}
            name="charges"
            label={clientPlanForm ? "Charges (Annually)" : "Charges"}

            // rules={[{ required: true }]}
          >
            <Input
              style={{ width: "21rem" }}
              disabled={!additionalPlanStartDate && additionalPlan}
              onChange={(e, v) => {
                // calculateCurrentPlanAmount(e, v);
                setCurrentCharge(e.target.value);
                setStateChanged(true);
              }}
              type={"number"}
            />
          </Form.Item>
        </Space>

        <Space>
          {!additionalPlan && (
            <>
              <Form.Item
                style={{ width: "21rem" }}
                labelCol={{ span: 24, offset: 0 }}
                name="discount_percentage"
                label={"Discount Percentage"}

                // rules={[{ required: true }]}
              >
                <Input
                max={100}
                  style={{ width: "21rem" }}
                  disabled={!additionalPlanStartDate && additionalPlan}
                  onChange={(e, v) => {
                    setDiscountPercentage(e.target.value);
                  }}
                  type={"number"}
                />
              </Form.Item>

              <Form.Item
                style={{ width: "21rem" }}
                labelCol={{ span: 24, offset: 0 }}
                name="taxes"
                label="Tax"
                // rules={[{ required: true }]}
              >
                <Select
                  loading={taxLoading}
                  onChange={TaxValues}
                  style={{ width: "21rem" }}
                  mode="multiple"
                  showArrow
                  tagRender={tagRender}
                  options={taxList}
                ></Select>
              </Form.Item>
            </>
          )}
        </Space>
      </div>
    );
  }
};

export default Price;
