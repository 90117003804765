import React from 'react'
import { Button, Layout } from "antd";

const Footer = () => {
 const { Footer } = Layout;
  return (
      <Footer
          style={{
              textAlign: "center",
          }}
      >



      </Footer>
  )
}

export default Footer