import { logRoles } from "@testing-library/react";
import { Radio, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";

const App = ({ taskLogs }) => {


  

  return (
    <>
      <Timeline reverse={true} mode={"left"}>
        {taskLogs.map((log) => {
         

          var color;
          var locaTime = new Date(log.time);
          var time = locaTime.toString();

          if (log.type == "assign" || log.type == "default") {
            color = "#007FFF";
          } else if (log.type == "complete") {
            color = "green";
          } else {
            color = "#5D76A9";
          }

          function formatTimeShow(h_24) {
            var h = h_24 % 12;
            if (h === 0) h = 12;
            return h + `: ${time.substr(19, 2)}` + (h_24 < 12 ? " am" : " pm");
          }

          var exactTime = formatTimeShow(parseFloat(time.substr(15, 10)));
          var text;
          var suffix = "";

          if (log.type == "assign") {
            text = "by  " + log?.by?.name + " to " + log?.to?.name;
          } else if (log.type == "default" || log.type == "complete") {
            text = "by  " + log?.by?.name;
          } else if (log.type == "check-in" || log.type == "check-out") {
            suffix = log.by.name;
          }


          return (
            <Timeline.Item
              style={{ fontSize: "15px" }}
              dot={log.type == "complete" ? <CheckOutlined /> : ""}
              color={color}
            >
              {suffix} {log?.text} {text}{" "}
              <span style={{ fontWeight: "200" }}> on</span>{" "}
              <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                {" "}
                {time.substr(3, 12)}, {exactTime}{" "}
              </span>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </>
  );
};

export default App;
