import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Statistic,
  Switch,
  Tag,
  Tooltip,
} from "antd";

import React, { useEffect, useState } from "react";
import TemplateApi from "utils/composable/templateApi";
import useTemplateStore from "stores/templateStore";
import useProductStore from "stores/productStore";
import useWarehouseStore from "stores/warehouseStore";
import ClientLocationApi from "utils/composable/clientLocationApi";
import moment from "moment";
import useTaxStore from "stores/taxStore";
import TaxApi from "utils/composable/taxApi";
import Price from "components/Price/price";
import TextArea from "antd/lib/input/TextArea";
import humanize from "utils/formate-status";

const ClientPlanForm = ({
  submitHandler,
  submitLoader,
  editData,
  setVisible,
  confirmEditHandler,
  bulkUpdates,
  handleBulkUpdate,
  selectedproducts,
  handleNewPlanSubmit,
  planEditData,
  additionalPlan,
  state,
  currentUser,
}) => {
 

  const [amountForCurrentCycle, setAmountForCurrentCycle] = useState(0);
  const [currentPlanAmount, setCurrentPlanAmount] = useState("");
  const [form] = Form.useForm();



  const [additionalPlanStartDate, setAdditonalPlanStartDate] = useState("");
  const [currentAnnualCost, setCurrentAnnualCost] = useState(0);
  const [monthNumber, setMonthNumber] = useState();
  const [currentCycle, setCurrentCycle] = useState("");
  const [priceData, setPriceData] = useState({});
  const [clientPlanForm, setClientPlanForm] = useState(true);
  const [initialValues, setinItalValues] = useState(false);
  const [stateChanged, setStateChanged] = useState(false);

  const StoreTaxes = useTaxStore((state) => state.taxes);




  useEffect(() => {
    const formatDate = (date) => {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    };

    console.log(planEditData, "plan edit Data");
    if (planEditData) {
    

      form.setFieldsValue({
        item_price: Math.round(
          (planEditData?.plan?.price?.item_price /
            parseInt(state?.clientDetails?.plan?.payment_cycle?.slice(4, 6))) *
            12
        ),
        qty: planEditData?.plan?.price?.qty || "",
        description: planEditData?.plan?.description || "",
        discount_percentage:
          planEditData?.plan?.price?.discount_percentage || 0,
        charges: Math.round(planEditData?.plan?.price?.charges || 0),
        no_of_products: planEditData.plan?.no_of_products || 1,
        taxes: planEditData?.plan?.price?.taxes.map((tax) => tax.title) || "",
        start_date:formatDate(planEditData?.plan?.start_date) || moment(new Date()).format("YYYY-MM-DD"),
        name: planEditData?.plan?.name || "",
        payment_cycle: planEditData?.plan.payment_cycle || "",
      });
    } else {
      form.setFieldsValue({
        start_date: moment(new Date()).format("YYYY-MM-DD"),
      });
    }

   
  }, [planEditData]);

  const onFinish = (values) => {
    if (!additionalPlan) {
      const submitPayload = {
        plan: {
          price: { ...priceData },
          no_of_products: parseFloat(values?.no_of_products || 1),
          start_date: moment(values.start_date).format("MM-DD-YYYY"),
          name: values.name,
          payment_cycle: values.payment_cycle,
          description: values.description,
        },
      };

      handleNewPlanSubmit(submitPayload, form);
    } else {
      const submitPayload = {
        plan: {
          price: {
            item_price: parseFloat(values.item_price),
            qty: 1,

            charges: parseFloat(values?.charges || 0),
          },
          no_of_products: parseFloat(values?.no_of_products || 1),

          start_date: moment(values.start_date).format("DD-MM-YYYY"),

          description: values.description,
        },
      };

      handleNewPlanSubmit(submitPayload, form);
    }
  };

  const onCycleChange = (e) => {
    setCurrentCycle(humanize(e ? e : ""));
    const number = e?.slice(4, 6);

    setMonthNumber(parseInt(number));
  };

  useEffect(() => {
    let initialValue = JSON.parse(JSON.stringify(state.clientDetails));

    setinItalValues(initialValue);
  }, []);

  useEffect(() => {
    if (priceData) {
      const totalTax = priceData.taxes
        ?.map((tax) => {
          return tax.percentage;
        })
        .reduce((partialSum, a) => partialSum + a, 0);

      const totalPrice = parseFloat(priceData.item_price) * 1;

      const discountAmount = percentage(
        totalPrice,
        priceData.discount_percentage || 0
      );

      var Amount =
        totalPrice -
          (discountAmount ? discountAmount : 0) +
          priceData.charges || 0;

      const totalTaxAmount = percentage(Amount, totalTax);

      console.log({ Amount });

      console.log({ discountAmount });

      Amount = Amount + totalTaxAmount;

      console.log({ Amount });

      Amount = (Amount / 12) * monthNumber;

      setAmountForCurrentCycle(Math.trunc(Amount));

      function percentage(num, per) {
        return (num / 100) * per;
      }
    }

    // setAmountForCurrentCycle(Math.trunc(amount));

    console.log({ currentAnnualCost });
  }, [currentAnnualCost, monthNumber, priceData]);

  useEffect(() => {
    setCurrentAnnualCost(planEditData?.plan?.price?.item_price);
    onCycleChange(planEditData?.plan?.payment_cycle);
  }, [planEditData]);

  const tagRender = (props) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Form
      form={form}
      key={editData?._id}
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
    >
      {additionalPlan && (
        <Divider style={{ marginTop: "-1rem" }}>Main Plan Details</Divider>
      )}

      {additionalPlan && (
        <>
          <div
            style={{
              margin: "0 auto",
              display: "flex",
            }}
            className="site-statistic-demo-card"
          >
            <Col span={8}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Statistic
                  title="Due Date"
                  value={moment(
                    state?.clientDetails?.plan?.next_due_date
                  ).format("DD-MM-YYYY")}
                  valueStyle={{ color: "#black", fontSize: "15px" }}
                />
              </Card>
            </Col>

            <Col span={8}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <>
                  <Statistic
                    title="Total Item Price"
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      border: "none",
                      textAlign: "center",
                    }}
                    value={`${Math.trunc(
                      initialValues?.plan?.price?.tempItem_price ||
                        initialValues?.plan?.price?.item_price
                    )}   `}
                    valueStyle={{ color: "green", fontSize: "15px" }}
                  />

                  <Statistic
                    value={
                      Math.round(
                        parseInt(initialValues?.plan?.price?.tempItem_price)
                      ) !==
                      Math.round(
                        parseInt(state.clientDetails.plan.price?.tempItem_price)
                      )
                        ? `${Math.trunc(
                            state.clientDetails.plan.price?.tempItem_price || 0
                          )}`
                        : 0
                    }
                    valueStyle={{ color: "orange", fontSize: "15px" }}
                  />
                </>
              </Card>
            </Col>

            <Col span={8}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <>
                  <Statistic
                    title={`Tax (${state?.clientDetails?.plan?.price?.taxes
                      ?.map((tax) => {
                        return tax.percentage;
                      })
                      ?.reduce((partialSum, a) => partialSum + a, 0)}%)`}
                    value={Math.trunc(initialValues?.plan?.price?.tax_amount)}
                    valueStyle={{ color: "green", fontSize: "15px" }}
                  />

                  <Statistic
                    value={
                      Math.round(initialValues?.plan?.price?.tax_amount) !==
                      Math.round(state?.clientDetails?.plan?.price?.tax_amount)
                        ? `${Math.trunc(
                            state?.clientDetails?.plan?.price?.tax_amount || 0
                          )}`
                        : 0
                    }
                    valueStyle={{ color: "orange", fontSize: "15px" }}
                  />
                </>
              </Card>
            </Col>
          </div>
          <div
            style={{
              margin: "0 auto",
              display: "flex",
            }}
            className="site-statistic-demo-card"
          >
            <Col span={8}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "-1rem",
                }}
              >
                <Statistic
                  title={"Balance Days"}
                  style={{
                    alignContent: "center",
                    justifyContent: "center",
                    border: "none",
                    textAlign: "center",
                  }}
                  value={moment(state?.clientDetails?.plan?.next_due_date).diff(
                    new Date(),
                    "days"
                  )}
                  valueStyle={{ color: "#black", fontSize: "15px" }}
                />
              </Card>
            </Col>

            <Col span={8}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "-1rem",
                }}
              >
                <>
                  <Statistic
                    title={"Annual Cost"}
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      border: "none",
                      textAlign: "center",
                    }}
                    value={Math.trunc(
                      (initialValues?.plan?.price?.amount /
                        parseInt(
                          state?.clientDetails?.plan?.payment_cycle?.slice(4, 6)
                        )) *
                        12
                    )}
                    valueStyle={{ color: "green", fontSize: "15px" }}
                  />
                  <Statistic
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      border: "none",
                      textAlign: "center",
                    }}
                    value={
                      Math.round(
                        parseInt(initialValues?.plan?.price?.amount)
                      ) !==
                      Math.round(
                        parseInt(state?.clientDetails?.plan?.price?.amount)
                      )
                        ? Math.trunc(
                            (state?.clientDetails?.plan?.price?.amount /
                              parseInt(
                                state?.clientDetails?.plan?.payment_cycle?.slice(
                                  4,
                                  6
                                )
                              )) *
                              12
                          )
                        : 0
                    }
                    valueStyle={{ color: "orange", fontSize: "15px" }}
                  />
                </>
              </Card>
            </Col>

            <Col span={8}>
              <Card
                style={{
                  border: "none",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "-1rem",
                }}
              >
                <>
                  <Statistic
                    title={
                      "Payment " +
                      humanize(state?.clientDetails?.plan?.payment_cycle)
                    }
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      border: "none",
                      textAlign: "center",
                    }}
                    value={Math.trunc(initialValues?.plan?.price?.amount)}
                    valueStyle={{ color: "green", fontSize: "15px" }}
                  />{" "}
                  <Statistic
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      border: "none",
                      textAlign: "center",
                    }}
                    value={
                      Math.round(
                        parseInt(initialValues?.plan?.price?.amount)
                      ) !==
                      Math.round(
                        parseInt(state.clientDetails.plan.price?.amount)
                      )
                        ? Math.trunc(state.clientDetails.plan.price?.amount)
                        : 0
                    }
                    valueStyle={{ color: "orange", fontSize: "15px" }}
                  />
                </>
              </Card>
            </Col>
          </div>
        </>
      )}

      {additionalPlan && (
        <Divider style={{ marginTop: "1rem" }}>Enter New Plan Details</Divider>
      )}

      <Space>
        {!additionalPlan && (
          <>
            <Form.Item
              style={{ width: "15rem" }}
              labelCol={{ span: 24, offset: 0 }}
              name="name"
              label="Name"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: "15rem" }}
                mode="multiple"
                showArrow
                tagRender={tagRender}
              >
                <Select.Option value="ASC"> ASC</Select.Option>
                <Select.Option value="AMC">AMC</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              name="start_date"
              label="Start Date"
              rules={[{ required: true }]}
              style={{ width: "15rem" }}
            >
              <Input
                style={{ width: "15rem" }}
                disabled={planEditData}
                onChange={(e) => {
                  setAdditonalPlanStartDate(e.target.value);
                  // calculateCurrentPlanAmount(currentAmount);
                }}
                type={"date"}
              />
            </Form.Item>

            <Form.Item
              style={{ width: "12rem" }}
              labelCol={{ span: 24, offset: 0 }}
              initialValue={1}
              name="no_of_products"
              label="No of Products"
            >
              <Input
                defaultValue={1}
                style={{ width: "12rem" }}
                type={"number"}
              />
            </Form.Item>
          </>
        )}
      </Space>

      {additionalPlan && (
        <Space>
          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            name="start_date"
            label="Start date"
            rules={[{ required: true }]}
            style={{ width: "21rem" }}
          >
            <Input
              style={{ width: "21rem" }}
              disabled={planEditData}
              onChange={(e) => {
                setAdditonalPlanStartDate(e.target.value);
                // calculateCurrentPlanAmount(currentAmount);
              }}
              type={"date"}
            />
          </Form.Item>

          <Form.Item
            style={{ width: "21rem" }}
            labelCol={{ span: 24, offset: 0 }}
            name="no_of_products"
            label="No of Products"
            initialValue={1}
          >
            <Input style={{ width: "21rem" }} type={"number"} />
          </Form.Item>
        </Space>
      )}

      <Space></Space>

      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        label="Description"
        name="description"
        initialValue={""}
      >
        <TextArea />
      </Form.Item>
      <Price
        setStateChanged={setStateChanged}
        monthNumber={monthNumber}
        clientPlanForm={clientPlanForm}
        setCurrentAnnualCost={setCurrentAnnualCost}
        currentAnnualCost={currentAnnualCost}
        state={state}
        additionalPlanStartDate={additionalPlanStartDate}
        additionalPlan={additionalPlan}
        currentPlanAmount={currentPlanAmount}
        setCurrentPlanAmount={setCurrentPlanAmount}
        setPriceData={setPriceData}
        priceEditData={planEditData}
      ></Price>

      {!additionalPlan && (
        <Space>
          <Form.Item
            style={{ width: "22rem" }}
            hidden={bulkUpdates}
            labelCol={{ span: 24, offset: 0 }}
            name="payment_cycle"
            label={
              !planEditData
                ? "Payment Cycle"
                : "Payment Cycle ( Changes will effect from next Payment )"
            }
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: "21rem" }}
              placeholder="Select Payment Cycle"
              allowClear
              // disabled={planEditData}
              onChange={(e) => {
                onCycleChange(e);
              }}
            >
              <Select.Option value="per_1_month">Per 1 month</Select.Option>
              <Select.Option value="per_2_month">Per 2 month</Select.Option>
              <Select.Option value="per_3_month">Per 3 month</Select.Option>
              <Select.Option value="per_4_month">Per 4 month</Select.Option>
              <Select.Option value="per_5_month">Per 5 month</Select.Option>
              <Select.Option value="per_6_month">Per 6 month</Select.Option>
              <Select.Option value="per_7_month">Per 7 month</Select.Option>
              <Select.Option value="per_8_month">Per 8 month</Select.Option>
              <Select.Option value="per_9_month">Per 9 month</Select.Option>
              <Select.Option value="per_10_month">Per 10 month</Select.Option>
              <Select.Option value="per_11_month">Per 11 month</Select.Option>
              <Select.Option value="per_12_month">Per 12 month</Select.Option>
            </Select>
          </Form.Item>
          <span
            style={{
              fontSize: "1rem",
              fontWeight: "400",
              padding: "35px",
              letterSpacing: "1px",
            }}
          >
            Amount {currentCycle ? currentCycle : ""} :
            <span style={{ fontSize: "1rem", fontWeight: "600" }}>
              {" "}
              {amountForCurrentCycle ? amountForCurrentCycle?.toFixed(2) : ""}
            </span>
          </span>
        </Space>
      )}

      <Form.Item>
        <Space direction="vertical" size={"large"}>
          <Space size={8}>
            <Button
              style={{ marginTop: "3rem" }}
              key={editData._id}
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            {planEditData ? (
              <Button
                style={{ marginTop: "3rem" }}
                type="primary"
                htmlType="submit"
                loading={submitLoader}
              >
                Confirm
              </Button>
            ) : !bulkUpdates ? (
              <Button
                style={{ marginTop: "3rem" }}
                type="primary"
                htmlType="submit"
                loading={submitLoader}
              >
                Submit
              </Button>
            ) : (
              <Button
                disabled={!selectedproducts.length}
                type="primary"
                htmlType="submit"
                loading={submitLoader}
              >
                Update All
              </Button>
            )}
          </Space>

          {additionalPlan && (
            <>
              {" "}
              <span style={{ color: "green" }}>* Current Plan Amount</span>
              <span style={{ color: "orange" }}>* Updated Plan Amount</span>
            </>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ClientPlanForm;
