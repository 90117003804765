import React from "react";

const Svg = () => {
  return (
    <svg

    style={{paddingLeft:'3rem'}}
      data-name="Layer 1"
      width="270.67538"
      height="160.57748"
      viewBox="0 0 809.67538 462.57748"
    

    >
      <path
        d="M328.25,429.03874v-130a7,7,0,1,1,14,0v130a7,7,0,0,1-14,0Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#f0f0f0"
      />
      <path
        d="M311.5,429.03874v-130a7,7,0,1,1,14,0v130a7,7,0,0,1-14,0Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#f0f0f0"
      />
      <path
        d="M294.75,429.03874v-130a7,7,0,1,1,14,0v130a7,7,0,0,1-14,0Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#f0f0f0"
      />
      <path
        d="M927.1543,636.6748a24.4405,24.4405,0,0,1-8.27051-1.43554l-.40918-.14746.18848-.90528c-4.752-9.60058-3.09082-22.75293,4.25293-33.56738,6.67675-9.83105,16.8291-16.97851,25.78613-23.28418a6.96667,6.96667,0,0,1,10.84765,7.0293l-3.20312,16.43359c-2.31152,11.85547-5.06152,23.07617-13.2168,30.07324A24.904,24.904,0,0,1,927.1543,636.6748Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#e6e6e6"
      />
      <path
        d="M948.13086,665.07227a18.621,18.621,0,0,1-15.56348-8.56543l-.23242-.36133.55371-.48828c1.43262-7.81836,8.17285-14.78418,17.22266-17.78028,8.22558-2.72265,17.30566-2.27734,25.31152-1.88574h0a5.34914,5.34914,0,0,1,3.32324,9.31445l-9.00781,8.13086c-6.5459,5.90723-13.07422,11.24121-20.94922,11.62305Q948.45948,665.07275,948.13086,665.07227Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#e6e6e6"
      />
      <path
        d="M920.17571,673.76422c-1.33022-9.90531-2.698-19.93784-1.765-29.94935.82868-8.89127,3.48213-17.57556,8.88423-24.69608a40.51552,40.51552,0,0,1,10.3105-9.55122c1.03105-.6653,1.98,1.00511.95345,1.6675a38.87072,38.87072,0,0,0-15.1119,18.64c-3.29008,8.55479-3.81842,17.88022-3.25145,26.96787.34287,5.49564,1.06951,10.95525,1.80176,16.40786a1.00364,1.00364,0,0,1-.65971,1.18778.948.948,0,0,1-1.1619-.67441Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#f2f2f2"
      />
      <path
        d="M916.41642,679.45831c6.40255-11.58138,13.829-24.45276,27.09868-28.56665a29.61321,29.61321,0,0,1,11.39812-1.20314c1.21015.10668.908,2.01365-.3,1.90718a27.63308,27.63308,0,0,0-18.18964,4.91957c-5.12878,3.56882-9.12211,8.53043-12.502,13.792-2.0703,3.22288-3.92475,6.58244-5.77955,9.93753-.59275,1.07222-2.32531.29825-1.72564-.78651Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#f2f2f2"
      />
      <path
        d="M913.53516,679.28875,920,679.43664,881.02,471.19879l-6.46484,1.21814,10.17871,54.01971H851.94043l10.17871-54.01971-6.46435-1.21814L817,679.43664l6.13916-.14789,20.32227-107.85211h49.75146ZM844.96875,563.43664l5.46436-29h35.8081l5.46436,29Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#cacaca"
      />
      <path
        d="M631.24992,432.35564V363.97023c0-2.03045,3.14014-3.6823,7-3.6823s7,1.65185,7,3.6823v68.38541c0,2.03045-3.14013,3.6823-7,3.6823S631.24992,434.38609,631.24992,432.35564Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#f0f0f0"
      />
      <path
        d="M614.49992,432.35564V363.97023c0-2.03045,3.14014-3.6823,7-3.6823s7,1.65185,7,3.6823v68.38541c0,2.03045-3.14013,3.6823-7,3.6823S614.49992,434.38609,614.49992,432.35564Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#f0f0f0"
      />
      <path
        d="M597.74992,432.35564V363.97023c0-2.03045,3.14014-3.6823,7-3.6823s7,1.65185,7,3.6823v68.38541c0,2.03045-3.14013,3.6823-7,3.6823S597.74992,434.38609,597.74992,432.35564Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#f0f0f0"
      />
      <path
        d="M738.66406,382.06511a1.49926,1.49926,0,0,0-.65625-1.02832L685.23486,346.198a3.95447,3.95447,0,0,0,1.894-1.65924h.00048a4.01219,4.01219,0,0,0-1.46435-5.46411l-4.33008-2.5a4.01141,4.01141,0,0,0-5.46387,1.46411,3.939,3.939,0,0,0-.50683,1.64282l-38.09375-25.14837A23.059,23.059,0,0,0,623,301.37706V290.28874H609v11.08838a23.00744,23.00744,0,0,0-16,21.91162v3h14a9,9,0,0,0,18,0h14v-3a23.12345,23.12345,0,0,0-.40479-4.28589l96.36915,63.61988L694.93311,431.293c-.311-.0014-.62012-.00421-.93311-.00421-3.19385,0-6.28857.0755-9.2373.20551a3.957,3.957,0,0,0,.22851-1.54376,4.01168,4.01168,0,0,0-4.20264-3.78644l-4.99316.26019a4.01193,4.01193,0,0,0-3.78662,4.20276,3.93234,3.93234,0,0,0,.542,1.77191C663.72168,433.33727,658,434.63072,658,435.28874c0,1.07831,15.36328.05176,34.59229.00238a1.49482,1.49482,0,0,0,2.56591.45026l.37061-.45007c19.17236.0534,34.47119,1.07348,34.47119-.00257,0-1.01458-13.602-3.53839-31.22607-3.94311l39.56591-48.10425A1.50081,1.50081,0,0,0,738.66406,382.06511Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#ccc"
      />
      <path
        d="M740.10747,679.789H616.89165a9.40286,9.40286,0,0,1-9.3916-9.39258V468.18056a8.40146,8.40146,0,0,0-8.39209-8.3916H310.89214a8.40146,8.40146,0,0,0-8.39209,8.3916V670.39638A9.40328,9.40328,0,0,1,293.108,679.789h-4.21582a9.40328,9.40328,0,0,1-9.39209-9.39258V457.289a22.52523,22.52523,0,0,1,22.5-22.5h425a22.52554,22.52554,0,0,1,22.5,22.5V670.39638A9.4037,9.4037,0,0,1,740.10747,679.789Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#e4e4e4"
      />
      <rect x="412.83769" y="268.57748" width="141" height="6" fill="#fff" />
      <rect x="412.83769" y="349.57748" width="141" height="6" fill="#fff" />
      <path
        d="M491.28362,433.80135l-4.05245-1.70635-2.78-20.315H447.431l-3.01327,20.232-3.62556,1.81276a.861.861,0,0,0,.38507,1.631h49.77242A.86091.86091,0,0,0,491.28362,433.80135Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#e6e6e6"
      />
      <path
        d="M557.07111,414.48284H375.21746a3.60288,3.60288,0,0,1-3.59478-3.60285V385.23624H560.66589V410.88A3.60291,3.60291,0,0,1,557.07111,414.48284Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#ccc"
      />
      <path
        d="M560.856,397.35609H371.45669V287.6319A4.34819,4.34819,0,0,1,375.8,283.28874H556.51262a4.34823,4.34823,0,0,1,4.34337,4.34316Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#3f3d56"
      />
      <path
        d="M549.54392,389.3923H382.76876a3.352,3.352,0,0,1-3.34838-3.34828V294.60081a3.35222,3.35222,0,0,1,3.34838-3.34848H549.54392a3.35221,3.35221,0,0,1,3.34837,3.34848V386.044A3.352,3.352,0,0,1,549.54392,389.3923Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#fff"
      />
      <path
        d="M736.50156,301.7499a11.02534,11.02534,0,0,1,12.59153,11.28127l36.5651,14.07449-16.5663,11.83611-32.183-15.18a11.08508,11.08508,0,0,1-.40728-22.01189Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#9e616a"
      />
      <path
        d="M875.33929,294.84079l6.18593,11.24675a32.29577,32.29577,0,0,1-10.96259,18.21081c-9.47092,7.98759-58.99165,37.73652-58.99165,37.73652l-62.51748-31.3853,10.93659-14.53307,49.0822,16.449,0,0a160.76689,160.76689,0,0,1,64.16431-37.09366Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#e4e4e4"
      />
      <ellipse
        cx="668.67452"
        cy="246.33609"
        rx="48.7906"
        ry="15.89806"
        fill="#cacaca"
      />
      <polygon
        points="559.217 447.105 546.261 443.524 553.909 391.846 573.031 397.133 559.217 447.105"
        fill="#9e616a"
      />
      <path
        d="M754.21182,679.28874l-41.77549-11.54807.146-.5284a16.8709,16.8709,0,0,1,20.75451-11.76586l.001.00028,25.515,7.05326Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#2f2e41"
      />
      <polygon
        points="521.653 447.273 509.356 441.843 524.448 391.831 542.596 399.844 521.653 447.273"
        fill="#9e616a"
      />
      <path
        d="M714.68813,679.28874,675.03931,661.781l.22143-.5015a16.87091,16.87091,0,0,1,22.2465-8.61851l.001.00043,24.21611,10.69321Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#2f2e41"
      />
      <path
        d="M901.80851,426.17823s6.51743,20.193-33.47128,45.73007l-51.50111,51.31877-53.51249,128.006-31.76954-14.243,6.23094-16.63206,4.18106-11.16038,2.1391-5.70985,2.293-6.12056L780.41,506.58053l28.56991-50.02329.82879-27.14347,7.02743-19.1336,50.98344-18.09089Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#2f2e41"
      />
      <path
        d="M875.80851,427.17823s6.51743,20.193-33.47128,45.73007l-51.50111,51.31877-53.51249,128.006-31.76954-14.243,6.23094-16.63206,4.18106-11.16038,2.1391-5.70985,2.293-6.12056L754.41,507.58053l9.56593-16.74906a305.08059,305.08059,0,0,1,45.64647-60.81518L819,420.32244l1-15,21.81956-12.13316Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#2f2e41"
      />
      <path
        d="M845,328.32244l16-34,27.92154-3.80877S910.72422,318.29069,905,357.32244c-2.14658,14.63691-3.89579,29.209-4,42-.02773,3.40345,2.94968,4.92317,3,8,.04943,3.02251-5.64319,7.946-4,10a17.53042,17.53042,0,0,1,4,10c-1.98145,4.71619-61.956-20.834-87.16406-17.0423Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#e4e4e4"
      />
      <circle
        cx="862.94934"
        cy="260.49201"
        r="25.25457"
        transform="translate(2.9207 650.45999) rotate(-59.27092)"
        fill="#9e616a"
      />
      <path
        d="M879.09546,251.42424c-.04839.66454-.11521,1.54463-.1922,2.54032a13.191,13.191,0,0,0,2.19881,7.6799c1.41285,2.3894-.27318,16.82081-.27318,16.82081,41.36829-28.83633,8.68128-57.80695-4.105-55.23166.26695-3.66713-10.16122-2.21439-10.16122-2.21439-3.97342-6.18782-12.68186,2.026-12.68186,2.026l1.68045-2.82693c-6.761,1.71972-13.4435,22.61535-13.4435,22.61535C844.41068,251.8483,879.36248,247.75711,879.09546,251.42424Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#2f2e41"
      />
      <path
        d="M718.61533,341.69729a231.84951,231.84951,0,0,0,33.32325-62.191,5.95528,5.95528,0,0,0-2.81225-7.16889L700.481,246.27219a5.87785,5.87785,0,0,0-5.15658-.20934,5.97828,5.97828,0,0,0-3.40674,3.99181,141.635,141.635,0,0,1-31.06747,57.98184,5.9766,5.9766,0,0,0-1.43709,5.04726,5.87782,5.87782,0,0,0,3.03039,4.17742l48.6454,26.06535A5.95525,5.95525,0,0,0,718.61533,341.69729Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#ccc"
      />
      <path
        d="M715.29577,339.20289a1.79733,1.79733,0,0,1-2.24514.46367L664.405,313.60078a1.72454,1.72454,0,0,1-.90327-1.24156,1.82454,1.82454,0,0,1,.44986-1.56119,145.80329,145.80329,0,0,0,31.98281-59.69024,1.82511,1.82511,0,0,1,1.04924-1.23878,1.72585,1.72585,0,0,1,1.53686.06353L747.165,275.9976a1.797,1.797,0,0,1,.85764,2.12755A227.6977,227.6977,0,0,1,715.29577,339.20289Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#fff"
      />
      <path
        d="M698.27691,274.69686l33.5522,19.66614c3.33827,1.95668,6.36129-3.22725,3.02831-5.18082L701.30522,269.516c-3.33828-1.95668-6.3613,3.22725-3.02831,5.18083Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#01336b"
      />
      <path
        d="M692.27691,285.69686l33.5522,19.66614c3.33827,1.95668,6.36129-3.22725,3.02831-5.18082L695.30522,280.516c-3.33828-1.95668-6.3613,3.22725-3.02831,5.18083Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#01336b"
      />
      <path
        d="M685.27691,297.69686l33.5522,19.66614c3.33827,1.95668,6.36129-3.22725,3.02831-5.18082L688.30522,292.516c-3.33828-1.95668-6.3613,3.22725-3.02831,5.18083Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#01336b"
      />
      <path
        d="M678.27691,307.69686l33.5522,19.66614c3.33827,1.95668,6.36129-3.22725,3.02831-5.18082L681.30522,302.516c-3.33828-1.95668-6.3613,3.22725-3.02831,5.18083Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#01336b"
      />
      <path
        d="M831.23167,423.37523,843.828,451.70071a4.46863,4.46863,0,0,1-.31887,4.24347,4.4794,4.4794,0,0,1-4.54,2.01919,4.12769,4.12769,0,0,1-.86825-.23164,4.51166,4.51166,0,0,1-2.49644-2.374l-12.59631-28.32548a4.5,4.5,0,1,1,8.22353-3.657Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#01336b"
      />
      <path
        d="M842.81215,449.41639l2.52357,5.71684,1.29034,8.10487a2.45095,2.45095,0,0,1-4.3287,1.9247l-5.22193-6.49731-2.48681-5.5921Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#3f3d56"
      />
      <path
        d="M820.20888,415.49455l1.8051,11.34012a2.45233,2.45233,0,0,0,3.41851,1.8555l1.78952-.7958,3.45383,7.76666a1,1,0,0,0,1.82745-.81266l-3.45383-7.76667,1.78953-.7958a2.4555,2.4555,0,0,0,1.24837-3.23242,2.46816,2.46816,0,0,0-.3365-.5488l-7.2116-8.93584a2.45251,2.45251,0,0,0-4.33038,1.92571Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#3f3d56"
      />
      <path
        d="M825.43444,435.96285a11.02532,11.02532,0,0,1,14.67057-8.40167l24.86617-30.27824,6.02148,19.44936-24.5365,25.77093a11.08508,11.08508,0,0,1-21.02172-6.54038Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#9e616a"
      />
      <path
        d="M856.739,433.99538,845.78715,418.176l31.09908-41.46526-16.02066-34.33,1.16655-40.83515,13.23866-2.44552.16436.08244a32.53741,32.53741,0,0,1,14.09925,16.42272c3.30263,8.25632,10.67508,39.25047,14.77594,56.99017a20.67916,20.67916,0,0,1-5.56052,19.38915Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#e4e4e4"
      />
      <path
        d="M548.37616,357.55691a14.71855,14.71855,0,0,0-.09078,2.31576l-56.07285,40.79387L476.808,393.8491l-13.3273,21.90343,26.09783,14.71916a11.18571,11.18571,0,0,0,13.31094-1.741l56.43542-55.12332a14.67718,14.67718,0,1,0-10.94871-16.05048Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#ffb6b6"
      />
      <path
        d="M490.39921,402.15781l-16.35194,27.248a6.292,6.292,0,0,1-9.388,1.62488l-24.4831-20.10741A17.47405,17.47405,0,0,1,458.247,381.01l29.16955,12.09913a6.292,6.292,0,0,1,2.98263,9.04871Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#01336b"
      />
      <rect
        x="420.33638"
        y="585.87873"
        width="32.05245"
        height="53.1155"
        transform="translate(677.56291 1006.16171) rotate(-180)"
        fill="#cacaca"
      />
      <rect
        x="483.52534"
        y="596.633"
        width="7.3266"
        height="92.96452"
        transform="translate(-513.69292 466.43358) rotate(-55.84051)"
        fill="#cacaca"
      />
      <rect
        x="339.05431"
        y="639.45196"
        width="92.96452"
        height="7.3266"
        transform="translate(-489.75804 108.72104) rotate(-34.15969)"
        fill="#cacaca"
      />
      <circle cx="330.48928" cy="450.50386" r="10.07363" fill="#cacaca" />
      <circle cx="240.74241" cy="450.50386" r="10.07363" fill="#cacaca" />
      <circle cx="151.91132" cy="450.50386" r="10.07363" fill="#cacaca" />
      <rect
        x="237.07927"
        y="398.30415"
        width="7.32628"
        height="52.19971"
        fill="#cacaca"
      />
      <rect
        x="429.49422"
        y="539.17373"
        width="13.73677"
        height="70.5154"
        transform="translate(677.56291 930.1516) rotate(-180)"
        fill="#cacaca"
      />
      <path
        d="M396.98388,550.16314V533.679a11.4602,11.4602,0,0,1,11.4473-11.4473h61.35756A11.4602,11.4602,0,0,1,481.236,533.679v16.48412a11.46021,11.46021,0,0,1-11.4473,11.44731H408.43118A11.46021,11.46021,0,0,1,396.98388,550.16314Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#3f3d56"
      />
      <polygon
        points="312.641 444.129 329.609 444.128 337.683 400.117 312.639 400.118 312.641 444.129"
        fill="#ffb8b8"
      />
      <path
        d="M503.47574,679.28874l54.715-.002v-.69206a21.29613,21.29613,0,0,0-21.29591-21.29641h-.00219l-33.418.00135Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#2f2e41"
      />
      <path
        d="M533.18349,635.54817l33.8292-75.86049a27.80148,27.80148,0,0,0-12.206-35.68844l-73.377-39.34744-33.48351-2.848-30.88625-2.80744-.22269.37712c-.28487.4812-6.92568,11.95156-2.43675,24.529,3.405,9.54016,12.26178,16.992,26.32433,22.14865,32.82355,12.03536,70.74125,22.32174,85.51986,26.18485a4.77656,4.77656,0,0,1,3.06054,2.43573,4.84211,4.84211,0,0,1,.27169,3.87392l-31.42862,70.61047a6.22842,6.22842,0,0,0,5.27258,8.34528l23.48377,1.70988q.29906.02838.59541.02838A6.2243,6.2243,0,0,0,533.18349,635.54817Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#2f2e41"
      />
      <polygon
        points="301.568 444.129 318.536 444.128 326.61 400.117 301.566 400.118 301.568 444.129"
        fill="#ffb8b8"
      />
      <path
        d="M492.40276,679.28874l54.715-.002v-.69206a21.29613,21.29613,0,0,0-21.29591-21.29641h-.0022l-33.418.00135Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#2f2e41"
      />
      <path
        d="M520.7264,639.70053l33.8292-75.86048a27.80148,27.80148,0,0,0-12.206-35.68844l-73.377-39.34744-33.4835-2.848-24.19165-2.09847.00331-.33186c-.28487.4812-6.92568,11.95157-2.43674,24.529,3.405,9.54017,5.34117,16.992,19.40372,22.14865,32.82355,12.03537,70.74125,22.32175,85.51985,26.18485a4.77664,4.77664,0,0,1,3.06055,2.43573,4.84215,4.84215,0,0,1,.27169,3.87392l-31.42862,70.61047a6.22843,6.22843,0,0,0,5.27257,8.34528l23.48377,1.70988q.29907.02838.59542.02839A6.2243,6.2243,0,0,0,520.7264,639.70053Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#2f2e41"
      />
      <circle
        cx="466.43293"
        cy="305.08477"
        r="33.99544"
        transform="translate(-133.45157 469.56581) rotate(-76.6227)"
        fill="#ffb8b8"
      />
      <path
        d="M583.61533,435.69729a231.84951,231.84951,0,0,0,33.32325-62.191,5.95528,5.95528,0,0,0-2.81225-7.16889L565.481,340.27219a5.87785,5.87785,0,0,0-5.15658-.20934,5.97828,5.97828,0,0,0-3.40674,3.99181,141.635,141.635,0,0,1-31.06747,57.98184,5.9766,5.9766,0,0,0-1.43709,5.04726,5.87782,5.87782,0,0,0,3.03039,4.17742l48.6454,26.06535A5.95525,5.95525,0,0,0,583.61533,435.69729Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#ccc"
      />
      <path
        d="M580.29577,433.20289a1.79733,1.79733,0,0,1-2.24514.46367L529.405,407.60078a1.72454,1.72454,0,0,1-.90327-1.24156,1.82454,1.82454,0,0,1,.44986-1.56119,145.80329,145.80329,0,0,0,31.98281-59.69024,1.82511,1.82511,0,0,1,1.04924-1.23878,1.72585,1.72585,0,0,1,1.53686.06353L612.165,369.9976a1.797,1.797,0,0,1,.85764,2.12755A227.6977,227.6977,0,0,1,580.29577,433.20289Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#fff"
      />
      <path
        d="M563.27691,368.69686l33.5522,19.66614c3.33827,1.95668,6.36129-3.22725,3.02831-5.18082L566.30522,363.516c-3.33828-1.95668-6.3613,3.22725-3.02831,5.18083Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#ccc"
      />
      <path
        d="M557.27691,379.69686l33.5522,19.66614c3.33827,1.95668,6.36129-3.22725,3.02831-5.18082L560.30522,374.516c-3.33828-1.95668-6.3613,3.22725-3.02831,5.18083Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#ccc"
      />
      <path
        d="M552.56283,390.65405l30.514,18.11469c3.036,1.80232,6.283-1.92391,3.25183-3.72337l-30.514-18.11469c-3.036-1.80232-6.283,1.92391-3.25183,3.72337Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#ccc"
      />
      <path
        d="M543.27691,401.69686l33.5522,19.66614c3.33827,1.95668,6.36129-3.22725,3.02831-5.18082L546.30522,396.516c-3.33828-1.95668-6.3613,3.22725-3.02831,5.18083Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#ccc"
      />
      <path
        d="M486.03429,487.40552l-67.27393-.40234-.01831-.47852c-1.28271-33.75391,2.84253-64.33008,12.61157-93.47461.13819-1.09082.28736-2.25683.43384-3.40137,1.6687-13.03906,2.99365-23.3916,15.39282-27.24707a8.81478,8.81478,0,0,1-1.75195-4.73437,8.61324,8.61324,0,0,1,2.145-6.29981,8.28292,8.28292,0,0,1,5.75268-2.80664l23.94532-.81543a5.60432,5.60432,0,0,1,3.47656,1.17871,9.55871,9.55871,0,0,1,2.78369,12.07715c-.282.56153-.38989.81739-.43115.92871.85132,4.46,3.3772,9.75782,6.0498,15.36426,3.82715,8.02735,8.1648,17.125,9.28,26.8584a49.12715,49.12715,0,0,1-.74121,15.749,31.03735,31.03735,0,0,0-.34155,8.45313c.21875,4.18164.425,8.12988-1.93848,11.94824-.60254,1.03516-1.292,2.13867-2.10083,3.36133l-3.325,7.94824Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#01336b"
      />
      <path
        d="M385.07868,534.64176V397.1802a16.91412,16.91412,0,0,1,16.89506-16.89506h4.67283a16.91412,16.91412,0,0,1,16.89506,16.89506V534.64176a16.91412,16.91412,0,0,1-16.89506,16.89506h-4.67283A16.91412,16.91412,0,0,1,385.07868,534.64176Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#3f3d56"
      />
      <path
        d="M565.48279,410.9a14.71807,14.71807,0,0,0-1.29948,1.91893l-69.15783,5.04965-9.48506-13.9216-22.88111,11.56857,14.39633,26.27728a11.18571,11.18571,0,0,0,12.22445,5.54752l77.02959-17.02881A14.67718,14.67718,0,1,0,565.48279,410.9Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#ffb6b6"
      />
      <path
        d="M492.6982,418.178,464.427,432.68936a6.292,6.292,0,0,1-8.83128-3.5754l-10.18069-30.00139A17.46464,17.46464,0,0,1,453.023,375.677c-12.53214,6.43264,13.56852-11.83946,23.53011,7.56782l18.38824,25.67342a6.292,6.292,0,0,1-2.2431,9.25978Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#01336b"
      />
      <path
        d="M451.07579,338.14966c-2.04614,0-4.30395-.98633-6.84937-2.98437a43.51291,43.51291,0,0,1-13.26538-51.0254c1.85083-4.42089,5.31226-10.42968,11.29248-13.95117,4.29883-2.53027,9.34864-3.44433,13.74463-4.10254,3.80567-.57031,7.65918-1.02441,11.45313-1.34765,4.53345-.3877,9.77-.64942,14.54614,1.165,3.49927,1.33007,6.47778,3.67871,9.35816,5.95019,1.0039.791,2.042,1.61035,3.082,2.36817a22.69659,22.69659,0,0,0,8.52758,4.1416,4.6711,4.6711,0,0,1,3.12647,2.29,4.75352,4.75352,0,0,1,.33374,3.92871c-.44922,1.25976-.85474,2.39551-.03442,3.626a3.23958,3.23958,0,0,0,2.70605,1.37891,1.67693,1.67693,0,0,1,1.41846.74023,1.70947,1.70947,0,0,1,.18554,1.58985l-.13159.33887a2.1809,2.1809,0,0,1,1.70069,1.10644,2.23484,2.23484,0,0,1-.41089,2.76758,22.40406,22.40406,0,0,1-10.91773,5.71387,21.82074,21.82074,0,0,1-8.499-.01953l-.45117-.0879.05542-.5c-11.344-4.04589-21.584,2.82032-27.42163,10.66993l-.84327-.53223a2.4192,2.4192,0,0,0,.10718-1.89648,2.94074,2.94074,0,0,0-1.45532-1.72071,3.80791,3.80791,0,0,0-4.14429.82715,5.6515,5.6515,0,0,0-1.54956,4.28613,15.33941,15.33941,0,0,0,1.25147,4.80567c.27441.72851.55835,1.48242.78906,2.24219a18.31127,18.31127,0,0,1-.96753,13.03515c-.88354,1.86035-2.48144,4.23242-5.07568,4.96777A6.07118,6.07118,0,0,1,451.07579,338.14966Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#2f2e41"
      />
      <path
        d="M1003.647,681.28874H196.353a1.19069,1.19069,0,0,1,0-2.38137h807.294a1.19068,1.19068,0,0,1,0,2.38137Z"
        transform="translate(-195.16231 -218.71126)"
        fill="#cacaca"
      />
    </svg>
  );
};

export default Svg;
