import create from "zustand";
import { devtools } from "zustand/middleware";

const warehouseStore = (set) => ({
   warehouses: [],
   warehouse_stock:[],
  

    addWarehouse: (newWarehouse) => {
        set((state) => ({
            warehouses: [newWarehouse, ...state.warehouses],
        }));
    },

    setWarehouse: (apiWarehouse) => {
        set((state) => ({
            warehouses: [...apiWarehouse],
        }));
    },

    setWarehouseStock: (apiWarehouseStock) => {
        set((state) => ({
            warehouse_stock: [...apiWarehouseStock],
        }));
    },


    updateWarehouse: (editData) => {
        set((state) => ({
            warehouses: state.warehouses.map((warehouse) => {
                if (warehouse._id === editData._id) {
                    return {
                        ...editData,

                    };
                } else {
                    return warehouse;
                }
            }),
        }));
    },


  

    updateWarehouseStock: (receivedProduct) => {
        set((state) => ({
            warehouse_stock: state.warehouse_stock.map((product) => {
                if (product._id === receivedProduct._id) {
                    return {
                        ...receivedProduct,

                    };
                } else {
                    return product;
                }
            }),
        }));
    },
});

const useWarehouseStore = create(devtools(warehouseStore));

export default useWarehouseStore
