import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space, Switch } from "antd";
import useCategoryStore from "stores/categoryStore";
import React, { useEffect, useState } from "react";

const TemplateForm = ({
  submitHandler,
  submitLoader,
  editData,
  setVisible,
  confirmEditHandler,
}) => {
  const categories = useCategoryStore((state) => state.categories);
  const [categoryList, setCategoryList] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const catList = categories.map((category) => {
      return {
        label: category.name,
        value: category._id,
      };
    });

    setCategoryList(catList);
    if (editData) {
      form.setFieldsValue({
        fields: editData?.fields,
        name: editData?.name,
        category: editData?.category?._id,
      });
    } else {
      form.setFieldsValue({
        fields: [],
        name: "",
        category: "",
      });
    }
  }, [editData]);

  const dataTypes = [
    {
      label: "Number",
      value: "number",
    },
    {
      label: "Text",
      value: "text",
    },
    {
      label: "Yes / No",
      value: "yes_or_no",
    },
    {
      label: "Date",
      value: "date",
    },
  ];  

  const onFinish = (values) => {
    if (!editData) {
      values.is_required ? values.is_required = true : values.is_required = false

      submitHandler(values,form);
    } else {
      values._id = editData._id;
      confirmEditHandler(values,form);
    }
  };

  // const handleChange = () => {
  //   form.setFieldsValue({
  //     fields: [],
  //   });
  // };


  return (
    <Form
      form={form}
      key={editData?._id}
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        key={editData?._id}
        initialValue={editData?.name || ""}
        labelCol={{ span: 24, offset: 0 }}
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Missing Name",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        key={editData?._id}
        initialValue={editData?.category?._id || ""}
        labelCol={{ span: 24, offset: 0 }}
        name="category"
        label="Category"
        rules={[
          {
            required: true,
            message: "Missing Category",
          },
        ]}
      >
        <Select
          key={editData?._id}
          options={categoryList} 
        />
      </Form.Item>

      <Form.List key={editData?.fields?._id} name="fields">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} size={40} align="baseline">
                <Form.Item
            
                  {...restField}
                  label="Name"
                  name={[name, "name"]}
                  rules={[
                    {
                      required: true,
                      message: "Missing Name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
               
                  {...restField}
                  initialValue={"text"}
                  name={[name, "data_type"]}
                  label="Data Type"
                  rules={[
                    {
                      required: true,
                      message: "Missing Data Type",
                    },
                  ]}
                >
                  <Select style={{ width: 120 }} options={dataTypes} />
                </Form.Item>

                <Form.Item
               
                  {...restField}
                  valuePropName={"checked"}
                  name={[name, "is_required"]}
                  initialValue={false}
                >
                  <Switch
           
                    checkedChildren="Required"
                    unCheckedChildren="Not Required"
                  />
                </Form.Item>

                <MinusCircleOutlined
            
                  onClick={() => remove(name)}
                />
              </Space>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Fields
              </Button>
            </Form.Item>
          </>
        )
        
        }
      </Form.List>
      <Form.Item
        wrapperCol={{
          offset: 18,
          span: 10,
        }}
      >
        <Space size={8}>
          <Button onClick={() => setVisible(false)}>Cancel</Button>
          {editData ? (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Confirm
            </Button>

          ) : (

            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Submit
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};


export default TemplateForm;
