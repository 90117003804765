import { PageHeader, Skeleton } from "antd";
import React, { useState } from "react";

import BinTable from "components/binPoruducts/BinTable";

const Receipts = () => {
 
  const [tableLoading, setTableLoadings] = useState(false);
  



 


  



 
  


  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Damaged Products"
      
      />

      <Skeleton active loading={tableLoading}>

        <BinTable
   
        
        />
      </Skeleton>

 

  
    </>
  );
};

export default Receipts;
