import { RedoOutlined } from "@ant-design/icons";
import { Button, Divider, Drawer, message, PageHeader, Skeleton } from "antd";
import GoogleMap from "components/googleMap/GoogleMap";
import StandtByForm from "components/stand-by-request/Stand-by-Form";
import StandByTable from "components/stand-by-request/Stand-by-table";
import TaskForm from "components/task/TaskForm";
import { useEffect, useState } from "react";
import useClientStore from "stores/clientStore";
import useProductStore from "stores/productStore";
import useStandByReqStore from "stores/standByReqStore";
import useTaskStore from "stores/taskStore";
import ClientApi from "utils/composable/clientApi";
import ProductApi from "utils/composable/productsApi";
import StandByApi from "utils/composable/standByApi";
import TaskApi from "utils/composable/taskApi";

const StandByProductReq = ({ currentUser }) => {
  const clients = useClientStore((state) => state.clients);
  const { getClients } = ClientApi();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [taskForm, setTaskForm] = useState(true);
  const [tableLoading, setTableLoadings] = useState(false);
  const {
    getTasks,
    getEngineers,
    editTaskJobs,
    AddTaskJobs,
  } = TaskApi();
  const [visible, setVisible] = useState(false);
  const [reqModalVisible, setReqModalVisible] = useState(false);
  const [locationDetails, setLocationDetails] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [hideAddressBox, setHideAddressBox] = useState(true);
  const [assignLoading, setAssignLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState();
  const [editData, setEditData] = useState("");
  const [jobEditMode, setJobEditMode] = useState(false);
  const [jobAddMode, setJobAddMode] = useState(editData ? false : true);
  const [standByProductJob, setStandByProductJob] = useState(false);
  const [currentStandbyRequest, setCurrentStandbyReq] = useState(false);
  const [currentDefectiveProductId, setCurrentDefectiveProductId] =
    useState("");
  //category store

  const { getStandByRequest, updateStandByRequest } = StandByApi();
  const circuttekProducts = useProductStore((state) => state.Products);
  const { getProducts, getProductsWithStatusAndUse } = ProductApi();
  const tasks = useTaskStore((state) => state.tasks);
  const request = useStandByReqStore((state) => state.standByReqs);



  //notification

  const jobSuccessMsg = (msg) => {
    message.success(msg);
  };

  const jobErrorMsg = (msg) => {
    message.error(msg);
  };

  useEffect(() => {
    setTaskForm(true);

    if (!request.length) {
      setTableLoadings(true);
      getStandByRequest().then((resp) => {
        setTableLoadings(false);
      });
    }

    if (!tasks.length) {
      getTasks();
    }

    if (!clients.length) {
      getClients();
    }

    if (!circuttekProducts.length) {
      getProductsWithStatusAndUse("in_stock", "stand-by");
      getProducts();
    }
  }, []);

  const confirmEditHandler = (values, form) => {
    const updatePayload = {
      request_id: values._id,
      client: values.client._id,
      client_product: values.client_product._id,

      ...values,
    };

   setSubmitLoader(true);
    updateStandByRequest(updatePayload)
      .then((resp) => {
        form.reset();
        message.success("updated");
        setSubmitLoader(false);
        setVisible(false);
        setReqModalVisible(false);
      })
      .catch((err) => {
        setVisible(false);
        setReqModalVisible(false);
        setSubmitLoader(false);
        message.success(err);
      });
  };

  const onClose = () => {
    setReqModalVisible(false);
    setEditData("");
    setVisible(false);
    setSubmitLoader(false);
  };

  const handleCancel = (form) => {
    setVisible(false);
    setReqModalVisible(false);

    if (standByProductJob) {
      editData.jobs.pop();
    }
  };

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData?.location?.coordinates);
  };

  const jobUpdateHandler = (key, form) => {
    let jobs = form.getFieldValue("jobs");
    const editedJob = jobs[key];

    editedJob.job_id = editedJob._id;
    editedJob.task_id = editData._id;

    editedJob.job = {
      product: editedJob.productId,
      description: editedJob.description,
      status: editedJob.status,
      type: editedJob.type,
      assigned: editedJob?.assigned[0]?._id
        ? editedJob?.assigned[0]?._id
        : editedJob.assigned,
    };

    const jobUpdatePayload = {
      job_id: editedJob._id,
      task_id: editData._id,
      job: {
        product: editedJob.productId,
        description: editedJob.description,
        status: editedJob.status,
        type: editedJob.type,
        assigned: editedJob?.assigned[0]?._id
          ? editedJob.assigned[0]?._id
          : editedJob?.assigned,
        stand_by_product_request: editData._id,
        defective_product: editedJob.defective_product._id,
      },
    };

    setJobLoading(true);
    editTaskJobs(jobUpdatePayload, key, editedJob)
      .then((resp) => {
        setJobLoading(false);
        setJobEditMode(false);
        jobSuccessMsg(resp);
      })
      .catch((err) => {
        setJobLoading(false);
        console.log(err);
        jobErrorMsg(err);
      });
  };

  const jobSubmitHandler = (key, form, fieldEngineer) => {
    if (typeof fieldEngineer == "object") {
      message.error("Please assign a Team Member");
      return;
    }

    const jobs = form.getFieldValue("jobs");

    jobs[jobs.length - 1].assigned = fieldEngineer;
    const newJob = jobs[jobs.length - 1];

    newJob.assigned = fieldEngineer;

    const newJobPayload = {
      task_id: editData?._id,
      job: {
        product: newJob.productId,
        description: newJob.description,
        assigned: typeof fieldEngineer == "object" ? null : fieldEngineer,
        type: "stand_by_product_delivery",
        stand_by_product_request: currentStandbyRequest?._id,
        defective_product: currentDefectiveProductId,
      },
    };

    setJobLoading(true);
    AddTaskJobs(newJobPayload)
      .then((task) => {
        if (editData) {
          editMode(task);
        }
        setJobLoading(false);
        setJobAddMode(false);
        jobSuccessMsg("Job added");
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  const fillForm = () => {
    message.error("Please select Client and Date first ");
  };

  const getFieldEngineers = (clientId, date) => {
   

    const client = clients.find(
      (client) => client._id == clientId?._id || clientId
    );

    client.location = editData.location;

    if (!client || !date) {
      fillForm();
    } else {
   
      setAssignLoading(true);
      getEngineers(client, date)
        .then((resp) => {
          setAssignLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAssignLoading(false);
          jobErrorMsg(err);
        });
    }
  };

  const editMode = (task) => {
    
    task.jobs = task.jobs.map((job) => {
      return {
        ...job,
        productId: job.product?._id,
      };
    });
setEditData(task);
    setVisible(true);
  };


  const [refreshLoading, setRefreshLoading] = useState(false);

  const refresh = () => {
    setRefreshLoading(true);

    getProductsWithStatusAndUse("in_stock", "stand-by");

    getStandByRequest().finally(() => {
      setRefreshLoading(false);
    });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Stand-By Product Request"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />
      <Skeleton active loading={tableLoading}>
        <StandByTable
          currentUser={currentUser.user}
        />
      </Skeleton>
      <Drawer
        destroyOnClose={true}
        key={editData?._id}
        size="large"
        title={"Edit Standby Product Request"}
        placement="right"
        onClose={onClose}
        visible={reqModalVisible}
      >
        <StandtByForm
          editData={editData}
          submitLoader={submitLoader}
          setVisible={setVisible}
          confirmEditHandler={confirmEditHandler}
        />
      </Drawer>
      <Drawer
        destroyOnClose={true}
        width={"100vw"}
        title={
          editData ? `Update Task ${editData.task_number} ` : "Create Task"
        }
        size={"large"}
        placement="right"
        onClose={handleCancel}
        visible={visible}
      >
        <Divider style={{ padding: "1rem" }}>Enter Task Details</Divider>

        <TaskForm
          standByProductJob={standByProductJob}
          jobSubmitHandler={jobSubmitHandler}
          jobUpdateHandler={jobUpdateHandler}
          locationDetails={locationDetails}
          // submitHandler={submitHandler}
          ChangeLocation={ChangeLocation}
          editData={editData}
          // updateHandler={updateHandler}
          getFieldEngineers={getFieldEngineers}
          assignLoading={assignLoading}
          setJobLoading={setJobLoading}
          jobLoading={jobLoading}
          setJobEditMode={setJobEditMode}
          jobEditMode={jobEditMode}
          jobAddMode={jobAddMode}
          setJobAddMode={setJobAddMode}
        />

        <Divider style={{ marginBottom: "1rem" }}>Client Location</Divider>

        <GoogleMap
          taskForm={taskForm}
          hideAddressBox={hideAddressBox}
          locationEditData={locationEditData}
          setLocationDetails={setLocationDetails}
        />
      </Drawer>
    </>
  );
};

export default StandByProductReq;
