import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Space,
  Table,
  Tooltip
} from "antd";
import { useEffect, useState } from "react";
import useProductStore from "stores/productStore";


import moment from "moment";
import useRentStore from "stores/rentStore";
import RentApi from "utils/composable/rentApi";
const RentProductWithUsTable = ({
  editHandler,
  showRentTask,
  closeRent,
  changeProduct,
  createRentProductDeliveryJob,
}) => {
  const rentWithUs = useRentStore((state) => state.rentWithUs);
  console.log({ rentWithUs });

  const [rents, setRents] = useState([]);
  const { getRentHistory, getRentWithUs } = RentApi();
  const products = useProductStore((state) => state.Products);
  const [tableLoading, setTableLoading] = useState(false);
  const [form] = Form.useForm();
  const [productList, setProductList] = useState();

  //Fuzzy filter

  const [filteredData, setFilteredData] = useState(false);
  const fuzzyFilter = (query, product) => {
    console.log(query);
    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length > 2) {
      results = [];

      for (j = 0, len = rentWithUs?.length; j < len; j++) {
        var rent = rentWithUs[j];

        if (product) {
          relevance = string_similarity(
            query,
            `${rent?.product?.name}${rent?.product?.barcode}` || ""
          );
        } else {
          relevance = string_similarity(query, rent?.client?.name || "");
        }

        obj = {
          ...rent,
          relevance: relevance,
        };
        results.push(obj);
      }

      var sortedResults = results.filter((result) => result.relevance > 0.2);
      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();
      console.log({ sortedResults });
      rent = [...sortedResults];
      setFilteredData(sortedResults);
      console.log(results);
    }
  };

  useEffect(() => {
    const productList = products
      .filter((product) => product.status == "in_stock")
      .map((product) => {
        if (!product.sold_on) {
          return {
            label: `${product.name}  ${product?.barcode ? "-" : ""}  ${
              product?.barcode || ""
            } `,
            value: product._id,
          };
        } else return {};
      });

    setProductList(productList);

    if (!rentWithUs.length) {
      setTableLoading(true);

      getRentWithUs()
        .then((rentWithUs) => {
          setTableLoading(false);
          console.log({ rentWithUs });
          setRents(rentWithUs);
        })
        .finally(() => {
          setTableLoading(false);
        });
    }
  }, []);

  const content = (data) => {
    return (
      <div>
        <p>{`Item Price :  ₹ ${data?.price.item_total.toFixed(2)}`}</p>
        <p>{`Discount :  ₹ ${data?.price.discount.toFixed(2)}`}</p>
        <p>{`Tax :  ₹ ${data?.price.tax_amount.toFixed(2)}`}</p>
        <p>{`Charges :  ₹ ${data?.price.charges.toFixed(2)}`}</p>
      </div>
    );
  };

  const handleChange = (product, rentId, rent) => {
    console.log(product, "product", rentId, "Rent==", rent);
    // setOpen(true);

    const handleOkay = () => {
      changeProduct(rentId, product, rent);
    };

    const handleCancel = () => {
      var name = `${rent.product.name}   ${
        rent.product.barcode ? -rent.product?.barcode : ""
      }`;

      form.setFieldsValue({ [rent._id]: name || "Assign a product" });

      // setCurrentReq(request)

      // setOpen(false);
    };

    Modal.confirm({
      title: "Confirm",

      content: "Change Rent Product ?",
      okText: "Okay",
      cancelText: "Cancel",
      onOk: () => handleOkay(rentId),
      onCancel: () => handleCancel(),
    });
  };

  const columns = [
    {
      title: "Product",

      key: "2",
      render: (rent) => (
        <Form form={form} key={rent._id}>
          <Form.Item
            key={rent._id}
            style={{ width: "15rem" }}
            name={rent._id}
            initialValue={
              rent?.product?.name
                ? `${rent?.product?.name} ${
                    rent?.product?.barcode ? -rent?.product?.barcode : ""
                  }`
                : "Assign a product"
            }
          >
            <Select
              disabled={!rent.with_defective}
              id={rent._id}
              key={rent._id}
              // disabled={req.status != "requested" || !currentUser.privileges.includes('stand_by_product_management')}

              onChange={(e) => handleChange(e, rent._id, rent)}
              options={productList}
              style={{ width: "13rem" }}
            />
          </Form.Item>
        </Form>
      ),

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0], true);
              }}
              onBlur={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Client",
      dataIndex: "client",
      key: "2",
      render: (client) => client?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0]);
              }}
              onBlur={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Barcode",

      render: (data) => data.product?.barcode,
      key: "2",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.product?.barcode
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
    },
    {
      title: "Brand",
      render: (data) => data.product?.brand,
      key: "3",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.product?.brand
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
    },

    {
      title: "Rented Date",

      key: "4",
      render: (record) => moment.utc(record?.rented_date).format("DD-MM-YYYY"),
    },

    {
      title: "Descriptions",
      dataIndex: "description",
      key: "1",
      width: "20%",
    },

    {
      title: "Amount",

      key: "1",
      width: "20%",
      width: "20%",

      render: (record) => (
        <>
          {record?.price?.amount.toFixed(2)}

          <Popover
            style={{ padding: "1rem" }}
            content={() => content(record)}
            title="Payment Details"
          >
            <ExclamationCircleOutlined
              style={{ paddingLeft: "0.5rem" }}
            ></ExclamationCircleOutlined>
          </Popover>
        </>
      ),
    },

    {
      title: "Action",
      key: "4",
      render: (rent) => (
        <Space size={"large"}>
          <Tooltip title={"Show Task"}>
            <EyeOutlined onClick={() => showRentTask(rent?.task)} />
          </Tooltip>

          <Tooltip title={"Close Rent"}>
            <Popconfirm
              title="Close this rent?"
              onConfirm={() => closeRent(rent?._id)}
            >
              <CheckCircleOutlined />
            </Popconfirm>
          </Tooltip>
          {/* <Tooltip title={"Create Delivery Task"}>

            <PlusCircleOutlined hidden= {!rent.with_defective} onClick={() => createRentProductDeliveryJob(rent,false,true)} />

          </Tooltip> */}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        loading={tableLoading}
        key={rents?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={false}
        dataSource={filteredData ? filteredData : rentWithUs}
      />
    </>
  );
};

export default RentProductWithUsTable;
