import create from "zustand";
import { devtools } from "zustand/middleware";

const salesStore = (set) => ({
    sales: [],

    foundProduct: {},
    addSale: (newSale) => {
        set((state) => ({
            sales: [newSale, ...state.sales],
        }));
    },

    setSales: (apiSales) => {
        set((state) => ({
            sales: [...apiSales],
        }));
    },

    updateSale: (editData) => {
        set((state) => ({
            Products: state.Products.map((Product) => {
                if (Product._id === editData._id) {
                    return {
                       ...editData
                    };
                } else {
                    return Product;
                }
            }),
        }));
    },
});

const useSalesStore = create(devtools(salesStore));
export default useSalesStore;
;
