import { Button, Input, Space, Table, Tooltip } from "antd";
import React from "react";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import useCategoryStore from "stores/categoryStore";


const CategoryTable = ({ toggleEditMode ,currentUser}) => {
  const categories = useCategoryStore((state) => state.categories);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "0",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {



        return record?.name.toLowerCase().includes(value.toLowerCase());

      },

      
    },


    {
      title: "Description",
      dataIndex: "description",
      key: "_1",
      width:'60%',
     
    },

    {
      title: "Action",
      key: "2",
      render: (_, categories) => (
        <Space key={categories?._id} size="middle">



          <Tooltip
            title={" Edit"}
          >

            <EditOutlined
              hidden={!currentUser.privileges.includes('product_management') }
              onClick={() => toggleEditMode(categories?._id)}
              key={categories?._id}
            />

          </Tooltip>




      
        </Space>
      ),
    },
  ];




  return (
    <>
      <Table
       rowClassName={'rowFontColor'}
        columns={columns}
        dataSource={categories}
        pagination={false}
        rowKey={'_id'}
      />
    </>
  );
};

export default CategoryTable;
