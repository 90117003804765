import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Tag
} from "antd";

import moment from "moment"; // Import moment for date manipulation
import { useEffect, useState } from "react";
import Plans from "utils/constants/plans";

const ClientForm = ({
  submitHandler,
  branches,
  locationSubmitHandler,
  editData,
  ChangeLocation,
  confirmEditHandler,
  confirmBranchEditHandler,
  locationDetails,
  clientLocation,
}) => {
  const [form] = Form.useForm();
  const [maintenanceAvailable, setMaintenanceAvailable] = useState(false); // Switch state
  const [nextMaintenanceDate, setNextMaintenanceDate] = useState(
    moment().add(1, "month") // Default next maintenance date to 1 month from today
  );

  const onFinish = (values) => {
    if (branches && editData) {
      console.log(values, "Branches and Edit data");

      //client branch edit function
      confirmBranchEditHandler(values, form);
    } else if (branches) {
      //client branch submit function
      locationSubmitHandler(values, form);
    } else if (editData) {
      if (
        !editData.next_maintenance_date &&
        values.maintenance_interval !== 0
      ) {
        values.interval_added = true;
      }

      if (values.maintenance_interval) {
        values.maintenance_interval = parseFloat(values.maintenance_interval);
      }

      confirmEditHandler(values, form);
    } else {
      submitHandler(values, form);
    }
    // form.resetFields();
  };
  const plans = Plans();
  const [planList, setPlanList] = useState([]);

  const roleOptions = [
    {
      label: "Primary",
      value: "primary",
      key: "1",
    },
    {
      label: "Accounts",
      value: "accounts",
      key: "2",
    },
    {
      label: "System Admin",
      value: "system-admin",
      key: "3",
    },
  ];

  const tagRender = (props) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  useEffect(() => {
    console.log(editData);
    if(editData?.maintenance_interval){
      setMaintenanceAvailable(true);
    }

    const planList = plans.map((plan) => {
      return {
        label: plan.title,
        value: plan.value,
        key: plan._id,
      };
    });

    setPlanList(planList);

  
  }, [editData]);

  return (
    <Form
      form={form}
      key={editData?._id}
      initialValues={{
        name: editData?.name || "",
        plan: editData?.plan || plans[0]?.value,
        status: editData?.status || "Ordinary",
        is_active:
          editData.is_active === undefined
            ? true
            : editData.is_active === false
            ? false
            : true,
            maintenance_available:
          editData.maintenance_available === undefined
            ? false
            : editData.maintenance_available === false
            ? false
            : true,
        contacts: editData?.contacts || [""],
        address: editData?.address || "",
        landline: editData.landline || "",
        maintenance_interval: editData?.maintenance_interval || (maintenanceAvailable ? 1 : 0),
        next_maintenance_date: editData?.next_maintenance_date
          ? moment(editData.next_maintenance_date)
          : moment().add(1, "month"), // Default 1 month from today
      }}
      id="clientForm"
      name="clientForm"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Space size={0} align="end" direction="horizontal" style={{ width: "100%" }}>
        <Form.Item
          style={{ marginLeft: "3rem", width: 270 }}
          labelCol={{ span: 24, offset: 0 }}
          name="name"
          label="Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter The Name" style={{ width: 250 }} />
        </Form.Item>
      

        {!branches && (
          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            name="status"
            label="Status"
            rules={[{ required: true }]}
            style={{ width: 150 }}
          >
            <Select
              placeholder="Select Status"
              allowClear
              style={{ width: 150 }}
            >
              <Select.Option value="VIP">VIP</Select.Option>
              <Select.Option value="Ordinary">Ordinary</Select.Option>
              <Select.Option value="Blacklisted">Blacklisted</Select.Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item
          style={{ marginLeft: "1rem", width: 180 }}
          labelCol={{ span: 24, offset: 0 }}
          name="landline"
          label="Landline No"
        >
          <Input placeholder="Landline Number" style={{ width: 180 }} />
        </Form.Item>

        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          label="Is Active ?"
          valuePropName={"checked"}
          name="is_active"
          style={{ marginLeft: "1rem" }}
        >
          <Switch
            style={{ width: 60 }}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </Form.Item>
      </Space>

      <Space size={0} align="end" direction="horizontal">
        <Form.Item
         labelCol={{ span: 24, offset: 0 }}
          label="Maintenance Available"
          valuePropName="checked"
           name="maintenance_available"
          style={{ marginLeft: "3rem"}}
        >
          <Switch
            onChange={(checked) => {
              setMaintenanceAvailable(checked); // Update state when switch is toggled
            }}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </Form.Item>
        {maintenanceAvailable && (
        <>
          {/* Maintenance Interval */}
          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            name="maintenance_interval"
            label="Maintenance Interval"
            style={{ marginLeft: "1rem" }}
            rules={[
              {
                required: true,
                message: "Please enter a maintenance interval",
              },
              {
                validator(_, value) {
                  // Convert value to a number and check the range
                  if (!value || (value >= 1 && value <= 12)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Interval must be between 1 and 12"));
                },
              },
            ]}
          >
            <Input
              placeholder="Interval 1 - 12"
              type="number"
              min={1}
              max={12}
              onChange={(e) => {
                const value = Number(e.target.value);
                e.target.value = value; // Ensure the value is a number
              }}
            />
          </Form.Item>

          {/* Next Maintenance Date */}
          <Form.Item
           labelCol={{ span: 24, offset: 0 }}
            label="Next Maintenance Date"
            name="next_maintenance_date"
            style={{ marginLeft: "1rem" }}
            rules={[{ required: true, message: "Please select a date" }]}
          >
            <DatePicker
              value={nextMaintenanceDate}
              onChange={(date) => setNextMaintenanceDate(date)}
              style={{}}
            />
          </Form.Item>
        </>
      )}
      </Space>

      

      <Divider style={{ fontWeight: "normal" }}>Points of Contact </Divider>

      <Form.List name="contacts">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key}>
                <Form.Item
                  style={{ marginLeft: "3rem" }}
                  labelCol={{ span: 24, offset: 0 }}
                  {...restField}
                  name={[name, "name"]}
                  label={"Name"}
                  rules={[
                    {
                      required: true,
                      message: "Missing  Name",
                    },
                  ]}
                >
                  <Input placeholder=" Name" />
                </Form.Item>
                <Form.Item
                  style={{ marginLeft: "0rem" }}
                  labelCol={{ span: 24, offset: 0 }}
                  {...restField}
                  name={[name, "email"]}
                  label={"Email"}
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Missing Email",

                    // },

                    {
                      type: "email",
                      message: "The input is not a valid email!",
                    },
                  ]}
                >
                  <Input type={"email"} placeholder="Email" />
                </Form.Item>
                <Form.Item
                  style={{ marginLeft: "0rem" }}
                  labelCol={{ span: 24, offset: 0 }}
                  {...restField}
                  name={[name, "phone"]}
                  label={"Phone"}
                  rules={[
                    {
                      required: true,
                      message: "Missing Phone Number",
                    },

                    {
                      len: 10,
                      message: "Phone Number Should be 10 Digits",
                    },
                  ]}
                >
                  <Input
                    type={"number"}
                    maxLength={10}
                    minLength={10}
                    placeholder="Phone"
                  />
                </Form.Item>

                <Form.Item
                  style={{ marginLeft: "0rem" }}
                  initialValue={"primary"}
                  labelCol={{ span: 24, offset: 0 }}
                  {...restField}
                  name={[name, "roles"]}
                  label={"Role"}
                  rules={[
                    {
                      required: true,
                      message: "Missing  Role",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    showArrow
                    tagRender={tagRender}
                    style={{ width: "100%" }}
                    options={roleOptions}
                  >
                    <Select.Option value="primary">Primary</Select.Option>
                    <Select.Option value="accounts">Accounts</Select.Option>
                    <Select.Option value="system-admin">
                      System Admin
                    </Select.Option>
                  </Select>
                </Form.Item>

                <MinusCircleOutlined
                  style={{ marginTop: "2rem" }}
                  onClick={() => remove(name)}
                />
              </Space>
            ))}

            <Form.Item>
              <Button
                style={{
                  width: "53.5rem",
                  marginLeft: "3rem",
                  marginBottom: "-1rem",
                }}
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Contacts
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        style={{ marginLeft: "3rem" }}
        labelCol={{ span: 24, offset: 0 }}
        name="address"
        label={clientLocation ? "Address" : "Primary Address"}
        rules={[{ required: true }]}
      >
        <Input
          placeholder="Enter The Address"
          style={{ width: 855 }}
          type={"text"}
        />
      </Form.Item>
    </Form>
  );
};

export default ClientForm;
