import create from "zustand";
import { devtools } from "zustand/middleware";

const invoiceStore = (set) => ({
    invoices: [],

 
    setInvoices: (apiInvoices) => {
        set((state) => ({
            invoices: [...apiInvoices],
        }));
    },

    updateInvoices: (editData) => {
        set((state) => ({
            invoices: state.invoices.map((invoice) => {
                if (invoice._id === editData._id) {
                    return {
                       ...editData,
                       client:invoice.client
                    }; 
                } else {
                    return invoice;
                }
            }),
        }));
    },

    changeInvoiceStatus: (status,invoiceNumbers) => {
        set((state) => ({
            invoices: state.invoices.map((invoice) => {

                if (invoiceNumbers.includes(invoice.invoice_number)) {

                    if (status === "cancelled") {

                        return {
                            ...invoice,
                            status: status,
                            balance_amount: 0

                        };

                    } else {

                        return {
                            ...invoice,
                            status: status,
                            balance_amount: (invoice.price.amount) - (invoice.adjusted_amount + invoice.paid_amount)
                        };
                    }




                } else {
                    return invoice;
                }
            }),
        }));
    },
  
});


const useInvoiceStore = create(devtools(invoiceStore));

export default useInvoiceStore
;
