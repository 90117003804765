import { Form, Table } from "antd";

const ClientContactsTable = ({ contacts }) => {
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "25%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: "30%",
    },
    {
      title: "Role",
      dataIndex: "roles",
      render: (_, clients) => {
        const Roles = clients?.roles.map((role, index) => {
          const str1 = role;
          const cleanStr = str1.replace(/ " " /g);
          const str2 = cleanStr.charAt(0).toUpperCase() + str1.slice(1);
          return (
            <>
              {" "}
              {index ? "," : ""} {str2}{" "}
            </>
          );
        });

        return Roles;
      },
      width: "20%",
    },
  ];

  return (
    <Form form={form} component={false}>
      <Table
        bordered
        dataSource={contacts}
        columns={columns}
        pagination={false}
      />
    </Form>
  );
};

export default ClientContactsTable;
