import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useProductStore from "stores/productStore";

const StandByProductTable = ({ assignHandler }) => {
  const navigate = useNavigate();
  const products = useProductStore((state) => state.Products);

  const inStockProducts = products?.filter((product) => product?.status == 'in_stock' && product?.use == 'stand-by')

  const [filteredData, setFilteredData] = useState([]);
  const [currentQuery, setCurrentQuery] = useState('');

  const createProductDescription = (product) => {
  
    const nameBrandCategory = `${product.name}`
  + ((product.brand && product.brand !== product.name )? ` - ${product.brand}` : '')
  + (product.category?.name ? ` - ${product.category.name}` : '');

const specifications = Object.entries(product?.specifications || {})
    .filter(([_, value]) => {
      const strValue = String(value);
      return value && strValue !== 'NIL' && strValue !== 'N/A';
    })
    .map(([key, value]) => `${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}: ${value}`)
    .join(', ');

  return `${nameBrandCategory} | ${specifications}`;



  };

  const enrichedProducts = inStockProducts.map((product) => ({
    ...product,
    description: createProductDescription(product),
  }));


  const fuzzyFilter = (query) => {
    if (query.length >= 2) {
      const getBigrams = (string) => {
        const s = string.toLowerCase();
        const v = new Array(s.length - 1);
        for (let i = 0; i < v.length; i++) {
          v[i] = s.slice(i, i + 2);
        }
        return v;
      };

      const stringSimilarity = (str1, str2) => {
        if (str1.length > 0 && str2.length > 0) {
          const pairs1 = getBigrams(str1);
          const pairs2 = getBigrams(str2);
          const union = pairs1.length + pairs2.length;
          let hitCount = 0;

          for (let x of pairs1) {
            for (let y of pairs2) {
              if (x === y) {
                hitCount++;
              }
            }
          }
          return (2.0 * hitCount) / union;
        }
        return 0.0;
      };

      

      const results = enrichedProducts.map((product) => {
        
        const relevance = stringSimilarity(query, product.description);
        return { ...product, relevance };
      }).filter((result) => result.relevance > 0.1)
        .sort((a, b) => b.relevance - a.relevance);

      setFilteredData(results);
    } else {
      setFilteredData([]);
    }
    setCurrentQuery(query);
  };

  useEffect(() => {
    if (filteredData.length) {
      fuzzyFilter(currentQuery);
    }
  }, [products]);

  const columns = [
    {
      title: "Name",
      dataIndex: "description",
      key: "description",
      width: '50%',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <>
          <Input
            style={{ margin: "1rem", width: "25rem" }}
            autoFocus
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => fuzzyFilter(selectedKeys[0])}
            onBlur={() => fuzzyFilter(selectedKeys[0])}
            placeholder="Search"
          />
          <Button
            style={{ margin: "1rem" }}
            type="primary"
            onClick={() => fuzzyFilter(selectedKeys[0])}
          >
            Search
          </Button>
          <Button
            style={{ marginRight: "1rem" }}
            onClick={() => {
              clearFilters();
              setFilteredData([]);
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </>
      ),
      filterIcon: () => <SearchOutlined />,
    },
    {
      title: "Barcode",
      dataIndex: "barcode",
      key: "barcode",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <>
          <Input
            style={{ margin: "1rem", width: "25rem" }}
            autoFocus
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
            placeholder="Search"
          />
          <Button
            style={{ margin: "1rem" }}
            type="primary"
            onClick={() => confirm()}
          >
            Search
          </Button>
          <Button
            style={{ marginRight: "1rem" }}
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </>
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value, record) => record?.barcode?.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Warehouse",
      dataIndex: "warehouse",
      key: "warehouse",
      render: (warehouse) => (
        <>
          <span>{warehouse?.title} </span>
          <EyeOutlined onClick={() => navigate("/warehouse/" + warehouse?._id, { state: warehouse })} />
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <>
          <Input
            style={{ margin: "1rem", width: "25rem" }}
            autoFocus
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
            placeholder="Search"
          />
          <Button
            style={{ margin: "1rem" }}
            type="primary"
            onClick={() => confirm()}
          >
            Search
          </Button>
          <Button
            style={{ marginRight: "1rem" }}
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </>
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value, record) => record?.warehouse?.title.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (products) => (
        <Button
        onClick={() =>assignHandler(products)}
      >
        Assign Product
      </Button>
       
      ),
    },
  ];

  return (
    <Table
      key={products?._id}
      rowKey={"_id"}
      columns={columns}
      dataSource={filteredData.length ? filteredData : enrichedProducts}
    />
  );
};

export default StandByProductTable;
