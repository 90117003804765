import { Divider, Table } from "antd";
import React, { useState } from "react";

import useDefectiveProductStore from "stores/defectiveProductStore";
import moment from "moment";

const JobRepairCostTable = ({ currentRepairDetails }) => {
  const defectiveProducts = useDefectiveProductStore(
    (state) => state.defectiveProducts
  );

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (_, record) => moment(_).format("DD-MM-YYYY"),
      width: "5%",
      key: "2",
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "9",
      width: "3%",
    },
    {
      title: "Created By",
      dataIndex: "created_by_name",
      key: "9",
      width: "3%",
    },

    {
      title: "Item Price",

      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.price?.item_price.toFixed(2);
      },
    },

    {
      title: "Discount",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.price?.discount.toFixed(2);
      },
    },
    {
      title: "Tax",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.price?.tax_amount.toFixed(2);
      },
    },
    {
      title: "Charge",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.price?.charges?.toFixed(2);
      },
    },

    {
      title: "Amount",
      dataIndex: "product",
      key: "9",
      width: "3%",
      render: (_, request) => {
        return request?.price?.amount?.toFixed(2);
      },
    },
    {
      title: "Received",
      dataIndex: "received_on_creation",
      key: "9",
      width: "3%",
      render: (value, request) => {
        if (value) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
  ];

  return (
    <>
      <Divider></Divider>

      <Table
        key={defectiveProducts?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={{ defaultPageSize: "5", showLessItems: "true" }}
        dataSource={currentRepairDetails}
      />
    </>
  );
};

export default JobRepairCostTable;
