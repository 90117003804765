import {
  KeyOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Dropdown, Menu, Space } from "antd";
import { useNavigate } from "react-router-dom";
import getCurrentUser from "utils/services/get-user";
import "./dropDown.css";
// import humanize from "utils/formate-status";

const handleMenuClick = (e) => {};

function titleCase(string) {
  return string[0]?.toUpperCase() + string.slice(1)?.toLowerCase();
}

const DropDownMenu = () => {
  var navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  const menu = () => {
    return (
      <Menu onClick={() => handleMenuClick}>
        <Menu.Item
          onClick={() => {
            navigate("/change-password");
          }}
          key="1"
        >
          <Space>
            {" "}
            <div>
              <KeyOutlined />
            </div>
            <span style={{ marginRight: "1.5rem" }}> Change Password </span>{" "}
          </Space>
        </Menu.Item>
        <Menu.Item onClick={logOut} key="2">
          <Space>
            {" "}
            <div>
              <PoweroffOutlined style={{ color: "red" }} />
            </div>
            Log Out{" "}
          </Space>
        </Menu.Item>
      </Menu>
    );
  };

  const user = getCurrentUser();


  return (
    <Dropdown.Button
      className="dropdown-btn"
      overlay={menu}
      icon={
        <UserOutlined
          style={{ fontSize: "1.3rem", border: "0px solid white !important" }}
        />
      }
    >
      <span
        style={{
          color: "grey",
          fontWeight: "lighter",
          fontFamily: "revert",
          fontSize: "1rem",
        }}
      >
        {" "}
        {user?.user?.first_name ? titleCase(user.user.first_name) : "Guest"}{" "}
     
      </span>
    </Dropdown.Button>
  );
};

export default DropDownMenu;
