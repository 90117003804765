import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import useTaskStore from "stores/taskStore";

export default function FielEnggMap({
  setValue,
  selectedClient,
  setActiveId,
  activeId,
  changeActiveMarker,
  allFieldEngineers,
  setOtherFieldEngineers,
  allDeliveryWorkers ,
  allServiceWorkers ,

  currentRole
}) {
  const fieldEngineers = useTaskStore((state) => state.fieldEngineers);
  const [fieldEnggfmarkers, setFieldEnggfmarkers] = useState("");
  const [hideFEmakers,setHideFemarkers]=useState(false)
  useEffect(() => {
    console.log({ selectedClient });

    //setting markers for fieldEngineers

    var FEmarkers = fieldEngineers.map((FeEngg, index) => {
      return {
        id: FeEngg.id,
        name: FeEngg.name,
        position: {
          lat: FeEngg?.location?.coordinates[1],
          lng: FeEngg?.location?.coordinates[0],
        },
        distance: Math.trunc(FeEngg?.distance),
        role: FeEngg.role
      };
    });

    console.log({ FEmarkers });

//if Field engineers are not available getting all other FE
    if(!FEmarkers.length){
      var otherFeEngg=[]
      console.log(allFieldEngineers,'Field Engineers' );
      console.log({currentRole},'inside FE marker');

      if(currentRole=='engineer'){

         otherFeEngg = allFieldEngineers.map((FeEngg, index) => {
          return {
            id: FeEngg._id,
            name: FeEngg.name,
            role: FeEngg.role,
            position: {
              lat: FeEngg?.location?.coordinates[1],
              lng: FeEngg?.location?.coordinates[0],
            },
            distance: Math.trunc(12342),
          };
        });

      } else if (currentRole == 'service') {


        otherFeEngg = allServiceWorkers.map((FeEngg, index) => {
          return {
            id: FeEngg._id,
            name: FeEngg.name,
            role: FeEngg.role,
            position: {
              lat: FeEngg?.location?.coordinates[1],
              lng: FeEngg?.location?.coordinates[0],
            },
            distance: Math.trunc(12342),
          };
        });

      }else{


        otherFeEngg = allDeliveryWorkers.map((FeEngg, index) => {
          return {
            id: FeEngg._id,
            name: FeEngg.name,
            role:FeEngg.role,
            position: {
              lat: FeEngg?.location?.coordinates[1],
              lng: FeEngg?.location?.coordinates[0],
            },
            distance: Math.trunc(12342),
          };
        });


      }

      setOtherFieldEngineers(otherFeEngg)
      setHideFemarkers(true)
     
    }else{

      FEmarkers.map((marker, index) => {
        if (
          marker?.position.lat == FEmarkers[index + 1]?.position.lat &&
          marker?.position.lng == FEmarkers[index + 1]?.position.lng
        ) {
          var randomnum = Math.floor(Math.random() * (7 - 1) + 1) / 1000;
          console.log(randomnum);

          console.log(
            marker.position,
            FEmarkers[index + 1].position,
            "same positions"
          );
          marker.position.lat = marker?.position.lat + 0.001;
          marker.position.lng = marker?.position.lng + randomnum;
        }
      });

    }
    // setOtherFieldEngineers([])
    setFieldEnggfmarkers(FEmarkers);

  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places", "visualization"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Map
      setValue={setValue}
      fieldEnggfmarkers={fieldEnggfmarkers}
      selectedClient={selectedClient}
      setActiveId={setActiveId}
      changeActiveMarker={changeActiveMarker}
      activeId={activeId}
      allFieldEngineers={allFieldEngineers}
      hideFEmakers={hideFEmakers}
    />
  );
}


function Map({ fieldEnggfmarkers, selectedClient, setActiveId, activeId, setValue, hideFEmakers}) {
  const [activeMarker, setActiveMarker] = useState(null);
  const icon = {
    url: "http://maps.google.com/mapfiles/kml/shapes/man.png", // url
    // eslint-disable-next-line no-undef
    scaledSize: new google.maps.Size(35, 35), // scaled size
  };

  useEffect(() => {
    handleActiveMarker(activeId);
  }, [activeId]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setValue(marker)
    setActiveId(marker);
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {


    
if(fieldEnggfmarkers.length){
      // eslint-disable-next-line no-undef
  const bounds = new google.maps.LatLngBounds();
  fieldEnggfmarkers.forEach(({ position }) => bounds.extend(position));
  map.fitBounds(bounds);

}else{
      // eslint-disable-next-line no-undef
  const bounds = new google.maps.LatLngBounds();
  bounds.extend({
    lat: selectedClient?.location?.coordinates[1],
    lng: selectedClient?.location?.coordinates[0],
  });
  map.fitBounds(bounds);

}
    

  };

  return (

    <>
    


        <GoogleMap
          onLoad={handleOnLoad}
          onClick={() => setActiveMarker(null)}
          mapContainerStyle={{ width: "55rem", height: "40rem" }}
        center={{
          lat: selectedClient?.location?.coordinates[1],
          lng: selectedClient?.location?.coordinates[0],
        }}
        >
          {fieldEnggfmarkers.map(({ id, name, position, distance }) => {
            console.log({ position });

            return (
              <>

                  <Marker
                    key={id}
                    position={position}
                    onClick={() => handleActiveMarker(id)}
                    icon={icon}
                  >
                    {activeMarker === id ? (
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>{`${name}   (${distance / 1000} km)`}</div>
                      </InfoWindow>
                    ) : null}
                  </Marker>

               
              </>
            );
          })}

        <Marker
          opacity={0.4}
          position={{
            lat: selectedClient?.location?.coordinates[1],
            lng: selectedClient?.location?.coordinates[0],
          }}
        >



        </Marker>
        </GoogleMap>
    
    


    </>
    
  );
}
