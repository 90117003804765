import create from "zustand";
import { devtools } from "zustand/middleware";

const defectiveProductStore = (set) => ({
    defectiveProducts: [],

   
    setDefectiveProduct: (apiDefectiveProducts) => {
        set((state) => ({
            defectiveProducts: [...apiDefectiveProducts],
        }));
    },

    updateDefectiveProduct: (editData) => {
        set((state) => ({
            defectiveProducts: state.defectiveProducts.map((Product) => {
                if (Product._id === editData._id) {
                    return {
                       ...editData,
                        client: Product.client,
                        client_product: Product.client_product

                    };
                } else {
                    return Product;
                }
            }),
        }));
    },





    switchDefectiveProduct: (switchedData) => {
        set((state) => ({
            defectiveProducts: state.defectiveProducts.map((Product) => {
                if (Product._id === switchedData._id) {
                    return {
                        ...switchedData,
                       

                    };
                } else {
                    return Product;
                }
            }),
        }));
    },


     moveToBinInStore: (editData) => {
        set((state) => ({
            defectiveProducts: state.defectiveProducts.map((Product) => {
                if (Product._id === editData._id) {
                    return {
                       ...Product,
                        status:'moved-to-bin'

                    };
                } else {
                    return Product;
                }
            }),
        }));
    },

       moveToWarehouseInStore: (defective_product) => {

        set((state) => ({
            defectiveProducts: state.defectiveProducts.map((Product) => {
                if (Product._id === defective_product._id) {
                    return {
                       ...Product,
                        status:'closed'

                    };
                } else {
                    return Product;
                }
            }),
        }));
    },


    setReturnTaskSheduledInDefectiveStore: (defective_product) => {

        set((state) => ({
            defectiveProducts: state.defectiveProducts.map((Product) => {
                if (Product._id === defective_product) {
                    return {
                       ...Product,
                        standby_return_scheduled:true

                    };
                } else {
                    return Product;
                }
            }),
        }));
    },



    SwitchRentProductInDefective: (client_product) => {

        set((state) => ({
            defectiveProducts: state.defectiveProducts.map((Product) => {
                if (Product.client_product._id === client_product   && Product.status !== "closed"    ) {
                    return {
                        ...Product,
                        rent_product_switched: true

                    };
                } else {
                    return Product;
                }
            }),
        }));
    },
   



    addNewNoteInStore: (newNote) => {
      
    set((state) => ({
        defectiveProducts: state.defectiveProducts.map((Product) => {

            const user = JSON.parse(localStorage.getItem("user"));

     

            if (Product._id === newNote.product_id) {

                newNote.by_name = user.user.name

                Product.notes.push(newNote)

                return Product

            } else {
                return Product;
            }
        }),
    }));
},
  
});

const useDefectiveProductStore = create(devtools(defectiveProductStore));

export default useDefectiveProductStore;
;
