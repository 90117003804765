import { RedoOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Skeleton,
  Space
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import CostTable from "components/defectiveProduct/CostTable";
import DefectiveProductForm from "components/defectiveProduct/DefectiveProductForm";
import HistoricalDefectiveProductTable from "components/defectiveProduct/HistoricalDefectiveProductTable";
import NotesTimeline from "components/defectiveProduct/NotesTimeline";
import Price from "components/Price/price";
import { useEffect, useState } from "react";
import useCategoryStore from "stores/categoryStore";
import useClientStore from "stores/clientStore";
import useDefectiveProductStore from "stores/defectiveProductStore";
import useProductStore from "stores/productStore";
import useStandByReqStore from "stores/standByReqStore";
import useTaskStore from "stores/taskStore";
import ClientApi from "utils/composable/clientApi";
import DefectiveProductApi from "utils/composable/defectivePorductApi";
import ProductApi from "utils/composable/productsApi";
import TaskApi from "utils/composable/taskApi";

const DefectiveProduct = ({ currentUser }) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const [visible, setVisible] = useState(false);
  const [taskFormVisible, setTaskFormVisible] = useState("");
  const [editData, setEditData] = useState("");
  const [tableLoading, setTableLoadings] = useState(false);
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [fullNoteModalVisible, setFullNoteModalVisible] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  const [currentNotes, setCurrentNotes] = useState("");
  const [taskForm, setTaskForm] = useState(true);
  const [currentProductId, setCurrentProductId] = useState("");
  const [currentDefectiveProduct, setCurrentDefectiveProduct] = useState("");
  const [costModalVisible, setCostModalVisible] = useState(false);
  const [costDrawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [priceData, setPriceData] = useState("");
  const clients = useClientStore((state) => state.clients);
  const { getClients } = ClientApi();

  const categories = useCategoryStore((state) => state.categories);

  const {
    getTasks,
    updateTask,
    addTask,
    getEngineers,
    editTaskJobs,
    AddTaskJobs,
  } = TaskApi();

  const [reqModalVisible, setReqModalVisible] = useState(false);
  const [locationDetails, setLocationDetails] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [hideAddressBox, setHideAddressBox] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState();

  const [jobEditMode, setJobEditMode] = useState(false);
  const [jobAddMode, setJobAddMode] = useState(editData ? false : true);
  const [standByProductJob, setStandByProductJob] = useState(false);
  const [currentStandbyRequest, setCurrentStandbyReq] = useState(false);
  const [currentDefectiveProductId, setCurrentDefectiveProductId] =
    useState("");
  const [costEditData, setCostEditData] = useState("");
  const [priceEditData, setPriceEditData] = useState("");

  const circuttekProducts = useProductStore((state) => state.Products);
  const { getProducts } = ProductApi();
  const tasks = useTaskStore((state) => state.tasks);
  const request = useStandByReqStore((state) => state.standByReqs);
  const { submitCost, editCost } = DefectiveProductApi();
  const defectiveProducts = useDefectiveProductStore(
    (state) => state.defectiveProducts
  );

  const [form] = Form.useForm();

  //filtering pickedup nd received  products
  const pickedUpDefectiveProduct = defectiveProducts.filter(
    (defectiveProduct) => defectiveProduct.status == "picked-up"
  );
  const pendingPickUps = defectiveProducts.filter(
    (defectiveProduct) => defectiveProduct.status == "created"
  );
  //category store
  const { setCategories } = useCategoryStore((state) => ({
    setCategories: state.setCategories,
  }));

  const {
    getDefectiveProduct,
    updateDefectiveProduct,
    addNewNote,
    receiveDefectiveProduct,
  } = DefectiveProductApi();

  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  useEffect(() => {
    setTaskForm(true);
    if (!defectiveProducts.length) {
      setTableLoadings(true);

      getDefectiveProduct()
        .then(() => {
          setTableLoadings(false);
        })
        .finally(() => {
          setTableLoadings(false);
        });
    }
    if (!tasks.length) {
      getTasks();
    }

    if (!clients.length) {
      getClients();
    }

    if (!circuttekProducts.length) {
      getProducts();
    }
  }, []);

  const editHandler = (defectiveProduct) => {
    const formatDate = (date) => {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    };
    defectiveProduct.formattedDate = formatDate(defectiveProduct.due_date);

    console.log({ defectiveProduct });

    setEditData(defectiveProduct);
    setVisible(true);
  };

  const costEditHandler = (costDetails) => {
    console.log({ costDetails });

    form.setFieldsValue({
      description: costDetails.description,
      product_id: costDetails.product_id,

      item_price: costDetails.price?.item_price || "",
      qty: costDetails.price?.price?.qty || "",
      discount_percentage: costDetails.price?.discount_percentage || "",
      charges: costDetails.price?.charges || "",
      taxes: costDetails.price?.taxes.map((tax) => tax.title) || "",
    });

    setCostEditData(costDetails);

    const priceEditData = {};

    priceEditData.plan = {};

    priceEditData.plan.price = costDetails.price;

    setPriceEditData(priceEditData);

    setDrawerVisible(true);
  };

  const confirmDefectiveEditHandler = (product, form) => {
    setSubmitLoader(true);

    try {
      receiveDefectiveProduct(product)
        .then((msg) => {
          setSubmitLoader(false);
          setVisible(false);
          editSuccessful(msg);
          form?.resetFields();
        })
        .catch((msg) => {
          console.log(msg);
          setSubmitLoader(false);
          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const noteSubmitHandler = (newNote, productId) => {
    console.log(newNote, productId);
    setNoteLoading(true);
    const payload = {
      note: newNote.note,
      product_id: currentProductId,
    };
    addNewNote(payload)
      .then((msg) => {
        message.success(msg);
        setNoteModalVisible(false);
        setNoteLoading(false);
      })
      .catch((err) => {
        setNoteLoading(false);
      });
  };

  //create job functions

  const handleCancel = (form) => {
    setTaskFormVisible(false);
    setVisible(false);
    setReqModalVisible(false);

    if (standByProductJob) {
      editData.jobs.pop();
    }
  };

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData?.location?.coordinates);
  };

  const jobUpdateHandler = (key, form) => {
    let jobs = form.getFieldValue("jobs");
    const editedJob = jobs[key];
    console.log(jobs[key], "exact job");
    editedJob.job_id = editedJob._id;
    editedJob.task_id = editData._id;

    editedJob.job = {
      product: editedJob.productId,
      description: editedJob.description,
      status: editedJob.status,
      type: editedJob.type,
      assigned: editedJob.assigned[0]._id
        ? editedJob.assigned[0]._id
        : editedJob.assigned,
    };

    console.log({ editedJob });
    const jobUpdatePayload = {
      job_id: editedJob._id,
      task_id: editData._id,
      job: {
        product: editedJob.productId,
        description: editedJob.description,
        status: editedJob.status,
        type: editedJob.type,
        assigned: editedJob.assigned[0]._id
          ? editedJob.assigned[0]._id
          : editedJob.assigned,
        defective_product: editedJob.defective_product._id,
        stand_by_product_request: editedJob.stand_by_product_request._id,
      },
    };

    !editData.stand_by_product_request &&
      delete jobUpdatePayload.stand_by_product_request;
    console.log(jobUpdatePayload, "edit job payload");
    setJobLoading(true);
    editTaskJobs(jobUpdatePayload, key, editedJob)
      .then((resp) => {
        setJobLoading(false);
        setJobEditMode(false);
        message.success(resp);
      })
      .catch((err) => {
        setJobLoading(false);
        console.log(err);
        message.error(err);
      });
  };

  const jobSubmitHandler = (key, form, fieldEngineer) => {
    // if (typeof fieldEngineer == "object") {
    //   message.error("Please assign a field engineer ");
    //   return;
    // }

    const jobs = form.getFieldValue("jobs");

    jobs[jobs.length - 1].assigned = fieldEngineer;
    const newJob = jobs[jobs.length - 1];

    newJob.assigned = fieldEngineer;
    console.log(newJob, "New job");

    const newJobPayload = {
      task_id: editData._id,
      job: {
        product: newJob.productId,
        description: newJob.description,
        assigned: typeof fieldEngineer == "object" ? null : fieldEngineer,
        status: newJob.status,
        type: "product_delivery",
        defective_product: currentDefectiveProductId,
        stand_by_product_request: currentStandbyRequest,
      },
    };

    !currentStandbyRequest && delete newJobPayload.job.stand_by_product_request;
    setJobLoading(true);
    AddTaskJobs(newJobPayload)
      .then((task) => {
        editMode(task);
        setJobLoading(false);
        setJobAddMode(false);
        message.success("Job added");
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  const fillForm = () => {
    message.error("Please select Client and Date first ");
  };

  const getFieldEngineers = (clientId, date) => {
   
    const client = clients.find(
      (client) => client._id == clientId?._id || clientId
    );

    client.location = editData.location;

    if (!client || !date) {
      fillForm();
    } else {
      setAssignLoading(true);
      getEngineers(client, date)
        .then((resp) => {
          setAssignLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAssignLoading(false);
          message.error(err);
        });
    }
  };

  const editMode = (task) => {
    const formatDate = (date) => {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    };

    // task.formattedDate = formatDate(task.due_date);

    task.jobs = task.jobs.map((job) => {
      return {
        ...job,
        productId: job.product?._id,
      };
    });

    // //reversing lat and lng position for google map
    // task.location.coordinates = task.location?.coordinates?.reverse();
    setEditData(task);
    setTaskFormVisible(true);
  };

  const createDeliveryJob = (Repairedproduct) => {
    setCurrentStandbyReq(Repairedproduct.stand_by_product_request);
    setCurrentDefectiveProductId(Repairedproduct._id);

    const task = tasks.find((task) => task._id == Repairedproduct.task);

    const newJob = {
      product: Repairedproduct?.client_product,
      status: "unassigned",
      type: "product_delivery",
      assigned: "",
      stand_by_product_request: Repairedproduct?.stand_by_product_request,
    };

    task?.jobs.push(newJob);

    setJobAddMode(true);
    editMode(task);
    setStandByProductJob(true);
  };

  const assignToVendor = (value, defectiveProduct) => {
    const updatePayload = {
      product_id: defectiveProduct._id,
      vendor: value,
    };

    try {
      updateDefectiveProduct(updatePayload)
        .then((msg) => {
          setSubmitLoader(false);

          editSuccessful(msg);
        })
        .catch((msg) => {
          console.log(msg);

          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const changeDefectiveProductStatus = (value, defectiveProduct) => {
    const updatePayload = {
      product_id: defectiveProduct._id,
      status: value,
    };

    try {
      updateDefectiveProduct(updatePayload)
        .then((msg) => {
          setSubmitLoader(false);

          editSuccessful(msg);
        })
        .catch((msg) => {
          console.log(msg);

          errorMsg(msg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setEditData("");
    setVisible(false);
    setTaskFormVisible(false);
    setTaskFormVisible(false);
  };


  //refresh

  const [refreshLoading, setRefreshLoading] = useState(false);

  const refresh = () => {
    setRefreshLoading(true);
    getProducts();
    getDefectiveProduct().finally(() => {
      setRefreshLoading(false);
    });
  };

  // Cost Submit
  const onCostSubmit = (data) => {
    var finalPaylaod = {};

    if (costEditData) {
      finalPaylaod = {
        type: "update",
        cost_id: costEditData?._id,
        product_id: currentDefectiveProduct._id,
        repair_details: {
          description: data.description,
          price: { ...priceData },
        },
      };

      setLoading(true);
      editCost(finalPaylaod)
        .then((data) => {
          setLoading(false);

          message.success(data.message);
          currentDefectiveProduct.repair_details =
            data.defective_product.repair_details;

          setCostModalVisible(true);
        })
        .finally(() => {
          setLoading(false);
          setDrawerVisible(false);
        });
    } else {
      finalPaylaod = {
        product_id: currentDefectiveProduct._id,
        repair_details: {
          description: data.description,
          price: { ...priceData },
        },
      };

      setLoading(true);

      console.log({ finalPaylaod });

      submitCost(finalPaylaod)
        .then((data) => {
          setLoading(false);

          message.success(data.message);

          currentDefectiveProduct.repair_details =
            data.defective_product.repair_details;

          // setCostModalVisible(false);
          setDrawerVisible(false);
          setCostModalVisible(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Historical Defective Products"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />

<Skeleton active loading={tableLoading}>
        <HistoricalDefectiveProductTable
          currentUser={currentUser}
          setCostModalVisible={setCostModalVisible}
          currentDefectiveProduct={currentDefectiveProduct}
          setCurrentDefectiveProduct={setCurrentDefectiveProduct}
          changeDefectiveProductStatus={changeDefectiveProductStatus}
          assignToVendor={assignToVendor}
          createDeliveryJob={createDeliveryJob}
          setCurrentNotes={setCurrentNotes}
          setFullNoteModalVisible={setFullNoteModalVisible}
          setNoteModalVisible={setNoteModalVisible}
          noteModalVisible={noteModalVisible}
          noteSubmitHandler={noteSubmitHandler}
          editHandler={editHandler}
          noteLoading={noteLoading}
          setCurrentProductId={setCurrentProductId}
          confirmDefectiveEditHandler={confirmDefectiveEditHandler}
          submitLoader={submitLoader}
        />
      </Skeleton>

      <Drawer
        destroyOnClose={true}
        key={editData?._id}
        size="large"
        title={"Edit Defective Product"}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <DefectiveProductForm
          editData={editData}
          submitLoader={submitLoader}
          setVisible={setVisible}
          confirmEditHandler={confirmDefectiveEditHandler}
        />
      </Drawer>

      <Modal
        destroyOnClose={true}
        footer={[]}
        style={{ marginLeft: "10rem" }}
        centered
        visible={fullNoteModalVisible}
        onOk={() => {
          setFullNoteModalVisible(false);
        }}
        onCancel={() => {
          console.log("called");
          setFullNoteModalVisible(false);
        }}
        width={850}
      >
        <Divider>Notes</Divider>
        <NotesTimeline
          noteSubmitHandler={noteSubmitHandler}
          currentProductId={currentProductId}
          currentNotes={currentNotes}
          noteLoading={noteLoading}
        />
      </Modal>

      

      <Drawer
        style={{ zIndex: 100 }}
        destroyOnClose={true}
        size={"large"}
        title={
          editData ? `Update Task ${editData.task_number} ` : "Cost Details"
        }
        placement="right"
        onClose={() => {
          form.setFieldsValue({
            description: "",
            item_price: "",
            qty: "",
            discount_percentage: "",
            charges: "",
            taxes: [],
          });
          setPriceEditData("");
          setDrawerVisible(false);
          setCostModalVisible(true);
        }}
        visible={costDrawerVisible}
      >
        <Form
          form={form}
          name="note"
          onFinish={onCostSubmit}
          autoComplete="off"
        >
          <Space direction="vertical">
            <Form.Item
              label="Description"
              name="description"
              labelCol={{ span: 24, offset: 0 }}
              style={{ width: "42rem" }}
              rules={[
                {
                  required: true,
                  message: "Description",
                },
              ]}
            >
              <TextArea
                style={{ width: "42rem" }}
                placeholder="Enter repairing item "
                type={"text"}
              />
            </Form.Item>

            <Price priceEditData={priceEditData} setPriceData={setPriceData} />

            <Form.Item
              hidden
              name="product_id"
              labelCol={{ span: 24, offset: 0 }}
              style={{ width: "22rem" }}
            >
              <Input value={currentDefectiveProduct.Id} type={"text"} />
            </Form.Item>

            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ marginTop: "1rem", float: "right" }}
            >
              {costEditData ? "Confirm" : "Submit"}
            </Button>
          </Space>
        </Form>
      </Drawer>

      <Modal
        destroyOnClose={true}
        title={"Enter Repairing Details"}
        footer={[]}
        centered
        visible={costModalVisible}
        onOk={() => {
          setCostModalVisible(false);
          // handleOkay(currentDefectiveProduct);
        }}
        onCancel={() => {
          setCostModalVisible(false);
          handleCancel();
        }}
        width={1400}
      >
        <CostTable
          setCostModalVisible={setCostModalVisible}
          editCost={editCost}
          costEditHandler={costEditHandler}
          setDrawerVisible={setDrawerVisible}
          currentDefectiveProduct={currentDefectiveProduct}
        ></CostTable>
      </Modal>
    </>
  );
};

export default DefectiveProduct;
