import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Select, Space, Switch } from "antd";
import useCategoryStore from "stores/categoryStore";
import React, { useEffect, useState } from "react";
import TemplateApi from "utils/composable/templateApi";
import useTemplateStore from "stores/templateStore";
import TextArea from "antd/lib/input/TextArea";
import useProductStore from "stores/productStore";

const StandByForm = ({
  submitHandler,
  submitLoader,
  editData,
  setVisible,
  confirmEditHandler,
}) => {
  const templates = useTemplateStore((state) => state.templates);

  const [form] = Form.useForm();
  const [currentFields, setCurrentFields] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState([]);
  const { addTemplate, getTemplates, updateTemplates } = TemplateApi();
  const products = useProductStore((state) => state.Products);

  const statusOptions = [
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  const circutekProducts = useProductStore((state) => state.Products);

  //creating circutek stock product list
  const stockProducts = circutekProducts.map((product) => {
    console.log({ product });

    return {
      label: `${product.name}             ( ${
        product?.warehouse?.title || ""
      } ) `,
      value: product._id,
      key: product._id,
    };
  });

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        standby_product: editData?.product?._id || "",
      });
    } else {
      form.setFieldsValue({
        fields: [],
        name: "",
        category: "",
      });
    }
  }, [editData]);

  useEffect(() => {
    if (!templates.length) {
      // setTableLoadings(true);

      try {
        getTemplates()
          .then(() => {
            // setTableLoadings(false);
          })
          .catch((err) => {
            console.log(err);

            // setTableLoadings(false);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const onFinish = (values) => {
    console.log(values, "on finish values");

    if (!editData) {
      submitHandler(values, form);
    } else {
      confirmEditHandler(
        {
          ...editData,
          product: values.standby_product,
          request_id: editData._id,
          status: values.status,
        },
        form
      );
    }
  };

  const onTemplateChange = (e) => {
    console.log(e);

    const selectedTemplate = templates.find((template) => template._id == e);

    console.log(selectedTemplate.fields);
    setCurrentTemplate(selectedTemplate);
    setCurrentFields(selectedTemplate.fields);

    const currentField = selectedTemplate.fields.map((field) => {
      return {
        fName: field.name,
        dataType: field.data_type,
        _id: field._id,
      };
    });

    console.log({ currentField });

    form.setFieldsValue({
      fields: currentField,
    });
  };

  return (
    <Form
      form={form}
      key={editData?._id}
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        specifications: editData ? editData.specifications : "",
      }}
    >
      <Form.Item
        key={editData?._id}
        initialValue={editData?.status || ""}
        labelCol={{ span: 24, offset: 0 }}
        name="status"
        label="Request Status"
        rules={[
          {
            required: true,
            message: "Missing Category",
          },
        ]}
      >
        <Select key={editData?._id} options={statusOptions} />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        name="standby_product"
        label="Standby Product"
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select  Standby Product"
          optionFilterProp="children"
          options={stockProducts}
          // onChange={onTypeChange}
        ></Select>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 18,
          span: 10,
        }}
      >
        <Space size={8}>
          <Button onClick={() => setVisible(false)}>Cancel</Button>
          {editData ? (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Confirm
            </Button>
          ) : (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Submit
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default StandByForm;
