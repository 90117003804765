import { Button, Divider, Form, Input, Select, Space, Switch } from "antd";

import React, { useEffect, useState } from "react";

import TextArea from "antd/lib/input/TextArea";
import useProductStore from "stores/productStore";
import useWarehouseStore from "stores/warehouseStore";
import useClientStore from "stores/clientStore";
import Price from "components/Price/price";
import humanize from "utils/formate-status";

const Paymentform = ({
  submitHandler,
  submitLoader,
  editData,
  setVisible,
  confirmEditHandler,
}) => {
  const [form] = Form.useForm();
  const [currentFields, setCurrentfields] = useState([]);

  const [priceData, setPriceData] = useState("");
  const [clientList, setClientList] = useState([]);

  //productStore
  const products = useProductStore((state) => state.Products);

  const clients = useClientStore((state) => state.clients);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        item_price: Math.trunc(editData?.plan?.price?.item_price) || "",
        qty: editData?.plan?.price?.qty || "",
        discount_percentage: editData?.plan?.price?.discount_percentage || "",
        charges: Math.trunc(editData?.plan?.price?.charges) || "",
        taxes: editData?.plan?.price?.taxes.map((tax) => tax.title) || "",
        description: editData.description,
      });
    }

    console.log({ editData });

    const clientList = clients.map((client) => {
      return {
        label: ` ${client.name}   (${humanize(client.status)})`,
        value: client._id,
        key: client._id,
      };
    });

    setClientList(clientList);
  }, []);

  useEffect(() => {
    console.log(priceData);
  }, [priceData]);

  const onFinish = (values) => {
    if (editData) {
      console.log({ values });
      console.log({ priceData });
      console.log({ editData });

      const finalPayload = {};

      finalPayload.price = priceData;
      finalPayload.price.item_price =
        priceData?.item_price || editData?.plan?.price?.item_price;
      finalPayload.price.charges =
        priceData?.charges || editData?.plan?.price?.charges;
      finalPayload.payment_id = editData.payment_id;
      finalPayload.description = values.description;
      console.log(finalPayload, "Values");

      confirmEditHandler(finalPayload);
    } else {
      values.price = priceData;
      submitHandler(values);
    }
  };

  const fuzzyFilter = (query, product) => {
    if (query !== "") {
      console.log(query);
      var get_bigrams, j, len, obj, relevance, results, string_similarity;

      get_bigrams = function (string) {
        console.log({ string });
        var i, j, ref, s, v;
        s = string.toLowerCase();
        v = new Array(s.length - 1);
        for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
          v[i] = s.slice(i, i + 2);
        }
        return v;
      };

      string_similarity = function (str1, str2) {
        console.log({ str1, str2 });

        var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
        if (str1.length > 0 && str2.length > 0) {
          pairs1 = get_bigrams(str1);
          pairs2 = get_bigrams(str2);
          union = pairs1.length + pairs2.length;
          hit_count = 0;
          for (j = 0, len = pairs1.length; j < len; j++) {
            x = pairs1[j];
            for (k = 0, len1 = pairs2.length; k < len1; k++) {
              y = pairs2[k];
              if (x === y) {
                hit_count++;
              }
            }
          }
          if (hit_count > 0) {
            return (2.0 * hit_count) / union;
          }
        }
        return 0.0;
      };

      if (query.length >= 2) {
        results = [];

        for (j = 0, len = clients.length; j < len; j++) {
          var client = clients[j];

          console.log({ client });
          relevance = string_similarity(query, client?.name || "");

          obj = {
            ...client,
            relevance: relevance,
          };
          results.push(obj);
        }

        console.log({ results });
        var sortedResults = results.filter((result) => result.relevance > 0.1);
        sortedResults = sortedResults
          .sort((a, b) => a.relevance - b.relevance)
          .reverse();
        console.log({ sortedResults });

        //  clientList= ([...sortedResults])

        let clientList = sortedResults.map((client) => {
          return {
            label: `${client.name}`,
            value: client._id,
            key: client._id,
          };
        });

        setClientList(clientList);
      } else {
        let clientList = clients.map((client) => {
          return {
            label: `${client.name}`,
            value: client._id,
            key: client._id,
          };
        });

        setClientList(clientList);
      }
    }
  };

  return (
    <Form
      form={form}
      key={editData?._id}
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
    >
      {!editData && (
        <Space>
          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            style={{ width: "21rem", transition: ".5s" }}
            name="client"
            label="Client"
            rules={[{ required: true }]}
            // disabled={editData}
            hidden={editData}
          >
            <Select
              disabled={editData}
              style={{ width: "100%", maxWidth: "100%" }}
              showSearch
              placeholder="Select a client"
              optionFilterProp="children"
              // onChange={onClientChange}
              onSearch={(input) => {
                fuzzyFilter(input);
              }}
              options={clientList}
              filterOption={(input, client) => {
                return true;
              }}
            ></Select>
          </Form.Item>

          <Form.Item
            hidden={editData}
            // disabled={editData}
            labelCol={{ span: 24, offset: 0 }}
            style={{ width: "21rem", transition: ".5s", marginLeft: ".5rem" }}
            name="type"
            label="Type"
            rules={[{ required: true }]}
            initialValue={"other"}
          >
            <Select
              disabled={editData}
              // hidden={editData}
              style={{ width: "100%", maxWidth: "100%" }}
              defaultValue={"other"}
              placeholder="Select a client"
              optionFilterProp="children"
              // onChange={onClientChange}
            >
              <Select.Option value="other">Other</Select.Option>
              <Select.Option value="plan_creation">Plan Creation</Select.Option>
              <Select.Option value="additional_plan">
                Additional Plan
              </Select.Option>
              <Select.Option value="plan_recurring">
                Plan Recurring
              </Select.Option>
              <Select.Option value="sale">Sale</Select.Option>
              <Select.Option value="rent_creation">Rent Creation</Select.Option>
              <Select.Option value="rent_recurring">
                Rent Recurring
              </Select.Option>
              <Select.Option value="defective_repair_cost">
                Defective Repair Cost
              </Select.Option>
              <Select.Option value="onsite_repair_cost">
                Onsite Repair Cost
              </Select.Option>
            </Select>
          </Form.Item>
        </Space>
      )}

      <Price priceEditData={editData} setPriceData={setPriceData} />

      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        label="Description"
        name="description"
        initialValue={""}
      >
        <TextArea />
      </Form.Item>

      {currentFields?.map((field, index) => (
        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          key={field._id}
          label={editData ? field?.name.toUpperCase() : field?.name}
          name={field?.name?.toLowerCase()}
          // hidden={field.name.toLowerCase() == "template"}
          rules={[
            {
              required: true,
              message: "Missing ",
            },
          ]}
          initialValue={field?.value || ""}
        >
          <Input type={field.data_type} />
        </Form.Item>
      ))}

      <Form.Item
        wrapperCol={{
          offset: 16,
          span: 10,
        }}
      >
        <Space size={8}>
          <Button key={editData._id} onClick={() => setVisible(false)}>
            Cancel
          </Button>
          {editData ? (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Update
            </Button>
          ) : (
            <Button type="primary" htmlType="submit" loading={submitLoader}>
              Submit
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default Paymentform;
