import create from "zustand";
import { devtools } from "zustand/middleware";

const planStore = (set) => ({
  plans: [],

  foundPlan: {},
  addPlan: (newPlan) => {
    set((state) => ({
      plans: [newPlan, ...state.plans],
    }));
  },
  setPlan: (apiPlans) => {
    set((state) => ({
      plans: [...apiPlans],
    }));
  },

  updatePlan: (editData) => {
    set((state) => ({
      plans: state.plans.map((plan) => {
        if (plan._id === editData._id) {
          return {
            ...plan,
            title: editData.title,
            description: editData.description,
          };
        } else {
          return plan;
        }
      }),
    }));
  },
    togglePlan: (togglePlan) => {
        set((state) => ({
            plans: state.plans.map((plan) => {
                if (plan._id === togglePlan._id) {
                    return {
                        ...plan,
                        is_active: !plan.is_active,
                    };
                } else {
                    return plan;
                }
            }),
        }));
    },
});

const usePlanStore = create(devtools(planStore));

export default usePlanStore;
