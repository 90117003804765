import { message } from "antd";
import axios from "axios/axios";
import useProductStore from "stores/productStore";
import authHeader from "utils/services/auth-header";


const ProductApi = () => {


  
    const {
        addProductToStore, setProductsToStore, updateProductInStore } = useProductStore((state) => ({
        addProductToStore: state.addProduct,
        setProductsToStore:state.setProduct,
        updateProductInStore:state.updateProduct
     
    }));


    const addProduct = async (data) => {

  
        return new Promise((resolve, reject) => {
            axios
                .post("/product", data, {
                    headers: authHeader(),
                })
                .then((resp) => {
                
                    addProductToStore(resp.data.product);
                    resolve(resp.data.message);
                })
                .catch((error) => {
                    console.log(error.response.data.message);
                    message.error(error.response.data.message)
                    reject(error.response.data.message);
                });
        });
    };

    const getProducts = async () => {


            return new Promise((resolve, reject) => {
                axios
                    .get("/products", { headers: authHeader() })
                    .then((response) => {
                    
                        setProductsToStore(response.data.products);
                        resolve();
                    })
                    .catch((err) => {
                        message.error(err.response.data.message)
                        console.log(err);

                        reject(err.response.data.message);
                    });
            });
        // }


   
}



    const getProductsWithStatusAndUse = async (status,use) => {


        return new Promise((resolve, reject) => {
            axios
                .get("/products?status="+status+'&use='+use, { headers: authHeader() })
                .then((response) => {
           
                    setProductsToStore(response.data.products);
                    resolve();
                })
                .catch((err) => {

                    console.log(err);

                    reject(err.response.data.message);
                });
        });
        // }
    }


    const checkBarcodeApi = async (barcode) => {

   
        return new Promise((resolve, reject) => {
            axios
                .post("/check-barcode", {barcode:barcode}, { headers: authHeader() })
                .then((resp) => {
            
              
                    resolve(resp.data.message);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err.response.data.message);
                });
        });

    };






    const updateProducts = async (data) => {    
    
        return new Promise((resolve, reject) => {
            axios
                .put("/product", data, { headers: authHeader() })
                .then((resp) => {
                
                    updateProductInStore (resp.data.product);
                    resolve(resp.data.message);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err.response.data.message);
                });
        });
    };



    return {
        addProduct, getProducts, updateProducts, getProductsWithStatusAndUse, checkBarcodeApi
};
};

export default ProductApi;
