import {
  Button,
  Descriptions,
  Divider,
  Drawer,

  message,

  PageHeader,
  Skeleton,
} from "antd";

import GoogleMap from "components/googleMap/GoogleMap";
import TaskForm from "components/task/TaskForm";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useClientStore from "stores/clientStore";
import useDefectiveProductStore from "stores/defectiveProductStore";
import useTaskStore from "stores/taskStore";
import useUserStore from "stores/teamStore";
import ClientApi from "utils/composable/clientApi";
import DefectiveProductApi from "utils/composable/defectivePorductApi";
import TaskApi from "utils/composable/taskApi";
import TeamApi from "utils/composable/teamApi";
import WareHouseApi from "utils/composable/warehouseApi";
import humanize from "utils/formate-status";

import DefectiveProductRepairTable from "./DefectiveProductRepairTable";

const DefectiveProductDetails = () => {



  const [loadings, setLoadings] = useState(false);



  const { state } = useLocation();

  console.log({state});
  const users = useUserStore((state) => state.users);
  const clients = useClientStore((state) => state.clients);

  const {  closeStandByrequest } = WareHouseApi();
  const [currentTask, setCurrentTask] = useState({});



  const { getDefectiveProduct } = DefectiveProductApi();
  const defectiveProducts = useDefectiveProductStore(
    (state) => state.defectiveProducts
  );

  //notification
 


  const fillForm = () => {
    message.error("Please select Client and Date first ");
  };
 

  useEffect(() => {
    console.log(state);
  }, []);

  const [visible, setVisible] = useState(false);
  const [tableLoading, setTableLoadings] = useState(false);
 
  const [locationDetails, setLocationDetails] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [hideAddressBox, setHideAddressBox] = useState(true);
 
  const [assignLoading, setAssignLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState();
  const [editData, setEditData] = useState("");
  const [jobEditMode, setJobEditMode] = useState(false);
  const [jobAddMode, setJobAddMode] = useState(editData ? false : true);
  const [currentStandbyRequest, setCurrentStandbyReq] = useState(false);
  const [jobViewMode, setJobViewMode] = useState(false);
  const [currentDefectiveProductId, setCurrentDefectiveProductId] =
    useState("");
  const [standByProductJob, setStandByProductJob] = useState(false);
  const [defectiveProductjob, setDefectiveProductJob] = useState(true);

  // for cchanging googlemap class name
  const [taskForm, setTaskForm] = useState(true);

  //composable
  const { getClients } = ClientApi();
  const { getUsers } = TeamApi();
  const {
   
    getTasks,
    getEngineers,
    editTaskJobs,
    AddTaskJobs,
  } = TaskApi();
  const tasks = useTaskStore((state) => state.tasks);

  const handleCancel = (form) => {
    setVisible(false);
    setEditData(false);
  };

  useEffect(() => {
    getClients();
    getUsers();

    if (!tasks.length) {
      setTableLoadings(true);

      getTasks()
        .then((resp) => {
          console.log(resp);
          const task = tasks.find((task) => task._id == state.task);

          setCurrentTask(task)
        })
        .finally(() => {
          setTableLoadings(false);
        });
    }else{

      const task = tasks.find((task) => task._id == state.task);

      setCurrentTask(task)
    }

    setTaskForm(true);
  }, []);

  
  const editMode = (task) => {
    const formatDate = (date) => {
      let d = new Date();
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    };

    console.log(task?.due_date,'due date');

    // task.formattedDate = formatDate(task?.due_date||"");

    console.log(task, "Editing task");

    task.jobs = task.jobs.map((job) => {
      console.log(job, "manupulating job");
      return {
        ...job,
        productId: job.product?._id,

      };
    });

    console.log(task, "edited task ");
    // //reversing lat and lng position for google map
    // task.location.coordinates = task.location?.coordinates?.reverse();
    setEditData(task);
    setVisible(true);
  };

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData?.location?.coordinates);
  };

  
  const jobUpdateHandler = (key, form) => {
    let jobs = form.getFieldValue("jobs");
    const editedJob = jobs[key];
    console.log(jobs[key], "exact job");
    editedJob.job_id = editedJob._id;
    editedJob.task_id = editData._id;

    editedJob.job = {
      product: editedJob.productId,
      description: editedJob.description,
      status: editedJob.status,
      type: editedJob.type,
      assigned: editedJob.assigned[0]._id
        ? editedJob.assigned[0]._id
        : editedJob.assigned,
    };

    console.log({ editedJob });
    const jobUpdatePayload = {
      job_id: editedJob._id,
      task_id: editData._id,
      job: {
        product: editedJob.productId,
        description: editedJob.description,
        status: editedJob.status,
        type: editedJob.type,
        assigned: editedJob.assigned[0]._id
          ? editedJob.assigned[0]._id
          : editedJob.assigned,
        defective_product: editedJob.defective_product._id,
        stand_by_product_request: editedJob.stand_by_product_request._id,
      },
    };

    !editData.stand_by_product_request &&
      delete jobUpdatePayload.stand_by_product_request;
    console.log(jobUpdatePayload, "edit job payload");
    setJobLoading(true);
    editTaskJobs(jobUpdatePayload, key,editedJob)
      .then((resp) => {
        setJobLoading(false);
        setJobEditMode(false);
        message.success(resp);
      })
      .catch((err) => {
        setJobLoading(false);
        console.log(err);
        message.error(err);
      });
  };

  const jobSubmitHandler = (key, form, fieldEngineer) => {


    
    const jobs = form.getFieldValue("jobs");
    console.log(jobs);
    console.log(jobs[jobs.length - 1], "New job");
    jobs[jobs.length - 1].assigned = fieldEngineer;
    const newJob = jobs[jobs.length - 1];
    console.log({ fieldEngineer });
    newJob.assigned = fieldEngineer;
    console.log(newJob, "New job");

    const newJobPayload = {
      task_id: editData._id,
      job: {
        product: newJob.productId,
        description: newJob.description,
        assigned: typeof fieldEngineer == "object" ? null : fieldEngineer,
        type: "product_delivery",
        defective_product: currentDefectiveProductId,
        stand_by_product_request: currentStandbyRequest,
      },
    };

    console.log(newJobPayload, "new job payload");
    !currentStandbyRequest && delete newJobPayload.job.stand_by_product_request;
    setJobLoading(true);

    AddTaskJobs(newJobPayload)
      .then((task) => {
        editMode(task);
        setJobLoading(false);
        setJobAddMode(false);
        getDefectiveProduct();
        const currentDefectiveProduct = defectiveProducts.find(
          (defectiveProduct) => (defectiveProduct._id = state._id)
        );
        currentDefectiveProduct.status = "Ready To Return";
        state.status = "Ready to return";
        message.success("Job added");
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  const getFieldEngineers = (clientId, date) => {

    const client = clients.find(
      (client) => client._id == clientId?._id || clientId
    );

    client.location = editData.location;
  
    if (!client || !date) {
      fillForm();
    } else {
      setAssignLoading(true);
      getEngineers(client, date)
        .then((resp) => {
          setAssignLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAssignLoading(false);
          message.error(err);
        });
    }
  };

  const createDeliveryJob = (Repairedproduct) => {
    
    setCurrentStandbyReq(Repairedproduct.stand_by_product_request);
    setCurrentDefectiveProductId(Repairedproduct._id);

    console.log({ Repairedproduct });

    const task = tasks.find((task) => task._id == Repairedproduct.task);
    console.log(task, "found req task");

    const newJob = {
      product: Repairedproduct?.client_product,
      status: "unassigned",
      type: "product_delivery",
      assigned: "",
      stand_by_product_request: Repairedproduct?.stand_by_product_request,
      description: state?.client_product?.barcode=='0000'? Repairedproduct.description:''
    };

    // task?.jobs.push(newJob);

    const currentTask = structuredClone(task)
    currentTask.jobs.push(newJob)

    console.log(task, "Task after Adding enw Job");
    setJobAddMode(true);
    editMode(currentTask);
    setStandByProductJob(true);
  };

  

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={state?.client_product?.name}
          subTitle={state?.client_product?.barcode}
          extra={[



          
            <Button
              onClick={() => {
                editMode(tasks.find((task) => task._id == state.task));
                setJobViewMode(true);
                setJobAddMode(false);
           
                setStandByProductJob(false);
              }}
          
            >
              Show Task
            </Button>, 
            (state.status == "completed" || state.status == "damaged" || state.status == "switched")&& (
              <Button
               hidden={state.rent_product_switched}
                onClick={() => createDeliveryJob(state)}
                key="1"
                type="primary"
              >
                {" "}
                Create Job{" "}
              </Button>
            )
          ]}
        >
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Client Name"
            >
              {state.client.name}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Defective Product"
            >
              {state.client_product.name} -{state.client_product?.barcode||""}
            </Descriptions.Item>

           

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Status"
            >
              <a>{humanize(state.status)}</a>
            </Descriptions.Item>

            {state?.picked_up_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Picked Up on"
              >
                {moment(state?.picked_up_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                - {users.find((user) => user._id == state?.picked_up_by)?.name}
              </Descriptions.Item>
            )}

            {state?.delivered_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Delivered on"
              >
                {moment(state?.delivered_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                -{" "}
                {
                  users.find((user) => user._id == state?.state?.delivered_by)
                    ?.name
                }
              </Descriptions.Item>
            )}

            {state?.returned_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label=" Returned on "
              >
                {moment(state?.returned_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                - {users.find((user) => user._id == state?.returned_by)?.name}
              </Descriptions.Item>
            )}

            {state?.closed_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label=" Closed on "
              >
                {moment(state?.closed_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                - {users.find((user) => user._id == state?.closed_by)?.name}
              </Descriptions.Item>
            )}

           
            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Task No"
            >
              <a>{currentTask?.task_number}</a>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      </div>

      <Skeleton active loading={false}>
        <Divider style={{ fontWeight: "bold" }}>Repair Cost Details</Divider>

        <DefectiveProductRepairTable RepairDetails={state?.repair_details} />

      

        
      </Skeleton>

      <Drawer
        destroyOnClose={true}
        width={"100vw"}
        title={
          defectiveProductjob
            ? "Create Product Deilvery Job"
            : editData && !jobViewMode
              ? `Update Task ${editData.task_number} `
            : editData && jobViewMode
                ? `Related Task ${editData.task_number} `
            : "Create Task"
        }
        size={"large"}
        placement="right"
        onClose={handleCancel}
        visible={visible}>

        <Divider style={{ padding: "1rem" }}>Enter Task Details</Divider>

        <TaskForm
          jobViewMode={jobViewMode}
          standByProductJob={standByProductJob}
          jobSubmitHandler={jobSubmitHandler}
          jobUpdateHandler={jobUpdateHandler}
          locationDetails={locationDetails}
          // submitHandler={submitHandler}
          ChangeLocation={ChangeLocation}
          editData={editData}
          // updateHandler={updateHandler}
          getFieldEngineers={getFieldEngineers}
          assignLoading={assignLoading}
          setJobLoading={setJobLoading}
          jobLoading={jobLoading}
          setJobEditMode={setJobEditMode}
          jobEditMode={jobEditMode}
          jobAddMode={jobAddMode}
          setJobAddMode={setJobAddMode}
          defectiveProductjob={defectiveProductjob}
        />

        <Divider style={{ marginBottom: "1rem" }}>Client Location</Divider>

        <GoogleMap
          taskForm={taskForm}
          hideAddressBox={hideAddressBox}
          locationEditData={locationEditData}
          setLocationDetails={setLocationDetails}
        />
      </Drawer>
    </>
  );
};

export default DefectiveProductDetails;
