import { Button, Input, Popconfirm, Space, Switch, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import useUserStore from "stores/teamStore";
import privileges from "utils/constants/privileges";
import {
  EditOutlined,
  SearchOutlined,
  UndoOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";
import TeamApi from "utils/composable/teamApi";

const ArchivedTeamTable = ({ editHandler, currentUser }) => {
  const { getArchivedUser, restoreUser } = TeamApi();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteId, setCurrentDeleteId] = useState("");
  var archivedUsers = useUserStore((state) => state.archivedUser);
  const [allArchivedUsers, setAllArchivedUsers] = useState([]);

  // const activeUsers = users.filter((user) => user.archived);

  useEffect(() => {
    if (!archivedUsers.length) {
      getArchivedUser();
    }
  }, []);

  useEffect(() => {
    setAllArchivedUsers(archivedUsers.filter((user) => user.archived));
  }, [archivedUsers]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "0",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, users) => {
        return users?.name.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "1",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, users) => {
        return users?.email.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "2",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "2",
      render: (_, users) => {
        const str1 = users?.role;

        var i,
          frags = str1.split("-");
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(" ");
      },
      filters: [
        { text: "Engineer", value: "engineer" },
        { text: "Super Admin", value: "super-admin" },
        { text: "Admin", value: "admin" },
        { text: "Manager", value: "manager" },
        { text: "Purchase", value: "purchase" },
        { text: "Account", value: "account" },
      ],

      onFilter: (value, record) => record.role.includes(value),
    },

    {
      title: "Action",
      dataIndex: "users",
      key: "3",
      render: (_, users) => {
        return (
          <Space size={"large"}>
            <Tooltip title={"Edit Team"}>
              <EditOutlined
                hidden={!currentUser.privileges.includes("team_management")}
                onClick={() => {
                  editHandler(users);
                }}
              />
            </Tooltip>

            <Tooltip title={"Restore User"}>
              <Popconfirm
                title="Restore this user??"
                onConfirm={() =>
                  restoreUser(
                    users._id,
                    privileges.privileges
                      .filter((privilege) =>
                        privilege.role.includes(users.role)
                      )
                      .map((privilege) => {
                        return privilege.value;
                      })
                  )
                }
              >
                <UndoOutlined
                  hidden={
                    !currentUser.privileges.includes("payment_management")
                  }
                  loading={submitLoading && deleteId == users._id}
                  onClick={() => setCurrentDeleteId(users._id)}
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowClassName={"rowFontColor"}
        columns={columns}
        dataSource={allArchivedUsers}
        pagination={true}
        rowKey={"_id"}
      />
    </>
  );
};

export default ArchivedTeamTable;
