import create from "zustand";
import { devtools } from "zustand/middleware";

const templateStore = (set) => ({
    templates: [],

    addTemplate: (newTemplate) => {
        set((state) => ({
            templates: [newTemplate, ...state.templates],
        }));
    },
    setTemplate: (apiTemplates) => {
        set((state) => ({
            templates: [...apiTemplates],
        }));
    },

    updateTemplate: (editData) => {
        set((state) => ({
            templates: state.templates.map((template) => {
                if (template._id === editData._id) {
                    return {
                        ...editData
                        
                    };
                } else {
                    return template;
                }
            }),
        }));
    },

});

const useTemplateStore = create(devtools(templateStore));

export default useTemplateStore
