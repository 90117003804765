import React from "react";

import CSVReader from "react-csv-reader";

const Import = () => {
  const readCSVFile = (rows) => {
    var JsonFormat = [];  
    var jsnonObject = {};
    var currentClient;
    var currentDate;
   

    for (let i = 1; i < rows.length; i++) {
      if (rows[i][0] !== "") {
        if (rows[i][0] !== "New Ref" || rows[i][0] !== "Agst Ref") {
          jsnonObject.date = rows[i][0];
          jsnonObject.client = rows[i][1];
          currentClient = jsnonObject.client;
          currentDate = jsnonObject.date;

   

      

          continue;
        }
      } else {
   

        if (rows[i][1] == "New Ref" || rows[i][1] == "Agst Ref") {
          jsnonObject.date = currentDate;
          jsnonObject.client = currentClient;
          jsnonObject.invoice = rows[i][2];
          jsnonObject.amount = rows[i][3];

          JsonFormat.push(jsnonObject);
          jsnonObject = {};
        }
        i++;
      }
    }

 
  };

  return (
    <CSVReader
      onFileLoaded={(data, fileInfo, originalFile) => {
        console.dir(data, fileInfo, originalFile);

        readCSVFile(data);
      }}
    />
  );
};

export default Import;
