import { message } from "antd";
import axios from "axios/axios";
import useSalesStore from "stores/salesStore";
import authHeader from "utils/services/auth-header";




const SalesApi = () => {

    const { 
        addSaleToStore, setSalesToStore } = useSalesStore((state) => ({
        addSaleToStore: state.addSale,
        setSalesToStore:state.setSales,
        // updateProductInStore:state.updateProduct
     
    }));

    const addSale = async (data) => {

    

        const   {
            specifications,
            name,
            barcode,
            brand,
            client,
            sold_date,
            category,
            description,
            warranty_date,
            vendor,
            price,
            saleProductId
        } = data;


        return new Promise((resolve, reject) => {
            axios.post("/product/sell", {
                    specifications,
                    name,
                    barcode,
                    brand,
                    client,
                    sold_date,
                    category,
                    description,
                    warranty_date,
                    vendor,
                    price,
                     saleProductId
                }, {
                    headers: authHeader(),
                })
                .then((resp) => {
            
                    // resp.data.sale.brand = data.clientProduct.brand
                    resp.data.sale.client=data.clientData
                    // resp.data.sale.client=data.clientData
                    // resp.data.sale.product = data.clientData
                    resp.data.sale.client_product = resp.data.clientProduct._id
                    // resp.data.sale.clientProduct = resp.data.clientProduct._id

              
                    addSaleToStore(resp.data.sale);
              
                    resolve(resp.data.message); })
                .catch((error) => {
                        console.log(error);
              
                    reject(error.response.data.message);
                });
        });
    };


    const getSales = async () => {

     

            return new Promise((resolve, reject) => {
                axios
                    .get("/product/sales", { headers: authHeader() })
                    .then((response) => {
                 
                        setSalesToStore(response.data.sales);
                        resolve();
                    })
                    .catch((err) => {

                        console.log(err);
                        message.error(err.response.data.message)
                        reject(err.response.data.message);
                    });
            });
        // }

   
}

    const updateSales = async (data) => {    

        return new Promise((resolve, reject) => {
            axios
                .put("/product", data, { headers: authHeader() })
                .then((resp) => {
                 
                    resolve(resp.data.message);
                })
                .catch((err) => {
                    console.log(err);
                    message.error(err.response.data.message)
                    reject(err.response.data.message);
                });
        });
    };



    return {addSale, getSales, updateSales}};

export default SalesApi;
