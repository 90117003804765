import { message } from "antd";
import axios from "axios/axios";
import useTemplateStore from "stores/templateStore";
import authHeader from "utils/services/auth-header";

const TemplateApi = () => {
  const { addTemplateToStore, setTemplateToStore, updateTemplateToStore } =
    useTemplateStore((state) => ({
      addTemplateToStore: state.addTemplate,
      setTemplateToStore: state.setTemplate,
      updateTemplateToStore: state.updateTemplate,
    }));



  const addTemplate = async (data) => {
 

    return new Promise((resolve, reject) => {
      axios
        .post("/product/template", data, { headers: authHeader() })

        .then((response) => {
       
          addTemplateToStore(response.data.template);
          resolve(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          message.error(error.response.data.message)
          reject(error.response.data.message);
        });
    });
  };



  const getTemplates = async () => {

      return new Promise((resolve, reject) => {
        axios
          .get("/product/templates", { headers: authHeader() })
          .then((response) => {
            setTemplateToStore(response.data.templates);
            resolve();
          })
          .catch((err) => {
            console.log(err);

            reject();
          });
      });

  };

  
  const updateTemplates = async (data) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/product/template", data, { headers: authHeader() })
        .then((response) => {
          updateTemplateToStore(response.data.template);
          resolve(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };

  return { addTemplate, getTemplates, updateTemplates };
};

export default TemplateApi;
