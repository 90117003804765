import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Statistic,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";

import React, { useEffect, useState } from "react";
import TemplateApi from "utils/composable/templateApi";
import useTemplateStore from "stores/templateStore";
import useProductStore from "stores/productStore";
import useWarehouseStore from "stores/warehouseStore";
import ClientLocationApi from "utils/composable/clientLocationApi";
import moment from "moment";

import TextArea from "antd/lib/input/TextArea";

const InvoiceForm = ({
  submitHandler,
  submitLoader,
  editData,
  setVisible,
  confirmEditHandler,
  bulkUpdates,
  handleBulkUpdate,
  selectedproducts,
  handleNewInvoiceSubmit,
  selectedproductsPriceDetails,
  handleInvoiceEdit,
  currentClient,
  setCurrentClient,
}) => {


  // const [currentPlanAmount, setCurrentPlanAmount] = useState("");
  const [form] = Form.useForm();



 

  const [paymentDetails, setPaymentDetails] = useState("");

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        description: editData?.description,
        client: editData?.client?.name,
        date: editData.date || "",
      });
    }else{

      form.setFieldsValue({

      date: moment(new Date()).format('YYYY-MM-DD'),

      });

    }
  }, [editData]);

  useEffect(() => {
    if (!editData) {
      const AmountReducer = (accumulator, currentValue) =>
        accumulator + currentValue?.price?.amount;
      const chargeReducer = (accumulator, currentValue) =>
        accumulator + currentValue?.price?.charges;
      const discountReducer = (accumulator, currentValue) =>
        accumulator + currentValue?.price?.discount;
      const priceReducer = (accumulator, currentValue) =>
        accumulator + currentValue?.price?.item_total;

      const taxReducer = (accumulator, currentValue) =>
        accumulator + currentValue?.price?.tax_amount;

      console.log(selectedproductsPriceDetails, "Price Details");

      const totalAmount = Math.trunc(
        selectedproductsPriceDetails.reduce(AmountReducer, 0)
      );
      const totalCharge = Math.trunc(
        selectedproductsPriceDetails.reduce(chargeReducer, 0)
      );
      const totalDiscount = Math.trunc(
        selectedproductsPriceDetails.reduce(discountReducer, 0)
      );
      const totalprice = Math.trunc(
        selectedproductsPriceDetails.reduce(priceReducer, 0)
      );

      const totalTaxAmount = Math.trunc(
        selectedproductsPriceDetails.reduce(taxReducer, 0)
      );

      const paymentDetails = {
        totalTaxAmount,
        totalAmount,
        totalCharge,
        totalDiscount,
        totalprice,
      };

      setPaymentDetails(paymentDetails);
    }

    console.log({ paymentDetails });
  }, []);

  const onFinish = (values) => {
    console.log(values, "finish called");

    if (!editData) {
      const submitPayload = {

        payment_ids: [...selectedproducts],
        description: values.description,
        client: currentClient._id,
        date: moment(values.date).format('MM-DD-YYYY'),
        
      };

      console.log([submitPayload]);

      handleNewInvoiceSubmit(submitPayload, form);
    } else {
      console.log({ values });

      values.invoice_id = editData._id;
      values.client = editData.client._id;

      handleInvoiceEdit(values, form);
    }
  };

  // const tagRender = (props) => {
  //   const { label, closable, onClose } = props;

  //   const onPreventMouseDown = (event) => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   };

  //   return (
  //     <Tag
  //       onMouseDown={onPreventMouseDown}
  //       closable={closable}
  //       onClose={onClose}
  //       style={{
  //         marginRight: 3,
  //       }}
  //     >
  //       {label}
  //     </Tag>
  //   );
  // };

  const columns = [
    {
      title: " No",
      dataIndex: "payment_number",
      width: "6%",
      key: "6",
    },

    {
      title: "Description",
      dataIndex: "description",
      width: "6 %",
      key: "6",
    },

    {
      title: "Price",

      width: "6%",
      key: "6",

      render: (_, record) => Math.trunc(record.price?.item_price),
    },

  
    {
      title: "Qty",

      width: "6%",
      key: "6",

      render: (_, record) => Math.trunc(record.price?.qty),
    },

    {
      title: "Discount",
      width: "6%",
      key: "6",

      render: (_, record) => Math.trunc(record.price?.discount),
    },

    {
      title: "Tax ",
      width: "6%",
      key: "6",

      render: (_, record) => Math.trunc(record.price?.tax_amount),
    },

    {
      title: "Charges",
      width: "6%",
      key: "6",

      render: (_, record) => Math.trunc(record.price?.charges),
    },
    {
      title: "Amount",

      width: "6%",
      key: "6",

      render: (_, record) => Math.trunc(record.price?.amount),
    },

  ];

  return (
    <>
      <Form
        form={form}
        key={editData?._id}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Space>
          <Form.Item
            style={{ width: "31rem" }}
            labelCol={{ span: 24, offset: 0 }}
            name="client"
            label="Client"
            rules={[{ required: true }]}
            initialValue={currentClient.name}
          >
            <Input disabled defaultValue={currentClient.name} />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24, offset: 0 }}
            name="date"
            label="Invoice Date"
            rules={[{ required: true }]}
            style={{ width: "31rem" }}
          >
            <Input
              style={{ width: "28rem" }}
              onChange={(e) => {
                // setAdditonalPlanStartDate(e.target.value);
                // calculateCurrentPlanAmount(currentAmount);
              }}
              type={"date"}
            />
          </Form.Item>
        </Space>

        <Form.Item
          style={{ width: "59rem" }}
          labelCol={{ span: 24, offset: 0 }}
          name="description"
          label="Description"
        >
          <TextArea style={{ width: "59rem" }} type={"number"} />
        </Form.Item>

        {!editData && <Divider>Payment Details</Divider>}
        {!editData && (
          <Table
            columns={columns}
            dataSource={selectedproductsPriceDetails}
            bordered
            style={{ width: "65rem" }}
            pagination={false}
            size="large"
          />
        )}

        {!editData && (
          <>
            {" "}
            <div
              style={{
                margin: "0 auto",
                display: "flex",
              }}
              className="site-statistic-demo-card"
            >
            

              <Col span={5}>
                <Card
                  style={{
                    border: "none",
                    alignContent: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Statistic
                    title="Total Price"
                    value={"₹" + paymentDetails?.totalprice}
                    valueStyle={{
                      color: "#black",
                    }}
                  />
                </Card>
              </Col>
              <Col span={4}>
                <Card
                  style={{
                    border: "none",
                    alignContent: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Statistic
                    title="Total Discount"
                    value={"₹" + paymentDetails.totalDiscount}
                    valueStyle={{
                      color: "#black",
                    }}
                  />
                </Card>
              </Col>

            
              <Col span={4}>
                <Card
                  style={{
                    border: "none",
                    alignContent: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Statistic
                    title="Total Tax Amount "
                    value={"₹" + paymentDetails.totalTaxAmount}
                    valueStyle={{
                      color: "#black",
                    }}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  style={{
                    border: "none",
                    alignContent: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Statistic
                    title="Total Charge "
                    value={"₹" + paymentDetails.totalCharge}
                    valueStyle={{
                      color: "#black",
                    }}
                  />
                </Card>
              </Col>
              <Col span={4}>
                <Card
                  style={{
                    border: "none",
                    alignContent: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Statistic
                    title="Total Amount"
                    value={"₹" + paymentDetails.totalAmount}
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      border: "none",
                      textAlign: "center",
                    }}
                    valueStyle={{
                      color: "#black",
                    }}
                  />
                </Card>
              </Col>
             

            </div>
          </>
        )}
        <Divider></Divider>
        <Form.Item>
          <Space size={8}>
            <Button key={editData._id} onClick={() => setVisible(false)}>
              Cancel
            </Button>
            {editData ? (
              <Button type="primary" htmlType="submit" loading={submitLoader}>
            Update
              </Button>
            ) : !bulkUpdates ? (
              <Button type="primary" htmlType="submit" loading={submitLoader}>
                Submit
              </Button>
            ) : (
              <Button
                disabled={!selectedproducts.length}
                type="primary"
                htmlType="submit"
                loading={submitLoader}
              >
                Update All
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default InvoiceForm;
