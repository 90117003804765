import React, { useState } from "react";
import { Modal, Form, Input, Space, Select, message } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

const TaskContactForm = ({ visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();




  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      values.email = values?.email || "";
      onSubmit(values);

      form.resetFields();
    } catch (error) {
      message.error("Please fill in all required fields.");
    }
  };

  return (
    <Modal
      visible={visible}
      width={600}
      title="Add Contact"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form form={form} name="dynamic_form_nest_item" autoComplete="off">
        <>
          <Space key={2}>
            <Form.Item
              style={{ marginLeft: "1rem" }}
              labelCol={{ span: 24, offset: 0 }}
              name={"name"}
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Missing Name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              style={{ marginLeft: "0rem" }}
              labelCol={{ span: 24, offset: 0 }}
              name={"phone"}
              label="Phone"
              rules={[
                {
                  required: true,
                  message: "Missing Phone Number",
                },
                {
                  len: 10,
                  message: "Phone Number Should be 10 Digits",
                },
              ]}
            >
              <Input
                type="number"
                maxLength={10}
                minLength={10}
                placeholder="Phone"
              />
            </Form.Item>
            <Form.Item
              style={{ marginLeft: "0rem" }}
              labelCol={{ span: 24, offset: 0 }}
              name={"email"}
              label="Email"
            >
              <Input type="email" placeholder="Email" />
            </Form.Item>
          </Space>

          <Form.Item></Form.Item>
        </>
      </Form>
    </Modal>
  );
};

export default TaskContactForm;
