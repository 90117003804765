import { Button, Form, Input, message } from "antd";
import axios from "axios/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";

import TeamApi from "utils/composable/teamApi";
import ForgotPassSVG from "assets/ForgotPassSVG";

const ChangePassword = () => {
  const logOut = () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const { forgotPassword, changePassword } = TeamApi();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const loginError = (msg) => {
    message.error(msg);
  };
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content:
        "Password Changed Successfully,Please Login with Your New Password",
      duration: 20,
    });
  };

  useEffect(() => {
    console.log({ newPassword, confirmPassword });
  }, [newPassword, confirmPassword]);

  const onFinish = (values) => {
    console.log("Success:", values);

    setloading(true);

    changePassword(values)
      .then((resp) => {
        logOut();
      })
      .finally(() => {
        setloading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      style={{ marginTop: "-4rem", marginBottom: "5rem" }}
      className="changePasswordContainer"
    >
      <Form
        className="loginForm"
        autoComplete="new-password"
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* <SVG style={{width:'5rem'}} ></SVG> */}
        <div style={{ marginLeft: "4rem" }}>
          <ForgotPassSVG />
        </div>
        <h1 style={{ fontWeight: "bold", marginLeft: "6rem" }}>
          Change Password
        </h1>
        <Form.Item
          autoComplete="new-password"
          style={{ marginTop: "1rem", marginLeft: "1rem" }}
          label="Current Password"
          name="current_password"
          rules={[
            {
              required: true,
              message: "Please Enter your Current Password",
            },
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            type={"email"}
            style={{ width: "300px" }}
          />
        </Form.Item>

        <Form.Item
          style={{ width: "300px", marginLeft: "1rem" }}
          label="New Password"
          name="new_password"
          rules={[
            {
              required: true,
              message: "Please Enter your New Password!",
            },
          ]}
        >
          <Input.Password
            autoComplete="off"
            onChange={(e) => setNewPassword(e.target.value)}
            style={{ width: "300px", marginLeft: "-.2rem" }}
          />
        </Form.Item>

        <Form.Item
          style={{ width: "300px", marginLeft: "1rem" }}
          label="Confirm New Password"
          name=""
          rules={[
            {
              required: true,
              message: "Confirm New Password",
            },
          ]}
        >
          <Input.Password
            autoComplete="off"
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{ width: "300px", marginLeft: "-.2rem" }}
          />
        </Form.Item>
        {/* <a style={{float:'left'}} >Forgot Password?</a> */}

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button
            disabled={newPassword !== confirmPassword}
            style={{ marginLeft: "-2rem", width: "6rem" }}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;
