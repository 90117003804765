import React from "react";
import { Card, Switch, Skeleton, Space, Button } from "antd";

const CardComponent = ({ plans, toggleEditMode, toggleStatus, showDesc }) => {




  return (
    <>
      <Space align="end" size={30} wrap>
        {plans.map((plan) => {
          if (plan.description.length < 30) {
            var snippet = plan.description;
          } else {
            snippet = plan.description.substring(0, 45) + ".....";
          }

          return (
            <Card
              style={{
                width: 366,
                marginTop: 16,
                boxShadow: "0px 8px 12px -9px rgba(0,0,0,0.18)",
              }}
              key={plan._id}
             
            >
             
                <h3 style={{ color: "#01336b", fontWeight: "bolder" }}>
                  {plan.title}
                </h3>
                <Button
                  onClick={() => showDesc(plan.description)}
                  style={{ marginLeft: "-1rem", color: "#01336b" }}
                  type="link"
                >
             {   'More..'}
                </Button>
        
            </Card>
          );
        })}
      </Space>
    </>
  );
};

export default CardComponent;
