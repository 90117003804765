import { message } from "antd";
import axios from "axios/axios";
import useInvoiceStore from "stores/invoiceStore";
import usePaymentStore from "stores/paymentStore";
import authHeader from "utils/services/auth-header";

const InvoiceApi = () => {
  

  const { setInvoicesToStore, changeInvoiceStatus, updateInvoicesInStore } =
    useInvoiceStore((state) => ({
      setInvoicesToStore: state.setInvoices,
      updateInvoicesInStore: state.updateInvoices,
      changeInvoiceStatus: state.changeInvoiceStatus,
    }));

  const { changePaymentStatus } = usePaymentStore((state) => ({
    setPaymentsToStore: state.setPayments,
    updateProductInStore: state.updateProduct,
    changePaymentStatus: state.changePaymentStatus,
  }));

  const getInvoice = async (invoiceId) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/invoice?invoice_id=" + invoiceId, { headers: authHeader() })
        .then((response) => {


          resolve(response.data.invoice);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message)
          reject(err.response.data.message);
        });
    });
    // }
  };

  const getAllInvoices = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/invoices", { headers: authHeader() })
        .then((response) => {
        
      
          const apiInvoices = structuredClone([...response.data.invoices]) 

   

     
          setInvoicesToStore(apiInvoices);
          resolve(apiInvoices);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message)
          reject(err.response.data.message);
        });
    });
    // }
  };

  const addInvoice = async (data) => {

    return new Promise((resolve, reject) => {
      axios
        .post("/invoice", data, { headers: authHeader() })
        .then((resp) => {
 
                 changePaymentStatus("invoice_created", data.payment_ids,resp.data.invoice._id);
          message.success(resp.data.message);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject(err.response.data.message);
        });
    });
  };

  const updateInvoice = async (data) => {


    return new Promise((resolve, reject) => {
      axios
        .put("/invoice", data, { headers: authHeader() })
        .then((resp) => {
        
          // updateRequestInStore(resp.data.request);

          if (data.cancel) {
            changeInvoiceStatus("cancelled", [data.invoice_number]);
          } else if (data.restore) {
            changeInvoiceStatus("pending", [data.invoice_number]);
          } else {
            updateInvoicesInStore(resp.data.invoice);
          }

          message.success(resp.data.message);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject(err.response.data.message);
          message.error(err.response.data.message);
        });
    });
  };

  return { getInvoice, addInvoice, updateInvoice, getAllInvoices };
};

export default InvoiceApi;
