import {
  Button,
  Card,
  Descriptions,
  Drawer,
  message,
  Modal,
  PageHeader,
  Popover,
  Skeleton,
  Statistic,
} from "antd";
import {
  PlusOutlined,
  RedoOutlined,
  ImportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import ProductsTable from "components/client/ClientProductsTable";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ClientProductForm from "components/client/ClientProductForm";
import useClientProductStore from "stores/clientProductStore";
import ClientApi from "utils/composable/clientApi";

import InvoiceTable from "components/Invoice/InvoiceTable";
import InvoiceForm from "components/Invoice/InvoiceForm";
import InvoiceApi from "utils/composable/invoiceApi";

import ImportTable from "components/import/ImportTable";
import PaymentApi from "utils/composable/paymentApi";
import CSVReader from "react-csv-reader";
import useInvoiceStore from "stores/invoiceStore";

const Invoice = ({ currentUser }) => {
  const [loadings, setLoadings] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState("");
  const [planEditData, setPlanEditData] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [selectedproducts, setSelectedProducts] = useState([]);
  const [bulkUpdates, setBulkUpdatestrue] = useState(false);
  const [planDrawervisible, setPlanDrawerVisible] = useState(false);
  const [additionalPlan, setAdditionalPlan] = useState("");
  const [currentClient, setCurrentClient] = useState("");
  const clientId = useParams();
  const [importModal, setImportModal] = useState(false);
  const [manualReceive, setManualReceive] = useState(false);

  const { getInvoice, updateInvoice, getAllInvoices } = InvoiceApi();
  const [importedInvoices, setImportedInvoices] = useState("");
  const [finalImportData, setFinalImportData] = useState("");
  const [receiptLoading, setReceiptLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [filteredTotalBalance, setFilteredTotalBalance] = useState(0);
  const [currentSearchClient, setCurrentSearchClient] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const { setClientProductsToStore } = useClientProductStore((state) => ({
    setClientProductsToStore: state.setClientProducts,
  }));
  const [refreshLoading, setRefreshLoading] = useState(false);
  const { addInvoice } = InvoiceApi();
  const { addReceipts } = PaymentApi();
  const invoices = useInvoiceStore((state) => state.invoices);
  const pendingInvoices = useInvoiceStore((state) => state.invoices).filter(
    (invoice) => invoice.status == "pending"
  );
  const totalBalance = invoices.reduce((previousVal, currentVal) => {
    return previousVal + currentVal?.balance_amount;
  }, 0);

  // const users = useUserStore((state)=>state.users)
  const inputRef = useRef(null);

  useEffect(() => {
    if (!invoices.length) {
      setTableLoading(true);
      getAllInvoices().finally(() => {
        setTableLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    console.log("Changed");

    if (invoices.length) {
      if (currentSearchClient) {
        const filteredClientOnly = invoices.filter(
          (invoice) => invoice.client._id == currentSearchClient._id
        );
        var filteredbalance = filteredClientOnly.reduce(
          (previousVal, currentVal) => {
            return previousVal + currentVal?.balance_amount;
          },
          0
        );
        setFilteredTotalBalance(
          filteredbalance ? filteredbalance.toFixed(2) : "0"
        );
      } else {
        console.log("Here set filtered");
        setFilteredTotalBalance(
          filteredbalance ? filteredbalance : totalBalance.toFixed(2)
        );
      }
    }
  }, [currentSearchClient]);

  const editHandler = (template) => {
    console.log({ template });
    const formatDate = (date) => {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    };

    template.date = formatDate(template.date);

    setEditData(template);
    setVisible(true);
  };

  const handleNewInvoiceSubmit = (values) => {
    console.log(values);

    setSubmitLoader(true);
    addInvoice(values).finally(() => {
      setSubmitLoader(false);
    });
  };

  const onClose = () => {
    setEditData("");

    setPlanDrawerVisible(false);
  };

  const refresh = () => {
    setRefreshLoading(true);

    getAllInvoices().finally(() => {
      setRefreshLoading(false);
    });
  };

  var currentProducts;

  const products = useClientProductStore((state) => state.products);

  const updateHandler = (data) => {
    setSubmitLoader(true);

    updateInvoice(data)
      .then(() => {
        console.log("After update");

        setSelectedRows([]);
        setVisible(false);
        setEditData(false);
        setSubmitLoader(false);
      })

      .finally(() => {
        setSelectedRows([]);
        setButtonLoading(false);
        setSubmitLoader(false);
      });
  };

  const submitReceipts = () => {
    const receipts = finalImportData.map((invoice) => {
      return {
        date: invoice.date,
        client_name: invoice.client.name,
        invoice_number: invoice.invoice_number,
        amount: parseFloat(invoice.price.received_amount),
        adjusted_amount: invoice.price.adjusted_amount || 0,
      };
    });

    setReceiptLoading(true);

    addReceipts(receipts, finalImportData).finally(() => {
      setSelectedRows([]);
      setReceiptLoading(false);
      setImportModal(false);
    });
  };

  const readCSVFile = (rows, info, file) => {
    const element = document.getElementById("fileInput");

    var JsonFormat = [];
    var jsnonObject = {};
    var currentClient;
    var currentDate;
    var j = 0;

    for (let i = 1; i < rows.length; i++) {
      if (rows[i][0] !== "") {
        if (rows[i][0] !== "New Ref" || rows[i][0] !== "Agst Ref") {
          jsnonObject.date = rows[i][0];
          jsnonObject.client = rows[i][1];
          currentClient = jsnonObject.client;
          currentDate = jsnonObject.date;

          console.log(rows[i][0], rows[i][1], "inserted objects");

          // continue;
        }
      } else {
        if (rows[i][1] == "New Ref" || rows[i][1] == "Agst Ref") {
          jsnonObject.date = currentDate;
          jsnonObject.client = currentClient;
          jsnonObject.invoice = rows[i][2];
          jsnonObject.amount = rows[i][3];
          JsonFormat.push(jsnonObject);
          jsnonObject = {};
        }
        // i++;
      }
    }

    let count = 0;

    for (let i = 0; i < JsonFormat.length; i++) {
      for (let j = 0; j < JsonFormat.length; j++) {
        if (JsonFormat[i].invoice == JsonFormat[j].invoice) {
          count++;

          if (count > 1) {
            JsonFormat[i].amount =
              parseFloat(JsonFormat[i].amount) +
              parseFloat(JsonFormat[j].amount);
            JsonFormat[j].invoice = "Duplicate";
            //   JsonFormat  = JsonFormat.splice(j, j);
          }
        }
      }
      count = 0;
    }

    console.log(JsonFormat, "Json Formate");

    setImportedInvoices(JsonFormat);
    setImportModal(true);

    element.value = "";
  };

  return (
    <>
      <Modal
        destroyOnClose
        title="Import"
        centered
        visible={importModal}
        onOk={() => {
          submitReceipts();
          setImportedInvoices("");
        }}
        onCancel={() => {
          setImportModal(false);
          setImportedInvoices("");
          setSelectedRows([]);
          setManualReceive(false);
        }}
        width={1500}
        confirmLoading={receiptLoading}
      >
        <ImportTable
          manualReceive={manualReceive}
          importedInvoices={importedInvoices}
          updateHandler={updateHandler}
          currentClient={currentClient}
          setCurrentClient={setCurrentClient}
          products={currentProducts}
          selectedproducts={selectedproducts}
          setSelectedProducts={setSelectedProducts}
          editHandler={editHandler}
          setFinalImportData={setFinalImportData}
        />
      </Modal>

      <div>
        <PageHeader
          ghost={false}
          className="site-page-header"
          onBack={() => window.history.back()}
          title="Invoice"
          extra={[
            <>
              <div style={{ marginTop: "-1.7rem", marginLeft: "1rem" }}>
                <Button
                  icon={<RedoOutlined />}
                  loading={refreshLoading}
                  onClick={refresh}
                  key="1"
                  type="dashed"
                  style={{ marginTop: "-3rem" }}
                ></Button>
              </div>
              <div style={{ marginTop: "-1.7rem", marginLeft: "1rem" }}>
                {/* <Statistic
                  title="Receive Invoice"
                  value={pendingInvoices?.length}
                  valueStyle={{ color: "orange", fontSize: "15px", marginLeft: '3rem', marginTop: '.5rem' }}
                /> */}
                <Button
                  disabled={!importedInvoices.length}
                  onClick={() => {
                    setImportModal(true);
                    setManualReceive(true);
                  }}
                  shape="round"
                  type="primary"
                >
                  Receive
                </Button>
              </div>
              <div style={{ marginTop: "-1.7rem", marginLeft: "1rem" }}>
                <label
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                  htmlFor="fileInput"
                >
                  <Button
                    hidden={
                      !currentUser.user.privileges.includes(
                        "payment_management"
                      )
                    }
                    href={require("../../assets/RECEIPTS Template.csv")}
                    shape="round"
                    download="Template"
                    icon={<DownloadOutlined />}
                    size={"middle"}
                  >
                    {" "}
                    Download{" "}
                  </Button>
                </label>{" "}
              </div>
              <div style={{ marginTop: "-1.7rem", marginLeft: "1rem" }}>
                <label
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                  htmlFor="fileInput"
                >
                  <ImportOutlined
                    hidden={
                      !currentUser.user.privileges.includes(
                        "payment_management"
                      )
                    }
                    size={"large"}
                  />{" "}
                </label>{" "}
              </div>{" "}
              <div style={{ marginTop: "-1.7rem", marginLeft: "-1rem" }}>
                <CSVReader
                  cssLabelClass="labelClass"
                  inputStyle={{ display: "none", cursor: "pointer" }}
                  inputId="fileInput"
                  cssClass="csv-reader-input"
                  label={"Import"}
                  onFileLoaded={(data, fileInfo, originalFile) => {
                    console.dir(data, fileInfo, originalFile);
                    readCSVFile(data, fileInfo, originalFile);
                  }}
                />
              </div>
              {/* <span>Import File</span> */}
            </>,

            <div style={{ marginTop: "0rem", marginLeft: "1rem" }}>
              <Statistic
                title="Pending Invoice"
                value={pendingInvoices?.length}
                valueStyle={{
                  color: "orange",
                  fontSize: "15px",
                  marginLeft: "3rem",
                  marginTop: ".5rem",
                }}
              />
            </div>,

            <div style={{ marginTop: "0rem", marginLeft: "1rem" }}>
              <Statistic
                title={
                  currentSearchClient
                    ? `${currentSearchClient.name} Total Balance `
                    : "Total Balance"
                }
                value={
                  !filteredTotalBalance
                    ? totalBalance.toFixed(2)
                    : filteredTotalBalance
                }
                valueStyle={{
                  color: "orange",
                  fontSize: "15px",
                  marginLeft: "1rem",
                  marginTop: ".5rem",
                }}
              />
            </div>,
          ]}
        >
          <Descriptions style={{ marginTop: "1rem" }}></Descriptions>
        </PageHeader>
      </div>

      <Skeleton active loading={loadings}>
        <InvoiceTable
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setImportedInvoices={setImportedInvoices}
          setCurrentSearchClient={setCurrentSearchClient}
          currentUser={currentUser}
          tableLoading={tableLoading}
          setButtonLoading={setButtonLoading}
          buttonLoading={buttonLoading}
          receiptLoading={receiptLoading}
          updateHandler={updateHandler}
          currentClient={currentClient}
          setCurrentClient={setCurrentClient}
          products={currentProducts}
          selectedproducts={selectedproducts}
          setSelectedProducts={setSelectedProducts}
          editHandler={editHandler}
        />
      </Skeleton>

      <Drawer
        destroyOnClose={true}
        key={editData?._id}
        width={"1000px"}
        title={
          editData
            ? "Edit Invoice "
            : `Create Invoice for ${selectedproducts.length} Payments`
        }
        placement="right"
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
      >
        <InvoiceForm
          currentClient={currentClient}
          setCurrentClient={setCurrentClient}
          additionalPlan={additionalPlan}
          planEditData={planEditData}
          handleNewInvoiceSubmit={handleNewInvoiceSubmit}
          selectedproducts={selectedproducts}
          bulkUpdates={bulkUpdates}
          editData={editData}
          submitLoader={submitLoader}
          setVisible={setPlanDrawerVisible}
          handleInvoiceEdit={updateHandler}
        />
      </Drawer>
    </>
  );
};

export default Invoice;
