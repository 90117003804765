import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  Form,
  message,
  Modal,
  PageHeader,
  Skeleton
} from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import useClientProductStore from "stores/clientProductStore";
import useProductStore from "stores/productStore";
import ClientApi from "utils/composable/clientApi";
import ProductApi from "utils/composable/productsApi";
import StandByApi from "utils/composable/standByApi";
import WareHouseApi from "utils/composable/warehouseApi";

import GoogleMap from "components/googleMap/GoogleMap";
import StandByProductTable from "components/stand-by-request/StandByProductTable";
import TaskForm from "components/task/TaskForm";

import useTaskStore from "stores/taskStore";
import TaskApi from "utils/composable/taskApi";
import TeamApi from "utils/composable/teamApi";

import moment from "moment";
import useStandByReqStore from "stores/standByReqStore";
import useUserStore from "stores/teamStore";

const StandbyReqDetails = () => {
  const [loadings, setLoadings] = useState(false);



  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [currentTask, setCurrentTask] = useState({});
  const [currentStandbyRequest, setCurrentStandbyReq] = useState(false);
  const [currentDefectiveProductId, setCurrentDefectiveProductId] =
    useState("");
  const [standByProductJob, setStandByProductJob] = useState(false);
  const [jobViewMode, setJobViewMode] = useState(false);
  // const [reqModalVisible, setReqModalVisible] = useState(false);

  const { state } = useLocation();
  const users = useUserStore((state) => state.users);

  const { getProductsWithStatusAndUse } = ProductApi();
  const [form] = Form.useForm();
  const { updateStandByRequest } = StandByApi();
  const { closeStandByrequest } = WareHouseApi();



  const { setClientProductsToStore } = useClientProductStore((state) => ({
    setClientProductsToStore: state.setClientProducts,
  }));

  const standbyProducts = useStandByReqStore((state) => state.standByReqs);

  //notification
  const taskAdded = (msg) => {
    message.success(msg);
  };

  const jobSuccessMsg = (msg) => {
    message.success(msg);
  };

  const jobErrorMsg = (msg) => {
    message.error(msg);
  };

  const fillForm = () => {
    message.error("Please select Client and Date first ");
  };
  const taskUpdated = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  useEffect(() => {
    console.log(state);

    // tasks.length && getTasks();
  }, []);

  const [visible, setVisible] = useState(false);
  const [tableLoading, setTableLoadings] = useState(false);

  const [locationDetails, setLocationDetails] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [hideAddressBox, setHideAddressBox] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState();
  const [editData, setEditData] = useState("");
  const [jobEditMode, setJobEditMode] = useState(false);
  const [jobAddMode, setJobAddMode] = useState(editData ? false : true);
  const [submitLoader, setSubmitLoader] = useState(false);

  const [taskForm, setTaskForm] = useState(true);

  const products = useProductStore((state) => state.Products);
  const request = useStandByReqStore((state) => state.standByReqs);

  const [currentReq, setCurrentReq] = useState(request);

  //composable
  const { getClients } = ClientApi();
  const { getUsers } = TeamApi();
  const {
    updateTask,
    addTask,
    getTasks,
    getEngineers,
    editTaskJobs,
    AddTaskJobs,
  } = TaskApi();
  const tasks = useTaskStore((state) => state.tasks);

  const handleCancel = (form) => {
    setVisible(false);
    setEditData(false);

    const task = tasks.find((task) => task._id == currentStandbyRequest.task);

    task.jobs[task.jobs.length - 1].assigned == "" && task.jobs.pop();
  };

  useEffect(() => {
    getClients();
    getUsers();

    if (!tasks.length) {
      setTableLoadings(true);

      getTasks()
        .then((resp) => {
          console.log(resp);
          const task = tasks.find((task) => task._id == state.task);

          setCurrentTask(task)
          console.log({task});

        })
        .finally(() => {
          setTableLoadings(false);
        });
    }else{

      const task = tasks.find((task) => task._id == state.task);

      setCurrentTask(task)

    }

    setTaskForm(true);
  }, []);



const inStockProducts = products?.filter((product) => product?.status == 'in_stock' && product?.use == 'stand-by')
console.log('inStockProducts', {inStockProducts});
  const productList = inStockProducts?.map((product) => ({
    label: `${product?.name} (${product?.barcode || ""})`,
    value: product?._id,
    key: product?._id,
  }));

  useEffect(() => {
    if (!products.length) getProductsWithStatusAndUse("in_stock", "stand-by");

    setCurrentReq(request);

    productList.push({
      label: `Select a stand by product`,
      value: undefined,
    });
  }, []);

  console.log({ state });

  const editMode = (task) => {
    console.log(task, "Showing task");

    const formatDate = (date) => {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    };

    // task.formattedDate = formatDate(task?.due_date);

    task.jobs = task.jobs.map((job) => {
      console.log(job, "manupulating job");
      return {
        ...job,
        productId: job.product?._id,
      };
    });

    console.log(task, "edited task ");
    // //reversing lat and lng position for google map
    // task.location.coordinates = task.location?.coordinates?.reverse();
    setEditData(task);
    setVisible(true);
  };

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData?.location?.coordinates);
  };

  
  const changeStandbyProduct = (product, request) => {
    console.log("product", product );
    console.log("request", request);
    setLoadings(true);
    const updatePayload = {
      request_id: request._id,
      client: request?.client?._id,
      product: product?._id,
    };

    updateStandByRequest(updatePayload)
      .then((resp) => {
        console.log("resp of req", resp);
        setCurrentReq(resp);
        message.success("Request updated");
        console.log("updating product", product);
        state.product = product;
        console.log("state Updated", state);
        setSubmitLoader(false);
        setVisible(false);
        setLoadings(false);
        // setReqModalVisible(false);
      })
      .catch((err) => {
        setVisible(false);
        // setReqModalVisible(false);
        setSubmitLoader(false);
        message.error(err);
      });
  };

  const handleChange = (value, req, name) => {
    console.log(value, "target value", req, "product");
    setOpen(true);

    const handleOkay = (req) => {
      console.log({ req });

      changeStandbyProduct(value, req);
    };

    const handleCancel = () => {
      var name = `product${req._id}`;

      form.setFieldsValue({
        [req._id]: req?.product?.name || "Assign a product",
      });

      setCurrentReq(request);

      setOpen(false);
    };

    Modal.confirm({
      title: "Confirm",

      content: "Change Standby Product ?",
      okText: "Okay",
      cancelText: "Cancel",
      onOk: () => handleOkay(req),
      onCancel: () => handleCancel(),
    });
  };

  const jobUpdateHandler = (key, form) => {
    let jobs = form.getFieldValue("jobs");
    const editedJob = jobs[key];
    console.log(jobs[key], "exact job");
    editedJob.job_id = editedJob._id;
    editedJob.task_id = editData._id;

    editedJob.job = {
      product: editedJob.productId,
      description: editedJob.description,
      status: editedJob.status,
      type: editedJob.type,
      assigned: editedJob?.assigned[0]?._id
        ? editedJob?.assigned[0]?._id
        : editedJob.assigned,
    };

    console.log({ editedJob });
    const jobUpdatePayload = {
      job_id: editedJob._id,
      task_id: editData._id,
      job: {
        product: editedJob.productId,
        description: editedJob.description,
        status: editedJob.status,
        type: editedJob.type,
        assigned: editedJob?.assigned[0]?._id
          ? editedJob.assigned[0]?._id
          : editedJob?.assigned,
        stand_by_product_request: editData._id,
        defective_product: editedJob.defective_product._id,
      },
    };

    console.log(jobUpdatePayload, "edit job payload");
    setJobLoading(true);
    editTaskJobs(jobUpdatePayload, key,editedJob)
      .then((resp) => {
        setJobLoading(false);
        setJobEditMode(false);
        jobSuccessMsg(resp);
      })
      .catch((err) => {
        setJobLoading(false);
        console.log(err);
        jobErrorMsg(err);
      });
  };

  const jobSubmitHandler = (key, form, fieldEngineer) => {

    // if (typeof fieldEngineer == "object") {
    //   message.error("Please assign a field engineer ");
    //   return;
    // }

    const jobs = form.getFieldValue("jobs");
    console.log(jobs);
    console.log(jobs[jobs.length - 1], "New job");
    jobs[jobs.length - 1].assigned = fieldEngineer;
    const newJob = jobs[jobs.length - 1];
    console.log({ fieldEngineer });
    newJob.assigned = fieldEngineer;
    console.log(newJob, "New job");

    const newJobPayload = {
      task_id: editData?._id,
      job: {
        product: newJob.productId,
        description: newJob.description,
        assigned: typeof fieldEngineer == "object" ? null : fieldEngineer,

        type: "stand_by_product_delivery",
        stand_by_product_request: currentStandbyRequest?._id,
        defective_product: currentDefectiveProductId,
      },
    };

    console.log(newJobPayload, "new job payload");
    setJobLoading(true);
    AddTaskJobs(newJobPayload)
      .then((task) => {
        if (editData) {
          editMode(task);
        }

        setJobLoading(false);
        setJobAddMode(false);
        const currentStandByProduct = standbyProducts.find(
          (SBP) => (SBP._id = state._id)
        );
        currentStandByProduct.status = "Processed";
        state.status = "Processed";

        jobSuccessMsg("Job added");
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  const getFieldEngineers = (client, date) => {

    if (!client || !date) {
      fillForm();
    } else {
      setAssignLoading(true);
      getEngineers(client, date)
        .then((resp) => {
          setAssignLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAssignLoading(false);
          jobErrorMsg(err);
        });
    }
  };

  const assignHandler = (product) => {
    
    changeStandbyProduct(product, state);
  };

  //task section ends here

  const createDeliveryJob = (standByReq) => {
    console.log({ currentReq });
    console.log({ standByReq });
    const task = tasks.find((task) => task._id == standByReq.task);
    console.log(task, "found req task");
    setCurrentStandbyReq(standByReq);
    setCurrentDefectiveProductId(standByReq.defective_product);

    const newJob = {
      product: standByReq?.client_product,
      status: "Unassigned",
      type: "stand_by_product_delivery",
      assigned: "",
      stand_by_product_request: standByReq?._id,
    };
    task.standByProduct = standByReq?.product;
    task.jobs.push(newJob);
    setCurrentTask(task);
    console.log(task, "Task after Adding enw Job");
    setJobAddMode(true);
    editMode(task);
    setStandByProductJob(true);
  };

  const handleReject = (req) => {
    setOpen(true);

    const handleOkay = (req) => {
      console.log({ req });

      setConfirmLoading(true);
      rejectRequest(req);
      setOpen(false);
    };

    Modal.confirm({
      title: "Confirm",

      content: "Are you sure?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => handleOkay(req),
    });
  };

  const rejectRequest = (request) => {
    console.log({ request });
    const updatePayload = {
      request_id: request._id,
      client: request.client._id,
      status: "rejected",
    };

    updateStandByRequest(updatePayload)
      .then((resp) => {
        console.log(resp, "resp of req");
        message.success("Request updated");
        setConfirmLoading(false);
        setVisible(false);

        state.status = "rejected";
      })
      .catch((err) => {
        setVisible(false);
        setConfirmLoading(false);
        message.error(err);
      });
  };

  

  return (
    <>
      <div className="site-page-header-ghost-wrapper">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={state.product?.name || "Product Not Assigned"}
          subTitle={state?.product?.barcode || ""}
          extra={[
            

            state.status == "requested" && (
              <Button
                loading={confirmLoading}
                onClick={() => handleReject(state)}
                key="2"
              >
                Reject
              </Button>
            ),

            <Button
              onClick={() => {
                editMode(tasks.find((task) => task._id == state.task));
                setJobViewMode(true);
                setJobAddMode(false);
                setStandByProductJob(false);
              }}
            >
              Show Task
            </Button>,
            state.status == "requested" && state.product?.name && (
              <Button
                onClick={() => createDeliveryJob(state)}
                key="1"
                type="primary"
              >
                {" "}
                Create Job{" "}
              </Button>
            ),
          ]}
        >
          <Descriptions
            bordered
            style={{ margin: "1rem" }}
            labelStyle={{ fontWeight: "bolder" }}
            size="small"
            column={1}
          >
            <Descriptions.Item label="Client Name">
              {state.client?.name}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Client Product"
            >
              {state.client_product?.name}-{state?.client_product?.barcode}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Warehouse"
            >
              <a>{state.product?.warehouse.title}</a>
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Status"
            >
              <a>{state.status}</a>
            </Descriptions.Item>

            {state?.picked_up_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Picked Up on"
              >
                {moment(state?.picked_up_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                - {users.find((user) => user._id == state?.picked_up_by)?.name}
              </Descriptions.Item>
            )}

            {state?.delivered_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label="Delivered on"
              >
                {moment(state?.delivered_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                - {users.find((user) => user._id == state?.delivered_by)?.name}
              </Descriptions.Item>
            )}

            {state?.returned_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label=" Returned on "
              >
                {moment(state?.returned_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                - {users.find((user) => user._id == state?.returned_by)?.name}
              </Descriptions.Item>
            )}

            {state?.closed_on && (
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label=" Closed on "
              >
                {moment(state?.closed_on).utc().format("DD-MM-YYYY")}{" "}
                <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>
                  {" "}
                  By{" "}
                </span>{" "}
                - {users.find((user) => user._id == state?.closed_by)?.name}
              </Descriptions.Item>
            )}

            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label=" Current Standby Product "
            >
              <a>{ state?.product?.name
                      ? `${state?.product?.name} ${
                      state?.product?.barcode? "("+state?.product?.barcode+")" || "":""
                        }`
                      : "Assign a product"}</a>
              
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Task No"
            >
              <a>{currentTask?.task_number}</a>
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "bolder" }}
              label="Description"
            >
             <a>{state.description}</a>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      </div>

      {state.status === 'requested' && (
  <Skeleton active loading={loadings}>
    <Divider style={{ fontWeight: "bold" }}>Available Products</Divider>
    <StandByProductTable assignHandler={assignHandler} />
  </Skeleton>
)}

      <Drawer
        destroyOnClose={true}
        width={"100vw"}
        title={
          standByProductJob
            ? "Create Standby Product Deilvery Job"
            : editData && !jobViewMode
              ? `Update Task ${editData.task_number} `
            : editData && jobViewMode
                ? `Related Task ${editData.task_number} `
            : "Create Task"
        }
        size={"large"}
        placement="right"
        onClose={handleCancel}
        visible={visible}
      >
        <Divider style={{ padding: "1rem" }}>Enter Task Details</Divider>

        <TaskForm
          jobViewMode={jobViewMode}
          standByProductJob={standByProductJob}
          jobSubmitHandler={jobSubmitHandler}
          jobUpdateHandler={jobUpdateHandler}
          locationDetails={locationDetails}
          // submitHandler={submitHandler}
          ChangeLocation={ChangeLocation}
          editData={editData}
          // updateHandler={updateHandler}
          getFieldEngineers={getFieldEngineers}
          assignLoading={assignLoading}
          setJobLoading={setJobLoading}
          jobLoading={jobLoading}
          setJobEditMode={setJobEditMode}
          jobEditMode={jobEditMode}
          jobAddMode={jobAddMode}
          setJobAddMode={setJobAddMode}
        />

        <Divider style={{ marginBottom: "1rem" }}>Client Location</Divider>

        <GoogleMap
          taskForm={taskForm}
          hideAddressBox={hideAddressBox}
          locationEditData={locationEditData}
          setLocationDetails={setLocationDetails}
        />
      </Drawer>
    </>
  );
};

export default StandbyReqDetails;
