import {
  AccountBookOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Timeline,
  Tooltip
} from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import JobRepairCostTable from "./JobRepairCostTable";

const TaskJobsTable = ({
  jobCancelLoading,
  jobs,
  currentTask,
  cancelJob,
  setJobModalVisible,
}) => {
  let currentJobs = jobs.map((job) => {
    if (job.type === "stand_by_product_return") {
      return {
        ...job,
        product: job?.stand_by_product_request?.product,
      };
    } else {
      return job;
    }
  });
  
  const [currentJobid, setCurrentJobId] = useState("");

  const [repairCostModalVisible, setRepairCostModalVisible] = useState("");
  const [currentRepairDetails, setCurrentRepairDetails] = useState([]);

  const ids = currentTask.assigned.map((fe) => fe?._id);
  const uniqueAssignedFe = currentTask.assigned.filter(
    (fe, index) => !ids.includes(fe?._id, index + 1)
  );


  const [form] = Form.useForm();

  const navigate = useNavigate();

  const HandleCancelJob = (taskId, jobId, jobNumber) => {
    console.log(jobNumber, "jobNumber");

    Modal.confirm({
      title: "Confirm",
      content: "Cancel this job ?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        cancelJob(taskId, jobId, jobNumber);
        setCurrentJobId(jobId);
      },
    });
  };

  const handleCancel = () => {
    repairCostModalVisible(false);
    // setJobModalVisible(true)
  };

  const showMoreData = (job) => {
    console.log(job);

    Modal.info({
      title: "Job Details",
      width: "600px",
      content: (
        <div>
          <Divider>Description </Divider>
          {
            <>
              <p>{job.description}</p>

              <Divider>Notes</Divider>

              <Timeline mode={"left"}>
                {job.notes.map((log) => {
                  var color;
                  var locaTime = new Date(log.time);
                  var time = locaTime.toString();

                  if (log.vendor_transferred_date) {
                    color = "#007FFF";
                  } else if (log.vendor_transferred_date) {
                    color = "green";
                  } else {
                    color = "#5D76A9";
                  }

                  function formatTimeShow(h_24) {
                    var h = h_24 % 12;
                    if (h === 0) h = 12;
                    return (
                      h +
                      `: ${time.substr(19, 2)}` +
                      (h_24 < 12 ? " am" : " pm")
                    );
                  }

                  var exactTime = formatTimeShow(
                    parseFloat(time.substr(15, 10))
                  );
                  var text;
                  var suffix = "";

                  text = "by  " + log?.by_name;

                  return (
                    <Timeline.Item
                      style={{ fontSize: "15px" }}
                      dot={log.type === "complete" ? <CheckOutlined /> : ""}
                      color={color}
                    >
                      {suffix} {log?.text} {text}{" "}
                      <span style={{ fontWeight: "200" }}> on</span>{" "}
                      <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                        {" "}
                        {time.substr(3, 12)}, {exactTime}{" "}
                      </span>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </>
          }
        </div>
      ),
      onOk() {},
    });
  };

  const columns = [
    {
      title: "Job No",
      dataIndex: "job_number",
      width: "10%",
    },

    {
      title: "Product",
      dataIndex: "product",
      width: "5%",
      render: (_, tasks) => tasks.product?.name,

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record });
        return record?.product.name.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: "Barcode",
      dataIndex: "barcode",
      width: "15%",
      render: (_, tasks) => tasks.product?.barcode,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record });
        return record?.product.barcode
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      width: "15%",
      render: (_, record) => {
        const str1 = record.status;

        var i,
          frags = str1.split("_");
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return (
          <>
            <p>{frags?.join(" ")}</p>{" "}
            <EyeOutlined
              onClick={() => {
                record.task = currentTask._id;
                record.client = currentTask.client;
                record.client_product = record.product;
                navigate(
                  "/products/defective-products/" + record?.product._id,
                  {
                    state: record,
                  }
                );
              }}
            />
          </>
        );
      },
      filters: [
        { text: "Assigned", value: "assigned" },
        { text: "In Progress", value: "in_progress" },
        { text: "Unable to Complete", value: "unable_to_complete" },
        { text: "Completed", value: "completed" },
        { text: "Closed", value: "closed" },
        { text: "Cancelled", value: "cancelled" },
      ],

      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "30%",
      render: (_, tasks) => {
        const str1 = tasks.type;

        var i,
          frags = str1.split("_");
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(" ");
      },
      filters: [
        { text: "Inventory Check", value: "inventory_check" },
        { text: "Complaint", value: "complaint" },
        { text: "Product Delivery", value: "product_delivery" },
        {
          text: "Standby Product Delivery",
          value: "stand_by_product_delivery",
        },
        { text: "Bill delivery", value: "bill_delivery" },
      ],

      onFilter: (value, record) => record.type.includes(value),
    },

    {
      title: "Assigned",
      dataIndex: "",
      width: "30%",
      render: (_, record) =>
        uniqueAssignedFe.map((Fe, index) => {
          if (Fe?._id === record?.assigned) {
            const str1 = Fe?.name;

            var i,
              frags = str1?.split("_");
            for (i = 0; i < frags.length; i++) {
              frags[i] = frags[i]?.charAt(0).toUpperCase() + frags[i]?.slice(1);
            }
            return frags.join(" ");
          }else{
            return '';
          }
        }),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      width: "30%",
      render: (_, record) =>
        uniqueAssignedFe.map((Fe) => {
          return (Fe?._id === record.assigned) ? Fe?.phone : '';
        }),
    },
    {
      title: "Standby Status",
      dataIndex: "contact",
      width: "30%",

      render: (_, record) => {
        const str1 = record?.stand_by_product_request?.status;
        var i,
          frags = str1?.split("_");
        for (i = 0; i < frags?.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }

        return (
          record?.stand_by_product_request?.status && (
            <>
              <p>{frags?.join(" ")}</p>{" "}
              <EyeOutlined
                onClick={() => {
                  record.stand_by_product_request.client_product =
                    record?.product;

                  record.stand_by_product_request.client = currentTask.client;

                  navigate(
                    "/standbyRequest/" + record?.stand_by_product_request._id,
                    {
                      state: record?.stand_by_product_request,
                    }
                  );
                }}
              />
            </>
          )
        );
      },

      filters: [
        { text: "Requested", value: "requested" },
        { text: "Rejected", value: "rejected" },
        { text: "Delivered", value: "delivered" },
        { text: "Returned", value: "returned" },
        { text: "Closed", value: "closed" },
      ],

      onFilter: (value, record) => {
        console.log({ value, record });

        return record?.stand_by_product_request?.status.includes(value);
      },
    },

    {
      title: "Warehouse",
      dataIndex: "contact",
      width: "30%",
      render: (_, record) => {
        return record.stand_by_product_request?.product?.warehouse.title;
      },
    },

    {
      title: "Repair Details",
      dataIndex: "contact",
      width: "30%",
      render: (_, record) => (
        <Tooltip title={"Repair Cost Details"}>
          <AccountBookOutlined
            hidden={!record.repair_costs.length}
            onClick={() => {
              // setJobModalVisible(false)
              setRepairCostModalVisible(true);
              setCurrentRepairDetails(record.repair_costs);
            }}
          />
        </Tooltip>
      ),
    },
    {
      title: "Action",

      width: "30%",
      render: (_, record) => {
        console.log(_);
        return (
          <Space>
            <Tooltip title={"More Info"}>
              <InfoCircleOutlined onClick={() => showMoreData(record)} />
            </Tooltip>
            <Tooltip title={" Cancel Job"}>
              <Button
                icon={<CloseCircleOutlined />}
                loading={jobCancelLoading && currentJobid === record._id}
                hidden={
                  !(
                    record.status === "assigned" ||
                    record.status === "unassigned" ||
                    ((record.status === "in_progress" || record.status === "unable_to_complete") &&
                      record.defective_product &&
                      record.rent &&
                      record.product.is_active === false &&
                      record.type === "complaint")
                  )
                }
                onClick={() =>
                  HandleCancelJob(
                    currentTask._id,
                    record._id,
                    record.job_number
                  )
                }
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        key={15}
        destroyOnClose={true}
        title={"Repair Cost Details"}
        footer={[]}
        centered
        visible={repairCostModalVisible}
        onOk={() => {
          setRepairCostModalVisible(false);
          setJobModalVisible(true);
          // handleOkay(currentDefectiveProduct);
        }}
        onCancel={() => {
          setRepairCostModalVisible(false);
          // setJobModalVisible(true)
          handleCancel();
        }}
        width={800}
      >
        <JobRepairCostTable
          setCostModalVisible={setRepairCostModalVisible}
          currentRepairDetails={currentRepairDetails}
        ></JobRepairCostTable>
      </Modal>

      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={currentJobs}
          columns={columns}
          pagination={false}
        />
      </Form>
    </>
  );
};

export default TaskJobsTable;
