import {
  ContactsFilled,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";

import useClientLocationStore from "stores/clientLocationStore";
const ClientLocationTable = ({
  setContacts,
  editHandler,
  setVisible,
  deleteLocation,
  currentUser,
}) => {
  const clientLocations = useClientLocationStore(
    (state) => state.clientLocations
  );

  const showLocationContacts = (clientLocation) => {
    setVisible(true);
    setContacts(clientLocation);
  };

  const [filteredData, setFilteredData] = useState("");
  const [currentQuery, setCurrentQuery] = useState("");

  const fuzzyFilter = (query) => {
    setCurrentQuery(query);

    var get_bigrams,
      j,
      len,
      name,
      names,
      obj,
      relevance,
      results,
      string_similarity;

    get_bigrams = function (string) {
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length > 2) {
      results = [];

      for (j = 0, len = clientLocations.length; j < len; j++) {
        var location = clientLocations[j];
        relevance = string_similarity(query, location?.name || "");
        obj = {
          ...location,
          relevance: relevance,
        };
        results.push(obj);
      }

      var sortedResults = results.filter((result) => result.relevance > 0.2);

      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();

      setFilteredData(sortedResults);
    }
  };

  useEffect(() => {
    if (filteredData.length) {
      fuzzyFilter(currentQuery);
    }
  }, [clientLocations]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "1",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0]);
              }}
              onBlur={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.address.toLowerCase().includes(value.toLowerCase());
      },
    },
   
    
    {
      title: "Address",
      dataIndex: "address",
      key: "2",
      width: "50%",
    },
    {
      title: "Next Maintenance ",
      dataIndex: "next_maintenance_date",
      key: "3",
      render: (record, row) => (row.is_active && row.maintenance_interval) ? moment.utc(record).format("DD-MM-YYYY") : '',
      width: "2rem",
    },
    {
      title: "Landline",
      dataIndex: "landline",
      key: "9",
      width: "50%",
    },
    {
      title: "Contacts",
      dataIndex: "contacts",
      key: "3",
      render: (_, clientLocations) => (
        <>
          <Tooltip title={"Show Contacts"}>
            <ContactsFilled
              onClick={() => showLocationContacts(clientLocations)}
            />{" "}
            ({clientLocations?.contacts?.length})
          </Tooltip>
        </>
      ),
      width: "5%",
    },

    {
      title: "Edit",
      key: " 4",
      render: (_, clientLocations) => (
        <Space key={clientLocations?._id} size="middle">
          <Tooltip title={"Edit"}>
            <EditOutlined onClick={() => editHandler(clientLocations)} />
          </Tooltip>
        </Space>
      ),
    },

    {
      title: "Delete",
      key: " 4",

      render: (_, clientLocations) => (
        <Space key={clientLocations?._id} size="middle">
          <Tooltip title={"Delete Location"}>
            <DeleteOutlined
              hidden={
                !currentUser?.user.privileges?.includes("client_management")
              }
              onClick={() => deleteLocation(clientLocations)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      ></Space>

      <Table
        columns={columns}
        dataSource={filteredData ? filteredData : clientLocations}
      />
    </>
  );
};

export default ClientLocationTable;
