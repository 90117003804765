import {
    DeleteOutlined,
    EditOutlined,
    SearchOutlined
} from "@ant-design/icons";
import { AutoComplete, Button, Divider, Form, Input, Modal, Popconfirm, Space, Table, Tooltip } from "antd";
import { useState } from "react";
import useUserStore from "stores/teamStore";
import TeamApi from "utils/composable/teamApi";


const TeamTable = ({editHandler,currentUser}) => {


    const [form] = Form.useForm()
    const {changeUserEmailApi} =TeamApi()
    const [submitLoading, setSubmitLoading] = useState(false);
    const [deleteId, setCurrentDeleteId] = useState("");
    const [emailModalOpen, setEmailModalOpen]=useState(false)
    const [loading,setLoading]=useState(false)

    const emailEditHandler=(user)=>{

        form.setFieldsValue({
          email:user.email,
          user_id:user._id
        
        });

        setEmailModalOpen(true)

    }



    const { ArchiveUser} = TeamApi();
    const users = useUserStore((state) => state.users)
    const activeUsers = users.filter((user) => user.archived==false);

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "0",

            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                      <AutoComplete
          style={{ margin: "1rem", width: "25rem" }}
          autoFocus
          value={selectedKeys[0]}
          options={[...new Set(users.map(user => user.name))].map(name => ({ value: name }))}

          onSearch={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          onSelect={(value) => {
            setSelectedKeys([value]);
            confirm();
          }}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          placeholder="Search"
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
                       
                        <Button
                            style={{ margin: "1rem" }}
                            type="primary"
                            onClick={() => {
                                confirm();
                            }}
                        >
                            Search
                        </Button>
                        <Button
                            style={{ marginRight: "1rem" }}
                            onClick={() => {
                                clearFilters({ confirm: true });
                                setSelectedKeys("");
                                confirm();
                            }}
                        >
                            Reset
                        </Button>
                    </>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },

            onFilter: (value, users) => {
                return users?.name.toLowerCase().includes(value.toLowerCase());
            },
            
        },

        {
            title: "Email",
      
            key: "1",
            render: (_, users) => {

                return (

                    <Space size={'large'} >
                        <span>{users.email}</span>
                        <Tooltip
                            title={"Edit User Email Id"}
                        >
                            <EditOutlined hidden={!currentUser.privileges.includes('team_management')} onClick={() => { emailEditHandler(users) }} />
                        </Tooltip>


                    
                    </Space>



                )


            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            style={{ margin: "1rem", width: "25rem" }}
                            autoFocus
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                            placeholder="Search"
                        ></Input>
                        <Button
                            style={{ margin: "1rem" }}
                            type="primary"
                            onClick={() => {
                                confirm();
                            }}
                        >
                            Search
                        </Button>
                        <Button
                            style={{ marginRight: "1rem" }}
                            onClick={() => {
                                clearFilters({ confirm: true });
                                setSelectedKeys("");
                                confirm();
                            }}
                        >
                            Reset
                        </Button>
                    </>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },

            onFilter: (value, users) => {
                return users?.email.toLowerCase().includes(value.toLowerCase());
            },
        }, 
        
        {
            title: "Phone",
            dataIndex: "phone",
            key: "2",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "2",
            render: (_, users) => {
                const str1 = users?.role;

                var i, frags = str1.split('-');
                for (i = 0; i < frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
            filters: [
                { text: "Engineer", value: "engineer" },
                { text: "Super Admin", value: "super-admin" },
                { text: "Admin", value: "admin" },
                { text: "Manager", value: "manager" },
                { text: "Purchase", value: "purchase" },
                { text: "Account", value: "account" },
            ],

            onFilter: (value, record) => record.role.includes(value),
        },
            
    
        {
            title: "Action",
            dataIndex: "users",
            key: "3",
            render: (_, users) => {

               return (

                   <Space size={'large'} >
                       <Tooltip
                           title={"Edit Team"}
                       >
                           <EditOutlined hidden={!currentUser.privileges.includes('team_management')}  onClick={() => { editHandler(users) }} />
                       </Tooltip>


                       <Tooltip
                           title={"Archive User"}
                       > <Popconfirm
                           title="Archive this user??"
                           onConfirm={() => ArchiveUser(users._id)}
                       >

                               <DeleteOutlined
                                   hidden={!currentUser.privileges.includes('payment_management')}
                                   loading={submitLoading && deleteId == users._id}
                                   onClick={() => setCurrentDeleteId(users._id)}
                               />

                           </Popconfirm>
                       </Tooltip>

                      
                   </Space>

            )


            },
        },

    ];


    const onEmailChange =(values)=>{

        setLoading(true)

        changeUserEmailApi(values).finally(()=>{

            setLoading(false)

        })

    }

    return (
        <>
            <Table
                rowClassName={'rowFontColor'}
                columns={columns}
                dataSource={activeUsers}
                pagination={true}
                rowKey={'_id'}
            />




            <Modal
                destroyOnClose={true}
                footer={[]}
                style={{ marginLeft: "10rem" }}
                centered
                visible={emailModalOpen}
                onOk={() => {
                    setEmailModalOpen(false);
                }}
                onCancel={() => {
                    setEmailModalOpen(false);
                }}
                width={850}
            >
                <Divider>Edit Email</Divider>
           
                <Form form={form}   name="form" onFinish={onEmailChange} autoComplete="off">
                    <Space>
                        <Form.Item
                            label="Email"
                            name="email"
                            labelCol={{ span: 24, offset: 0 }}
                            style={{ width: "42rem" }}
                            rules={[
                                {
                                    required: true,
                                    message: "Missing Note",
                                },
                            ]}
                        >
                            <Input type={"email"} />
                        </Form.Item>

                        <Form.Item  
                            hidden
                            label=""
                            name="user_id"
                            labelCol={{ span: 24, offset: 0 }}
                            style={{ width: "42rem" }}
                            rules={[
                                {
                                    required: true,
                                    message: "Missing Note",
                                },
                            ]}
                        >
                            <Input type={"text"} />
                        </Form.Item>

                        <Button
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                            style={{ marginTop: "1rem" }}
                        >
                            Confirm
                        </Button>
                    </Space>
                </Form>
            </Modal>




        </>
    );
};

export default TeamTable;
