import create from "zustand";
import { devtools } from "zustand/middleware";

const categoryStore = (set) => ({
    categories: [],

    addCategory: (newCategory) => {
        set((state) => ({
            categories: [newCategory, ...state.categories],
        }));
    },
    setCategories: (apicategories) => {
        set((state) => ({
            categories: [...apicategories],
        }));
    },

    updateCategory: (editData) => {
        set((state) => ({
            categories: state.categories.map((category) => {
                if (category._id === editData._id) {
                    return {
                        ...category,
                        name: editData.name,
                        description: editData.description,
                    };
                } else {
                    return category;
                }
            }),
        }));
    },

});

const useCategoryStore = create(devtools(categoryStore));

export default useCategoryStore
