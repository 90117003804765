import { Button, Card, Col, PageHeader, Skeleton, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import useDashboardStore from "stores/dashboardStore";
import DashboardApi from "utils/composable/dashboardApi";
import { PlusOutlined, RedoOutlined } from "@ant-design/icons";

const DashBoard = () => {
  const reports = useDashboardStore((state) => state.reports);

  const { getReports } = DashboardApi();

  useEffect(() => {
    setLoading(true);

    getReports().finally(() => {
      setLoading(false);
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const refresh = () => {
    setRefreshLoading(true);

    getReports().finally(() => {
      setRefreshLoading(false);
    });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Dashboard"
        extra={[
          <Button
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          ></Button>,
        ]}
      />
      <div
        style={{
          margin: "0 auto",
          display: "flex",
        }}
        className="site-statistic-demo-card"
      >
        <Col span={6}>
          <Card
            loading={loading}
            style={{
              alignContent: "center",
              justifyContent: "center",
              textAlign: "center",
              boxShadow: "1px 3px 15px -4px rgba(0,0,0,0.15)",
              margin: ".5rem",
            }}
          >
            <Statistic
              title="Active Clients"
              value={reports.active_clients}
              valueStyle={{
                color: "#black",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            />
          </Card>
        </Col>

        <Col span={6}>
          <Card
            loading={loading}
            style={{
              border: "none",
              alignContent: "center",
              justifyContent: "center",
              textAlign: "center",
              boxShadow: "1px 3px 15px -4px rgba(0,0,0,0.15)",
              margin: ".5rem",
            }}
          >
            <Statistic
              title={"New Tasks"}
              style={{
                alignContent: "center",
                justifyContent: "center",
                border: "none",
                textAlign: "center",
              }}
              value={reports.new_tasks}
              valueStyle={{
                color: "#black",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            />
          </Card>
        </Col>

        <Col span={6}>
          <Card
            loading={loading}
            style={{
              border: "none",
              alignContent: "center",
              justifyContent: "center",
              textAlign: "center",
              boxShadow: "1px 3px 15px -4px rgba(0,0,0,0.15)",
              margin: ".5rem",
            }}
          >
            <>
              <Statistic
                title={"Products with Defective"}
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                  border: "none",
                  textAlign: "center",
                }}
                value={reports.products_with_defective}
                valueStyle={{
                  color: "#black",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              />
            </>
          </Card>
        </Col>

        <Col span={6}>
          <Card
            loading={loading}
            style={{
              border: "none",
              alignContent: "center",
              justifyContent: "center",
              textAlign: "center",
              boxShadow: "1px 3px 15px -4px rgba(0,0,0,0.15)",
              margin: ".5rem",
            }}
          >
            <>
              <Statistic
                title={"Tasks in Progress"}
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                  border: "none",
                  textAlign: "center",
                }}
                value={reports.tasks_in_progress}
                valueStyle={{
                  color: "#black",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              />
            </>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default DashBoard;
