import { message } from "antd";
import axios from "axios/axios";
import useDefectiveProductStore from "stores/defectiveProductStore";
import useProductStore from "stores/productStore";
import useRentStore from "stores/rentStore";
import authHeader from "utils/services/auth-header";




const RentApi = () => {




    const { 
        addRentToStore, setRentToStore, setRentHistoryToStore, setRentWithUsToStore, closeRentInStore, ChangeProductInStore } = useRentStore((state) => ({
            addRentToStore: state.addRent,
            setRentToStore:state.setRent,
           setRentHistoryToStore: state.setRentHistory,
            setRentWithUsToStore: state.setRentWithUsToStore,
            closeRentInStore:state.closeRent,
            ChangeProductInStore: state.ChangeProductInStore
           
        // updateProductInStore:state.updateProduct
     
    }));


    const {  SwitchRentProductInDefective } = useDefectiveProductStore((state) => ({

        
        SwitchRentProductInDefective: state.SwitchRentProductInDefective

    }));




    const {removeProductFromStore } = useProductStore((state) => ({
        removeProductFromStore: state.removeProductFromStore,
          

         

        }));

    const addRent = async (data) => {

     

        const { client, description, payment_cycle, product, rented_date,price}=data


        removeProductFromStore(data)

        return new Promise((resolve, reject) => {
            axios
                .post("/product/rent", {price, client, description, payment_cycle, product, rented_date }, {
                    headers: authHeader(),
                })
                .then((resp) => {
           

                    resp.data.rent.clientProduct = resp.data.clientProduct._id
                    resp.data.rent.client_product = resp.data.clientProduct._id
                    resp.data.rent.client=data.clientData
                    resp.data.rent.client=data.clientData
                    resp.data.rent.product=data.productData
                    resp.data.rent.barcode = resp.data.rent.clientProduct.barcode
                    addRentToStore(resp.data.rent);
                    removeProductFromStore(data)
                    resolve(resp.data.message); })
                .catch((error) => {
                    console.log(error.response.data.message);
                    reject(error.response.data.message);
                });
        });
    };


    const getRents = async () => {

            return new Promise((resolve, reject) => {
                axios
                    .get("/product/rent", { headers: authHeader() })
                    .then((response) => {
           
                        setRentToStore(response.data.rented_products);
                        resolve();
                    })
                    .catch((err) => {

                        console.log(err);

                        reject(err.response.data.message);
                    });
            });
        // }
}



    const changeProduct = async (rent_id, product,rent) => {


    

        return new Promise((resolve, reject) => {
            axios
                .put("/product/rent/product", { rent_id: rent_id, product: product },{ headers: authHeader() })
                .then((response) => {
          
                    // setRentToStore(response.data.rented_products);
                    
                    ChangeProductInStore(rent_id, response.data.clientProduct, response.data.companyProduct, response.data.rent)
                    SwitchRentProductInDefective(rent.client_product)
                    resolve();
                    message.success(response?.data?.message)
                })
                .catch((err) => {

                    console.log(err);

                    reject(err.response.data.message);
                });
        });
        
    }




    const closeRent = async (rentId) => {


        return new Promise((resolve, reject) => {
            axios
                .post("/product/rent/close", {
                rent_id:rentId
                },{ headers: authHeader() })
                .then((response) => {
                
                    // setRentToStore(response.data.rented_products);
                  
                    message.success(response.data.message)
                    closeRentInStore(rentId)
                    resolve();
                })
                .catch((err) => {

                    console.log(err);

                    reject(err.response.data.message);
                });
        });
        // }

    }



    const getRentHistory = async () => {

        return new Promise((resolve, reject) => {
            axios
                .get("/product/rent?closed=true", { headers: authHeader() })
                .then((response) => {
               
                    // setRentToStore(response.data.rented_products);
                    resolve(response.data.rented_products);
                    setRentHistoryToStore(response.data.rented_products)
                })
                .catch((err) => {

                    console.log(err);
                    message.error(err.response.data.message)
                    reject(err.response.data.message);
                });
        });
        // }


    }



    const getRentWithUs = async () => {

        return new Promise((resolve, reject) => {
            axios.get("/product/rent?returned=true", { headers: authHeader() })
                .then((response) => {
                
                    // setRentToStore(response.data.rented_products);
                    resolve(response.data.rented_products);
                    setRentWithUsToStore(response.data.rented_products)
                })
                .catch((err) => {

                    console.log(err);
                    message.error(err.response.data.message)
                    reject(err.response.data.message);
                });
        });
        // }
    }




    return {
        addRent, getRents, getRentHistory, getRentWithUs, closeRent, changeProduct
}};

export default RentApi;
