import create from "zustand";
import { devtools } from "zustand/middleware";

const userStore = (set) => ({
    users: [],
    archivedUser:[],

    addUser: (newUser) => {
        set((state) => ({
            users: [newUser, ...state.users],
        }));
    },
    setUsers: (apiUsers) => {
        set((state) => ({
            users: [...apiUsers],
        }));
    },
    setArchivedUsers: (apiUsers) => {
        set((state) => ({
            archivedUser: [...apiUsers],
        }));
    },

    updateUser: (editData) => {
        set((state) => ({
            users: state.users.map((user) => {
                if (user._id === editData._id) {
                 
                    return {
                       
                        ...editData,
                      
                    };
                } else {
                    return user;
                }
            }),
        }));
    },


    archiveUserinStore: (userId,archive) => {


        set((state) => ({
            users: state.users.map((user) => {
                if (user._id === userId) {
                   
                    user.archived=true
                    return user
                } else {
                    return user;
                }
            }),

            archivedUser:[ ...state.archivedUser,state.users.find((user)=>user._id === userId)]

        }));
    },

    restoreUserinStore: (userId) => {


        set((state) => ({
            users: state.users.map((user) => {
                if (user._id === userId) {
                    
                    user.archived = false
                    return user
                } else {
                    return user;
                }
            }),

            archivedUser: state.archivedUser.map((user) => {
                if (user._id === userId) {
                   
                    user.archived = false
                    return user
                } else {
                    return user;
                }
            }),


        }));
    },


    changeUserEmailinStore: (userId,email) => {


        set((state) => ({

            users: state.users.map((user) => {

                if (user._id === userId) {
              
                    user.email = email
                    return user
                } else {
                    return user;
                }
            }),

        }));
    }

});

const useUserStore = create(devtools(userStore));

export default useUserStore
