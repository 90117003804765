import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ContactsOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  ProfileOutlined,
  SearchOutlined,
  WarningOutlined
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Divider,
  Input,
  Modal,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import useTaskStore from "stores/taskStore";
import TaskJobsTable from "./TaskJobsTable";

const TaskTable = ({
  jobCancelLoading,
  taskCancelLoading,
  cancelJob,
  cancelTask,
  closeTask,
  refresh,
  setMoredata,
  setModalVisible,
  editMode,
  taskcancelled,
  currentUser,
}) => {
  const tasks = useTaskStore((state) => state.tasks);

  

  const [clientNames, setClientNames] = useState([]);
  const [filteredClientNames, setFilteredClientNames] = useState([]);

  const [teamNames, setTeamNames] = useState([]);

    const [taskSearchValue, setTaskSearchValue] = useState('');
  const [clientSearchValue, setClientSearchValue] = useState('');

  const [jobs, setJobs] = useState("");
  const [currentTask, setCurrenttask] = useState("");
  const [jobModalVisible, setJobModalVisible] = useState(false);
  
  const showMoreData = (task) => {
    setModalVisible(true);
    setMoredata(task);
  };

  const ShowMoreData = (currentTask) => {
    const ids = currentTask?.assigned?.map((o) => o._id);
    const uniqueAssignedFe = currentTask?.assigned?.filter(
      (fe, index) => !ids.includes(fe._id, index + 1)
    );

    Modal.info({
      title: "More",
      content: (
        <div>
          <Divider>Assigned Engineers</Divider>

          {uniqueAssignedFe?.map((Fe) => (
            <>
              <Space size={27}>
                <span style={{ fontWeight: "bolder" }}>{Fe?.name}</span>:{" "}
                <span style={{ float: "left" }}>{Fe?.phone} </span>
              </Space>
              <br></br>
              <br></br>
            </>
          ))}
        </div>
      ),
      onOk() {},
    });
  };



  useEffect(() => {
    const names = [...new Set(tasks.map(task => task.client.name))];
    setClientNames(names);
    setFilteredClientNames(names);

    const teamNames = [...new Set(tasks.flatMap(task => task.assigned.map(fe => fe.name)))];
    setTeamNames(teamNames);

   
  }, [tasks]);

  useEffect(() => {
    setFilteredData(false);
  }, []);

  const handleClientSearch = (value) => {
    const lowercasedValue = value.toLowerCase();
    setFilteredClientNames(clientNames.filter(name =>
      name.toLowerCase().includes(lowercasedValue)
    ));
  };

  const [filteredData, setFilteredData] = useState([]);
  const [currentQuery, setCurrentQuery] = useState("");

  const fuzzyFilter = (query) => {


    setCurrentQuery(query);

    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
 
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {


      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;

      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length >= 2) {
      results = [];

      for (j = 0, len = tasks.length; j < len; j++) {
        var task = tasks[j];
        relevance = string_similarity(query, task.client?.name || "");
        obj = {
          ...task,
          relevance: relevance,
        };
        results.push(obj);
      }

      var sortedResults = results.filter((result) => result.relevance > 0.1);

      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();

   

      setFilteredData(sortedResults);

    }
  };

  useEffect(() => {
    if (!taskcancelled) {
      if (filteredData.length) {
      

        fuzzyFilter(currentQuery);
      }
    }
  }, []);

  const handleCancelTask = (taskId, task) => {
    Modal.confirm({
      title: "Confirm",
      content: "Cancel this Task ?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        cancelTask(taskId, task);
        setCurrenttask(taskId);
      },
    });
  };


  const handleCloseTask = (taskId, task)=>{

    Modal.confirm({
      title: "Confirm",
      content: "Close this Task ?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        closeTask(taskId, task);
        setCurrenttask(taskId);
      },
    });


  }

  const showEngineerData = (tasks) => {
    Modal.info({
      title: "Contact Details",
      content: (
        <div>
          <Divider> </Divider>

          {
            <>
              <p>{tasks.contact.name}</p>
              <p>{tasks.contact.phone}</p>
              <p>{tasks.contact.email}</p>
            </>
          }
        </div>
      ),
      onOk() {},
    });
  };

  const columns = [
    
    {
      title: "Task No",
      dataIndex: "task_number",
      key: "1",
      width: "3%",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
          {selectedKeys[0]}
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },

      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
     
        return record?.task_number.toString()?.includes(value.toString());
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      render: (_, tasks) => tasks?.client?.name,
      key: "1",
      width: "40%",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            {selectedKeys[0]}
          <AutoComplete
            style={{ margin: "1rem", width: "25rem" }}
            autoFocus
            options={filteredClientNames.map(name => ({ value: name }))}
            value={selectedKeys[0] || clientSearchValue}
            onSearch={(value) => {
              setSelectedKeys(value ? [value] : []);
            }}
            onChange={(value) => {
              setSelectedKeys(value ? [value] : []);
              setClientSearchValue(value);
              handleClientSearch(value);
            }}
            onSelect={(value) => {
              setSelectedKeys([value]);
              confirm();
            }}
            placeholder="Search"
          />
              <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
               // fuzzyFilter(selectedKeys[0]);
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters();
                setFilteredClientNames(clientNames);
                setSelectedKeys([]);
                setClientSearchValue('');
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
  
        return record.client?.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Contact",
      dataIndex: "contacts",
      key: "5",
      render: (_, tasks) => (
        <Space>
          <p>{tasks?.contact?.name}</p>
          <ContactsOutlined
            onClick={() => showEngineerData(tasks)}
            type="primary"
            style={{ marginBottom: "1rem" }}
          />
        </Space>
      ),
      width: "10%",
    },

    {
      title: "Status",
      key: "4",
      dataIndex: "status",
      render: (_, tasks) => {
        const str1 = tasks?.status;

        var i,
          frags = str1.split("_");
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(" ");
      },
      width: "10%",

      filters: [
        { text: "Assigned", value: "assigned" },
        { text: "In Progress", value: "in_progress" },
        { text: "Unable to Complete", value: "unable_to_complete" },
        { text: "Unassigned", value: "unassigned" },
        { text: "Completed", value: "completed" },
        { text: "Closed", value: "closed" },
      ],

      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: "Date",
      dataIndex: "due_date",
      key: "2",
      render: (_, tasks) => {
        return moment(tasks?.due_date).utc().format("DD-MM-YYYY");
      },
      width: "10%",
    },/*
    {
      title: "Address",
      dataIndex: "address",
      key: "3",
      width: "20%",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.address.toLowerCase().includes(value.toLowerCase());
      },
    },
*/
    {
      title: "Assigned",
      // dataIndex: "assigned",
      key: "6",
      width: "25%",
      render: (record) => {
        const ids = record?.assigned.map((o) => o?._id);
        const uniqueAssignedFe = record?.assigned?.filter(
          (fe, index) => !ids.includes(fe?._id, index + 1)
        );

        return (
          <>
            <Space>
              {" "}
              <Tag> {uniqueAssignedFe[0]?.name || "Unassigned"} </Tag>
              {uniqueAssignedFe[1]?.name && (
                <Tag> {uniqueAssignedFe[1]?.name} </Tag>
              )}
            </Space>

            {uniqueAssignedFe?.length > 2 ? (
              <Button onClick={() => ShowMoreData(tasks)} type="link">
                More..
              </Button>
            ) : (
              ""
            )}
          </>
        );
      },
      align: "center",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
         
            <AutoComplete
          style={{ margin: "1rem", width: "25rem" }}
          autoFocus
          value={selectedKeys[0]}
          options={teamNames.map(name => ({ value: name }))}
          onSearch={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          onSelect={(value) => {
            setSelectedKeys([value]);
            confirm();
          }}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          placeholder="Search"
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
        <Button
          style={{ margin: "1rem" }}
          type="primary"
          onClick={() => {
            confirm();
          }}
        >
          Search
        </Button>
        <Button
          style={{ marginRight: "1rem" }}
          onClick={() => {
            clearFilters({ confirm: true });
            setSelectedKeys("");
            confirm()
          }}
        >
          Reset
        </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        // Check if assigned is an array and has at least one element
          if (!Array.isArray(record.assigned) || record.assigned.length === 0) {
            return false; // If there's no assigned data, return false to filter it out
        }
          // Extract names and join them into a single string
         const names = record.assigned.map(fe => fe.name).join(' ');
     
      // Perform the filtering
    return names
    .replace(/[^a-zA-Z]/g, "")
    .toLowerCase()
    .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Jobs",
      // dataIndex: "jobs",
      key: "6",
      render: (tasks) => {
        const hasInstallationPending = tasks?.jobs.some(job => job.installation_pending);
        return (
          <>
            <Space size={3}>
              <ProfileOutlined
                onClick={() => {
                  setJobs(tasks.jobs);
                  setCurrenttask(tasks);
                  setJobModalVisible(true);
                }}
              />{" "}
              ({tasks?.jobs.length} {hasInstallationPending && (
                <Tooltip title="Installation pending jobs">
                  <WarningOutlined style={{ color: "red", marginLeft:2, fontSize: 'small' }} />
                </Tooltip>
              )}){" "}
            </Space>
          </>
        );
      },
      align: "center",
      width: "5%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "7",
      render: (_, tasks) => {
        const str1 = tasks.type;

        var i,
          frags = str1.split("_");
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(" ");
      },
      align: "center",
      width: "11  %",
      filters: [
        { text: "Complaint", value: "complaint" },
        { text: "Product Delivery", value: "product_delivery" },
        { text: "Bill Delivery", value: "bill_delivery" },
        { text: "Inventory Check", value: "inventory_check" },
        { text: "Maintenance Service", value: "maintenance_service" },
      ],

      onFilter: (value, record) => record.type.includes(value),
    },

    {
      title: "Action",
      key: "8",
      render: (_, tasks) => (
        <>
          <Space size="large">
            {tasks.status === "cancelled" ||
            tasks.status === "completed" ||
            tasks.status ==="closed" ? (
              <Tooltip title={"Show Task"}>
                <EyeOutlined onClick={() => editMode(tasks)} key={tasks._id} />
              </Tooltip>
            ) : (
              <Tooltip title={"Edit Task"}>
                <EditOutlined
                  hidden={!currentUser?.privileges?.includes("task_management")}
                  onClick={() => editMode(tasks)}
                  key={tasks._id}
                />
              </Tooltip>
            )}
            <Tooltip title={"More Info"}>
              <InfoCircleOutlined onClick={() => showMoreData(tasks)} />
            </Tooltip>
            <Tooltip title={"Cancel Task"}>
              <Button
                icon={<CloseCircleOutlined />}
                loading={taskCancelLoading && currentTask === tasks._id}
                hidden={
                  (tasks.status !== "assigned" &&
                    tasks.status !== "unassigned") ||
                  !currentUser?.privileges?.includes("task_management")
                }
                onClick={() => handleCancelTask(tasks._id, tasks)}
              />
            </Tooltip>
            <Tooltip title={"Close Task"}>
              <Button

                icon={<CheckCircleOutlined/>}
                loading={taskCancelLoading && currentTask === tasks._id}
                hidden={tasks.status !== "completed" }
                onClick={() => handleCloseTask(tasks._id, tasks)}
              />
            </Tooltip>
          </Space>
        </>
      ),
      align: "center",
      width: "5%",
    },
  ];

  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      ></Space>

      <Divider orientation="left"> </Divider>

      <Table
        pagination={{ defaultPageSize: "20", showLessItems: "true" }}
        rowKey={"_id"}
        columns={columns}
        dataSource={filteredData ? filteredData : tasks}
      />

      <Modal
        destroyOnClose={true}
        footer={[]}
        title={"Jobs"}
        centered
        visible={jobModalVisible}
        onOk={() => {
          setJobModalVisible(false);
        }}
        onCancel={() => {
          setJobModalVisible(false);
        }}
        width={1200}
      >
        <TaskJobsTable
          setJobModalVisible={setJobModalVisible}
          jobCancelLoading={jobCancelLoading}
          cancelJob={cancelJob}
          jobs={jobs}
          currentTask={currentTask}
        />
      </Modal>
    </>
  );
};

export default TaskTable;
