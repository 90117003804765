import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const parseJwt = (token) => {

    
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

const AuthVerify = (props) => {



    const navigate = useNavigate();

    


    let location = useLocation();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));

        if (user) {
            const decodedJwt = parseJwt(user.token);

         

            if (decodedJwt.exp * 1000 < Date.now()) {
                localStorage.removeItem('user')
                navigate('/login')
            }
        }
    }, [location]);

    return;
};

export default AuthVerify;