const plans = () => {
  return [
    {
      title: "WARRANTY",
      value: "WARRANTY",
      _id: "WARRANTY",
      description: "Service for 6 months",
    },

    {
      title: "ASC",
      value: "ASC",
      description: "asc",
      _id: "ASC",
    },

    {
      title: "AMC",
      value: "AMC",
      description: "AMC",
      _id: "AMC",
    },

    {
      title: "PCB",
      value: "PCB",
      description: "pcb",
      _id: "PCB",
    },

    {
      title: "RENT",
      value: "RENT",
      description: "rent",
      _id: "RENT",
    },
  ];
};

export default plans;
