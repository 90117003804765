import create from "zustand";
import { devtools } from "zustand/middleware";

const productStore = (set) => ({
    Products: [],

    foundProduct: {},
    addProduct: (newProduct) => {
        set((state) => ({
            Products: [newProduct, ...state.Products],
        }));
    },
    setProduct: (apiProducts) => {

        var filteredProducts=apiProducts

        set((state) => ({
            

            Products: [...filteredProducts],
        }));
    },

    updateProduct: (editData) => {
        set((state) => ({
            Products: state.Products.map((Product) => {
                if (Product._id === editData._id) {
                    return {
                       ...editData
                    };
                } else {
                    return Product;
                }
            }),
        }));
    },
     removeProductFromStore: (rentData) => {

    
        set((state) => ({


            Products: state.Products.filter((product)=>product._id!==rentData.product)
        }));
    }
});

const useProductStore = create(devtools(productStore));

export default useProductStore;
;
