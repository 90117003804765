import { message } from "antd";
import axios from "axios/axios";
import useStandByReqStore from "stores/standByReqStore";
import authHeader from "utils/services/auth-header";


const StandByApi = () => {



    const {
        setRequestsToStore, updateRequestInStore } = useStandByReqStore((state) => ({
  
            setRequestsToStore: state.setRequest,
            updateRequestInStore:state.updateRequest

        }));


 

    const getStandByRequest = async () => {
        return new Promise((resolve, reject) => {
            axios
                .get("/stand-by-product-requests", { headers: authHeader() })
                .then((response) => {
            
                    setRequestsToStore(response.data.requests.reverse());
                    resolve(response.data.requests);
                })
                .catch((err) => {

                    console.log(err);

                    reject(err.response.data.message);
                });
        });
     }

    const getHistoricalStandByRequest = async () => {
        return new Promise((resolve, reject) => {
            axios
                .get("/historical-stand-by-product-requests", { headers: authHeader() })
                .then((response) => {
                    resolve(response.data.requests);
                })
                .catch((err) => {

                    console.log(err);

                    reject(err.response.data.message);
                });
        });
     }

    const updateStandByRequest = async (data) => {

     
        return new Promise((resolve, reject) => {
            axios
                .put("/request-stand-by-product", data, { headers: authHeader() })
                .then((resp) => {
            
                    updateRequestInStore(resp.data.request);

                    resolve(resp.data.request);
                })
                .catch((err) => {
                    message.error(err.response.data.message)
            
                    reject(err.response.data.message);
                });
        });
    };



    return {  getStandByRequest,getHistoricalStandByRequest,updateStandByRequest };
};

export default StandByApi;
