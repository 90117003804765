import jsPDF from 'jspdf';
import moment from 'moment-timezone';

export const generatePdf = (task) => {
  const doc = new jsPDF();
  const pageHeight = doc.internal.pageSize.height || 297;
  
  const formatType = (type) => {
    return type
      .split('_') // Split by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join words with space
  };

  let engineers = {};

  if(task.assigned.length){
     engineers = task.assigned.reduce((acc, user) => {
      acc[user._id] = user.name;
      return acc;
    }, {});
  }

 

  // Two Column Layout
  const leftColumnX = 10;
  const rightColumnX = 150;
  let yStart = 40;

  const addPage = () => {
    doc.addPage();
    yStart = 20; // reset yStart for the new page
  };

  const checkPageOverflow = (currentY) => {
    if (currentY > pageHeight - 30) {
      addPage();
      return yStart;
    }
    return currentY;
  };

  // Header
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.text('Service Report', 105, 20, { align: 'center' });

  // Left Column
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  doc.line(leftColumnX, yStart - 5 , leftColumnX + 26, yStart - 5 ); // Draw horizontal line
  doc.text('Circutek', leftColumnX, yStart);
  doc.line(leftColumnX, yStart +1 , leftColumnX + 26, yStart+1  ); // Draw horizontal line
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.text('The name that service built', leftColumnX, yStart + 7);
  doc.text('www.circutek.com', leftColumnX, yStart + 14);

  // Right Column
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.text(`Task No: ${task.task_number}`, rightColumnX, yStart);
  doc.text(`Date: ${moment(task.due_date).utc().format('DD-MM-YYYY')}`, rightColumnX, yStart + 7);
  doc.text(`Type: ${formatType(task.type)}`, rightColumnX, yStart + 14);

  // Full Width Column for Client and Contact
 
 
// Client Name with Plan
doc.setFontSize(10);
doc.setFont('helvetica', 'normal');
doc.text(`Client: ${task.client?.name} `, leftColumnX, yStart + 30);

// Add plan in bold
if(task.client?.plan){
    doc.text(`Plan: ${task.client?.plan || ''}`, leftColumnX + doc.getStringUnitWidth(`Client: ${task.client?.name} `) * 12, yStart + 30);
}


  // Contact Name with Phone Number
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.text(`Contact: ${task.contact?.name} (${task.contact?.phone})`, leftColumnX, yStart + 37);

  // Set the border style
  doc.setLineWidth(0.1); // Line width
  doc.setDrawColor(0, 0, 0); // Border color (black)
  
  const pageWidth = doc.internal.pageSize.width - 20; // Full width of the page minus margins
  doc.line(leftColumnX, yStart + 42, leftColumnX + pageWidth, yStart + 42); // Draw horizontal line

  yStart += 46;
  const lineHeight = 5;
  if (task.description) {
    yStart += 10;
    // Split the description into lines that fit within the maxWidth
    const descriptionLines = doc.splitTextToSize(`Description: ${task.description}`, pageWidth);
    // Set your desired line height (e.g., 12)
  

  // Loop through each line and render it with the custom line height
  descriptionLines.forEach((line, index) => {
    doc.text(line, leftColumnX, yStart + (index * lineHeight));
  });
    
    //  doc.text(descriptionLines, leftColumnX, yStart);
      
      // Increase yStart based on the number of lines rendered
    yStart += descriptionLines.length * 7; // Adjust the 7 based on line height
  }



  if(task.jobs.length > 0){


  doc.setFontSize(12);
  doc.setFont('helvetica', 'bold');
  doc.text('Jobs', 105, yStart, { align: 'center' });

  yStart += 7;
   
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    let loopStartY = yStart;
    task.jobs.forEach((job, index) => {

       // Initialize variables to store started_time and completed_time
      let started_time = null;
      let completed_time = null;
      if(job.logs.length > 0){
        // Loop through the logs to find the first 'started' log and the last 'complete' log
        job.logs.forEach(log => {
          // Check for the first log with the relevant text and save the time
          if (log.text.includes("Job status changed from Assigned to In Progress") && !started_time) {
            started_time = moment(log.time).tz('Asia/Kolkata').format('DD-MM-YYYY h:mm:ss A');
          }
          
          // Check for the last log with type 'complete' and save the time
          if (log.type === 'complete') {
            completed_time = moment(log.time).tz('Asia/Kolkata').format('DD-MM-YYYY h:mm:ss A');
          }
        });
      }
        // Set initial vertical position for the job entry
        let currentY = checkPageOverflow(loopStartY);

        doc.setFont('helvetica', 'bold');
        doc.text(`Job No: ${job.job_number}`, leftColumnX, currentY);
        doc.setFont('helvetica', 'normal');
        doc.text(`Type: ${formatType(job.type)}`, leftColumnX + 40, currentY);
        doc.text(`Engineer: ${engineers[job.assigned]}`, leftColumnX + 100, currentY );
        if(started_time && completed_time){
          doc.text(`Started on: ${started_time}`, leftColumnX, currentY + 7);
          doc.text(`Completed on: ${completed_time}`, leftColumnX + 100, currentY + 7);
          currentY = currentY + 7;
        }
        doc.text(`Product: ${job.product.name} (${job.product.plan || 'PCB'})`, leftColumnX, currentY + 9);
        // Description (if exists)
        if (job.description) {
          let descriptionJobLines = doc.splitTextToSize(`Description: ${job.description}`, pageWidth);

        

        // Loop through each line and render it with the custom line height
          descriptionJobLines.forEach((line, index) => {
            doc.text(line, leftColumnX, currentY + 16 + (index * lineHeight));
          });
    
   
           currentY += descriptionJobLines.length * 7; // Adjust the 7 based on line height
      
            currentY += 16; // Move Y position down by description height plus some margin
        } else {
            currentY += 16; // Move Y position down by fixed space if no description
        }
         // Notes (if any)
        if (job.notes && job.notes.length > 0) {

            doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.text('Notes', leftColumnX, currentY + 3);
                currentY += 10; 
                doc.setFont('helvetica', 'normal');// Move Y position down for the notes title
            job.notes.forEach(note => {
              let notesLines = doc.splitTextToSize(note.text, pageWidth);
              doc.text(`*`, leftColumnX, currentY + 1);
              notesLines.forEach((line, index) => {
                doc.text(line, leftColumnX + 2, currentY + (index * lineHeight));
              });
                currentY += 7; // Move Y position down for each note
            });
        }
        // Set the border style
        /*
        doc.setLineWidth(0.1); // Line width
        doc.setDrawColor(0, 0, 0); // Border color (black)
        doc.setLineDashPattern([3, 3]); // Dotted line pattern

        doc.line(leftColumnX, currentY , leftColumnX + pageWidth, currentY ); // Draw horizontal line
         */
   //     currentY += 7; // Update based on content height
       

        currentY = checkPageOverflow(currentY +7 );
        loopStartY = currentY;
    });
    yStart = loopStartY;
  }
  /*
  // Collect unique assigned engineers based on IDs
  const uniqueEngineers = [];
  const uniqueIds = new Set();

  task.assigned?.forEach(engineer => {
      if (!uniqueIds.has(engineer._id)) {
          uniqueIds.add(engineer._id);
          uniqueEngineers.push(engineer);
      }
  });

  // Render the unique assigned engineers
  uniqueEngineers.forEach((engineer, index) => {
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(`Engineer: ${engineer.name}`, leftColumnX, yStart );

    doc.text('Signature:', 140, yStart );

    yStart += 10;
});
*/
doc.setLineDashPattern([]);
doc.line(leftColumnX, yStart, leftColumnX + pageWidth, yStart); 
doc.setFontSize(9);
doc.text('* Call for Service on: 41622002,41622003,4162200425356025', leftColumnX, yStart + 8 );
doc.text('* Data Backup is very IMPORTANT. CIRCUTEK is not responsible for Data Backup or loss of data under any Circumstances.', leftColumnX, yStart + 14 );
doc.text('* Repeated calls to be Re- Registered and Escalated to our Service Managers', leftColumnX, yStart + 20 );
doc.text('* This is an electronically generated document and does not require a signature.', leftColumnX , yStart + 26 );
doc.line(leftColumnX, yStart + 34, leftColumnX + pageWidth, yStart + 34); 


  // Save the PDF as a Blob
  const pdfBlob = doc.output('blob');
  // Create a URL for the Blob
  const pdfUrl = URL.createObjectURL(pdfBlob);

  // Open the PDF in a new tab
  window.open(pdfUrl, '_blank');
};
