import { message } from "antd";
import axios from "axios/axios";
import useClientProductStore from "stores/clientProductStore";
import useClientStore from "stores/clientStore";
import authHeader from "utils/services/auth-header";


const ClientApi = () => {
  const { setClientToStore, updateClientToStore, addClientToStore, AddNewPlanToStore } = useClientStore((state) => ({
    setClientToStore: state.setClient,
    addClientToStore: state.addClient,
    updateClientToStore: state.updateClient,
      AddNewPlanToStore: state.AddNewPlanToStore
  }));

  const { setClientProductsToStore, updateClientProductsToStore, bulkUpdateClientProductsToStore
} = useClientProductStore((state) => ({
    setClientProductsToStore: state.setClientProducts,
  updateClientProductsToStore: state.updateClientProductsToStore,
  bulkUpdateClientProductsToStore: state.bulkUpdateClientProductsToStore,
  
  }));


  const getClients = (log) => {

    return new Promise((resolve, reject) => {

      try {
        axios
          .get("/clients", { headers: authHeader() })
          .then((response) => {
            setClientToStore(response.data.clients);
            
            resolve()
          }).catch((err)=>{
            message.error(err.response.data.message)
            reject()
          })
          .finally(() => {});
      } catch (error) {
        console.log(error);
      }
  
    })
  };


 
  const addClient = async (data) => { 
  
    return new Promise((resolve, reject) => {
          axios
            .post("/client",data, { headers: authHeader() })
            .then((response) => {
      
              addClientToStore(response.data.client);
              resolve(response.data.message)
            }).catch((error) => {
              reject(error.response.data.message)
            })
            .finally(() => { });
      
    })

};


  const updateClient = async (data) => {
    return new Promise((resolve, reject) => {
      
      axios
        .put("/client", data, { headers: authHeader() })
        .then((response) => {
          updateClientToStore(response.data.client);
          resolve(response.data.message)
        })
        .catch((error) => {
          reject(error.response.data.message)
          console.log(error);
        });
    })

  };


  const getSingleClient = async (client_id) => {
    return new Promise((resolve, reject) => {

      axios
        .get("/client/?client_id="+ client_id,  { headers: authHeader() })
        .then((response) => {

          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response.data.message)
          message.error(error.response.data.message)
          console.log(error);
        });
    })

  };


  const clientProducts = async (clientId,clientLocationId) => {


    return new Promise((resolve, reject) => {
      axios
        .get(`/client/products?client=${clientId}&active=true&client_location=${clientLocationId ? clientLocationId :''}`,  { headers: authHeader() })
        .then((response) => {

          setClientProductsToStore(response.data.client_products)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response.data.message)
          message.error(error.response.data.message)
          console.log(error);
        });
    })

  };


  const bulkUpdateApi=(data)=>{

    delete data.payment_cycle
    return new Promise((resolve, reject) => {
    axios
      .put(`/client/product/bulk-update`,data, { headers: authHeader() })
      .then((response) => {
  
        bulkUpdateClientProductsToStore(data)
        message.success(response.data.message)
        resolve(response.data)
      })
      .catch((error) => {
        reject(error.response.data.message)
        message.error(error.response.data.message)
        console.log(error);
      });
  })


  }



  const newPlanSubmitApi =(data)=>{

    return new Promise((resolve, reject) => {

      axios
        .post(`/client/plan`, data, { headers: authHeader() })
        .then((response) => {
  
          AddNewPlanToStore(data)
          message.success(response.data.message)
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message)
          message.error(error.response.data.message)
          console.log(error);
        });

 
    })
    
  }


  const newAdditionalPlanSubmitApi = (data) => {


    return new Promise((resolve, reject) => {
      axios
        .post(`/client/additional-plan`, data, { headers: authHeader() })
        .then((response) => {

          // AddNewPlanToStore(data)
          message.success(response.data.message)
          resolve(response)
        })
        .catch((error) => {
          message.error(error.response.data.message)
          reject(error.response.data.message)
          console.log(error);
        });
    })

  }



  const updateClientProducts = async (data) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/client/product",data,{ headers: authHeader()})
        .then((response) => {
  
          updateClientProductsToStore(response?.data?.client_product)
          resolve(response?.data?.message)
        })
        .catch((error) => {
          reject(error?.response?.data?.message)
          message.error(error.response.data.message)
          console.log(error);
        });
    })
  };



  return { getClients, addClient, updateClientProducts, updateClient, clientProducts, bulkUpdateApi, newPlanSubmitApi, newAdditionalPlanSubmitApi, getSingleClient };

}
export default ClientApi;
