import {
  Button,
  Card,
  Divider,
  message,
  Modal,
  PageHeader,
  Skeleton,
  Space,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import WareHouseForm from "components/warehouse/WarehouseForm";
import { useNavigate } from "react-router-dom";
import useWarehouseStore from "stores/warehouseStore";
import WareHouseApi from "utils/composable/warehouseApi";
import "./pageHeaderStyle.css";
const WareHouse = ({ currentUser}) => {
  const [visible, setVisible] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [cardLoadings, setCardLoadings] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [editData, setEditData] = useState("");

  const navigate = useNavigate();

  const warehouses = useWarehouseStore((state) => state.warehouses);
  const { addWarehouse, getWarehouse, updateWarehouse } =
    WareHouseApi();

  //notification
  const vendorAdded = (msg) => {
    message.success(msg);
  };
  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  const addNewVendor = () => {
    setEditData(false);
    showModal();
  };

  const handleCancel = () => {
    setEditData("");
    setVisible(false);
    setName("");
    setDescription("");
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const editMode = (warehouse) => {
    setVisible(true);
    setEditData(warehouse);
  };

  const submitHandler = (values, form) => {
    setLoadings(true);

    try {
      addWarehouse(values)
        .then((msg) => {
          setLoadings(false);
          setVisible(false);
          vendorAdded(msg);
          form.setFieldsValue({
            name: "",
            email: "",
            phone: "",
            gstin: "",
            vat: "",
            is_active: true,
            pan: "",
            address: "",
            notes: "",
            state: "Karnataka",
            city: editData.city || "",
          });
        })
        .catch((msg) => {
          setLoadings(false);
          errorMsg(msg);
        });
    } catch (error) {
      setLoadings(false);
      console.log(error);
    }
  };

  const editHandler = (values, form) => {
    setLoadings(true);

    try {
      updateWarehouse(values)
        .then((msg) => {
          setLoadings(false);
          setVisible(false);
          editSuccessful(msg);
          form.resetFields();
        })
        .catch((msg) => {
          setLoadings(false);
          errorMsg(msg);
        });
    } catch (error) {
      setLoadings(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!warehouses.length) {
      setCardLoadings(true);
      getWarehouse()
        .then((response) => {
          setCardLoadings(false);
        })
        .catch((err) => {
          setCardLoadings(false);
        });
    }
  }, []);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Warehouse"
      />

      <Divider orientation="center">
        <Button
          disabled={!currentUser.user.privileges.includes('warehouse_creation')}
        type="primary" icon={<PlusOutlined />} onClick={addNewVendor}>
          Add Warehouse
        </Button>
      </Divider>
      {/* 
            {tableLoading ? (
                <Skeleton active loading={tableLoading} />
            ) : (
                    <WareHouseTable editMode={editMode}  />
            )} */}

      <Modal
        destroyOnClose={true}
        width={1000}
        footer
        title={editData ? "Edit Warehouse" : "Enter Warehouse Details"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <WareHouseForm
          submitHandler={submitHandler}
          submitLoading={loadings}
          confirmEditHandler={editHandler}
          name={name}
          description={description}
          editData={editData}
          handleCancel={handleCancel}
        />
      </Modal>

          <Skeleton active loading={cardLoadings}>
      <Space size={20} direction="horizontal" wrap>
        {warehouses.map((warehouse) => {
          return (

              <Card
                style={{
                  cursor: "pointer",
                  width: 366,
                  marginTop: 16,
                  boxShadow: "0px 8px 12px -9px rgba(0,0,0,0.18)",
                }}
                onClick={() =>
                  navigate("/warehouse/" + warehouse?._id, {
                    state: warehouse,
                  })
                }
                key={warehouse._id}

                actions={[  
                  <EditOutlined
                    onClick={() => editMode(warehouse)}
                    key={warehouse._id}
                  />,
                ]}
              >
               
                  <h3 style={{ color: "#01336b", fontWeight: "bolder" }}>
                    {warehouse.title}
                  </h3>
                  <span> {warehouse.address}</span>
             
              </Card>
         
          );
        })}
      </Space>
         </Skeleton>
    </div>
  );
};

export default WareHouse;
