import {
  Button,
  Checkbox,
  Input,
  Modal,
  Space,
  Table,
  Timeline,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";

import {
  EyeOutlined,
  EditOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import useClientProductStore from "stores/clientProductStore";
import useCategoryStore from "stores/categoryStore";
import moment from "moment";
import humanize from "utils/formate-status";
import useUserStore from "stores/teamStore";

const ProductsTable = ({
  editHandler,
  showSpecs,

  products,
  state,
  setBulkUpdatestrue,
  setSelectedRowKeys,
  selectedRowKeys,
  currentUser,
}) => {
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);

  const [currentQuery, setCurrentQuery] = useState("");
  const [currentFilterPlan, setCurrentFilterPlan] = useState(false);
  const [currentFilterStatus, setCurrentFilterStatus] = useState(false);
  const [currentFilterCategory, setCurrentFilterCategory] = useState(false);
  const users = useUserStore((state) => state.users);

  function humanizeStatus(str) {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, "")
      .replace(/[_\s]+/g, " ")
      .replace(/[-\s]+/g, " ")
      .replace(/[\s_]+/g, " ")
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase();
      });
  }

  const filterizeCategory = (products) => {
    let array = products?.map((product) => {
      return product?.category?.name;
    });

    let uniqueArray = [...new Set(array)];
    let filter = uniqueArray.map((element) => {
      return {
        text: element,
        value: element,
      };
    });

    return filter;
  };

  const filterizeStatus = (products) => {
    console.log(products, "products");
    let array = products?.map((product) => {
      return product?.status;
    });

    let uniqueArray = [...new Set(array)];

    let filter = uniqueArray.map((element) => {
      return {
        text: humanize(element),
        value: element,
      };
    });

    return filter;
  };

  const filterizeLocation = (products) => {
    let array = products?.map((product) => {
      return product?.client_location?.name || "Primary Location";
    });

    let uniqueArray = [...new Set(array)];

    let filter = uniqueArray.map((element) => {
      return {
        text: element,
        value: element,
      };
    });

    return filter;
  };

  useEffect(() => {
    let categoryFilter = filterizeCategory(products);

    console.log(categoryFilter, "filter");

    setCategoryFilter(categoryFilter);

    let statusFilter = filterizeStatus(products);
    let locationFilter = filterizeLocation(products);

    setStatusFilter(statusFilter);
    setLocationFilter(locationFilter);

    console.log({ statusFilter, categoryFilter }, "filters");
  }, [products]);

  useEffect(() => {
    if (filteredData.length) {
      fuzzyFilter(currentQuery);
    }
  }, [products]);

  const logTimeline = (data) => {
    console.log(data.plan_logs, "Logs");
    Modal.info({
      width: "50rem",

      title: "Product Logs",
      content: (
        <Timeline mode={"left"}>
          {data.logs.map((log) => {
            console.log(log, "logs");

            var color;
            var locaTime = new Date(log.time);
            var time = locaTime.toString();

            if (log.vendor_transferred_date) {
              color = "#007FFF";
            } else if (log.vendor_transferred_date) {
              color = "green";
            } else {
              color = "#5D76A9";
            }

            function formatTimeShow(h_24) {
              var h = h_24 % 12;
              if (h === 0) h = 12;
              return (
                h + `: ${time.substr(19, 2)}` + (h_24 < 12 ? " am" : " pm")
              );
            }

            var exactTime = formatTimeShow(parseFloat(time.substr(15, 10)));
            var text;
            var suffix = "";

            text = "by  " + users.find((user) => user._id == log.by).name;

            return (
              <Timeline.Item
                style={{ fontSize: "15px" }}
                dot={
                  log.type == "created" ? (
                    <CheckOutlined />
                  ) : log.type == "cancelled" ? (
                    "X"
                  ) : (
                    ""
                  )
                }
                color={color}
              >
                {suffix} {log?.text} {text}{" "}
                <span style={{ fontWeight: "200" }}> on</span>{" "}
                <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                  {" "}
                  {time.substr(3, 12)}, {exactTime}{" "}
                </span>
              </Timeline.Item>
            );
          })}
        </Timeline>
      ),
      onOk() {},
    });
  };

  //Fuzzy filter function

  var [filteredData, setFilteredData] = useState(false);

  const fuzzyFilter = (query) => {
    setCurrentQuery(query);

    console.log(query);

    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1?.length > 0 && str2?.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length > 2) {
      results = [];

      for (j = 0, len = products.length; j < len; j++) {
        var product = products[j];
        relevance = string_similarity(query, product?.name || "");
        obj = {
          ...product,
          relevance: relevance,
        };
        results.push(obj);
      }

      var sortedResults = results.filter((result) => result.relevance > 0.2);

      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();

      console.log({ sortedResults });

      products = [...sortedResults];

      setFilteredData(sortedResults);
      console.log(results);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "1",

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0]);
              }}
              onBlur={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, products) => {
        return products?.name.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: "Brand",
      dataIndex: "brand",
      key: "2",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "3",
      render: (_, products) => products?.category?.name,
      filters: categoryFilter,
      onreset: () => {
        setCurrentFilterCategory(false);
      },
      onFilter: (value, products) => {
        setCurrentFilterCategory(value.toLowerCase());

        return products?.category?.name?.includes(value);
      },
    },

    {
      title: "Plan",
      dataIndex: "category",
      key: "4",
      render: (_, products) => products?.plan,
      filters: [
        { text: "AMC", value: "AMC" },
        { text: "ASC", value: "ASC" },
        { text: "WARRANTY", value: "WARRANTY" },
        { text: "RENT", value: "RENT" },
        { text: "PCB", value: "PCB" },
      ],
      onreset: () => {
        setCurrentFilterPlan(false);
      },
      onFilter: (value, products) => {
        setCurrentFilterPlan(value.toLowerCase());
        return products?.plan?.includes(value);
      },
    },
    {
      title: "Active",
      dataIndex: "category",
      key: "4",
      render: (_, products) => (products.is_active ? "Yes" : "Noca"),
    },
    {
      title: "Status",
      key: "5",
      render: (_, products) => humanizeStatus(products.status),
      filters: statusFilter,
      onFilter: (value, products) => {
        setCurrentFilterStatus(value.toLowerCase());
        return products.status.includes(value);
      },
      onreset: () => {
        setCurrentFilterStatus(false);
      },
    },

    {
      title: "Start Date",
      dataIndex: "category",
      key: "6",
      render: (_, products) => {
        const date = moment.utc(products?.start_date).format("DD-MM-YYYY");

        if (products.start_date) {
          return date;
        } else {
          return " ";
        }
      },
    },

    {
      title: "Barcode",
      dataIndex: "barcode",
      key: "8",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                setFilteredData(false);
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, products) => {
        return products?.barcode?.toLowerCase()?.includes(value?.toLowerCase());
      },
    },

    {
      title: "Client Location",

      key: "9",
      render: (_, products) => {
        return products?.client_location?.name || "Primary Location";
      },
      filters: locationFilter,
      onFilter: (value, products) => {
        // setCurrentFilterStatus(value.toLowerCase())

        if (value == "Primary Location") {
          if (products?.client_location == null) return true;
        } else {
          return products?.client_location?.name.includes(value);
        }
      },
      onreset: () => {
        setCurrentFilterStatus(false);
      },
    },

    {
      title: "Action",
      dataIndex: "",
      key: "10",
      render: (products) => (
        <Space key={products?._id} size="middle">
          <Tooltip title={" Edit"}>
            <EditOutlined
              hidden={
                selectedRowKeys.length ||
                (products.plan == "RENT") |
                  !currentUser.privileges.includes("client_management")
              }
              key={products?._id}
              onClick={() => {
                editHandler(products);
                setBulkUpdatestrue(false);
              }}
            >
              {" "}
            </EditOutlined>
          </Tooltip>

          <Tooltip title={" Product Specification"}>
            <InfoCircleOutlined
              onClick={() => showSpecs(products.specifications)}
            />{" "}
          </Tooltip>

          <Tooltip title={"Logs"}>
            <FieldTimeOutlined
              size={"large"}
              onClick={() => {
                logTimeline(products);
              }}
              style={{ paddingLeft: "0.5rem" }}
            >
              {" "}
            </FieldTimeOutlined>{" "}
          </Tooltip>
        </Space>
      ),
    },
  ];

  //Selection

  const handleSelect = (record, selected) => {
    console.log({ record, selected });

    if (selected) {
      if (record.plan !== "RENT") {
        setSelectedRowKeys((keys) => [...keys, record._id]);
      }
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record._id);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  console.log(selectedRowKeys, "selectedrowkeyss");

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) => {
      if (filteredData) {
        if (currentFilterPlan || currentFilterStatus || currentFilterCategory) {
          return keys.length ===
            filteredData.filter(
              (product) =>
                product.plan?.toLowerCase().includes(currentFilterPlan) ||
                product.status?.toLowerCase().includes(currentFilterStatus) ||
                product.category?.name
                  ?.toLowerCase()
                  .includes(currentFilterCategory)
            )?.length
            ? []
            : filteredData
                .filter(
                  (filter) =>
                    filter.plan.toLowerCase().includes(currentFilterPlan) ||
                    filter.status.toLowerCase().includes(currentFilterStatus) ||
                    filter?.category?.name
                      ?.toLowerCase()
                      ?.includes(currentFilterCategory)
                )
                .filter((product) => product.plan !== "RENT")
                .map((r) => r._id);
        } else {
          return keys.length ===
            filteredData.filter((product) => product.plan !== "RENT").length
            ? []
            : filteredData
                .filter((product) => product.plan !== "RENT")
                .map((r) => r._id);
        }
      } else {
        if (currentFilterPlan || currentFilterStatus || currentFilterCategory) {
          console.log({ currentFilterPlan, currentFilterStatus });
          return keys.length ===
            products.filter(
              (product) =>
                product?.plan?.toLowerCase().includes(currentFilterPlan) ||
                product?.status?.toLowerCase().includes(currentFilterStatus) ||
                product?.category?.name
                  ?.toLowerCase()
                  ?.includes(currentFilterCategory)
            )?.length
            ? []
            : products
                .filter(
                  (product) =>
                    product.plan?.toLowerCase().includes(currentFilterPlan) ||
                    product.status
                      ?.toLowerCase()
                      .includes(currentFilterStatus) ||
                    product?.category?.name
                      ?.toLowerCase()
                      ?.includes(currentFilterCategory)
                )
                .filter((product) => product.plan !== "RENT")
                .map((r) => r._id);
        } else {
          return keys.length ===
            products.filter((product) => product.plan !== "RENT").length
            ? []
            : products
                .filter((product) => product.plan !== "RENT")
                .map((r) => r._id);
        }
      }
    });
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < products.length
      }
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox",
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,

    getCheckboxProps: (record) => {
      return {
        disabled: record.plan == "RENT",
      };
    },
    //onSelectAll: this.handleSelectAll
  };

  return (
    <>
      <Table
        rowSelection={!state.ASC_AMC && rowSelection}
        rowClassName={"rowFontColor"}
        columns={columns}
        dataSource={filteredData ? filteredData : products}
        pagination={true}
        rowKey={(record) => record._id}
      />
    </>
  );
};

export default ProductsTable;
