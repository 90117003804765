import {
  Button,
  Divider,
  Input,
  Modal,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import useProductStore from "stores/productStore";
import {
  EyeOutlined,
  EditOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import useSalesStore from "stores/salesStore";
import moment from "moment";
const SalesTable = ({ editHandler, showSaleTask }) => {
  const sales = useSalesStore((state) => state.sales).filter(
    (sale) => sale.pending_delivery === true && sale.task
  );

  const products = useProductStore((state) => state.Products);

  //Fuzzy filter function

  const [filteredData, setFilteredData] = useState(false);

  const fuzzyFilter = (query, productSearch, clientProduct) => {
    console.log(query);

    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length >= 2) {
      results = [];

      for (j = 0, len = sales.length; j < len; j++) {
        var sale = sales[j];

        if (productSearch) {
          relevance = string_similarity(query, sale?.name || "");
        } else if (clientProduct) {
          relevance = string_similarity(
            query,
            sale?.client_product?.name || ""
          );
        } else {
          relevance = string_similarity(query, sale?.client?.name || "");
        }

        obj = {
          ...sale,
          relevance: relevance,
        };
        results.push(obj);
      }

      var sortedResults = results.filter((result) => result.relevance > 0.2);

      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();

      console.log({ sortedResults });

      sale = [...sortedResults];

      setFilteredData(sortedResults);
      console.log(results);
    }
  };

  const columns = [
    {
      title: "Product",
      key: "2",
      render: (product) => product?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0], true);
              }}
              onBlur={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Client",
      dataIndex: "client",
      key: "2",
      render: (client) => client?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0]);
              }}
              onBlur={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },
    {
      title: "Barcode",

      render: (data) => data?.barcode,
      key: "2",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.barcode?.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Brand",
      render: (data) => data?.brand,
      key: "3",
      width: "10%",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.brand?.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: "Sale Date",
      dataIndex: "createdAt",
      key: "4",
      render: (record) => moment.utc(record).format("DD-MM-YYYY"),
    },
    {
      title: "Warranty Date",
      dataIndex: "warranty_date",
      key: "4",
      render: (record) => moment.utc(record).format("DD-MM-YYYY"),
    },

    {
      title: "Descriptions",
      dataIndex: "description",
      key: "1",
      width: "20%",
    },

    {
      title: "Price",

      key: "1",
      width: "10%",

      render: (record) => record?.price?.item_price.toFixed(2),
    },

   

    {
      title: "Discount",
      key: "1",
      width: "10%",
      render: (record) => record?.price?.discount_percentage.toFixed(2),
    },
    {
      title: "tax",
      key: "1",
      width: "10%",
      render: (record) => record?.price?.tax_amount.toFixed(2),
    },
    {
      title: "Charges",

      key: "1",
      width: "20%",
      width: "20%",
      render: (record) => record?.price?.charges.toFixed(2),
    },

    {
      title: "Amount",

      key: "1",
      width: "20%",
      width: "20%",
      render: (record) => record?.price?.amount.toFixed(2),
    },

    {
      title: "Action",

      key: "4",
      render: (sale) => (
        <Tooltip title={"Show Task"}>
          <EyeOutlined onClick={() => showSaleTask(sale.task)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Table
        key={sales?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={false}
        // expandable={{
        //     expandedRowRender: (templates) => (
        //         <>
        //             {templates.fields.map((field) => {

        //                 return <Tag key={field._id}>{field.name.toUpperCase()}</Tag>;
        //             })}
        //         </>
        //     ),
        //     rowExpandable: (templates) => templates.fields.length > 3,
        // }}
        dataSource={filteredData ? filteredData : sales}
      />
    </>
  );
};

export default SalesTable;
