import { Button, Form, Input, Space } from "antd";

import TextArea from "antd/lib/input/TextArea";
import React from "react";

const CategoryForm = ({ submitHandler,
                    loadings,
                    name,
                    description,
                    editData,
                    editHandler,
                    handleCancel }) => {


  const [form] = Form.useForm();
  const onFinish = (values) => {


  
    if(!editData){
      submitHandler(values,form);
    }else{
      values._id=editData._id
      editHandler(values,form)
    }
 
  };

  return (
    <Form
    form={form}
    key={editData?._id}
      name="basic"
      initialValues={{
        remember: true,
        name:editData?.name||'',
        description:editData?.description || '',
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
      
        labelCol={{ span: 24, offset: 0 }}
        labelAlign="left"
        label="Name"
        name="name" 
        rules={[
          {
            required: true,
            message: "Please Enter Category Name!",
          },
        ]}
      >
        <Input   />
      </Form.Item>

      <Form.Item
       
        labelCol={{ span: 24, offset: 0 }}
        labelAlign="right"
        label="Description"
        name="description"
       
      >
        <TextArea maxLength={80} placeholder={description}  rows={4} />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 18,
          span: 10,
        }}
   
      >
        <Space size={8} >
          <Button onClick={handleCancel}  >Cancel</Button>
          {editData ? <Button type="primary" htmlType="submit" loading={loadings}>
            Confirm
          </Button   >
            :
            <Button type="primary" htmlType="submit" loading={loadings}>
              Submit
            </Button>}
        </Space>
      
      
      </Form.Item>
    </Form>
  );
};
export default CategoryForm;
