import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Input,
  message,
  PageHeader,
  Select,
  Skeleton,
  Space
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";

import { useLoadScript } from "@react-google-maps/api";

import ServiceTable from "components/service/ServiceTable";
import ServiceApi from "utils/composable/serviceApi";

import ClientApi from "utils/composable/clientApi";

import GoogleMapComponent from "components/googleMap/GoogleMap";
import TaskForm from "components/task/TaskForm";

import useTaskStore from "stores/taskStore";
import TaskApi from "utils/composable/taskApi";
import TeamApi from "utils/composable/teamApi";

import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export default function Maintenance({
  setLocationDetails,
  setMapVisible,
  locationEditData,
  hideAddressBox,
  editData,
  clientForm,
  taskForm,
  clientMap,
  clientLocation,
  currentUser,
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places", "visualization"],
  });

  if (!isLoaded) return <div>Loading...</div>;

  return <MaintenanceLoaded currentUser={currentUser} />;
}

const { Option } = Select;

const MaintenanceLoaded = ({ currentUser }) => {
  const [visible, setVisible] = useState(false);
  //Service Due store
  const { getServiceDueProducts, getServiceDueProductsWithLocation } =
    ServiceApi();

  useEffect(() => {
    getClients();
    // getServiceDueProducts();
  }, []);

  const [buttonVisible, setButtonVisible] = useState(false);

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const [selected, setSelected] = useState("");

  //notification
  const taskAdded = (msg) => {
    message.success(msg);
  };



  const fillForm = () => {
    message.error("Please select Client and Date first ");
  };
  const taskUpdated = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  const [tableLoading, setTableLoadings] = useState(false);

  const [locationDetails, setLocationDetails] = useState("");
  const [locationEditData, setLocationEditData] = useState(true);
  const [hideAddressBox, setHideAddressBox] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState();
  const [editData, setEditData] = useState("");
  const [jobEditMode, setJobEditMode] = useState(false);
  const [jobAddMode, setJobAddMode] = useState(editData ? false : true);
  const [jobViewMode, setJobViewMode] = useState(false);
  const [keywords, setKeywords] = useState(false);
  useState("");

  const [annualServiceTask, setAnnualServicetask] = useState(true);
  const [annualServiceData, setAnnualServiceData] = useState({});

  const [latLng, setLatLng] = useState(null); // State to hold lat/lng
const [distance, setDistance] = useState(''); // State for distance
const [month, setMonth] = useState(''); // State for month
  // for changing googlemap class name
  const [taskForm, setTaskForm] = useState(true);

  //composable
  const { getClients } = ClientApi();
  const { getUsers } = TeamApi();
  const { updateTask, addTask, getTasks, getEngineers } = TaskApi();
  const tasks = useTaskStore((state) => state.tasks);

  const handleCancel = (form) => {
    setVisible(false);
  };

  useEffect(() => {
    getClients();
    getUsers();

    if (!tasks.length) {
      setTableLoadings(true);

      getTasks()
        .then((resp) => {
          console.log(resp);
        })
        .finally(() => {
          setTableLoadings(false);
        });
    }
    setTaskForm(true);
  }, []);

  const ChangeLocation = (LocationEditData) => {
    setLocationEditData(LocationEditData?.location?.coordinates);
  };

  const submitHandler = (payload, form) => {
    console.log(payload, "paylaod");

    payload.jobs.map((job) => {
      if (!job.assigned) {
        delete job.assigned;
      }
    });

    if (!payload.assigned.length) delete payload.assigned;

    console.log(payload, "submit paylaod");
    setsubmitLoading(true);
    addTask(payload)
      .then((msg) => {
        getServiceDueProducts();
        setsubmitLoading(false);
        setVisible(false);
        form.resetFields();
        taskAdded(msg);
        setLocationEditData(false);
        setLocationDetails(false);
      })
      .catch((msg) => {
        setsubmitLoading(false);
        errorMsg(msg);
      });
  };

  const updateHandler = (payload, form) => {
    console.log(payload, "edit payload");
    setsubmitLoading(true);
    updateTask(payload)
      .then((msg) => {
        setsubmitLoading(false);
        setVisible(false);
        form.resetFields();
        taskUpdated(msg);
        setLocationEditData(false);
        setLocationDetails(false);
      })
      .catch((msg) => {
        setsubmitLoading(false);
        errorMsg(msg);
      });
  };

  const getFieldEngineers = (client, date) => {
    if (!client || !date) {
      fillForm();
    } else {
      setAssignLoading(true);
      getEngineers(client, date)
        .then((resp) => {
          setAssignLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAssignLoading(false);
          message.error(err);
        });
    }
  };

  const createAnnualServiceJob = (serviceData, clientProducts) => {
    var tempServiceData = {};
    setAnnualServiceData(serviceData);

    console.log({ serviceData });

    //if  given client is not main location
    if (!serviceData.branch) {
      const jobs = clientProducts
        .filter(
          (product) => product.status == "with_client" && !product?.unregistered
        )
        .map((product) => {
          return {
            productId: product._id,
            status: "unassigned",
            type: "maintenance_service",
            due_date: product.due_date,
            description: "",
          };
        });

      console.log(jobs, "jobsss");

      const productListProp = clientProducts
        .filter((product) => !product.unregistered)
        .map((product) => {
          console.log({ product });

          return {
            label: `${product.name}  ${
              product?.barcode || product.plan ? "-" : ""
            }  ${product?.barcode || ""}     ${
              product.plan ? "(" + product?.plan + ")" || "" : ""
            } `,
            value: product._id,
            key: product.key,
          };
        });

      tempServiceData = {
        ...serviceData,
        jobs: jobs,
        productList: productListProp,
      };
    } else {
      tempServiceData = {
        ...serviceData,
        jobs: [],
        productList: [],
        branch: true,
      };
    }

    setAnnualServiceData(tempServiceData);

    setJobAddMode(true);

    setVisible(true);
  };

  //Refresh
  const [refreshLoading, setRefreshLoading] = useState(false);

  const refresh = () => {
    setRefreshLoading(true);

    getServiceDueProducts().finally(() => {
      setRefreshLoading(false);
    });
  };
  const handleSearch = () => {
     
    if (latLng && month && distance) {
      setTableLoadings(true);
      getServiceDueProductsWithLocation(latLng.lat, latLng.lng, month, distance)
        .finally(() => setTableLoadings(false));
    } else {
      message.error("Please select a location, month, and distance.");
    }
  };
  

  const handleSelect = async (address) => {
    setValue(address, false); // Set value of the combobox
    clearSuggestions();
  
    try {
      const results = await getGeocode({ address });
      if (results) {
        var addressKeywords = results[0].address_components.map(
          (address) => address.long_name
        );
        setKeywords(addressKeywords);
        const { lat, lng } = await getLatLng(results[0]);
        setSelected({ lat, lng });
        setLatLng({ lat, lng }); // Set lat/lng into state
      
      }
    } catch (error) {
      console.error("Geocode error:", error);
      message.error("Error retrieving location. Please try again.");
      setTableLoadings(false);
    }
  };
  

  const handleMonthChange = (date, dateString) => {
    setMonth(dateString); // Handle the month and year value
  };



  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Maintenance"
        extra={[
          <Button
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
          >
            Show all Service Due
          </Button>,
        ]}
      />
       <Space
    className="search-form"
  style={{
    marginBottom: 16,
    marginTop: 16,
    width: '100%',
    justifyContent: 'space-between',
   
  }}
>

<div style={{ flex: '1 1 40%', minWidth: '300px', marginRight: '10px', marginBottom: '10px' }}>

            <Combobox id="combo" 
            className="combobox-search" onSelect={handleSelect}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <ComboboxInput
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    setButtonVisible(false);
                  }}
                  className="combobox-input-search"
                  placeholder="Search Client Location"
                />
              </Space>
              <ComboboxPopover   style={{ marginTop: "11rem", width: "50rem" }}
              portal={false}
              className="ComboboxPopover-search">
                <ComboboxList>
                  {status === "OK" &&
                    data.map(({ place_id, description }) => (
                      <ComboboxOption key={place_id} value={description} />
                    ))}
                </ComboboxList>
              </ComboboxPopover>
            </Combobox>
          
</div>
<div style={{ flex: '1 1 20%', minWidth: '150px', marginRight: '10px', marginBottom: '10px' }}>
<DatePicker
      picker="month" // Only allow selecting month and year
      value={month ? moment(month, "YYYY-MM") : null} // Set the initial value if any
      onChange={handleMonthChange}
      placeholder="Select Month"
      style={{ width: "100%" }}
    />

</div>

<div style={{ flex: '1 1 20%', minWidth: '150px', marginRight: '10px', marginBottom: '10px' }}>
<Input
              type="number"
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              placeholder="Enter distance in km"
            />
</div>
<div style={{ flex: '1 1 20%', minWidth: '100px', marginBottom: '10px' }}>
<Button
          onClick={handleSearch}
          type="primary"
          disabled={!latLng || !month || !distance}
          style={{ width: '100%' }}
          icon={<SearchOutlined />}
        >
          Search
        </Button>
</div>
</Space>

   

      <Skeleton active loading={tableLoading}>
        <ServiceTable
          currentUser={currentUser}
          createAnnualServiceJob={createAnnualServiceJob}
        />
      </Skeleton>

      <Drawer
        destroyOnClose={true}
        width={"100vw"}
        title={"Create Task"}
        size={"large"}
        placement="right"
        onClose={handleCancel}
        visible={visible}
      >
        <Divider style={{ padding: "1rem" }}>Enter Task Details</Divider>

        <TaskForm
          annualServiceTask={annualServiceTask}
          annualServiceData={annualServiceData}
          jobViewMode={jobViewMode}
          // jobSubmitHandler={jobSubmitHandler}
          // jobUpdateHandler={jobUpdateHandler}
          locationDetails={locationDetails}
          submitHandler={submitHandler}
          ChangeLocation={ChangeLocation}
          // editData={editData}
          // updateHandler={updateHandler}
          getFieldEngineers={getFieldEngineers}
          assignLoading={assignLoading}
          setJobLoading={setJobLoading}
          jobLoading={jobLoading}
          setJobEditMode={setJobEditMode}
          jobEditMode={jobEditMode}
          jobAddMode={jobAddMode}
          setJobAddMode={setJobAddMode}
        />

        <Divider style={{ marginBottom: "1rem" }}>Client Location</Divider>

        <GoogleMapComponent
          taskForm={taskForm}
          hideAddressBox={hideAddressBox}
          locationEditData={locationEditData}
          setLocationDetails={setLocationDetails}
        />

        <Button
          className="submitButtonTask"
          form="taskForm"
          key="submit"
          type="primary"
          htmlType="submit"
          loading={submitLoading}
        >
          Submit
        </Button>
      </Drawer>
    </>
  );
};
