import { message } from "antd";
import axios from "axios/axios";
import useUserStore from "stores/teamStore";
import authHeader from "utils/services/auth-header";

const TeamApi = () => {

  const { addUserTostore, setUsersTostore, updateUserTostore, archiveUserinStore, setArchivedUsers, restoreUserinStore, changeUserEmailinStore
} = useUserStore(
    (state) => ({
      addUserTostore: state.addUser,
      setUsersTostore: state.setUsers,
      updateUserTostore: state.updateUser,
    archiveUserinStore: state.archiveUserinStore,
      setArchivedUsers: state.setArchivedUsers,
    restoreUserinStore: state.restoreUserinStore,
    changeUserEmailinStore: state.changeUserEmailinStore

    })
  );

  const users = useUserStore((state) => state.users);

  const getUsers = () => {


      return new Promise((resolve, reject) => {
        axios
          .get("/get-users", { headers: authHeader() })
          .then((response) => {

      
            setUsersTostore(response.data.users);

            resolve();
          })
          .catch((err) => {
            console.log(err);

            reject();
          });
      });
    }
 
  const addUser = (data) => {
 
    return new Promise((resolve, reject) => {

      data.location = {
        type: "Point",
        coordinates: [0,0]
      }

      axios
        .post("/create-user", data, { headers: authHeader() })
        .then((resp) => {
          addUserTostore(resp.data.user);
          resolve(resp.data.message);
        })
        .catch((error) => {
          reject(error.response.data.message);
          console.log(error);
        });
    });
  };

  const forgotPassword = (email) => {

    return new Promise((resolve, reject) => {

   
      axios
        .post("/request-password",{email:email} , { headers: authHeader() })
        .then((resp) => {
          // addUserTostore(resp.data.user);
          resolve(resp.data.message);
          message.success(resp.data.message)
        })
        .catch((error) => {
          reject(error.response.data.message);
          message.error(error.response.data.message)
          console.log(error);
        });
    });
  };

  const updateUser = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/user/update", data, { headers: authHeader() })
        .then((resp) => {
          updateUserTostore(resp.data.user);
          resolve(resp.data.message);
        })
        .catch((error) => {
          reject(error.response.data.message);

          reject();
        });
    });

  };


  const ArchiveUser = (userId) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/archive-user", {user_id:userId}, { headers: authHeader() })
        .then((resp) => {
          archiveUserinStore(userId);
          resolve(resp.data.message);
          message.success("User Archived")
        })
        .catch((error) => {
          reject(error.response.data.message);
          message.error(error.response.data.message)
          reject();
        });
    });

  };

  const restoreUser = (userId,privileges) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/restore-user", { user_id: userId, privileges: [...privileges] }, { headers: authHeader() })
        .then((resp) => {
          restoreUserinStore(userId);
          resolve(resp.data.message);
          message.success("User Restored")
        })
        .catch((error) => {
          reject(error.response.data.message);
          message.error(error.response.data.message)
          reject();
        });
    });

  };
  const getArchivedUser = (userId) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/get-users?archived=true", { headers: authHeader() })
        .then((resp) => {
          setArchivedUsers(resp.data.users);
          resolve(resp.data.users);
        })
        .catch((error) => {
          reject(error.response.data.message);

          reject();
        });
    });

  };


  const changeUserEmailApi = (values) => {

    return new Promise((resolve, reject) => {
      axios
        .put("/user/update-email", { email: values.email, user_id: values.user_id}, { headers: authHeader() })
        .then((resp) => {
          // setArchivedUsers(resp.data.users);
          message.success(resp.data.message)
          changeUserEmailinStore(values.user_id, values.email)
          resolve();
 
        })
        .catch((error) => {
          reject(error.response.data.message);
          message.error(error.response.data.message)
          reject();
        });
    });

  };


  const changePassword = (values) => {


    
    return new Promise((resolve, reject) => {
      axios
        .put("/change-password", { current_password: values.current_password, new_password: values.new_password }, { headers: authHeader() })
        .then((resp) => {
          // setArchivedUsers(resp.data.users);

          // changeUserEmailinStore(values.user_id, values.email)
          message.success("Password Changed Successfully , Please Login with Your New Password ")
       
          resolve(resp);
  
        })
        .catch((error) => {
          message.error(error.response.data.message)
          reject(error);
  
    console.log(error);
          reject();
        });
    });

  };



  return {
    getUsers, addUser, updateUser, ArchiveUser, getArchivedUser, restoreUser, forgotPassword, changeUserEmailApi, changePassword
}
};

export default TeamApi;
