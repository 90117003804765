import {
  Button,
  Divider,
  Form,
  Input,
  PageHeader,
  Space
} from "antd";
import { useRef, useState } from "react";
import Barcode from "react-barcode";

import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import ShortUniqueId from "short-unique-id";

const BarcodeGenerator = () => {
  const [uniqueIds, setUniqueIds] = useState([]);
  const [numberOfBarcodes, setNumberOfBarcodes] = useState(false);

  const ref = useRef();

  const generateBarcodes = (count, copies) => {
    console.log({ count }, { copies });

    var ids = [];

    for (let i = 0; i < parseFloat(count); i++) {
      const newId = new ShortUniqueId({ length: "12", dictionary: "number" });
      let currentNewId = newId();
      for (let j = 0; j < parseInt(copies); j++) {
        console.log({ currentNewId });
        ids = [...ids, currentNewId];
      }

      setUniqueIds(ids);
    }

    console.log(uniqueIds, "outside");
  };

  const onFinish = (data) => {
    console.log({ data }, "data copies count");
    if (data.copies == undefined || data.copies == NaN || data.copies == "")
      data.copies = 1;
    // setCopies(data.copies)
    setNumberOfBarcodes(data.count);
    generateBarcodes(data.count, parseInt(data.copies));
  };

  const labelWidth = 38; // mm
  const labelHeight = 13; // mm
  const ratio = 3; // Choose an appropriate ratio
  const barcodeWidth = labelWidth / ratio;
  const barcodeHeight = labelHeight;


  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Generate Barcode"
        extra={[
          <ReactToPrint
            trigger={() => (
              <Button icon={<PrinterOutlined />} disabled={!numberOfBarcodes}>
                Print
              </Button>
            )}
            content={() => ref.current}
          />,
        ]}
      />
      <Divider>
        {" "}
        <>
          <Form onFinish={onFinish} autoComplete="off">
            <Space>
              <Form.Item
                name="count"
                labelCol={{ span: 24, offset: 0 }}
                label="Enter Barcode Count"
                rules={[
                  {
                    required: true,
                    message: "Missing Barcode Count",
                    Max: 40,
                    min: 1,
                  },
                ]}
              >
                <Input
                  min={1}
                  max={40}
                  style={{ marginLeft: ".5rem", zIndex: "100", width: "10rem" }}
                  type={"number"}
                  placeholder="Max Count 40 "
                />
              </Form.Item>

              <Form.Item
                name="copies"
                labelCol={{ span: 24, offset: 2 }}
                label="Copies Required"
              >
                <Input
                  min={1}
                  initialValue={0}
                  style={{ marginLeft: ".5rem", zIndex: "100", width: "10rem" }}
                  type={"number"}
                  placeholder="1"
                />
              </Form.Item>

              <Form.Item
                name="count"
                labelCol={{ span: 24, offset: 0 }}
                style={{ marginTop: "2.5rem" }}
                label=""
              >
                <Button
                  htmlType="submit"
                  disabled={numberOfBarcodes > 40}
                  style={{ marginLeft: "1rem", zIndex: "100" }}
                  type="primary"
                >
                  Generate
                </Button>{" "}
              </Form.Item>
            </Space>
          </Form>
        </>
      </Divider>

      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
        }}
        ref={ref}
      >
       
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "960px",
            margin: "0 auto",
          }}
        >
          {uniqueIds.map((id) => (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                maxWidth: "",
                margin: "0 auto",
              }}
            >
         <Barcode
                height={17}
                width={1.5}
                fontSize={"15px"}
                value={id}
              ></Barcode>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BarcodeGenerator;
