import {
 
  PageHeader,

} from "antd";
import React from "react";
import Taxtable from "components/taxes/Taxtable";

const Taxes = ({ currentUser}) => {
  const onFinish = (data) => {};

  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Taxes"
      />

      <Taxtable currentUser={currentUser.user}></Taxtable>
    </>
  );
};

export default Taxes;
