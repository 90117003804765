import create from "zustand";
import { devtools } from "zustand/middleware";

const serviceDueProductStore = (set) => ({
    serviceDueProducts: [],


    setServiceDueProduct: (apiServiceDueProductsProducts) => {

        set((state) => ({
            serviceDueProducts: [...apiServiceDueProductsProducts],
        }));

    },

})


const useServiceDueProductStore = create(devtools(serviceDueProductStore));

export default useServiceDueProductStore;

