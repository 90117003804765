import { Button, Input, Space, Table, Tag, Tooltip } from "antd";
import React from "react";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import useTemplateStore from "stores/templateStore";

const TemplateTable = ({ editHandler,currentUser }) => {
  const templates = useTemplateStore((state) => state.templates);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "1",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </> 
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {

        console.log({ record });

        return record?.name.toLowerCase().includes(value.toLowerCase());

      },

    },
    {
      title: "Category",
      dataIndex: "category",
      render: (category) => category?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </> 
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {

        console.log({ record });

        return record?.category?.name.toLowerCase().includes(value.toLowerCase());

      },
      key: "2",
    },
    {
      title: "Fields",
      key: "3",
      dataIndex: "fields",
      render: (_, { fields }) => (
        <>
          {fields.slice(0, 6).map((field, id) => {
            if (id > 4) {
              return (
             "...."
              );
            } else {
              return <Tag key={field._id}>{field?.name.toUpperCase()} </Tag>;
            }
          })}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (templates) => (
        <Space key={templates._id} size="middle">





          <Tooltip
            title={" Edit"}
          >

            <EditOutlined
              hidden={!currentUser.privileges.includes('product_management')}
              key={templates._id}
              onClick={() => editHandler(templates)}
            />

          </Tooltip>

    
        </Space>
      ),
    },
  ];

  return (
    <>


      <Table
        key={templates._id}
        rowKey={"_id"}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (templates) => (
            <>
              {templates.fields.map((field) => {
                
                return <Tag key={field._id}>{field?.name?.toUpperCase()}</Tag>;
              })}
            </>
          ),
          rowExpandable: (templates) => templates?.fields.length > 3,
        }}
        dataSource={templates}
      />
    </>
  );

};
export default TemplateTable;
