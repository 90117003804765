import {
  EyeOutlined,

  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import useProductStore from "stores/productStore";


import moment from "moment";
import useRentStore from "stores/rentStore";
import RentApi from "utils/composable/rentApi";
const RentTable = ({ editHandler, showRentTask }) => {
  const rentHistory = useRentStore((state) => state.rentHistory)
  console.log({ rentHistory }, "rentHistory");

  const [rents,setRents]=useState([])
  const { getRentHistory}=RentApi()
  const products = useProductStore((state) => state.Products);
  const [tableLoading,setTableLoading]=useState(false)




  //Fuzzy filter

  const [filteredData, setFilteredData] = useState(false)
  const fuzzyFilter = (query, product) => {
    console.log(query);
    var get_bigrams, j, len, obj, relevance, results, string_similarity;


    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };


    string_similarity = function (str1, str2) {

      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length > 2) {

    results = [];

    for (j = 0, len = rents.length; j < len; j++) {
      var rent = rents[j];

      if (product) {

        relevance = string_similarity(query, rent?.product?.name || "");
      } else {

        relevance = string_similarity(query, rent?.client?.name || "");
      }

      obj = {
        ...rent,
        relevance: relevance
      };
      results.push(obj);
    }


    var sortedResults = results.filter((result) => result.relevance > 0.2)
    sortedResults = sortedResults.sort((a, b) => a.relevance - b.relevance).reverse()
    console.log({ sortedResults });
    rent = [...sortedResults]
    setFilteredData(sortedResults)
    console.log(results);
  }
  }



  useEffect(() => {
    


    if (!rentHistory.length){
      setTableLoading(true)

      getRentHistory().then((rentHistory) => {

        setTableLoading(false)
        console.log({ rentHistory });
        setRents(rentHistory)

      }).finally(() => {
        setTableLoading(false)

      })

    }

 
   
  }, [])
  



  const columns = [

    
    {
      title: "Product",
      dataIndex: "product",
      key: "2",
          render: (product) => product?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0],true)
              }}
              onBlur={() => {

                fuzzyFilter(selectedKeys[0], true);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0], true);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false)
                setSelectedKeys("");
                confirm()
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name.replace(/[^a-zA-Z]/g, "").toLowerCase().includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Client",
      dataIndex: "client",
      key: "2",
      render: (client) => client?.name,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {



        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0])
              }}
              onBlur={() => {

                fuzzyFilter(selectedKeys[0]);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false)
                setSelectedKeys("");
                confirm()
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name.replace(/[^a-zA-Z]/g, "").toLowerCase().includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },


      {
          title: "Barcode",

          render: (data) => data.product?.barcode,
          key: "2",
          filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
          }) => {
              return (
                  <>
                      <Input
                          style={{ margin: "1rem", width: "25rem" }}
                          autoFocus
                          value={selectedKeys[0]}
                          onChange={(e) => {
                              setSelectedKeys(e.target?.value ? [e.target.value] : []);
                          }}
                          onPressEnter={() => {
                              confirm();
                          }}
                          onBlur={() => {
                              confirm();
                          }}
                          placeholder="Search"
                      ></Input>
                      <Button
                          style={{ margin: "1rem" }}
                          type="primary"
                          onClick={() => {
                              confirm();
                          }}
                      >
                          Search
                      </Button>
                      <Button
                          style={{ marginRight: "1rem" }}
                          onClick={() => {
                              clearFilters({ confirm: true });
                              setSelectedKeys("");
                              confirm();
                          }}
                      >
                          Reset
                      </Button>
                  </>
              );
          },
          filterIcon: () => {
              return <SearchOutlined />;
          },

          onFilter: (value, record) => {
              console.log({ record }, "record");

              return record?.product?.barcode
                  ?.toLowerCase()
                  .includes(value.toLowerCase());
          },
      },
      {
          title: "Brand",
          render: (data) => data.product?.brand,
          key: "3",
          filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
          }) => {
              return (
                  <>
                      <Input
                          style={{ margin: "1rem", width: "25rem" }}
                          autoFocus
                          value={selectedKeys[0]}
                          onChange={(e) => {
                              setSelectedKeys(e.target?.value ? [e.target.value] : []);
                          }}
                          onPressEnter={() => {
                              confirm();
                          }}
                          onBlur={() => {
                              confirm();
                          }}
                          placeholder="Search"
                      ></Input>
                      <Button
                          style={{ margin: "1rem" }}
                          type="primary"
                          onClick={() => {
                              confirm();
                          }}
                      >
                          Search
                      </Button>
                      <Button
                          style={{ marginRight: "1rem" }}
                          onClick={() => {
                              clearFilters({ confirm: true });
                              setSelectedKeys("");
                              confirm();
                          }}
                      >
                          Reset
                      </Button>
                  </>
              );
          },
          filterIcon: () => {
              return <SearchOutlined />;
          },

          onFilter: (value, record) => {
              console.log({ record }, "record");

              return record?.product?.brand?.toLowerCase().includes(value.toLowerCase());
          },
      },

   
    {
      title: "Rented Date",

      key: "4",
      render: (record) => moment.utc(record?.rented_date).format('DD-MM-YYYY')  
    },
   
    

    {
      title: "Descriptions",
      dataIndex: "description",
      key: "1",
     width:'20%'
    },

    {
      title: "Price",
  
      key: "1",
      width: '20%',

      render: (record) => record?.price?.item_price.toFixed(2)
    },



    {
      title: "Discount",
      key: "1",
      width: '20%',
      render: (record) => record?.price?.discount_percentage.toFixed(2),
    },
    {
      title: "tax",

      key: "1",
      width: '20%',
      render: (record) => record?.price?.tax_amount.toFixed(2) ,
    },
    {
      title: "Charges",

      key: "1",
      width: '20%',
      width: '20%',
      render: (record) => record?.price?.charges.toFixed(2)
    },


    {
      title: "Amount",

      key: "1",
      width: '20%',
      width: '20%',
      render: (record) => record?.price?.amount.toFixed(2)
    },


    {
      title: "Action",
      key: "4",
      render: (rent) => (



        <Tooltip
          title={"Show Task"}
        >

          <EyeOutlined onClick={() => showRentTask(rent?.task)} />

        </Tooltip>
   

      ),
    },

    
  ];

  return (
    <>
      <Table
      loading={tableLoading}
        key={rents?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={false}
     
        dataSource={filteredData ? filteredData : rentHistory}
      />
    </>
  );
};

export default RentTable;
