import { Button, Card, Divider, Drawer, message, PageHeader, Skeleton, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, RedoOutlined } from "@ant-design/icons";


import useCategoryStore from "stores/categoryStore";
// import PaymentTable from "components/payment/PaymentTable";

import useWarehouseStore from "stores/warehouseStore";
import PaymentApi from "utils/composable/paymentApi";
// import PaymentForm from "components/payment/PaymentForm";
import usePaymentStore from "stores/paymentStore";
import InvoiceForm from "components/Invoice/InvoiceForm";
import InvoiceApi from "utils/composable/invoiceApi";
import TeamApi from "utils/composable/teamApi";
// import PaymentTable from "components/paymentComponents/Paymenttable";

// import Paymenttable from "components/paymentComponents/Paymenttable";
import Paymentform from "components/paymentComponents/Paymentform";
import Paymenttable from "components/paymentComponents/Paymenttable";
import ClientApi from "utils/composable/clientApi";

const Payments = ({currentUser}) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const categories = useCategoryStore((state) => state.categories);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState("");
  const [tableLoadings, setTableLoadings] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [currentClient, setCurrentClient] = useState("");
  const [selectedproducts, setSelectedProducts] = useState([]);
  const [selectedproductsPriceDetails, setSelectedProductsPriceDetails] = useState([]);

  const [invoiceFormVisible, setInvoiceFormVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  //category store
  const { setCategories } = useCategoryStore((state) => ({
    setCategories: state.setCategories,
  }));
  const warehouses = useWarehouseStore((state) => state.warehouses);

  const { getPayments, updatePayment, createPaymentApi } = PaymentApi();
  const { addInvoice } = InvoiceApi();
  const {getUsers}=TeamApi()
  var payments = usePaymentStore((state) => state.payments);
  const {getClients}=ClientApi()
  const pendingPayments = usePaymentStore((state) => state.payments).filter((payment) => payment.status == 'pending');


  //notification
  const productAdded = (msg) => {
    message.success(msg);
  };
  const editSuccessful = (msg) => {
    message.success(msg);
  };

  const errorMsg = (msg) => {
    message.error(msg);
  };

  const editHandler = (payment) => {
  
    const priceData = {};
    priceData.plan = {};

    priceData.plan.price = payment.price;
    priceData.payment_id = payment._id;
    priceData.description = payment.description;

    setEditData(priceData);
    setVisible(true);
  };



  const createPayment = () => {

    // const priceData = {};
    // priceData.plan = {};
    // priceData.plan.price = payment.price;
    // priceData.payment_id = payment._id;
    // priceData.description = payment.description;

    setEditData(false);
    setVisible(true);
  };

  const confirmEditHandler = (updatePayload, cancel, restore) => {
    setButtonLoading(true);
    setSubmitLoader(true);

    if (cancel) {
      const cancelPayload = { payment_id: updatePayload._id, cancel: true };
      updatePayment(cancelPayload).finally(() => {
        setSubmitLoader(false);
        setButtonLoading(false);
        setEditData(false)
      });
    } else if (restore) {
      const restorePayload = { payment_id: updatePayload._id, restore: true };
      updatePayment(restorePayload).finally(() => {
        setSubmitLoader(false);
        setEditData(false)
        setButtonLoading(false);
      });
    } else {
      updatePayment(updatePayload).then(()=>{
        setEditData(false)
        setVisible(false)
      }).finally(() => {
        setSubmitLoader(false);
        setButtonLoading(false);
      });
    }
  };



  const submitHandler =(values)=>{

    console.log(values,'create Payment Values');

    setButtonLoading(true);
    setSubmitLoader(true);

    createPaymentApi(values).then(() => {

      setVisible(false)

    }).finally(() => {
      setSubmitLoader(false);
      setButtonLoading(false);
    });

  }


  useEffect(() => {


    getClients()

    if (!payments.length) {

      setTableLoadings(true)

      getPayments().then(() => {

        setTableLoadings(false)

       });

    
    }
  }, []);

  const onClose = () => {
    setEditData("");
    setVisible(false);
  };

  //Refresh
  const [refreshLoading, setRefreshLoading] = useState(false);

  const refresh = () => {

    setRefreshLoading(true);

    getPayments().finally(() => {

      setRefreshLoading(false);
    });
  };

  const handleNewInvoiceSubmit = (values) => {
      setSubmitLoader(true)
   
    addInvoice(values).then(()=>{

      setCurrentClient('')
      setSelectedProducts([])
      setSelectedProductsPriceDetails([])
        setSubmitLoader(false)
        setInvoiceFormVisible(false)

    }).finally(()=>{
      setSubmitLoader(false)
      setInvoiceFormVisible(false)

    });
  };



    useEffect(() => {
        getUsers()
    }, [])



  return (
    <>
      <PageHeader
        className="site-page-header"
        style={{ padding: 0, marginTop: -15 }}
        title="Payments"
        extra={[
          <Button
      
            icon={<RedoOutlined />}
            loading={refreshLoading}
            onClick={refresh}
            key="1"
            type="dashed"
            
          ></Button>,
       
            <div style={{marginLeft:'1rem'}} >


              <Statistic
                title="Pending Payments"
                value={pendingPayments.length}
                valueStyle={{ color: "orange", fontSize: "15px", marginLeft: '3rem' }}
              />

            </div>
          
            
        
        ]}
      />

    
      <Divider> 
        
        
        
        <Button onClick={() => createPayment()} >Create Payment</Button>
        
       </Divider>
   
      <Skeleton active loading={tableLoadings}>

        <Button
          type="primary"
          disabled={!selectedproducts?.length || !currentUser?.user?.privileges?.includes('payment_management')}
          loading={refreshLoading}
          onClick={() => {
            setInvoiceFormVisible(true);
          }}
          key="1"
        >
          Create Invoice
        </Button>
        <Paymenttable
          createPayment={createPayment}
        currentUser={currentUser}
        selectedproductsPriceDetails={selectedproductsPriceDetails}
          setSelectedProductsPriceDetails={setSelectedProductsPriceDetails}
          currentClient={currentClient}
          setCurrentClient={setCurrentClient}
          setButtonLoading={setButtonLoading}
          buttonLoading={buttonLoading}
          selectedproducts={selectedproducts}
          setSelectedProducts={setSelectedProducts}
          confirmEditHandler={confirmEditHandler}
          editHandler={editHandler}
        />
      </Skeleton>


      <Drawer
        destroyOnClose={true}
        key={editData?._id}
        size="large"
        title={editData? "Edit Payment":"Create payment"}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <Paymentform
          submitHandler={submitHandler}
          duplicate={duplicate}
          editData={editData}
          submitLoader={submitLoader}
          setVisible={setVisible}
          confirmEditHandler={confirmEditHandler}
        />

      </Drawer>

      <Drawer
        destroyOnClose={true}
        key={editData?._id}
       width={'1000px'}
        title={
          editData
            ? "Edit Invoice "
            : `Create Invoice for ${selectedproducts?.length} Payments for Client ${selectedproductsPriceDetails[0]?.client?.name}  `
        }
        placement="right"
        onClose={() => {
          setInvoiceFormVisible(false);
        }}
        visible={invoiceFormVisible}
      >
        <InvoiceForm
          setVisible={setInvoiceFormVisible}
                  selectedproductsPriceDetails={selectedproductsPriceDetails}
          currentClient={currentClient}
          setCurrentClient={setCurrentClient}
          submitLoader={submitLoader}
          handleNewInvoiceSubmit={handleNewInvoiceSubmit}
          selectedproducts={selectedproducts}
          editData={editData}
        />
      </Drawer>
    </>
  );
};

export default Payments;
