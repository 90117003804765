import { Table,} from "antd";
import React, { useState } from "react";

import moment from "moment";




const DefectiveProductRepaiTable = ({RepairDetails}) => {

   

 
  

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            render: (_, record) => moment(_).format("DD-MM-YYYY"),
            width: "5%",
            key: "2",
        },

        {
            title: "Description",
            dataIndex: "description",
            key: "9",
            width: "3%",
        },

        {
            title: "Status",
            dataIndex: "status",
            width: "10%",
            render: (_, record) => {

                const str1 = record?.status;

                var i,
                    frags = str1.split("-");
                for (i = 0; i < frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(" ");
            },
            filters: [
                { text: "Approved", value: "approved" },
                { text: "Cancelled", value: "cancelled" },
                { text: "Rejected", value: "rejected" },
                { text: "Waiting For Approval", value: "waiting-for-approval" },

            ],

            onFilter: (value, record) => record?.status.includes(value),
            key: "1",
        },
        {
            title: "Item Price",

            key: "9",
            width: "3%",
            render: (_, request) => {
                return Math.trunc(request?.price?.item_price);
            },
        },
        {
            title: "Qty",
            dataIndex: "product",
            key: "9",
            width: "3%",
            render: (_, request) => {
                return Math.trunc(request?.price?.qty);
            },
        },
        {
            title: "Discount",
            dataIndex: "product",
            key: "9",
            width: "3%",
            render: (_, request) => {
                return Math.trunc(request?.price?.discount);
            },
        },
        {
            title: "Tax",
            dataIndex: "product",
            key: "9",
            width: "3%",
            render: (_, request) => {
                return Math.trunc(request?.price?.tax_amount);
            },
        },
        {
            title: "Charge",
            dataIndex: "product",
            key: "9",
            width: "3%",
            render: (_, request) => {
                return Math.trunc(request?.price?.charges);
            },
        },

        {
            title: "Amount",
            dataIndex: "product",
            key: "9",
            width: "3%",
            render: (_, request) => {
                return Math.trunc(request?.price?.amount);
            },
        },
        
    ];

    return (
        <>
           

            <Table
            
                rowKey={"_id"}
                columns={columns}
                pagination={{ defaultPageSize: "5", showLessItems: "true" }}
                dataSource={RepairDetails}
            />


        </>
    );
};

export default DefectiveProductRepaiTable;
