import { Button, Checkbox, Divider, Form, Input, Select, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import privileges from "utils/constants/privileges";

import React, { useEffect, useState } from "react";

const TeamForm = ({
  submitHandler,
  loadings,
  editData,
confirmEditHandler,
  handleCancel,
}) => {
  const [form] = Form.useForm();
  const [buttonVisible, setButtonVisible] = useState(false);
  const [currentRole,setCurrentRole]=useState(false)
  const [defaultOptions, setDefaultOptions] = useState([
    'team_view',
    'team_creation',
    'team_management',
    'client_view',
    'client_creation',
    'client_management',
    'client_product_management',
    'vendor_view',
    'vendor_creation',
    'vendor_management',
    'plan_view',
    'plan_creation',
    'plan_management',
    'product_view',
    'product_creation',
    'product_management',
    'task_view',
  ]);

  const onFinish = (values) => {
    if (!editData) {
      //   values.privileges = CheckedValues;
      console.log(values, "values on finish");
      submitHandler(values, form);
    } else {
      values.user_id = editData._id;
      confirmEditHandler(values,form);
      console.log(values, "edit values");
    }
  };

  const options = [
    {
      label: "Manager",
      value: "manager",
      key: "1",
    },
    {
      label: "Super Admin",
      value: "super-admin",
      key: "2",
    },
    {
      label: "Admin",
      value: "admin",
      key: "3",
    },
    {
      label: "Purchase",
      value: "purchase",
      key: "4",
    },
    {
      label: "Account",
      value: "account",
      key: "5",
    },
    {
      label: "Engineer",
      value: "engineer",
      key: "6",
    },
    {
      label: "Service",
      value: "service",
      key: "7",
    },
    {
      label: "Delivery",
      value: "delivery",
      key: "8",
    },
  ];

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);

    setButtonVisible(true);
  };

  //selecting privileges according to selected role
  const selectPrivileges = (selectedRole) => {
    setDefaultOptions([]);

    setCurrentRole(selectedRole)

    const newPrivilageOptions = privileges.privileges.filter((privilege)=>privilege.role.includes(selectedRole)).map((privilege) => {
      if (privilege.role.includes(selectedRole)) {
        return privilege.value;
      }
    });
    
    setDefaultOptions(newPrivilageOptions);
    console.log(newPrivilageOptions);
    form.setFieldsValue({
      privileges: newPrivilageOptions,
    });
  };

  const resetPrivileges = () => {
    // eslint-disable-next-line no-lone-blocks

    if (editData && currentRole){


      form.setFieldsValue({
        privileges: defaultOptions,
      });


    }else if(editData){

      form.setFieldsValue({
        privileges: editData?.privileges,
      })

    }else{

      form.setFieldsValue({
        privileges: defaultOptions,
      });
    }

   
    setButtonVisible(false);
  };

  useEffect(() => {
   

      form.setFieldsValue({
        
        first_name: editData?.first_name || "",
        last_name: editData?.last_name || "",
        phone: editData.phone || "",
        email: editData?.email || "",
        role: editData?.role || "manager",
        address: editData.address || "",
        privileges: editData?.privileges || ['team_view',
          'team_creation',
          'team_management',
          'client_view',
          'client_creation',
          'client_management',
          'client_product_management',
          'vendor_view',
          'vendor_creation',
          'vendor_management',
          'plan_view',
          'plan_creation',
          'plan_management',
          'product_view',
          'product_creation',
          'product_management',
          'task_view',
          'task_creation',
          'task_management',]
      });

    
  }, [editData]);
  return (
    <Form
      form={form}
      key={editData?._id}
      name="basic"
      initialValues={{
        remember: true,
        first_name: editData?.first_name || "",
        last_name: editData?.last_name || "",
        phone: editData.phone || "",
        email: editData?.email || "",
        role: editData?.role || "manager",
        address: editData.address || "",
        privileges: editData?.privileges,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Space>
        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          labelAlign="left"
          label="First Name"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please Enter First Name!",
            },
          ]}
        >
          <Input style={{ width: "250px" }} type={"text"} />
        </Form.Item>
        <Form.Item
          style={{ width: "250px", marginLeft: "-2rem" }}
          labelCol={{ span: 24, offset: 0 }}
          labelAlign="left"
          label="Last Name"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please Enter Last Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Space>

      <Space>
        <Form.Item

          labelCol={{ span: 24, offset: 0 }}
          labelAlign="left"
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please Enter Email!",
            },
          ]}
        >
          <Input disabled={editData}  style={{ width: "250px" }} type={"email"}  />
        </Form.Item>
        <Form.Item
          style={{ width: "250px" }}
          labelCol={{ span: 24, offset: 0 }}
          labelAlign="left"
          label="Phone"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please Enter Phone Number!",
            },
            {
              len: 10,
              message: "Phone Number Should be 10 Digits",
            },
          ]}
        >
          <Input type={"number"} />
        </Form.Item>
      </Space>

      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        style={{ width: "550px" }}
        labelAlign="left"
        label="Address"
        name="address"
      >
        <TextArea type={"text"} />
      </Form.Item>

      <Form.Item
        style={{ width: "550px" }}
        labelCol={{ span: 24, offset: 0 }}
        labelAlign="right"
        label="Role"
        name="role"
        rules={[
          {
            required: true,
            message: "Please Select a Role",
          },
        ]}
      >
        <Select
          options={options}
          onChange={selectPrivileges}
          placeholder="Select Role"
        />
      </Form.Item>

      <Divider>
        {buttonVisible ? (
          <Button onClick={resetPrivileges}>Reset Privileges</Button>
        ) : (
          "Select Privileges"
        )}
      </Divider>

      <Space wrap>
        <Form.Item
          name="privileges"
          //    valuePropName="checked"
          // initialValue={editData?.privileges || defaultOptions}
        >
          <Checkbox.Group options={privileges.privileges} onChange={onChange} />
        </Form.Item>
      </Space>

      <Divider></Divider>
      <br></br>
      <Form.Item
        wrapperCol={{
          offset: 16,
          span: 6,
        }}
      >
        <Space size={20}>
          <Button onClick={()=>handleCancel(form)}>Cancel</Button>
          {editData ? (
            <Button type="primary" htmlType="submit" loading={loadings}>
              Confirm
            </Button>
          ) : (
            <Button type="primary" htmlType="submit" loading={loadings}>
              Submit
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};
export default TeamForm;
