import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Table,
  Tooltip
} from "antd";
import { useEffect, useState } from "react";

import moment from "moment";
import useSalesStore from "stores/salesStore";
import useServiceDueProductStore from "stores/serviceDueStore";

const ServiceTable = ({ editHandler, createAnnualServiceJob, currentUser }) => {
  var serviceDueProducts = useServiceDueProductStore(
    (state) => state.serviceDueProducts
  ).filter((product) => product.maintenance_interval != 0 && product.is_active);
  const sales = useSalesStore((state) => state.sales);

  const [filteredData, setFilteredData] = useState(false);

  const [currentQuery, setCurrentQuery] = useState("");

  const fuzzyFilter = (query) => {
    console.log(query);
    setCurrentQuery(query);

    var get_bigrams, j, len, obj, relevance, results, string_similarity;

    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
      console.log({ str1, str2 });

      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };

    if (query.length >= 2) {
      results = [];

      for (j = 0, len = serviceDueProducts.length; j < len; j++) {
        var client = serviceDueProducts[j];
        relevance = string_similarity(query, client?.name || "");
        obj = {
          ...client,
          relevance: relevance,
        };
        results.push(obj);
      }

      var sortedResults = results.filter((result) => result.relevance > 0.2);

      sortedResults = sortedResults
        .sort((a, b) => a.relevance - b.relevance)
        .reverse();

      console.log({ sortedResults });

      serviceDueProducts = [...sortedResults];

      setFilteredData(sortedResults);
      console.log(results);
    }
  };

  useEffect(() => {
    if (filteredData.length) {
      fuzzyFilter(currentQuery);
    }
  }, [serviceDueProducts]);

  const columns = [
    {
      title: "Client",

      render: (data) => data.name,
      key: "2",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                // confirm();

                fuzzyFilter(selectedKeys[0]);
              }}
              onBlur={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                fuzzyFilter(selectedKeys[0]);
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });

                setFilteredData(false);
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
          .includes(value.replace(/[^a-zA-Z]/g, "").toLowerCase());
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "4",
      filters: [
        { text: "VIP", value: "VIP" },
        { text: "Ordinary", value: "Ordinary" },
        { text: "Blacklisted", value: "Blacklisted" },
      ],

      onFilter: (value, record) => record?.status.includes(value),
    },
    {
      title: "Active",

      render: (data) => (data.is_active ? "Yes" : "No"),
      key: "1",
    },

    {
      title: "Products",
      key: "2",
      render: (data) => data.products?.length,
    },

    {
      title: "Last Maintenance",
      dataIndex: "last_maintenance_date",
      render: (record) => 
        record ? moment.utc(record).format("DD-MM-YYYY") : "",
      key: "6",
    },
    {
      title: "Next Maintenance ",
      dataIndex: "next_maintenance_date",
      key: "3",
      render: (record) => moment.utc(record).format("DD-MM-YYYY"),
    },

    {
      title: "Active",

      render: (data) => (data.is_active ? "Yes" : "No"),
      key: "1",
    },

    {
      title: "Distance",
      dataIndex: "distance",
      key: "5",
      defaultSortOrder: "ascend",

      render: (record) => Math.trunc(record ? record / 1000 : "") + "KM",
    },

    {
      title: "Location",

      key: "5",
      defaultSortOrder: "ascend",

      render: (record) => record?.location_keywords[0],
    },

    {
      title: "Action",
      render: (record) => (
        <Tooltip title={"Create Maintenance Task"}>
          {/* <PlusCircleOutlined hidden={(!record.products?.length) || (!currentUser.user.privileges.includes('task_creation'))} onClick={() => createAnnualServiceJob(record,record.products)}/  >
          
          */}

          <PlusCircleOutlined
            hidden={!currentUser.user.privileges.includes("task_creation")}
            onClick={() => createAnnualServiceJob(record, record.products)}
          />
        </Tooltip>
      ),
      key: "1",
      width: "20%",
    },
  ];

  return (
    <>
      <Table
        key={sales?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={false}
        // }}
        dataSource={filteredData ? filteredData : serviceDueProducts}
      />
    </>
  );
};

export default ServiceTable;
