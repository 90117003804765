import { message } from "antd";
import axios from "axios/axios";
import useCategoryStore from "stores/categoryStore";
import authHeader from "utils/services/auth-header";

const CategoryApi = () => {
    const {
        addCategoryToStore,
        setCategoriesToStore,
        updateCategoryToStore,
    } = useCategoryStore((state) => ({
        addCategoryToStore: state.addCategory,
       setCategoriesToStore: state.setCategories,
        updateCategoryToStore: state.updateCategory,

    }));

   
    const addCategory = async (data) => {
 
      

        return new Promise((resolve, reject) => {
            axios
                .post("/product/category", data, {
                    headers: authHeader(),
                })
                .then((resp) => {
            
                    addCategoryToStore(resp.data.category);
                    resolve(resp.data.message);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error.response.data.message);
                });
        });
 

    };

    const getCategories = async () => {


      

            return new Promise((resolve, reject) => {

                axios.get("/product/categories", { headers: authHeader() })
                    .then((response) => {
                        setCategoriesToStore(response.data.categories);
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err);
                        message.error(err.response.data.message)
                        reject();
                    });
            });

     

    };

    
    const updateCategory = async (data) => {

        return new Promise((resolve, reject) => {
            axios
                .put("/product/category", data, { headers: authHeader() })
                .then((resp) => {

                    updateCategoryToStore(resp.data.category)
              
          
                    resolve(resp.data.message);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error.response.data.message);
                });
        });

    };

    return { addCategory, getCategories, updateCategory, };
};

export default CategoryApi;
