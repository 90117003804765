import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import useTaxStore from "stores/taxStore";
import taxApi from "utils/composable/taxApi";
import "./taxTable.css";
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const Taxtable = ({ currentUser }) => {
  const { getTaxes, addTax, deleteTax } = taxApi();
  const storeTaxes = useTaxStore((state) => state.taxes);
  const [taxList, setTaxlist] = useState([]);
  const [tableLoading, setTableLoading] = useState("");
  useEffect(() => {
    setTableLoading(true);

    getTaxes()
      .then(() => {
        setTableLoading(false);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, []);

  useEffect(() => {
    const taxList = storeTaxes.map((tax, index) => {
      return {
        key: index,
        title: tax.title,
        percentage: tax.percentage,
        _id: tax._id,
      };
    });

    setTaxlist(taxList);
  }, [storeTaxes]);

  const [dataSource, setDataSource] = useState([
    {
      key: "0",
      title: "Edward King 0",
      percenatge: "32",
    },
    {
      key: "1",
      title: "Edward King 1",
      percentage: "32",
    },
  ]);
  const [count, setCount] = useState(2);
  const [title, setTitle] = useState("");
  const [percentage, setPercenatge] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteId, setCurrentDeleteId] = useState("");

  const handleDelete = (key, _id) => {
    console.log({ key, _id });

    console.log(storeTaxes[key + 1]);

    setSubmitLoading(true);
    deleteTax(_id)
      .then(() => {
        const newData = taxList.filter((item) => item.key !== key);
        setTaxlist(newData);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const defaultColumns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (value, row, index) => {
        return value ? (
          value
        ) : (
          <Input
            type={"text"}
            placeholder={"Tax Title"}
            onChange={(e) => setTitle(e.target.value)}
          />
        );
      }, // just an example
    },

    {
      title: "Percentage",
      dataIndex: "percentage",
      render: (value, row, index) => {
        return value ? (
          value
        ) : (
          <Input
            style={{ width: "20rem" }}
            placeholder={"Tax Percentage"}
            type={"number"}
            onChange={(e) => setPercenatge(e.target.value)}
            required
          />
        );
      }, // just an example
    },

    {
      title: "Operation",
      dataIndex: "title",
      render: (value, row, index) => {
        return value ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(row.key, row._id)}
          >
            <DeleteOutlined
              hidden={!currentUser.privileges.includes("payment_management")}
              loading={submitLoading && deleteId == row._id}
              onClick={() => setCurrentDeleteId(row._id)}
            />
          </Popconfirm>
        ) : (
          <Space>
            <Button onClick={() => handleCancel(value, row, index)}>
              Cancel
            </Button>

            <Button
              loading={submitLoading}
              onClick={() => handleSave(value, row, index)}
            >
              Submit
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleAdd = () => {
    setTaxlist([...taxList, {}]);
    setCount(count + 1);
  };

  const handleCancel = () => {
    taxList.pop();

    setTaxlist([...taxList]);
    setCount(count - 1);
  };

  const handleSave = (value, row, indexog) => {
    console.log(value, row.key, indexog);

    setSubmitLoading(true);

    // newData[index] = { title: title, percentage: percentage, key: count + 1 }

    addTax({ title: title, percentage: parseFloat(percentage) })
      .then((newTax) => {
        setSubmitLoading(false);

        console.log("Resolved");

        var newData = [...taxList];
        var index = newData.findIndex((item) => row.key === item.key);

        console.log({ index });

        console.log(newData[index]);

        newData[index] = {
          title: title,
          percentage: percentage,
          key: count,
          _id: newTax._id,
        };
        setTaxlist(newData);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const components = {
    body: {
      row: EditableRow,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Divider>
        {" "}
        <Button
          disabled={!currentUser.privileges.includes("payment_management")}
          onClick={handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          New Tax
        </Button>
      </Divider>
      <Table
        loading={tableLoading}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={taxList}
        columns={columns}
      />
    </div>
  );
};
export default Taxtable;
